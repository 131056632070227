import PlayerBet from "screens/EOSPowerball/types/PlayerBet"
import Market from "screens/EOSPowerball/types/Market"
import BetHistoryDetails from "screens/EOSPowerball/types/BetHistoryDetails"
import TimerProgress from "screens/EOSPowerball/types/TimerProgress"
import MarketConfig, {DefaultMarket} from "screens/EOSPowerball/types/MarketConfig"
import {
  EOS_ONE,
  EOS_TWO,
  EOS_THREE,
  EOS_FOUR,
  EOS_FIVE,
} from "screens/EOSPowerball/constant"
import {
  POWERBALL_ODD_EVEN_LOCKS,
  POWERBALL_UNDER_OVER_LOCKS,
  NORMALBALL_ODD_EVEN_LOCKS,
  NORMALBALL_UNDER_OVER_LOCKS,
  SECTION_LOCKS,
  POWERBALL_ODD_EVEN_REMOVE,
  POWERBALL_UNDER_OVER_REMOVE,
  NORMALBALL_ODD_EVEN_REMOVE,
  NORMALBALL_UNDER_OVER_REMOVE,
  SECTION_REMOVE,
} from "screens/EOSPowerball/config"

export const getMaxTimer = (code: string) => {
  switch (code) {
    case EOS_ONE:
      return 60
    case EOS_TWO:
      return 120
    case EOS_THREE:
      return 180
    case EOS_FOUR:
      return 240
    case EOS_FIVE:
      return 300
    default:
      return 300
  }
}

export const getTimerProgress = (
  timer: number,
  code: string,
  maxRotation: number,
): TimerProgress => {
  const time = `${timer}`
  let min = 0
  let sec = 0
  if (time.length > 2) {
    min = parseInt(time.slice(0, 1))
    sec = parseInt(time.slice(1, 3))
  } else if (time.length > 1 && time.length < 2) {
    sec = parseInt(time.slice(0, 2))
  } else {
    sec = parseInt(time)
  }

  const maxTime = getMaxTimer(code)
  const totalSec = min * 60 + sec
  const percent = Math.abs((totalSec / maxTime) * 100)
  const timerRotate = 32 - percent * 0.01 * maxRotation
  const waterRotate = 0

  return {
    min,
    sec,
    percent,
    totalSec,
    timerRotate,
    waterRotate,
  }
}

export const handleParseMarketConfig = (markets: Market[]): MarketConfig => {
  const result = DefaultMarket
  if (markets)
    markets.forEach(market => {
      const odds = market?.odds
      odds.forEach(odd => {
        result[odd.code] = {
          market_code: market.code,
          odds_code: odd.code,
          odds_value: Number(odd.value),
          hangul_name: odd?.hangul_name,
        }
      })
    })
  return result
}

export const handleCheckCode = (
  activeBet: PlayerBet[],
  oddCode: string,
): boolean => {
  return activeBet.find(bet => bet.odds_code === oddCode)?.odds_code !== undefined
}

export const handleCheckLockedMarkets = (code: string): string[] => {
  switch (code) {
    case "powerball_odd":
    case "powerball_even":
      return POWERBALL_ODD_EVEN_LOCKS
    case "powerball_under":
    case "powerball_over":
      return POWERBALL_UNDER_OVER_LOCKS
    case "normal_ball_odd":
    case "normal_ball_even":
      return NORMALBALL_ODD_EVEN_LOCKS
    case "normal_ball_under":
    case "normal_ball_over":
      return NORMALBALL_UNDER_OVER_LOCKS
    case "by_section_large_81_130":
    case "by_section_medium_65_80":
    case "by_section_small_15_64":
      return SECTION_LOCKS
    default:
      return []
  }
}

export const handleCheckRemoveMarkets = (code: string): string[] => {
  switch (code) {
    case "powerball_odd":
    case "powerball_even":
      return POWERBALL_ODD_EVEN_REMOVE.filter(item => item !== code)
    case "powerball_under":
    case "powerball_over":
      return POWERBALL_UNDER_OVER_REMOVE.filter(item => item !== code)
    case "normal_ball_odd":
    case "normal_ball_even":
      return NORMALBALL_ODD_EVEN_REMOVE.filter(item => item !== code)
    case "normal_ball_under":
    case "normal_ball_over":
      return NORMALBALL_UNDER_OVER_REMOVE.filter(item => item !== code)
    case "by_section_large_81_130":
    case "by_section_medium_65_80":
    case "by_section_small_15_64":
      return SECTION_REMOVE.filter(item => item !== code)
    default:
      return []
  }
}

export const getHangulName = (code: string): string => {
  switch (code) {
    case "powerball_odd":
      return "파워볼 홀"
    case "powerball_even":
      return "파워볼 짝"
    case "powerball_under":
      return "파워볼 언더"
    case "powerball_over":
      return "파워볼 오버"
    case "normal_ball_odd":
      return "일반볼 홀"
    case "normal_ball_even":
      return "일반볼 짝"
    case "normal_ball_under":
      return "일반볼 언더"
    case "normal_ball_over":
      return "일반볼 오버"
    case "by_section_large_81_130":
      return "구간별 대"
    case "by_section_medium_65_80":
      return "구간별 중"
    case "by_section_small_15_64":
      return "구간별 소"
    default:
      return ""
  }
}

export const handleGetPlayerBetLabel = (bets: PlayerBet[]): string[] => {
  return bets?.map(bet => getHangulName(bet.odds_code))
}

export const handleGetPlayerBetLabelByBetHistory = (
  bets: BetHistoryDetails[],
): string[] => {
  return bets?.map(bet => getHangulName(bet?.odds?.code))
}

export const getBetOdds = (bets: PlayerBet[]): number => {
  if (bets?.length > 1) {
    return 3.6
  } else if (bets && bets !== null && bets?.length > 0) {
    const odds = bets?.map(bet => bet?.odds_value)
    return odds.reduce((a, b) => a * b, 1)
  } else {
    return 0
  }
}

export const computeWinningAmount = (amount: number, bets: PlayerBet[]): number => {
  const oddsValue = Math.trunc(getBetOdds(bets) * 100) / 100
  if (oddsValue) {
    return amount * oddsValue
  } else return 0
}

const handleZeroPadDecimal = (value: string) => {
  const length = value.length

  if (length === 3) {
    return `${value}0`
  } else {
    return value
  }
}

export const handleParseToTwoDecimal = (value: number) => {
  const stringVal = String(value)
  const formattedValue = handleZeroPadDecimal(stringVal)
  return formattedValue.substring(0, 4)
}

export const handleGetStatusLabel = (value: string) => {
  switch (value) {
    case "TMP_WIN":
    case "WIN":
      return "당첨"
    case "TMP_LOSE":
    case "LOSE":
      return "미당첨"
    case "TMP_CANCEL":
      return "적중특례"
    default:
      return ""
  }
}

export const handleGetBallType = (index: number): string => {
  switch (index) {
    case 0:
      return "red"
    case 1:
      return "green"
    case 2:
      return "orange"
    case 3:
      return "blue"
    default:
      return "orange"
  }
}

export const getCodeURL = (code: string) => {
  switch (code) {
    case EOS_ONE:
      return "https://ntry.com/stats/eos_powerball/date.php?game_type=1"
    case EOS_TWO:
      return "https://ntry.com/stats/eos_powerball/date.php?game_type=2"
    case EOS_THREE:
      return "https://ntry.com/stats/eos_powerball/date.php?game_type=3"
    case EOS_FOUR:
      return "https://ntry.com/stats/eos_powerball/date.php?game_type=4"
    case EOS_FIVE:
      return "https://ntry.com/stats/eos_powerball/date.php?game_type=5"
    default:
      return "https://ntry.com/stats/eos_powerball/date.php"
  }
}
