import React, {CSSProperties, FC, useContext} from "react"
import cx from "classnames"
import shallow from "zustand/shallow"
import {HOME, MY_PAGE, NAV_HOME_SELECTED_PAGE} from "screens/NavHome/utils/config"
import useWalletStore from "components/Wallet/hooks/useWalletStore"
import {useUserStore} from "stores/userStore"
import s from "screens/NavHome/components/Logo/logo.module.scss"
import {LogoIcon} from "../../../../components/SideBarButton/icons/LogoIcon"
import {ThemeContext} from "context/ThemeContext"

interface iLogo {
  className?: string
  handleClick: (label: string) => void
  selectedPage?: string
}
interface CSSCustomProperties extends CSSProperties {
  "--headerHoverColor"?: string
}
const Logo: FC<iLogo> = ({className, handleClick, selectedPage}): JSX.Element => {
  const {theme} = useContext(ThemeContext)
  const custom_colors: CSSCustomProperties = {
    "--headerHoverColor": theme?.colors.headerHoverColor,
  }

  const authToken = useUserStore(x => x.authToken, shallow)
  const isOpen = useWalletStore(store => store.isOpen, shallow)

  const backToHome = () => {
    localStorage.setItem(NAV_HOME_SELECTED_PAGE, HOME)
    handleClick(HOME)
  }

  return (
    <div
      className={cx(s.homeBGLogo, className, {
        [s.walletOpen]: isOpen,
        [s.logout]: !authToken,
        [s.slideHomeBGLogo]: authToken && selectedPage !== MY_PAGE,
      })}
      onClick={() => backToHome()}
      style={custom_colors}
    >
      {/* <img src="/assets/img/logo-white-new.svg" /> */}
      <LogoIcon />
    </div>
  )
}

export default Logo
