import create from "zustand"
import {ShowConfirmModal} from "hooks/useConfirmModal"
export interface iRegisterModalStore {
  isOpen: boolean
  isOtpSent: boolean
  isEnabled: boolean
  close: () => void
  showConfirm: (value: ShowConfirmModal) => void
  setIsOpen: (value: boolean) => void
  setIsOtpSent: (value: boolean) => void
  setIsEnabled: (value: boolean) => void
  setClose: (value: () => void) => void
  setShowConfirm: (value: (value: ShowConfirmModal) => void) => void
  handleConfirmClose: (callback: () => void) => void
  handleOnExit: (callback: () => void) => void
  handleCheckOpen: (callback: () => void) => void
}

const useRegisterModalStore = create<iRegisterModalStore>((set, get) => ({
  isOpen: false,
  showConfirm: null,
  close: null,
  isOtpSent: false,
  isEnabled: true,
  setIsOpen: isOpen => set({isOpen}),
  setIsOtpSent: isOtpSent => set({isOtpSent}),
  setShowConfirm: showConfirm => set({showConfirm}),
  setIsEnabled: isEnabled => set({isEnabled}),
  setClose: close => set({close}),
  handleConfirmClose: (callback: () => void) => {
    const confirm = get().showConfirm
    confirm({
      message: "저장되지 않은 변경 사항이 있습니다.\n계속하시겠습니까?",
      onConfirm: () => callback(),
    })
  },
  handleOnExit: (callback: () => void) => {
    const isOtpSent = get().isOtpSent
    const handleConfirmClose = get().handleConfirmClose
    if (isOtpSent) {
      handleConfirmClose(() => callback())
    } else {
      callback()
    }
  },
  handleCheckOpen: (callback: () => void) => {
    const isOpen = get().isOpen
    const close = get().close
    const handleOnExit = get().handleOnExit
    if (isOpen) {
      handleOnExit(() => {
        close()
        setTimeout(() => callback(), 200)
      })
    } else {
      callback()
    }
  },
}))

export default useRegisterModalStore
