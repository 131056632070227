// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from "react"
/**
 * Styles
 */
import s from "./loaderellipsis.module.css"

import cx from 'classnames'

function LoaderEllipsis({color}: {color?: string}): JSX.Element {
  return (
    <div className={cx(s.ldsEllipsis, s[color])}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}

export default LoaderEllipsis
