import React, {DetailedHTMLProps, HTMLAttributes} from "react"

export type IconProps = {
  fill?: string
  fillTo?: string
  width?: number
  height?: number
} & DetailedHTMLProps<HTMLAttributes<SVGSVGElement>, SVGSVGElement>

export const AccountChange: React.FC<IconProps> = ({
  fill = "#747474",
  ...props
}: IconProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20.001"
    height="16"
    viewBox="0 0 20.001 16"
    {...props}
  >
    <path
      id="money-check-edit-alt-solid"
      d="M6.4,3.671l.128.128,2.24-2.244L7.462.25A.753.753,0,0,0,6.4.25L5.219,1.433a.75.75,0,0,0,0,1.056ZM19,5.031H13.657L17.415,8.79A2,2,0,0,1,18,10.2v2.327a.5.5,0,0,1-.5.5H8.251a.25.25,0,0,1-.25-.25v-.5a.25.25,0,0,1,.25-.25h5.094l-2-2H8.251A.25.25,0,0,1,8,9.782v-.5a.25.25,0,0,1,.25-.25h2.094l-4-4H1a1,1,0,0,0-1,1v9a1,1,0,0,0,1,1H19a1,1,0,0,0,1-1v-9A1,1,0,0,0,19,5.031Zm-14.5,8v.5a.25.25,0,0,1-.25.25h-.5a.25.25,0,0,1-.25-.25v-.509a1.789,1.789,0,0,1-.98-.355.25.25,0,0,1-.018-.379l.367-.351a.257.257,0,0,1,.317-.023.753.753,0,0,0,.4.117h.878a.393.393,0,0,0,.369-.412.408.408,0,0,0-.274-.4L3.153,10.8a1.414,1.414,0,0,1-.987-1.356A1.392,1.392,0,0,1,3.5,8.035v-.5a.25.25,0,0,1,.25-.25h.5a.25.25,0,0,1,.25.25v.509a1.787,1.787,0,0,1,.98.355.25.25,0,0,1,.018.379l-.367.351a.257.257,0,0,1-.317.023.748.748,0,0,0-.4-.117H3.535a.393.393,0,0,0-.369.412.408.408,0,0,0,.274.4l1.406.422a1.414,1.414,0,0,1,.987,1.356A1.391,1.391,0,0,1,4.5,13.028Zm9.965-1.289a1,1,0,0,0,.707.293H16.5a.5.5,0,0,0,.5-.5V10.2a1,1,0,0,0-.293-.707L9.475,2.265,7.234,4.505Z"
      transform="translate(0 -0.032)"
      fill={fill}
    />
  </svg>
)

export const Bank: React.FC<IconProps> = ({fill = "#747474", ...props}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="16"
    viewBox="0 0 17 16"
    {...props}
  >
    <path
      id="university-solid"
      d="M33,35.428V36a.285.285,0,0,1-.283.286h-.85v.429a.427.427,0,0,1-.425.429H17.558a.427.427,0,0,1-.425-.429v-.429h-.85A.285.285,0,0,1,16,36v-.571a.286.286,0,0,1,.175-.264l8.217-3.143a.281.281,0,0,1,.217,0l8.217,3.143A.286.286,0,0,1,33,35.428Zm-.85,10.857H16.85a.854.854,0,0,0-.85.857v.571a.285.285,0,0,0,.283.286H32.717A.285.285,0,0,0,33,47.714v-.571A.854.854,0,0,0,32.15,46.285ZM18.833,37.714v6.857H17.558a.427.427,0,0,0-.425.429v.714H31.867V45a.427.427,0,0,0-.425-.429H30.167V37.714H27.9v6.857H25.633V37.714H23.367v6.857H21.1V37.714Z"
      transform="translate(-16 -32)"
      fill={fill}
    />
  </svg>
)

export const BettingHistory: React.FC<IconProps> = ({
  fill = "#747474",
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="16"
    viewBox="0 0 12 16"
    {...props}
  >
    <path
      id="file-alt-solid"
      d="M7,4.25V0H.75A.748.748,0,0,0,0,.75v14.5A.748.748,0,0,0,.75,16h10.5a.748.748,0,0,0,.75-.75V5H7.75A.752.752,0,0,1,7,4.25Zm2,7.375A.376.376,0,0,1,8.625,12H3.375A.376.376,0,0,1,3,11.625v-.25A.376.376,0,0,1,3.375,11h5.25A.376.376,0,0,1,9,11.375Zm0-2A.376.376,0,0,1,8.625,10H3.375A.376.376,0,0,1,3,9.625v-.25A.376.376,0,0,1,3.375,9h5.25A.376.376,0,0,1,9,9.375Zm0-2.25v.25A.376.376,0,0,1,8.625,8H3.375A.376.376,0,0,1,3,7.625v-.25A.376.376,0,0,1,3.375,7h5.25A.376.376,0,0,1,9,7.375Zm3-3.566V4H8V0h.191a.749.749,0,0,1,.531.219l3.059,3.063A.748.748,0,0,1,12,3.809Z"
      fill={fill}
    />
  </svg>
)

export const CellphoneNumber: React.FC<IconProps> = ({
  fill = "#747474",
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="16"
    viewBox="0 0 10 16"
    {...props}
  >
    <path
      id="mobile-android-solid"
      d="M8.5,0h-7A1.5,1.5,0,0,0,0,1.5v13A1.5,1.5,0,0,0,1.5,16h7A1.5,1.5,0,0,0,10,14.5V1.5A1.5,1.5,0,0,0,8.5,0Zm-2,14.125a.376.376,0,0,1-.375.375H3.875a.376.376,0,0,1-.375-.375v-.25a.376.376,0,0,1,.375-.375h2.25a.376.376,0,0,1,.375.375Z"
      fill={fill}
    />
  </svg>
)

export const CustomerSupport: React.FC<IconProps> = ({
  fill = "#747474",
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21.333"
    height="16"
    viewBox="0 0 21.333 16"
    {...props}
  >
    <path
      id="concierge-bell-solid"
      d="M12,66.773V66h.667a.666.666,0,0,0,.667-.667v-.667A.666.666,0,0,0,12.667,64h-4A.666.666,0,0,0,8,64.667v.667A.666.666,0,0,0,8.667,66h.667v.773a9.328,9.328,0,0,0-8,9.227H20A9.328,9.328,0,0,0,12,66.773Zm8.667,10.561h-20A.666.666,0,0,0,0,78v1.333A.666.666,0,0,0,.667,80h20a.666.666,0,0,0,.667-.667V78A.666.666,0,0,0,20.667,77.333Z"
      transform="translate(0 -64)"
      fill={fill}
    />
  </svg>
)

export const Mail: React.FC<IconProps> = ({fill = "#747474", ...props}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20.533"
    height="14"
    viewBox="0 0 20.533 14"
    {...props}
  >
    <path
      id="Icon_ionic-md-mail"
      data-name="Icon ionic-md-mail"
      d="M20.828,5.625H4.206a1.845,1.845,0,0,0-1.956,1.7V17.928a1.845,1.845,0,0,0,1.956,1.7H20.828a1.845,1.845,0,0,0,1.956-1.7V7.322A1.845,1.845,0,0,0,20.828,5.625Zm-.244,3.606L12.517,13.9,4.45,9.231v-1.7L12.517,12.2l8.067-4.667Z"
      transform="translate(-2.25 -5.625)"
      fill={fill}
    />
  </svg>
)

export const RechargeHistory: React.FC<IconProps> = ({
  fill = "#747474",
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="16"
    viewBox="0 0 12 16"
    {...props}
  >
    <path
      id="file-invoice-dollar-solid"
      d="M11.781,3.281,8.722.219A.749.749,0,0,0,8.191,0H8V4h4V3.809A.748.748,0,0,0,11.781,3.281ZM7,4.25V0H.75A.748.748,0,0,0,0,.75v14.5A.748.748,0,0,0,.75,16h10.5a.748.748,0,0,0,.75-.75V5H7.75A.752.752,0,0,1,7,4.25Zm-5-2A.25.25,0,0,1,2.25,2h2.5A.25.25,0,0,1,5,2.25v.5A.25.25,0,0,1,4.75,3H2.25A.25.25,0,0,1,2,2.75Zm0,2.5v-.5A.25.25,0,0,1,2.25,4h2.5A.25.25,0,0,1,5,4.25v.5A.25.25,0,0,1,4.75,5H2.25A.25.25,0,0,1,2,4.75ZM6.5,13v.754a.25.25,0,0,1-.25.25h-.5a.25.25,0,0,1-.25-.25v-.759a1.789,1.789,0,0,1-.98-.355.25.25,0,0,1-.018-.379l.367-.35a.256.256,0,0,1,.317-.023.752.752,0,0,0,.4.116h.878a.393.393,0,0,0,.369-.412.408.408,0,0,0-.274-.4l-1.406-.422a1.414,1.414,0,0,1-.987-1.356A1.392,1.392,0,0,1,5.5,8V7.25A.25.25,0,0,1,5.75,7h.5a.25.25,0,0,1,.25.25v.759a1.787,1.787,0,0,1,.98.355.25.25,0,0,1,.018.379l-.367.35a.256.256,0,0,1-.317.023A.75.75,0,0,0,6.413,9H5.535a.393.393,0,0,0-.369.412.408.408,0,0,0,.274.4l1.406.422a1.414,1.414,0,0,1,.987,1.356A1.391,1.391,0,0,1,6.5,13Z"
      fill={fill}
    />
  </svg>
)

export const MyInformation: React.FC<IconProps> = ({fill = "#747474", ...props}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    {...props}
  >
    <g
      id="Group_28596"
      data-name="Group 28596"
      transform="translate(-179.18 -592.18)"
    >
      <path
        id="user-circle-solid"
        d="M8,8a8,8,0,1,0,8,8A8,8,0,0,0,8,8Zm0,3.1a2.839,2.839,0,1,1-2.839,2.839A2.839,2.839,0,0,1,8,11.1Zm0,11.1a6.182,6.182,0,0,1-4.726-2.2,3.6,3.6,0,0,1,3.177-1.929.789.789,0,0,1,.229.035A4.271,4.271,0,0,0,8,18.323,4.255,4.255,0,0,0,9.319,18.1a.789.789,0,0,1,.229-.035,3.6,3.6,0,0,1,3.177,1.929A6.182,6.182,0,0,1,8,22.194Z"
        transform="translate(179.18 584.18)"
        fill={fill}
      />
    </g>
  </svg>
)

export const PasswordChange: React.FC<IconProps> = ({
  fill = "#747474",
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="16"
    viewBox="0 0 14 16"
    {...props}
  >
    <path
      id="lock-alt-solid"
      d="M12.5,7h-.75V4.75a4.75,4.75,0,1,0-9.5,0V7H1.5A1.5,1.5,0,0,0,0,8.5v6A1.5,1.5,0,0,0,1.5,16h11A1.5,1.5,0,0,0,14,14.5v-6A1.5,1.5,0,0,0,12.5,7ZM8.25,12.25a1.25,1.25,0,1,1-2.5,0v-1.5a1.25,1.25,0,1,1,2.5,0ZM9.25,7H4.75V4.75a2.25,2.25,0,1,1,4.5,0Z"
      fill={fill}
    />
  </svg>
)

export const SelfRestrict: React.FC<IconProps> = ({fill = "#747474", ...props}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="16"
    viewBox="0 0 20 16"
    {...props}
  >
    <path
      id="user-alt-slash-solid"
      d="M19.835,14.316,12.2,8.416A4.488,4.488,0,1,0,5.685,3.381L1.45.106a.5.5,0,0,0-.7.088L.135.981a.5.5,0,0,0,.087.7L18.61,15.891a.5.5,0,0,0,.7-.087l.612-.791a.5.5,0,0,0-.091-.7ZM6.229,10a4.2,4.2,0,0,0-4.2,4.2v.3a1.5,1.5,0,0,0,1.5,1.5H15.472L7.71,10Z"
      transform="translate(-0.029)"
      fill={fill}
    />
  </svg>
)

export const SmsNotification: React.FC<IconProps> = ({
  fill = "#747474",
  ...props
}): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="16"
    viewBox="0 0 20 16"
    {...props}
  >
    <path
      id="bell-on-solid"
      d="M3.5,6a.75.75,0,0,0-.75-.75h-2a.75.75,0,1,0,0,1.5h2A.75.75,0,0,0,3.5,6ZM1.614,2.024l1.732,1a.75.75,0,1,0,.75-1.3l-1.732-1a.75.75,0,1,0-.75,1.3Zm14.665,1.1a.744.744,0,0,0,.375-.1l1.732-1a.75.75,0,1,0-.75-1.3l-1.732,1a.75.75,0,0,0,.375,1.4ZM10,16a2,2,0,0,0,2-2H8A2,2,0,0,0,10,16ZM19.25,5.25h-2a.75.75,0,0,0,0,1.5h2a.75.75,0,0,0,0-1.5ZM15,6.5a4.935,4.935,0,0,0-4-4.849V1A1,1,0,0,0,9,1v.651A4.935,4.935,0,0,0,5,6.5c0,3.2-1.129,4.173-1.733,4.821A.978.978,0,0,0,3,12a1,1,0,0,0,1,1H16a1,1,0,0,0,1-1,.976.976,0,0,0-.269-.679C16.127,10.673,15,9.7,15,6.5Z"
      fill={fill}
    />
  </svg>
)

export const Arrow = ({fill = "#ffffff", ...props}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12.115"
    height="12.419"
    viewBox="0 0 12.115 12.419"
    {...props}
  >
    <g
      id="Group_19765"
      data-name="Group 19765"
      transform="translate(11.365 11.365) rotate(180)"
    >
      <line
        id="Line_238"
        data-name="Line 238"
        x2="10.31"
        transform="translate(0 5.155)"
        fill="none"
        stroke={fill}
        strokeLinecap="round"
        strokeWidth="1.5"
      />
      <line
        id="Line_239"
        data-name="Line 239"
        x2="4.124"
        y2="5.155"
        transform="translate(6.186)"
        fill="none"
        stroke={fill}
        strokeLinecap="round"
        strokeWidth="1.5"
      />
      <line
        id="Line_240"
        data-name="Line 240"
        y1="5.155"
        x2="4.124"
        transform="translate(6.186 5.155)"
        fill="none"
        stroke={fill}
        strokeLinecap="round"
        strokeWidth="1.5"
      />
    </g>
  </svg>
)

export const DepositWithdraw: React.FC<IconProps> = ({
  fill = "#747474",
  ...props
}): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19.765"
    height="16"
    viewBox="0 0 19.765 16"
    {...props}
  >
    <path
      id="DepWid"
      d="M16.586,0h-.21V8.142a.556.556,0,0,0,.555.555h1.115a.556.556,0,0,1,.392.949l-3.928,3.929a.557.557,0,0,1-.786,0L9.8,9.646a.556.556,0,0,1,.392-.949H11.3a.555.555,0,0,0,.555-.555V0H3.178A3.178,3.178,0,0,0,0,3.178v9.643A3.178,3.178,0,0,0,3.178,16h.21V6.959A.556.556,0,0,0,2.833,6.4H1.718a.555.555,0,0,1-.392-.948L5.255,1.526a.555.555,0,0,1,.786,0L9.969,5.455a.555.555,0,0,1-.392.948H8.461a.557.557,0,0,0-.555.556V16h8.68a3.178,3.178,0,0,0,3.178-3.178V3.178A3.178,3.178,0,0,0,16.586,0"
      fill={fill}
    />
  </svg>
)

export const SignOut: React.FC<IconProps> = ({
  fill = "#747474",
  ...props
}): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19.493"
    height="14.855"
    viewBox="0 0 19.493 14.855"
    {...props}
  >
    <path
      id="Icon_awesome-sign-out-alt"
      data-name="Icon awesome-sign-out-alt"
      d="M19.223,12.583l-6.5,6.5a.93.93,0,0,1-1.586-.658V14.71H5.879a.926.926,0,0,1-.928-.928V10.069a.926.926,0,0,1,.928-.928h5.26V5.428a.93.93,0,0,1,1.586-.658l6.5,6.5A.936.936,0,0,1,19.223,12.583Zm-11.8,6.3V17.34a.466.466,0,0,0-.464-.464H3.713a1.236,1.236,0,0,1-1.238-1.238V8.212A1.236,1.236,0,0,1,3.713,6.975H6.962a.466.466,0,0,0,.464-.464V4.963A.466.466,0,0,0,6.962,4.5H3.713A3.714,3.714,0,0,0,0,8.212v7.426a3.714,3.714,0,0,0,3.713,3.713H6.962A.466.466,0,0,0,7.426,18.888Z"
      transform="translate(0 -4.498)"
      fill={fill}
    />
  </svg>
)

export const BronzeRibbon: React.FC<IconProps> = ({...props}): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="97.5"
    height="97.5"
    viewBox="0 0 97.5 97.5"
    {...props}
  >
    <defs>
      <linearGradient
        id="a"
        x1={43.517}
        x2={42.516}
        y1={0.5}
        y2={0.5}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#4b341b" />
        <stop offset={0.413} stopColor="#84613c" />
        <stop offset={1} stopColor="#c78e53" />
      </linearGradient>
      <linearGradient
        xlinkHref="#a"
        id="b"
        x1={1126.467}
        x2={1126.248}
        y1={5216.779}
        y2={5216.779}
      />
      <linearGradient
        id="c"
        x1={-1.566}
        x2={-1.566}
        y1={0.003}
        y2={1.05}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#c78e53" />
        <stop offset={0.176} stopColor="#f2cda7" />
        <stop offset={1} stopColor="#f2cda7" />
      </linearGradient>
      <linearGradient
        id="d"
        x1={1.14}
        x2={0.135}
        y1={0.5}
        y2={0.5}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#dba873" />
        <stop offset={0.176} stopColor="#f2cda7" />
        <stop offset={1} stopColor="#ffd6ad" />
      </linearGradient>
    </defs>
    <g
      style={{
        isolation: "isolate",
      }}
    >
      <path
        d="M-27.4 50.37a5.628 5.628 0 0 0-1.871 4.23 6 6 0 0 0 1.871 4.34Z"
        style={{
          fill: "url(#a)",
        }}
        transform="translate(29.271 38.24)"
      />
      <path
        d="M-64.641 19.37a5.63 5.63 0 0 1 4.235-1.87 6 6 0 0 1 4.34 1.871Z"
        style={{
          fill: "url(#b)",
        }}
        transform="translate(153.283 -17.499)"
      />
      <path
        d="M-31.247 17.5h-21.285a6.056 6.056 0 0 0-4.281 1.771l-6.157 6.16 11.9 11.9 17.952-17.961a6.148 6.148 0 0 1 4.068-1.87Z"
        style={{
          fill: "url(#c)",
        }}
        transform="translate(121.763 -17.499)"
      />
      <path
        d="M-53.026 77.46a6.054 6.054 0 0 0-1.774 4.281v23.447a6.048 6.048 0 0 1 1.483-3.645l69.206-69.206-11.9-11.9Z"
        style={{
          fill: "url(#d)",
        }}
        transform="translate(54.8 -12.51)"
      />
    </g>
  </svg>
)
export const SilverRibbon: React.FC<IconProps> = ({...props}): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="97.5"
    height="97.5"
    viewBox="0 0 97.5 97.5"
    {...props}
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="43.517"
        y1="0.5"
        x2="42.516"
        y2="0.5"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0.413" stop-color="#3e4041" />
        <stop offset="1" stop-color="#8c8d90" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-2"
        x1="1126.467"
        y1="5216.779"
        x2="1126.248"
        y2="5216.779"
        href="#linear-gradient"
      />
      <linearGradient
        id="linear-gradient-3"
        x1="-1.566"
        y1="0.003"
        x2="-1.566"
        y2="1.05"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stop-color="#8c8d90" />
        <stop offset="0.227" stop-color="#d5d5d5" />
        <stop offset="1" stop-color="#d5d5d5" />
      </linearGradient>
    </defs>
    <g
      id="Group_33184"
      data-name="Group 33184"
      transform="translate(0.502 0.502)"
      style={{isolation: "isolate"}}
    >
      <path
        id="Path_29788"
        data-name="Path 29788"
        d="M-27.4,50.37A5.628,5.628,0,0,0-29.271,54.6a6,6,0,0,0,1.871,4.34Z"
        transform="translate(29.271 38.056)"
        fill="url(#linear-gradient)"
      />
      <path
        id="Path_29789"
        data-name="Path 29789"
        d="M-64.641,19.37A5.63,5.63,0,0,1-60.406,17.5a6,6,0,0,1,4.34,1.871Z"
        transform="translate(153.064 -17.499)"
        fill="url(#linear-gradient-2)"
      />
      <path
        id="Union_9"
        data-name="Union 9"
        d="M0,90.338V69.1a6.051,6.051,0,0,1,1.775-4.271L58.656,7.916,64.8,1.77A6.046,6.046,0,0,1,69.065,0H92.492a6.171,6.171,0,0,0-4.053,1.871L70.523,19.789l0,0L1.483,88.861A6.072,6.072,0,0,0,0,92.494Z"
        transform="translate(-0.002 -0.002)"
        stroke="rgba(0,0,0,0)"
        stroke-miterlimit="10"
        stroke-width="1"
        fill="url(#linear-gradient-3)"
      />
    </g>
  </svg>
)

export const GoldRibbon: React.FC<IconProps> = ({...props}): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="97.5"
    height="97.5"
    viewBox="0 0 97.5 97.5"
    {...props}
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="1126.467"
        y1="5216.779"
        x2="1126.248"
        y2="5216.779"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stop-color="#f3a424" />
        <stop offset="0.413" stop-color="#ffe156" />
        <stop offset="1" stop-color="#f3a424" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-3"
        x1="-1.566"
        y1="0.003"
        x2="-1.566"
        y2="1.05"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stop-color="#f3a424" />
        <stop offset="0.227" stop-color="#ffe156" />
        <stop offset="1" stop-color="#f3a424" />
      </linearGradient>
    </defs>
    <g
      id="Group_33186"
      data-name="Group 33186"
      transform="translate(0.502 0.502)"
      style={{isolation: "isolate"}}
    >
      <path
        id="Path_29788"
        data-name="Path 29788"
        d="M-27.4,50.37A5.628,5.628,0,0,0-29.271,54.6a6,6,0,0,0,1.871,4.34Z"
        transform="translate(29.271 38.056)"
        fill="url(#linear-gradient)"
      />
      <path
        id="Path_29789"
        data-name="Path 29789"
        d="M-64.641,19.37A5.63,5.63,0,0,1-60.406,17.5a6,6,0,0,1,4.34,1.871Z"
        transform="translate(153.064 -17.499)"
        fill="url(#linear-gradient)"
      />
      <path
        id="Union_9"
        data-name="Union 9"
        d="M0,90.338V69.1a6.051,6.051,0,0,1,1.775-4.271L58.656,7.916,64.8,1.77A6.046,6.046,0,0,1,69.065,0H92.492a6.171,6.171,0,0,0-4.053,1.871L70.523,19.789l0,0L1.483,88.861A6.072,6.072,0,0,0,0,92.494Z"
        transform="translate(-0.002 -0.002)"
        stroke="rgba(0,0,0,0)"
        stroke-miterlimit="10"
        stroke-width="1"
        fill="url(#linear-gradient-3)"
      />
    </g>
  </svg>
)

export const DiamondRibbon: React.FC<IconProps> = ({...props}): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="97.5"
    height="97.5"
    viewBox="0 0 97.5 97.5"
    {...props}
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="1126.467"
        y1="5216.779"
        x2="1126.248"
        y2="5216.779"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stop-color="#447941" />
        <stop offset="0.502" stop-color="#b1ded9" />
        <stop offset="1" stop-color="#5e935d" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-3"
        x1="-1.566"
        y1="0.003"
        x2="-1.566"
        y2="1.05"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stop-color="#447941" />
        <stop offset="0.248" stop-color="#b1ded9" />
        <stop offset="1" stop-color="#5e935d" />
      </linearGradient>
    </defs>
    <g
      id="Group_20229"
      data-name="Group 20229"
      transform="translate(0.502 0.502)"
      style={{isolation: "isolate"}}
    >
      <path
        id="Path_29788"
        data-name="Path 29788"
        d="M-27.4,50.37A5.628,5.628,0,0,0-29.271,54.6a6,6,0,0,0,1.871,4.34Z"
        transform="translate(29.271 38.056)"
        fill="url(#linear-gradient)"
      />
      <path
        id="Path_29789"
        data-name="Path 29789"
        d="M-64.641,19.37A5.63,5.63,0,0,1-60.406,17.5a6,6,0,0,1,4.34,1.871Z"
        transform="translate(153.064 -17.499)"
        fill="url(#linear-gradient)"
      />
      <path
        id="Union_9"
        data-name="Union 9"
        d="M0,90.338V69.1a6.051,6.051,0,0,1,1.775-4.271L58.656,7.916,64.8,1.77A6.046,6.046,0,0,1,69.065,0H92.492a6.171,6.171,0,0,0-4.053,1.871L70.523,19.789l0,0L1.483,88.861A6.072,6.072,0,0,0,0,92.494Z"
        transform="translate(-0.002 -0.002)"
        stroke="rgba(0,0,0,0)"
        stroke-miterlimit="10"
        stroke-width="1"
        fill="url(#linear-gradient-3)"
      />
    </g>
  </svg>
)

export const VVipRibbon: React.FC<IconProps> = ({...props}): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="97.5"
    height="97.5"
    viewBox="0 0 97.5 97.5"
    {...props}
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="1126.467"
        y1="5216.779"
        x2="1126.248"
        y2="5216.779"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stop-color="#6e6c94" />
        <stop offset="0.413" stop-color="#e6dbdc" />
        <stop offset="1" stop-color="#6e6c94" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-3"
        x1="-1.566"
        y1="0.003"
        x2="-1.566"
        y2="1.05"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stop-color="#6e6c94" />
        <stop offset="0.227" stop-color="#e6dbdc" />
        <stop offset="1" stop-color="#6e6c94" />
      </linearGradient>
    </defs>
    <g
      id="Group_33185"
      data-name="Group 33185"
      transform="translate(0.502 0.502)"
      style={{isolation: "isolate"}}
    >
      <path
        id="Path_29788"
        data-name="Path 29788"
        d="M-27.4,50.37A5.628,5.628,0,0,0-29.271,54.6a6,6,0,0,0,1.871,4.34Z"
        transform="translate(29.271 38.056)"
        fill="url(#linear-gradient)"
      />
      <path
        id="Path_29789"
        data-name="Path 29789"
        d="M-64.641,19.37A5.63,5.63,0,0,1-60.406,17.5a6,6,0,0,1,4.34,1.871Z"
        transform="translate(153.064 -17.499)"
        fill="url(#linear-gradient)"
      />
      <path
        id="Union_9"
        data-name="Union 9"
        d="M0,90.338V69.1a6.051,6.051,0,0,1,1.775-4.271L58.656,7.916,64.8,1.77A6.046,6.046,0,0,1,69.065,0H92.492a6.171,6.171,0,0,0-4.053,1.871L70.523,19.789l0,0L1.483,88.861A6.072,6.072,0,0,0,0,92.494Z"
        transform="translate(-0.002 -0.002)"
        stroke="rgba(0,0,0,0)"
        stroke-miterlimit="10"
        stroke-width="1"
        fill="url(#linear-gradient-3)"
      />
    </g>
  </svg>
)

export const Avatar: React.FC<IconProps> = ({...props}): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="36"
    viewBox="0 0 36 36"
    {...props}
  >
    <defs>
      <clipPath id="clip-path">
        <circle
          id="Ellipse_1166"
          data-name="Ellipse 1166"
          cx="18"
          cy="18"
          r="18"
          fill="#00acee"
          stroke="#fff"
          stroke-width="2"
        />
      </clipPath>
    </defs>
    <g id="IconProfile" clip-path="url(#clip-path)">
      <g
        id="Group_15268"
        data-name="Group 15268"
        transform="translate(-6.201 3.921)"
      >
        <g
          id="Group_15254"
          data-name="Group 15254"
          transform="translate(33.433 31.579)"
        >
          <g
            id="Group_15253"
            data-name="Group 15253"
            transform="translate(0.874 0.901)"
          >
            <path
              id="Path_29483"
              data-name="Path 29483"
              d="M342.326,387.982a2.027,2.027,0,0,1-2.114-1.935l-.553-12.562a67.047,67.047,0,0,0-3.044-17.23l-3.785-11.99a2.027,2.027,0,0,1,3.865-1.22l3.785,11.99a71.114,71.114,0,0,1,3.228,18.272l.553,12.562A2.026,2.026,0,0,1,342.326,387.982Z"
              transform="translate(-332.735 -341.627)"
              fill="#ff9395"
            />
          </g>
          <path
            id="Path_29484"
            data-name="Path 29484"
            d="M375.534,631.71a.711.711,0,0,1-.425-.139.126.126,0,0,0-.2.066.845.845,0,0,1-1.4.378.126.126,0,0,0-.208.057.968.968,0,0,1-1.856.017.126.126,0,0,0-.214-.047.847.847,0,0,1-1.479-.564s-.017-.846-.027-1.183a.134.134,0,0,0-.133-.129c-.955,0-1.218-1.235-.894-2.673.381-1.689,1.984-4.616,4.125-4.513,1.784.086,3.4,1.791,3.45,4.211.01.431.011-.353-.016,3.8A.729.729,0,0,1,375.534,631.71Z"
            transform="translate(-362.461 -580.94)"
            fill="#ffdab1"
          />
          <path
            id="Path_29485"
            data-name="Path 29485"
            d="M339.505,368.006a71.979,71.979,0,0,0-3.269-18.5l-3.785-11.991a2.927,2.927,0,0,0-4.052-1.761,3.011,3.011,0,0,0-1.495,3.639l3.748,11.874a66.127,66.127,0,0,1,3,17l.309,7.015a.45.45,0,0,0,.47.43l4.949-.218a.45.45,0,0,0,.43-.47Z"
            transform="translate(-326.758 -335.466)"
            fill="#4c62a1"
          />
          <path
            id="Path_29486"
            data-name="Path 29486"
            d="M384.309,611.443a.346.346,0,0,1-.33.361l-4.258.187a.346.346,0,0,1-.361-.33l-.1-2.227,4.949-.218Z"
            transform="translate(-371.585 -569.191)"
            fill="#4c62a1"
          />
        </g>
        <g id="Group_15256" data-name="Group 15256" transform="translate(0 31.579)">
          <g
            id="Group_15255"
            data-name="Group 15255"
            transform="translate(1.426 0.901)"
          >
            <path
              id="Path_29487"
              data-name="Path 29487"
              d="M109.787,387.982a2.027,2.027,0,0,0,2.114-1.935l.553-12.562a67.051,67.051,0,0,1,3.044-17.23l3.785-11.99a2.027,2.027,0,0,0-3.865-1.22l-3.785,11.99a71.113,71.113,0,0,0-3.228,18.272l-.553,12.562A2.026,2.026,0,0,0,109.787,387.982Z"
              transform="translate(-107.849 -341.627)"
              fill="#ff9395"
            />
          </g>
          <path
            id="Path_29488"
            data-name="Path 29488"
            d="M98.85,631.71a.711.711,0,0,0,.425-.139.126.126,0,0,1,.2.066.845.845,0,0,0,1.4.378.126.126,0,0,1,.208.057.968.968,0,0,0,1.856.017.126.126,0,0,1,.214-.047.848.848,0,0,0,1.479-.564s.017-.846.027-1.183a.134.134,0,0,1,.133-.129c.955,0,1.218-1.235.894-2.673-.381-1.689-1.984-4.616-4.125-4.513-1.784.086-3.4,1.791-3.45,4.211-.01.431-.011-.353.016,3.8A.728.728,0,0,0,98.85,631.71Z"
            transform="translate(-98.095 -580.94)"
            fill="#ffdab1"
          />
          <path
            id="Path_29489"
            data-name="Path 29489"
            d="M103.684,368.006a71.985,71.985,0,0,1,3.269-18.5l3.785-11.991a2.927,2.927,0,0,1,4.052-1.761,3.011,3.011,0,0,1,1.495,3.639l-3.749,11.874a66.135,66.135,0,0,0-3,17l-.309,7.015a.45.45,0,0,1-.47.43l-4.949-.218a.45.45,0,0,1-.43-.47Z"
            transform="translate(-102.603 -335.466)"
            fill="#4c62a1"
          />
          <path
            id="Path_29490"
            data-name="Path 29490"
            d="M105.977,611.8l4.258.187a.346.346,0,0,0,.361-.33l.1-2.227-4.949-.218-.1,2.227A.346.346,0,0,0,105.977,611.8Z"
            transform="translate(-104.543 -569.191)"
            fill="#4c62a1"
          />
        </g>
        <g
          id="Group_15257"
          data-name="Group 15257"
          transform="translate(16.629 25.782)"
        >
          <path
            id="Path_29491"
            data-name="Path 29491"
            d="M224.76,302.277a2.957,2.957,0,0,0,1.062-2.036c0-.589-1.058-.9-2.006-2.484-.582-1.07-2.777-1.942-4.992-1.942s-4.41.873-4.992,1.942c-.948,1.583-2.006,1.9-2.006,2.484a2.958,2.958,0,0,0,1.062,2.036,15.162,15.162,0,0,0,11.872,0Z"
            transform="translate(-211.826 -295.814)"
            fill="#4c62a1"
          />
        </g>
        <g
          id="Group_15259"
          data-name="Group 15259"
          transform="translate(20.085 19.655)"
        >
          <circle
            id="Ellipse_54"
            data-name="Ellipse 54"
            cx="2.702"
            cy="2.702"
            r="2.702"
            transform="translate(0.844 8.301)"
            fill="#ffdab1"
          />
          <g id="Group_15258" data-name="Group 15258" transform="translate(0 0.001)">
            <path
              id="Path_29492"
              data-name="Path 29492"
              d="M239.006,271.538A3.541,3.541,0,0,1,235.464,268V257.46a3.542,3.542,0,0,1,7.083,0V268A3.541,3.541,0,0,1,239.006,271.538Z"
              transform="translate(-235.464 -253.919)"
              fill="#ffdab1"
            />
          </g>
          <path
            id="Path_29493"
            data-name="Path 29493"
            d="M239.006,253.909a3.541,3.541,0,0,0-3.542,3.541v5.1a10.1,10.1,0,0,0,7.083,0v-5.1A3.541,3.541,0,0,0,239.006,253.909Z"
            transform="translate(-235.464 -253.909)"
            fill="#ffdab1"
          />
        </g>
        <g
          id="Group_15260"
          data-name="Group 15260"
          transform="translate(8.862 30.207)"
        >
          <circle
            id="Ellipse_55"
            data-name="Ellipse 55"
            cx="2.702"
            cy="2.702"
            r="2.702"
            transform="translate(19.343 42.33)"
            fill="#ffdab1"
          />
          <circle
            id="Ellipse_56"
            data-name="Ellipse 56"
            cx="2.702"
            cy="2.702"
            r="2.702"
            transform="translate(4.782 42.33)"
            fill="#ffdab1"
          />
          <circle
            id="Ellipse_57"
            data-name="Ellipse 57"
            cx="2.026"
            cy="2.026"
            r="2.026"
            transform="translate(25.449 2.272)"
            fill="#ffdab1"
          />
          <circle
            id="Ellipse_58"
            data-name="Ellipse 58"
            cx="2.026"
            cy="2.026"
            r="2.026"
            transform="translate(0.035 2.272)"
            fill="#ffdab1"
          />
          <path
            id="Path_29494"
            data-name="Path 29494"
            d="M182.106,326.737a57.539,57.539,0,0,0-17.272,0c-1.456.271-4.5,1.138-5.636,2.36a2.135,2.135,0,0,0,.118,2.858,5.918,5.918,0,0,1,1.869,3.068h0c1.189,4.927,2.327,9.219,3.219,12.681h0a25.1,25.1,0,0,1-.521,14.229c-.452,1.335-.594,2.278-.581,6.389-.064,5.076.483,6.984,10.167,6.984h0c9.684,0,10.231-1.909,10.167-6.984.012-3.8-.07-4.882-.581-6.389a25.1,25.1,0,0,1-.521-14.229h0c.891-3.462,2.03-7.754,3.219-12.681h0a5.919,5.919,0,0,1,1.869-3.069,2.135,2.135,0,0,0,.118-2.858C186.611,327.875,183.562,327.008,182.106,326.737Z"
            transform="translate(-158.706 -326.085)"
            fill="#ffdab1"
          />
        </g>
        <path
          id="Path_29495"
          data-name="Path 29495"
          d="M206.341,576.7h3.009c0-.01-.013-.676-.013-.676h-11.29V574a.178.178,0,0,1,.178-.178h10.846c-.04-.172-.079-.343-.127-.513a.227.227,0,0,0-.219-.163h-19.46a.228.228,0,0,0-.219.163c-.048.17-.087.341-.127.513h8.472a.851.851,0,0,0-.019.178v2.026h-8.719s-.012.666-.013.676h3.009A2.781,2.781,0,0,1,188.6,579.3c0,.231.005.458.013.681a3.46,3.46,0,0,0,3.715-3.288h5.041v5.338a.45.45,0,0,0,.45.45h.867a2,2,0,0,0,2-2V576.7h4.967a3.46,3.46,0,0,0,3.715,3.288c.008-.223.012-.451.013-.681A2.781,2.781,0,0,1,206.341,576.7Zm-6.323,3.785a1.328,1.328,0,0,1-1.328,1.328h-.552a.09.09,0,0,1-.09-.09V576.7h1.97Z"
          transform="translate(-175.37 -506.812)"
          fill="#4c62a1"
        />
        <g id="Group_15265" data-name="Group 15265" transform="translate(12.305)">
          <g
            id="Group_15261"
            data-name="Group 15261"
            transform="translate(0.119 1.734)"
          >
            <path
              id="Path_29496"
              data-name="Path 29496"
              d="M316.4,209.3c-.834-.15-1.767,1.162-2.084,2.93s.1,3.322.935,3.472,1.767-1.162,2.084-2.93S317.229,209.454,316.4,209.3Z"
              transform="translate(-295.032 -197.895)"
              fill="#ffdab1"
            />
            <path
              id="Path_29497"
              data-name="Path 29497"
              d="M184.109,209.3c.834-.15,1.767,1.162,2.084,2.93s-.1,3.322-.935,3.472-1.767-1.162-2.084-2.93S183.276,209.454,184.109,209.3Z"
              transform="translate(-183.068 -197.895)"
              fill="#ffdab1"
            />
            <path
              id="Path_29498"
              data-name="Path 29498"
              d="M201.806,131.343c-7.974,0-9.917,5.619-9.917,12.551s4.44,12.553,9.917,12.553,9.917-5.62,9.917-12.553S209.78,131.343,201.806,131.343Z"
              transform="translate(-190.599 -131.343)"
              fill="#ffdab1"
            />
            <path
              id="Path_29499"
              data-name="Path 29499"
              d="M239.717,258.779a5.9,5.9,0,0,1-6.757,0,.354.354,0,0,0-.543.455c.045.054,1.13,1.317,3.922,1.317s3.875-1.262,3.92-1.316a.354.354,0,0,0-.542-.456Z"
              transform="translate(-225.132 -240.04)"
              fill="#4c62a1"
            />
            <path
              id="Path_29500"
              data-name="Path 29500"
              d="M217.232,223.94v.484a.775.775,0,0,0,.775.775h0a.775.775,0,0,0,.775-.775v-.484a.776.776,0,0,0-1.551,0Z"
              transform="translate(-212.237 -209.74)"
              fill="#4c62a1"
            />
            <path
              id="Path_29501"
              data-name="Path 29501"
              d="M292.356,225.2a.776.776,0,0,0,.775-.775v-.484a.776.776,0,0,0-1.551,0v.483A.776.776,0,0,0,292.356,225.2Z"
              transform="translate(-275.715 -209.74)"
              fill="#4c62a1"
            />
            <path
              id="Path_29502"
              data-name="Path 29502"
              d="M290.733,207.718c-1.222-1.088-3.464-.784-3.559-.771a.45.45,0,0,0,.126.892,4.119,4.119,0,0,1,2.833.552.45.45,0,1,0,.6-.673Z"
              transform="translate(-271.623 -195.852)"
              fill="#4c62a1"
            />
            <path
              id="Path_29503"
              data-name="Path 29503"
              d="M208.2,207.841a.45.45,0,0,0,.126-.892c-.095-.013-2.337-.317-3.559.771a.45.45,0,0,0,.6.673A4.127,4.127,0,0,1,208.2,207.841Z"
              transform="translate(-201.47 -195.854)"
              fill="#4c62a1"
            />
            <path
              id="Path_29504"
              data-name="Path 29504"
              d="M286.219,242.5a.708.708,0,0,0-.467,1.338c.291.1.762.389.846.669a.708.708,0,1,0,1.357-.406A2.847,2.847,0,0,0,286.219,242.5Z"
              transform="translate(-270.336 -226.215)"
              fill="#ffdab1"
            />
            <path
              id="Path_29505"
              data-name="Path 29505"
              d="M218.31,242.934a.709.709,0,0,0-.9-.435,2.847,2.847,0,0,0-1.736,1.6.708.708,0,1,0,1.357.406c.084-.28.554-.565.846-.669A.71.71,0,0,0,218.31,242.934Z"
              transform="translate(-210.882 -226.215)"
              fill="#ffdab1"
            />
          </g>
          <g id="Group_15262" data-name="Group 15262">
            <path
              id="Path_29506"
              data-name="Path 29506"
              d="M259.395,147.151c1.215-1.844,5.858-2.447,7.414-.654,0,0,.289-3.146-3.947-3.146S259.395,147.151,259.395,147.151Z"
              transform="translate(-248.07 -139.861)"
              fill="#4c62a1"
            />
            <path
              id="Path_29507"
              data-name="Path 29507"
              d="M189.139,138.579a4.459,4.459,0,0,0-4.025,3.743c-.267,1.97.245,3.348,0,4.166-.263.877-.985,1.055-.854,1.9a15.269,15.269,0,0,1,.094,3.192c-.033.608-.58.138-.577-.141a20.886,20.886,0,0,0-.113-2.983,9.445,9.445,0,0,1-.45-2.139,2.95,2.95,0,0,0-.394.619,9.758,9.758,0,0,1-.1-3.757,1.487,1.487,0,0,0-.347.647s-.948-4.794,2.514-6.145S189.139,138.579,189.139,138.579Z"
              transform="translate(-182.256 -134.652)"
              fill="#4c62a1"
            />
            <path
              id="Path_29508"
              data-name="Path 29508"
              d="M278.321,138.912c2.04.169,4.344,1.084,5.094,3.406a34.011,34.011,0,0,1,.736,4.306c.263.877.985,1.055.854,1.9a19.176,19.176,0,0,0-.094,3.192c-.018.7.58.084.577-.141a21.182,21.182,0,0,1,.112-2.983,9.456,9.456,0,0,0,.45-2.139,2.954,2.954,0,0,1,.394.619,14.95,14.95,0,0,0,.29-4.833s-.275-3.606-2.359-4.422A6,6,0,0,0,278.321,138.912Z"
              transform="translate(-264.275 -134.789)"
              fill="#4c62a1"
            />
            <path
              id="Path_29509"
              data-name="Path 29509"
              d="M221.5,126.227c-2.439-1.36-5.857-1.2-5.857-3.312,0-2.561,4.337-3.434,7.827-3.434,2.575,0,7.259.964,7.625,4.37,0,0-4.215-3.912-8.69-.816A2.26,2.26,0,0,0,221.5,126.227Z"
              transform="translate(-210.764 -119.481)"
              fill="#4c62a1"
            />
            <path
              id="Path_29510"
              data-name="Path 29510"
              d="M240.621,136.084c-1.065-.563-3.27-.83-3.27-2.411,0-1.759,3.27-3.284,7.318-3.284,4.2,0,6.37,1.332,6.913,3.617,0,0-1.787-2.757-7.926-1.272A3.741,3.741,0,0,0,240.621,136.084Z"
              transform="translate(-229.296 -128.794)"
              fill="#a6cbe0"
            />
            <path
              id="Path_29511"
              data-name="Path 29511"
              d="M261.54,145.281c.727-1.2,1.792-1.97,4.874-1.744,2.619.192,5.844,3.1,6.44,5.471,0,0,2.116-6.555-5.253-6.555C265.7,142.453,262.556,142.739,261.54,145.281Z"
              transform="translate(-249.948 -139.094)"
              fill="#4c62a1"
            />
          </g>
          <g
            id="Group_15264"
            data-name="Group 15264"
            transform="translate(0.882 13.066)"
          >
            <g id="Group_15263" data-name="Group 15263">
              <path
                id="Path_29512"
                data-name="Path 29512"
                d="M204.31,208.911l-.027,0a15.09,15.09,0,0,0-2.242.341c-2.151.439-2.843.952-3.406.952s-1.255-.513-3.406-.952a15.091,15.091,0,0,0-2.242-.341l-.026,0a8.8,8.8,0,0,0-4.669.538v1.125a2.679,2.679,0,0,1,.348.291,1.206,1.206,0,0,1,.348.673,5.714,5.714,0,0,0,1.137,2.661,3.38,3.38,0,0,0,2.616.9h0a6.788,6.788,0,0,0,2.653-.488,3.482,3.482,0,0,0,1.683-2.567,1.37,1.37,0,0,1,.3-.407,2.089,2.089,0,0,1,2.525,0,1.371,1.371,0,0,1,.3.407,3.483,3.483,0,0,0,1.683,2.567,6.79,6.79,0,0,0,2.653.488,3.379,3.379,0,0,0,2.616-.9,5.716,5.716,0,0,0,1.137-2.661,1.209,1.209,0,0,1,.348-.673,2.665,2.665,0,0,1,.348-.291v-1.125A8.8,8.8,0,0,0,204.31,208.911Zm-9.1,5.359a6.421,6.421,0,0,1-2.471.442h0a3.034,3.034,0,0,1-2.314-.756,5.464,5.464,0,0,1-1.094-3.839c.053-.3.652-.871,2.548-.871.331,0,.692.017,1.073.052,2.123.191,3.259.529,3.685,1.1a1.282,1.282,0,0,1,.168,1.074C196.536,212.847,196.189,213.747,195.211,214.27Zm11.633-.314a3.035,3.035,0,0,1-2.314.756,6.422,6.422,0,0,1-2.471-.442c-.979-.522-1.325-1.423-1.594-2.8a1.282,1.282,0,0,1,.168-1.074c.426-.567,1.563-.9,3.685-1.1.381-.034.742-.052,1.073-.052,1.9,0,2.5.569,2.548.871A5.465,5.465,0,0,1,206.844,213.956Z"
                transform="translate(-188.291 -208.848)"
                fill="#53596c"
              />
            </g>
            <path
              id="Path_29513"
              data-name="Path 29513"
              d="M201.078,216.593a6.421,6.421,0,0,1-2.471.442h0a3.035,3.035,0,0,1-2.314-.756,5.464,5.464,0,0,1-1.094-3.839c.052-.3.652-.871,2.548-.871.331,0,.692.017,1.073.052,2.123.191,3.259.529,3.685,1.1a1.282,1.282,0,0,1,.168,1.074C202.4,215.17,202.056,216.071,201.078,216.593Z"
              transform="translate(-194.158 -211.172)"
              fill="#53596c"
            />
            <path
              id="Path_29514"
              data-name="Path 29514"
              d="M277.687,216.28a3.035,3.035,0,0,1-2.314.756,6.422,6.422,0,0,1-2.471-.442c-.979-.522-1.325-1.423-1.594-2.8a1.283,1.283,0,0,1,.168-1.074c.426-.567,1.563-.9,3.685-1.1.381-.034.742-.052,1.073-.052,1.9,0,2.5.569,2.548.871A5.465,5.465,0,0,1,277.687,216.28Z"
              transform="translate(-259.134 -211.172)"
              fill="#53596c"
            />
          </g>
        </g>
        <g
          id="Group_15267"
          data-name="Group 15267"
          transform="translate(7.968 27.724)"
        >
          <path
            id="Path_29515"
            data-name="Path 29515"
            d="M237.108,359.516v36.375h6.6V359.516a23.278,23.278,0,0,1-6.6,0Z"
            transform="translate(-224.751 -352.144)"
            fill="#e3e8f3"
          />
          <path
            id="Path_29516"
            data-name="Path 29516"
            d="M230.959,340.015a15.162,15.162,0,0,1-11.872,0,7.432,7.432,0,0,0,2.635,1.549v1.3a23.278,23.278,0,0,0,6.6,0v-1.3A7.431,7.431,0,0,0,230.959,340.015Z"
            transform="translate(-209.365 -335.494)"
            fill="#e3e8f3"
          />
          <rect
            id="Rectangle_5320"
            data-name="Rectangle 5320"
            width="6.603"
            height="1.539"
            transform="translate(12.357 43.747)"
            fill="#e3e8f3"
          />
          <path
            id="Path_29517"
            data-name="Path 29517"
            d="M164.948,365.621V326.45c-2.2-.78-3.7-2.251-3.7-3.585a.812.812,0,0,1,.307-.552q-1.121.134-2.111.318c-.182.034-4.483.853-6.132,2.634l-.03.034a3.054,3.054,0,0,0,.183,4.084,5,5,0,0,1,1.619,2.633c.934,3.871,1.826,7.308,2.613,10.341l.3,1.175c.884,3.575-1.49,13.589-1.663,19.9a1.374,1.374,0,0,0,.6,1.169c1.425.984,3.424,1.5,7.294,1.693A.678.678,0,0,0,164.948,365.621Z"
            transform="translate(-152.591 -320.381)"
            fill="#4c62a1"
          />
          <path
            id="Path_29518"
            data-name="Path 29518"
            d="M181.752,447.4a8.376,8.376,0,0,0-2.759-4.337q.318,1.234.619,2.389l.3,1.175c.884,3.575-1.49,13.589-1.663,19.9a1.374,1.374,0,0,0,.6,1.169,6.622,6.622,0,0,0,1.1.61C180.373,461.735,182.047,449.245,181.752,447.4Z"
            transform="translate(-174.501 -423.476)"
            fill="#4c62a1"
          />
          <path
            id="Path_29519"
            data-name="Path 29519"
            d="M164.308,322.865a.812.812,0,0,1,.307-.552q-1.121.134-2.111.318c-.182.034-4.483.853-6.132,2.634l-.03.034a2.713,2.713,0,0,0-.172.23,10.348,10.348,0,0,0,1.789,5.252c.737.988,6.024,2.364,10.045,2.977V326.45C165.8,325.67,164.308,324.2,164.308,322.865Z"
            transform="translate(-155.648 -320.381)"
            fill="#4c62a1"
          />
          <path
            id="Path_29520"
            data-name="Path 29520"
            d="M186.857,591.3c-4.872-.219-7.181-.981-8.481-2.306-.062.746-.105,1.461-.124,2.135a1.37,1.37,0,0,0,.6,1.164c1.425.984,3.424,1.5,7.293,1.693a.678.678,0,0,0,.713-.675Z"
            transform="translate(-174.5 -548.071)"
            fill="#4c62a1"
          />
          <path
            id="Path_29521"
            data-name="Path 29521"
            d="M282.267,365.621V326.45c2.2-.78,3.7-2.251,3.7-3.585a.813.813,0,0,0-.307-.552q1.121.134,2.111.318c.182.034,4.483.853,6.132,2.634l.03.034a3.054,3.054,0,0,1-.183,4.084,5,5,0,0,0-1.619,2.633c-.934,3.871-1.826,7.308-2.613,10.341l-.3,1.175c-.884,3.575,1.49,13.589,1.663,19.9a1.374,1.374,0,0,1-.6,1.169c-1.425.984-3.424,1.5-7.294,1.693A.678.678,0,0,1,282.267,365.621Z"
            transform="translate(-263.307 -320.381)"
            fill="#4c62a1"
          />
          <path
            id="Path_29522"
            data-name="Path 29522"
            d="M293.929,325.3l-.03-.034c-1.65-1.782-5.95-2.6-6.132-2.634q-.988-.184-2.11-.318a.812.812,0,0,1,.307.552c0,1.334-1.493,2.8-3.7,3.585v7.307c4.022-.613,9.308-1.99,10.045-2.977a10.349,10.349,0,0,0,1.789-5.252A2.741,2.741,0,0,0,293.929,325.3Z"
            transform="translate(-263.307 -320.381)"
            fill="#4c62a1"
          />
          <path
            id="Path_29523"
            data-name="Path 29523"
            d="M316.985,447.4a8.377,8.377,0,0,1,2.759-4.337q-.318,1.234-.619,2.389l-.3,1.175c-.884,3.575,1.49,13.589,1.663,19.9a1.373,1.373,0,0,1-.6,1.169,6.613,6.613,0,0,1-1.1.61C318.364,461.735,316.69,449.245,316.985,447.4Z"
            transform="translate(-292.92 -423.476)"
            fill="#4c62a1"
          />
          <path
            id="Path_29524"
            data-name="Path 29524"
            d="M282.98,593.987c3.87-.188,5.869-.709,7.293-1.693a1.369,1.369,0,0,0,.6-1.164c-.018-.674-.062-1.39-.124-2.135-1.3,1.324-3.609,2.086-8.481,2.306v2.011A.678.678,0,0,0,282.98,593.987Z"
            transform="translate(-263.307 -548.071)"
            fill="#4c62a1"
          />
          <path
            id="Path_29525"
            data-name="Path 29525"
            d="M283.224,347.852a8.793,8.793,0,0,1-.957.4v39.171a.678.678,0,0,0,.711.675l.246-.013Z"
            transform="translate(-263.307 -342.186)"
            fill="#ff9b69"
          />
          <path
            id="Path_29526"
            data-name="Path 29526"
            d="M230.563,388.089l.246.013a.678.678,0,0,0,.711-.675V348.255a8.806,8.806,0,0,1-.957-.4Z"
            transform="translate(-219.163 -342.186)"
            fill="#ff9b69"
          />
          <g
            id="Group_15266"
            data-name="Group 15266"
            transform="translate(18.96 0.093)"
          >
            <path
              id="Path_29527"
              data-name="Path 29527"
              d="M285.963,312.122c0-.578-1.016-.889-1.949-2.392.53.964-.531,2.272-1.22,2.974a1.818,1.818,0,0,0-.527,1.273v1.73C284.47,314.926,285.963,313.456,285.963,312.122Z"
              transform="translate(-282.267 -309.73)"
              fill="#4c62a1"
            />
          </g>
          <path
            id="Path_29528"
            data-name="Path 29528"
            d="M217.857,312.343h0a5.311,5.311,0,0,1-1.3-.147,4.173,4.173,0,0,1-2.57-1.345,1.833,1.833,0,0,1-.16-1.754c-.948,1.583-2.006,1.9-2.006,2.484,0,1.334,1.494,2.8,3.7,3.585a6.5,6.5,0,0,0,2.334.423h0a1.647,1.647,0,0,0,0-3.246Z"
            transform="translate(-203.165 -309.097)"
            fill="#4c62a1"
          />
          <path
            id="Path_29529"
            data-name="Path 29529"
            d="M245.767,337.509a.837.837,0,1,1-.837-.837A.837.837,0,0,1,245.767,337.509Z"
            transform="translate(-230.714 -332.64)"
            fill="#fd9b69"
          />
        </g>
      </g>
    </g>
  </svg>
)

export const EmptyInbox: React.FC<IconProps> = ({...props}): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="42.857"
    height="51"
    viewBox="0 0 42.857 51"
    {...props}
  >
    <g id="Group_33224" data-name="Group 33224" transform="translate(-380.571 -591)">
      <path
        id="Icon_ionic-md-mail"
        data-name="Icon ionic-md-mail"
        d="M41.025,5.625H6.332A3.89,3.89,0,0,0,2.25,9.261V31.989a3.89,3.89,0,0,0,4.082,3.636H41.025a3.89,3.89,0,0,0,4.082-3.636V9.261A3.89,3.89,0,0,0,41.025,5.625Zm-.51,7.727-16.837,10-16.837-10V9.716l16.837,10,16.837-10Z"
        transform="translate(378.321 606.375)"
        fill="#00acee"
      />
      <g id="Alert" transform="translate(295 65.986)">
        <circle
          id="Ellipse_1262"
          data-name="Ellipse 1262"
          cx="14"
          cy="14"
          r="14"
          transform="translate(93 525.014)"
          fill="#c8c8c8"
        />
        <path
          id="Path_66520"
          data-name="Path 66520"
          d="M13.952,13.536c2.288,0,4.24-1.456,4.24-5.312V7.536c0-3.856-1.952-5.28-4.224-5.28C11.712,2.256,9.7,3.664,9.7,7.584v.688C9.7,12,11.552,13.536,13.952,13.536Zm0-1.5c-1.488,0-2.208-1.216-2.208-3.76V7.424c0-2.56.736-3.648,2.224-3.648,1.44,0,2.192,1.152,2.192,3.76v.816C16.16,10.976,15.424,12.032,13.952,12.032Z"
          transform="translate(93 531.014)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
)

export const EditBox: React.FC<IconProps> = ({
  fill = "#00acee",
  ...props
}): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      id="pen-square-solid"
      d="M17.857,52H2.143A2.143,2.143,0,0,1,0,49.857V34.143A2.143,2.143,0,0,1,2.143,32H17.857A2.143,2.143,0,0,1,20,34.143V49.857A2.143,2.143,0,0,1,17.857,52ZM10.629,38.513,4.571,44.571,4.29,47.121a.541.541,0,0,0,.594.594l2.549-.281,6.058-6.058a.272.272,0,0,0,0-.379l-2.478-2.478A.274.274,0,0,0,10.629,38.513Zm4.772-.571L14.058,36.6a1.067,1.067,0,0,0-1.513,0l-1.031,1.031a.272.272,0,0,0,0,.379l2.478,2.478a.272.272,0,0,0,.379,0L15.4,39.455A1.075,1.075,0,0,0,15.4,37.942Z"
      transform="translate(0 -32)"
      fill={fill}
    />
  </svg>
)

export const Dropdown: React.FC<IconProps> = ({fill = "00acee", ...props}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="10.855"
    viewBox="0 0 20 10.855"
    {...props}
  >
    <path
      id="chevron-left-light"
      d="M17.32,52.793l.317-.317a.538.538,0,0,0,0-.761L8.872,42.95l8.765-8.765a.538.538,0,0,0,0-.761l-.317-.317a.538.538,0,0,0-.761,0L7.1,42.57a.538.538,0,0,0,0,.761l9.462,9.462A.538.538,0,0,0,17.32,52.793Z"
      transform="translate(-32.951 17.795) rotate(-90)"
      fill={fill}
    />
  </svg>
)

export const Clock: React.FC<IconProps> = ({fill = "#fff", ...props}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    {...props}
  >
    <g id="Group_33226" data-name="Group 33226" transform="translate(-1301 -850)">
      <path
        id="clock-regular"
        d="M7.25,3.75a.75.75,0,0,1,1.5,0V7.6l2.666,1.775a.724.724,0,0,1,.181,1.041.688.688,0,0,1-1.012.181l-3-2a.685.685,0,0,1-.334-.625Z"
        transform="translate(1301 850)"
        fill={fill}
      />
    </g>
  </svg>
)

export const Play: React.FC<IconProps> = ({fill = "#00acee", ...props}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="28"
    viewBox="0 0 24 28"
    {...props}
  >
    <path
      fill={fill}
      d="M10.545,5.923a4,4,0,0,1,6.91,0l7.036,12.061A4,4,0,0,1,21.036,24H6.964a4,4,0,0,1-3.455-6.015Z"
      transform="translate(24) rotate(90)"
    />
  </svg>
)

export const ArrowUp: React.FC<IconProps> = ({fill = "#fff", ...props}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    {...props}
  >
    <path
      id="noun-back-to-top-1590837"
      d="M175.847,103.239l-1.237,1.168-3.456-3.263v7.486H169.4v-7.486l-3.456,3.263-1.237-1.168,5.568-5.258Zm1.48-8.609h-14v1.652h14Z"
      transform="translate(-163.327 -94.63)"
      fill={fill}
    />
  </svg>
)

export const HandShake: React.FC<IconProps> = ({fill = "#747474", ...props}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="16"
    viewBox="0 0 20 16"
    {...props}
  >
    <path
      id="handshake"
      d="M27.095,15.858c0-.036,0-.071,0-.11A2.844,2.844,0,0,0,24.26,12.9h-4.9v2.85a1.29,1.29,0,1,1-2.58,0V11.862h.013A2.326,2.326,0,0,1,19.1,9.789h3.611a2.424,2.424,0,0,1,.268.016l1.293-1.3a1.8,1.8,0,0,1,2.554,0l2.518,2.529a1.818,1.818,0,0,1,0,2.565L27.1,15.858Zm-11.347-4v3.886a2.322,2.322,0,1,0,4.643,0V13.935H24.26a1.815,1.815,0,0,1,.348,3.595,1.555,1.555,0,0,1-1.122,2.623H23.4a1.533,1.533,0,0,1,.087.518,1.552,1.552,0,0,1-1.548,1.554H16.779a.782.782,0,0,1-.087,0l-1.216,1.221a1.8,1.8,0,0,1-2.554,0L10.4,20.917a1.818,1.818,0,0,1,0-2.565l2.247-2.257V14.971A3.1,3.1,0,0,1,15.748,11.862Z"
      transform="translate(-9.875 -7.975)"
      fill={fill}
    />
  </svg>
)

export const TermsConditions: React.FC<IconProps> = ({
  fill = "#00acee",
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14.001"
    height="18.002"
    viewBox="0 0 14.001 18.002"
    {...props}
  >
    <path
      id="Step_1"
      data-name="Step 1"
      d="M11155-19055h-10a2,2,0,0,1-2-2v-14a2,2,0,0,1,2-2h10a2,2,0,0,1,2,2v14A2,2,0,0,1,11155-19055Zm-8.5-4a.5.5,0,0,0-.5.5.5.5,0,0,0,.5.5h7a.5.5,0,0,0,.5-.5.5.5,0,0,0-.5-.5Zm0-4a.5.5,0,0,0-.5.5.5.5,0,0,0,.5.5h7a.5.5,0,0,0,.5-.5.5.5,0,0,0-.5-.5Zm0-4a.5.5,0,0,0-.5.5.5.5,0,0,0,.5.5h7a.5.5,0,0,0,.5-.5.5.5,0,0,0-.5-.5Zm0-4a.5.5,0,0,0-.5.5.5.5,0,0,0,.5.5h7a.5.5,0,0,0,.5-.5.5.5,0,0,0-.5-.5Z"
      transform="translate(-11142.998 19073)"
      fill={fill}
    />
  </svg>
)

export const Forms: React.FC<IconProps> = ({fill = "#00acee", ...props}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    {...props}
  >
    <path
      id="Step_2"
      data-name="Step 2"
      d="M0,9.828H7.322v.218c0,.722,0,1.445,0,2.167a.812.812,0,0,0,1.009.849.983.983,0,0,0,.424-.245q1.622-1.6,3.228-3.211a.806.806,0,0,0,0-1.211q-1.6-1.6-3.211-3.2a.829.829,0,0,0-1.45.6c0,.722,0,1.445,0,2.167v.217H.014A8.9,8.9,0,0,1,7.8.078,9,9,0,1,1,0,9.828"
      fill={fill}
    />
  </svg>
)

export const OTP: React.FC<IconProps> = ({fill = "#00acee", ...props}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23.981"
    height="7.865"
    viewBox="0 0 23.981 7.865"
    {...props}
  >
    <path
      id="Step_3"
      data-name="Step 3"
      d="M18.478,6.866l.082-2.319L16.6,5.776l-.779-1.294,2.056-1.04L15.841,2.331l.74-1.248L18.56,2.325,18.478,0h1.478l-.1,2.325L21.835,1.05l.749,1.3-2.07,1.088L22.574,4.5l-.729,1.251L19.857,4.547l.1,2.319Zm-7.91,0,.082-2.319L8.69,5.776,7.911,4.482l2.056-1.04L7.931,2.331l.74-1.248L10.65,2.325,10.568,0h1.478l-.1,2.325L13.924,1.05l.75,1.3L12.6,3.442,14.664,4.5l-.729,1.251L11.947,4.547l.1,2.319Zm-7.912,0,.083-2.319L.779,5.776,0,4.482l2.056-1.04L.02,2.331.76,1.083,2.739,2.325,2.656,0H4.135l-.1,2.325L6.013,1.05l.75,1.3L4.693,3.442,6.753,4.5l-.73,1.251L4.036,4.547l.1,2.319Z"
      transform="translate(0.706 0.5)"
      fill={fill}
      stroke="rgba(0,0,0,0)"
      stroke-width="1"
    />
  </svg>
)

export const CircleCheck: React.FC<IconProps> = ({fill = "#00acee", ...props}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    {...props}
  >
    <path
      id="Step_4"
      data-name="Step 4"
      d="M9.525,18H8.47c-.3-.038-.6-.065-.9-.115A8.766,8.766,0,0,1,2.7,15.415,8.788,8.788,0,0,1,.044,8.095a8.585,8.585,0,0,1,2.2-5.03A8.816,8.816,0,0,1,8.619.011a8.562,8.562,0,0,1,4.966,1.254A8.869,8.869,0,0,1,17.86,7.451c.063.337.094.681.14,1.022V9.527c-.014.126-.029.253-.043.379A8.891,8.891,0,0,1,15.7,15a8.832,8.832,0,0,1-5.148,2.86c-.339.06-.682.094-1.023.14M7.6,10.482c-.038-.032-.069-.057-.1-.084q-.668-.611-1.335-1.223c-.351-.32-.689-.654-1.059-.95a.863.863,0,0,0-1.394.587.936.936,0,0,0,.347.839q1.451,1.317,2.9,2.638a.9.9,0,0,0,1.393-.025l5.59-5.547c.05-.049.1-.1.144-.154A.9.9,0,0,0,13.837,5.2a.911.911,0,0,0-1.161.21L7.74,10.346c-.045.045-.091.087-.143.136"
      transform="translate(-0.001)"
      fill={fill}
    />
  </svg>
)

export const Check: React.FC<IconProps> = ({fill = "#fff", ...props}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="9"
    viewBox="0 0 12 9"
    {...props}
  >
    <path
      id="check"
      d="M609.167,3996.827a1,1,0,0,1-.71-.3l-3.606-3.64a1,1,0,0,1,1.421-1.408l2.866,2.893,5.685-6.224a1,1,0,1,1,1.477,1.349l-6.394,7a1,1,0,0,1-.718.325Z"
      transform="translate(-604.561 -3987.827)"
      fill={fill}
    />
  </svg>
)

export const Lock: React.FC<IconProps> = ({fill = "#d5d5d5", ...props}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.5"
    height="20"
    viewBox="0 0 17.5 20"
    {...props}
  >
    <path
      id="lock-alt-light"
      d="M8.75,16.405a.784.784,0,0,1-.781-.781v-2.5a.781.781,0,0,1,1.562,0v2.5A.784.784,0,0,1,8.75,16.405Zm8.75-5.781v7.5A1.875,1.875,0,0,1,15.625,20H1.875A1.875,1.875,0,0,1,0,18.124v-7.5A1.875,1.875,0,0,1,1.875,8.749H2.5v-2.5A6.25,6.25,0,1,1,15,6.308V8.749h.625A1.875,1.875,0,0,1,17.5,10.624ZM3.75,8.749h10v-2.5a5,5,0,0,0-10,0Zm12.5,9.375v-7.5A.627.627,0,0,0,15.625,10H1.875a.627.627,0,0,0-.625.625v7.5a.627.627,0,0,0,.625.625h13.75A.627.627,0,0,0,16.25,18.124Z"
      transform="translate(0 0.001)"
      fill={fill}
    ></path>
  </svg>
)

export const Sparkle: React.FC<IconProps> = ({fill = "#747474", ...props}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11.428"
    height="11.43"
    viewBox="0 0 11.428 11.43"
    {...props}
  >
    <path
      id="sparkle_2"
      data-name="sparkle 2"
      d="M6.264,4.966,7.321,8.659l3.693,1.057a.572.572,0,0,1,0,1.1L7.321,11.873,6.264,15.566a.572.572,0,0,1-1.1,0L4.107,11.873.414,10.816a.572.572,0,0,1,0-1.1L4.107,8.659,5.164,4.966a.572.572,0,0,1,1.1,0Z"
      transform="translate(0 -4.551)"
      fill={fill}
    />
  </svg>
)

export const ReviewApplication: React.FC<IconProps> = ({
  fill = "#00acee",
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="21"
    viewBox="0 0 18 21"
    {...props}
  >
    <g id="Group_37327" data-name="Group 37327" transform="translate(10333 12389)">
      <path
        id="file-lines-regular"
        d="M12.843,3.283,10.219.659A2.26,2.26,0,0,0,8.627,0H2.25A2.25,2.25,0,0,0,0,2.25v13.5A2.25,2.25,0,0,0,2.25,18h9a2.257,2.257,0,0,0,2.25-2.25V4.873A2.252,2.252,0,0,0,12.843,3.283ZM11.813,15.75a.563.563,0,0,1-.562.563h-9a.562.562,0,0,1-.562-.562V2.254a.563.563,0,0,1,.563-.563H7.875V4.5A1.125,1.125,0,0,0,9,5.625h2.781V15.75ZM3.375,9.844a.844.844,0,0,0,.844.844H9.281A.844.844,0,0,0,9.281,9H4.219A.846.846,0,0,0,3.375,9.844Zm5.906,2.531H4.219a.844.844,0,0,0,0,1.688H9.281a.844.844,0,0,0,0-1.687Z"
        transform="translate(-10333 -12389)"
        fill={fill}
      />
      <g id="Group_37329" data-name="Group 37329" transform="translate(-11 -8)">
        <path
          id="Path_86211"
          data-name="Path 86211"
          d="M4.649,0A4.649,4.649,0,1,1,0,4.649,4.649,4.649,0,0,1,4.649,0Z"
          transform="translate(-10315.704 -12371.698)"
          fill="#fff"
        />
        <path
          id="magnifying-glass-solid"
          d="M9.75,4.874A4.862,4.862,0,0,1,8.813,7.75l2.967,2.969a.751.751,0,0,1-1.062,1.062L7.751,8.811a4.875,4.875,0,1,1,2-3.937ZM4.875,8.249A3.375,3.375,0,1,0,1.5,4.874,3.376,3.376,0,0,0,4.875,8.249Z"
          transform="translate(-10316 -12372)"
          fill={fill}
        />
      </g>
    </g>
  </svg>
)
