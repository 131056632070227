// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, {useContext, useEffect} from "react"
/**
 * Component
 */
import Checkbox from "components/Forms/Checkbox"
import {CaptchaLogo} from "../../../screens/Account/Register/CaptchaLogo"
/**
 * Styles
 */
import s from "./captcha.module.css"
import {ThemeContext} from "context/ThemeContext"

interface iCaptcha {
  value: string
  label: string
  type: string
  onChange: (value: string) => void
}

function Captcha({value, label, type, onChange}: iCaptcha): JSX.Element {
  const {theme} = useContext(ThemeContext)
  function resetCaptcha(): void {
    setTimeout(() => {
      onChange("")
    }, 120000)
  }

  function generateCapchaToken(): void {
    return (window as any).grecaptcha.ready(() => {
      return (window as any).grecaptcha
        .execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, {action: type})
        .then((token: string) => {
          onChange(token)
          resetCaptcha()
        })
    })
  }

  useEffect(() => {
    const script = document.createElement("script")
    script.src =
      "https://www.google.com/recaptcha/api.js?render=" +
      process.env.REACT_APP_RECAPTCHA_SITE_KEY
    document.body.appendChild(script)
  }, [])

  return (
    <div className={s.captchaContainer}>
      <div
        className={s.captchaTarget}
        onClick={() => {
          if (value === "") {
            return generateCapchaToken()
          } else {
            onChange("")
          }
        }}
      />
      <div
        className="g-recaptcha"
        data-sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
        data-size="invisible"
      />
      <div className={s.captchaContent}>
        <div>
          <Checkbox
            keyName="register-captcha"
            checked={value !== ""}
            label={label}
            labelSize={16}
            labelWeight={600}
            onChange={() => null}
          />
        </div>
        <div>
          {/* <img width="40" height="48" src="/assets/img/captcha-logo.svg" alt="" /> */}
          <CaptchaLogo color={theme.colors.registerCaptchaTextColor} />
        </div>
      </div>
    </div>
  )
}

export default Captcha
