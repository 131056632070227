import React, {FC, memo, Suspense} from "react"
import shallow from "zustand/shallow"
import {lazyWithRetry} from "utils/lazyWithRetry"
import {useUserStore} from "stores/userStore"
const Private = lazyWithRetry(() => import("./Private"))
const Public = lazyWithRetry(() => import("./Public"))

const MobileBody: FC = (): JSX.Element => {
  const authToken = useUserStore(store => store.authToken, shallow)

  return <Suspense fallback={<></>}>{authToken ? <Private /> : <Public />}</Suspense>
}

export default memo(MobileBody)
