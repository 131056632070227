import React, {ReactNode} from "react"
import s from "./categoryButton.module.scss"
import NewIcon from "./NewIcon"
import cx from "classnames"

type GameCategoryButtonType = {
  children?: ReactNode
  className?: string
  active: boolean
  isNew?: boolean
  onClick?: () => void
}

const GameCategoryButton = ({
  children,
  className,
  active,
  isNew = false,
  onClick,
}: GameCategoryButtonType): JSX.Element => {
  const handleClick = () => {
    onClick()
  }

  return (
    <div
      onClick={handleClick}
      className={cx(s.container, {
        [className]: Boolean(className),
        [s.active]: active,
      })}
    >
      {children}
      {isNew && <NewIcon className={s.new} />}
    </div>
  )
}

export default GameCategoryButton
