import React from "react"

export default function useBreakpoint(mediaQuery: string): boolean {
  const [matchesBreakpoint, setMatchesBreakpoint] = React.useState(
    window.matchMedia(mediaQuery).matches,
  )

  React.useEffect(() => {
    const handler = e => {
      setMatchesBreakpoint(e.matches)
    }
    window.matchMedia(mediaQuery).addEventListener("change", handler)

    return () => window.removeEventListener("change", handler)
  }, [setMatchesBreakpoint, mediaQuery])

  return matchesBreakpoint
}
