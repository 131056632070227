import React, {FC} from "react"
import {IconProps} from "screens/MyProfile/icons"

const LogoName: FC<IconProps> = ({fill = "#00acee", ...props}) => (
  <svg
    id="CustomFont"
    xmlns="http://www.w3.org/2000/svg"
    width="108.35"
    height="18.936"
    viewBox="0 0 108.35 18.936"
    {...props}
  >
    <path
      id="Path_20451"
      data-name="Path 20451"
      d="M1068.844,151.148h-15.9a3.592,3.592,0,0,1-3.592-3.592V135.8a3.592,3.592,0,0,1,3.592-3.592h15.9V136h-13.119q-2.125,0-2.125,1.894v1.893h11.032v3.787H1053.6v2.494a1.293,1.293,0,0,0,1.293,1.293h13.95Z"
      transform="translate(-1049.353 -132.212)"
      fill={fill}
    />
    <path
      id="Path_20452"
      data-name="Path 20452"
      d="M1080.527,147.372,1093.513,136h-12.987v-3.787h17.7a2.155,2.155,0,0,1,1.421,3.776l-12.964,11.373h12.957v3.787h-17.695A2.155,2.155,0,0,1,1080.527,147.372Z"
      transform="translate(-1057.924 -132.212)"
      fill={fill}
    />
    <path
      id="Path_20453"
      data-name="Path 20453"
      d="M1129.67,145.467a4.993,4.993,0,0,1-.5,2.218,5.775,5.775,0,0,1-1.366,1.8,6.557,6.557,0,0,1-2.017,1.218,6.847,6.847,0,0,1-2.489.446h-12.745V132.212H1123.3a6.879,6.879,0,0,1,2.474.447,6.531,6.531,0,0,1,2.033,1.217,5.739,5.739,0,0,1,1.366,1.812,5.024,5.024,0,0,1,.5,2.205,5.165,5.165,0,0,1-1.639,3.786A5.166,5.166,0,0,1,1129.67,145.467Zm-4.249-7.574a1.735,1.735,0,0,0-.621-1.34,2.184,2.184,0,0,0-1.5-.555h-8.5v3.787h8.5a2.181,2.181,0,0,0,1.5-.555A1.731,1.731,0,0,0,1125.422,137.894Zm0,7.574a1.734,1.734,0,0,0-.621-1.339,2.184,2.184,0,0,0-1.5-.555h-8.5v3.787h8.5a2.181,2.181,0,0,0,1.5-.555A1.733,1.733,0,0,0,1125.422,145.467Z"
      transform="translate(-1065.871 -132.212)"
      fill={fill}
    />
    <path
      id="Path_20454"
      data-name="Path 20454"
      d="M1161.159,151.148h-15.9a3.592,3.592,0,0,1-3.592-3.592V135.8a3.592,3.592,0,0,1,3.592-3.592h15.9V136h-13.119q-2.125,0-2.124,1.894v1.893h10.313v3.787h-10.313v2.494a1.293,1.293,0,0,0,1.293,1.293h13.95Z"
      transform="translate(-1074.634 -132.212)"
      fill={fill}
    />
    <path
      id="Path_20455"
      data-name="Path 20455"
      d="M1184.252,136v15.149H1180V136h-7.434v-3.787h19.117V136Z"
      transform="translate(-1083.336 -132.212)"
      fill={fill}
    />
  </svg>
)

export default LogoName
