import React, {CSSProperties, FC, useContext} from "react"
import cx from "classnames"
import s from "screens/MyProfile/components/DateRangePicker/Button/button.module.scss"
import {ThemeContext} from "context/ThemeContext"
interface iButton {
  type: string
  label: string
  handleClick: () => void
  className?: string
  Icon?: React.JSX.Element
}

interface CSSCustomProperties extends CSSProperties {
  "--readbuttonBackground"?: string
  "--buttonLabel"?: string
}

const Button: FC<iButton> = ({className, type, label, handleClick, Icon}) => {
  const onClick = () => {
    if (type !== "disabled") handleClick()
  }
  const {theme} = useContext(ThemeContext)
  const custom_colors: CSSCustomProperties = {
    "--readbuttonBackground": theme?.colors.readbuttonBackground,
    "--buttonLabel": theme?.colors.buttonLabel,
  }
  return (
    <button
      className={cx(s.button, className, {
        [s.confirm]: type === "confirm",
        [s.cancel]: type === "cancel",
        [s.delete]: type === "delete",
        [s.read]: type === "read",
        [s.disabled]: type === "disabled",
      })}
      onClick={() => onClick()}
      style={custom_colors}
    >
      {Icon && Icon}
      <span>{label}</span>
    </button>
  )
}

export default Button
