export const PAGE_TITLE = "이벤트"
export const PAGE_SUB_TITLE = "EZBet 특권과 프리미엄 혜택"
export const BACK_LABEL = "돌아가기"

export const PROMO_CODE_1 = "NEW_FIRST_DEPOSIT"
export const PROMO_CODE_2 = "FIRST_DEPOSIT"
export const PROMO_CODE_3 = "MULTI_BET"
export const PROMO_CODE_4 = "REWARD"
export const PROMO_CODE_5 = "CASH_OUT"
export const PROMO_CODE_6 = "REVIEW_EVENT"
export const PROMO_CODE_7 = "EVOLUTION"
export const PROMO_CODE_8 = "SPORTS"
export const PROMO_CODE_9 = "SPORTS_EVENT"
export const PROMO_CODE_10 = "REFER_FRIEND_EVENT"
export const PROMO_CODE_11 = "PAY_BACK"
export const PROMO_CODE_12 = "SECOND_DEPOSIT"
export const PROMO_CODE_13 = "SPORTS_BONUS"
export const PROMO_CODE_14 = "SPORTS_MAINTENANCE"
export const PROMO_CODE_15 = "SLOTS"
export const PROMO_CODE_16 = "CASINO_INFINITE_DEPOSIT"
export const PROMO_CODE_17 = "BET365_INFINITE_DEPOSIT"
export const PROMO_CODE_18 = "EVO_BET_WIN"
export const PROMO_CODE_19 = "CHICKEN_COKE"
export const PROMO_CODE_20 = "UEFA"
export const PROMO_CODE_21 = "MILAGE"
export const PROMO_CODE_22 = "COMBO_BONUS"
export const PROMO_CODE_23 = "FREE_GAME"
export const PROMO_CODE_24 = "NEW_MEMBERS"
