import {useEffect} from "react"
import shallow from "zustand/shallow"
import {useQueryClient} from "react-query"
import usePusherStore from "stores/usePusherStore"
import {useSkyparkStore} from "screens/SkyPark/store"
import {useBet365Store} from "screens/Bet365/store"
import EVENT from "provider/pusher/pusherEvents"
import {
  CATEGORIZED_QUERY_NAME,
  FAVORITE_QUERY_NAME,
  TOP_RATED_QUERY_NAME,
  JACKPOT_QUERY_NAME,
  VIDEO_QUERY_NAME,
  CLASSIC_QUERY_NAME,
  RECENTLY_PLAYED_QUERY_NAME,
  SEARCH_QUERY_NAME,
} from "screens/Slots/utils/Config"
import {
  CASINO_QUERY_NAME,
  POPULAR_QUERY_NAME,
  RECENTLY_PLAYED_QUERY_NAME as CASINO_RECENTLY_PLAYED_QUERY_NAME,
  BACCARAT_QUERY_NAME,
  ROULETTE_QUERY_NAME,
  BLACKJACK_QUERY_NAME,
  DRAGON_TIGER_QUERY_NAME,
  POKER_QUERY_NAME,
  GAME_SHOWS_QUERY_NAME,
  FIRST_PERSON_QUERY_NAME,
  SEARCH_QUERY_NAME as CASINO_SEARCH_QUERY_NAME,
} from "screens/Casino/utils/Config"
import {ONGOING_BETS_QUERY} from "screens/Bet365/components/BetSlips/OngoingBets"
import {GET_SPORTS_URL_QUERY} from "screens/BTI/config"

const usePublicGameEvents = () => {
  const client = useQueryClient()
  const publicGameNotifChannel = usePusherStore(
    store => store.publicGameNotifChannel,
    shallow,
  )

  const refreshGames = (data: Record<string, any>): void => {
    const vendor = data?.data?.game_type
    const skypark = useSkyparkStore.getState()
    const bet365 = useBet365Store.getState()
    switch (vendor) {
      case "casino":
        client.invalidateQueries("casinoCategories")
        client.invalidateQueries([CASINO_RECENTLY_PLAYED_QUERY_NAME])
        client.invalidateQueries([CASINO_QUERY_NAME])
        client.invalidateQueries([POPULAR_QUERY_NAME])
        client.invalidateQueries([BACCARAT_QUERY_NAME])
        client.invalidateQueries([ROULETTE_QUERY_NAME])
        client.invalidateQueries([BLACKJACK_QUERY_NAME])
        client.invalidateQueries([DRAGON_TIGER_QUERY_NAME])
        client.invalidateQueries([POKER_QUERY_NAME])
        client.invalidateQueries([GAME_SHOWS_QUERY_NAME])
        client.invalidateQueries([FIRST_PERSON_QUERY_NAME])
        client.invalidateQueries([CASINO_SEARCH_QUERY_NAME])
        client.invalidateQueries(["GET_EVOLUTION_POWERBALL"])
        break
      case "slots":
        client.invalidateQueries("slotsSummary")
        client.invalidateQueries([RECENTLY_PLAYED_QUERY_NAME])
        client.invalidateQueries([CATEGORIZED_QUERY_NAME])
        client.invalidateQueries([FAVORITE_QUERY_NAME])
        client.invalidateQueries([TOP_RATED_QUERY_NAME])
        client.invalidateQueries([JACKPOT_QUERY_NAME])
        client.invalidateQueries([VIDEO_QUERY_NAME])
        client.invalidateQueries([CLASSIC_QUERY_NAME])
        client.invalidateQueries([SEARCH_QUERY_NAME])
        break
      case "mini-games":
        client.invalidateQueries(["MINI_GAME_DETAILS"])
        break
      case "free-games": // need to check
        client.invalidateQueries(["FREE_GAME_DETAILS"])
        break
      case "skypark":
        client.invalidateQueries(["skyPark/games"])
        client.invalidateQueries(["skyPark/status"])
        skypark.setRefresh(true)
        break
      case "bet365":
        client.invalidateQueries(["bet365/status"])
        client.invalidateQueries([ONGOING_BETS_QUERY])
        client.invalidateQueries(["bet365/presets"])
        client.invalidateQueries(["charge-history"])
        bet365.setIsRefresh(true)
        break
      case "bti_sports":
        client.invalidateQueries([GET_SPORTS_URL_QUERY])
        break
      default:
        break
    }
  }

  const refreshNtryQueries = () => {
    client.invalidateQueries(["LAUNCH_FREE_NTRY"])
    client.invalidateQueries(["MINI_GAME_DETAILS"])
    client.invalidateQueries(["FREE_GAME_DETAILS"])
    client.invalidateQueries(["FREE_NTRY_SETTINGS"])
    client.invalidateQueries(["FREE_NTRY_DETAILS"])
    client.invalidateQueries(["FREE_GAME_HEADER_DETAILS"])
  }

  const refreshFreeBet365Queries = () => {
    client.invalidateQueries(["LAUNCH_FREE_BET365"])
    client.invalidateQueries(["FREE_BET365_SETTINGS"])
    client.invalidateQueries(["FREE_BET365_DETAILS"])
  }

  const refreshFreeGames = (data: Record<string, any>): void => {
    refreshNtryQueries()
    refreshFreeBet365Queries()
  }

  const refreshFreeGamesLanding = () => {
    client.invalidateQueries(["FREE_GAME_DETAILS"])
    client.invalidateQueries(["FREE_GAME_HEADER_DETAILS"])
  }

  const refreshLevelSettings = (data: Record<string, any>): void => {
    refreshNtryQueries()
    client.invalidateQueries(["PLAYER_CAN_PLAY_FREE_GAMES"])
  }

  const activateFreeGames = (data: Record<string, any>): void => {
    refreshNtryQueries()
    refreshFreeBet365Queries()
    // const ntry = useFreeNtryPowerBallStore.getState()
    // ntry.setIsLoading(false)
  }

  const reLaunchFreeNtry = (data: Record<string, any>): void => {
    client.invalidateQueries(["LAUNCH_FREE_NTRY"])
  }

  const reLaunchFreeBet365 = (data: Record<string, any>): void => {
    refreshFreeGamesLanding()
    client.invalidateQueries(["LAUNCH_FREE_BET365"])
  }

  useEffect(() => {
    if (!publicGameNotifChannel) return

    publicGameNotifChannel.bind(EVENT.GAME_UPDATE, refreshGames)
    publicGameNotifChannel.bind(EVENT.GAME_ACTIVATED, activateFreeGames)
    publicGameNotifChannel.bind(EVENT.GAME_DEACTIVATED, refreshFreeGames)
    publicGameNotifChannel.bind(EVENT.GAME_SETTINGS, refreshFreeGames)
    publicGameNotifChannel.bind(EVENT.LEVEL_SETINGS, refreshLevelSettings)
    publicGameNotifChannel.bind(EVENT.FREE_NTRY_RELAUNCH, reLaunchFreeNtry)
    publicGameNotifChannel.bind(
      EVENT.FREE_DRAGONTIGER_RELAUNCH,
      refreshFreeGamesLanding,
    )
    publicGameNotifChannel.bind(
      EVENT.FREE_BACCARAT_RELAUNCH,
      refreshFreeGamesLanding,
    )
    publicGameNotifChannel.bind(EVENT.FREE_DICE_RELAUNCH, refreshFreeGamesLanding)
    publicGameNotifChannel.bind(
      EVENT.FREE_BET365_SOCCER_RELAUNCH,
      reLaunchFreeBet365,
    )
    publicGameNotifChannel.bind(
      EVENT.FREE_BET365_BASKETBALL_RELAUNCH,
      reLaunchFreeBet365,
    )

    return () => {
      const pusherStore = usePusherStore.getState()
      const channel = pusherStore.publicGameNotifChannel
      if (channel) {
        channel.unbind(EVENT.GAME_UPDATE)
        channel.unbind(EVENT.GAME_ACTIVATED)
        channel.unbind(EVENT.GAME_DEACTIVATED)
        channel.unbind(EVENT.GAME_SETTINGS)
        channel.unbind(EVENT.LEVEL_SETINGS)
        channel.unbind(EVENT.FREE_NTRY_RELAUNCH)
        channel.unbind(EVENT.FREE_BET365_SOCCER_RELAUNCH)
        channel.unbind(EVENT.FREE_BET365_BASKETBALL_RELAUNCH)
      }
    }
  }, [publicGameNotifChannel])
}

export default usePublicGameEvents
