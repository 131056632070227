const REQUIRED = `필수 정보입니다.`
const USERNAME_LABEL = `아이디`
const USERNAME_LABEL_HELPER = `6 ~ 14자 영문 소문자, 숫자 필수 [ 특수문자 불가 ]`
const USERNAME_LABEL_PLACEHOLDER = `6 ~ 14자 영문 소문자, 숫자 필수 [ 특수문자 불가 ]`
const USERNAME_PATTERN = /^(?:[0-9]+[a-z]|[a-z]+[0-9])[a-z0-9]*$/i

const PASSWORD_LABEL = `비밀번호`
const PASSWORD_LABEL_HELPER = `8~20 자 영문, 숫자, 특수문자 사용`
const PASSWORD_PLACEHOLDER = `8~20 자 영문, 숫자, 특수문자 사용`
const PASSWORD_PATTERN =
  /^(?=.*[a-z])(?=.*[A-Z])(?!=.*[가-힣])(?=.*\d)(?=.*[$&+,:;=?@#|'"{}_[\]\\/`~<>.^*()%!-])[A-Za-z\d$&+,:;=?@#|'"{}_[\]\\/`~<>.^*()%!-]{1,}$/

const PASSWORD_CONFIRM_LABEL = `비밀번호 재확인`
const PASSWORD_CONFIRM_PLACEHOLDER = `비밀번호 재확인`

const PLAYER_LABEL = `이름`
const PLAYER_LABEL_HELPER = `입금계좌 예금주와 동일명`
const PLAYER_PLACEHOLDER = `입금계좌 예금주와 동일명`

const PLAYER_MOBILE_LABEL = `전화번호`
const PLAYER_MOBILE_LABEL_HELPER = `숫자만 입력해 주세요`
const PLAYER_MOBILE_PLACEHOLDER = `숫자만 입력해 주세요`

const REFERRER_LABEL = `추천인코드`
const REFERRER_LABEL_HELPER = `추천인 아이디를 입력해 주세요`
const REFERRER_PLACEHOLDER = `추천인 아이디를 입력해 주세요`

const CAPTCHA_LABEL = `나는 로봇이 아닙니다.`

const TERMS_LABEL = `아이디저장`

export default {
  messages: {
    valid: "인증번호가 전송되었습니다.",
    invalid: "사용 할 수 없는 번호 입니다.",
    restricted: "현재 이용 할 수 없는\n회원 입니다.",
    maxtry: "인증번호 요청 5 회 초과 10 분 후 재요청 해주시기 바랍니다.",
  },
  username: {
    label: USERNAME_LABEL,
    labelHelper: USERNAME_LABEL_HELPER,
    placeholder: USERNAME_LABEL_PLACEHOLDER,
    rules: {
      required: REQUIRED,
      minLength: {
        value: 6,
        message: REQUIRED,
      },
      maxLength: {
        value: 14,
        message: REQUIRED,
      },
      pattern: {
        value: USERNAME_PATTERN,
        message: REQUIRED,
      },
    },
  },
  password: {
    label: PASSWORD_LABEL,
    labelHelper: PASSWORD_LABEL_HELPER,
    placeholder: PASSWORD_PLACEHOLDER,
    rules: {
      required: REQUIRED,
      minLength: {
        value: 8,
        message: REQUIRED,
      },
      maxLength: {
        value: 20,
        message: REQUIRED,
      },
      pattern: {
        value: PASSWORD_PATTERN,
        message: "does not match",
      },
    },

    notMatch: "입력하신 비밀번호와 일치합니다.",
  },
  passwordConfirm: {
    label: PASSWORD_CONFIRM_LABEL,
    placeholder: PASSWORD_CONFIRM_PLACEHOLDER,
    rules: {
      required: REQUIRED,
      minLength: {
        value: 8,
        message: REQUIRED,
      },
      maxLength: {
        value: 20,
        message: REQUIRED,
      },
      pattern: {
        value: PASSWORD_PATTERN,
        message: "does not match",
      },
    },

    notMatch: "입력하신 비밀번호와 일치합니다.",
  },
  playerName: {
    label: PLAYER_LABEL,
    labelHelper: PLAYER_LABEL_HELPER,
    placeholder: PLAYER_PLACEHOLDER,
    rules: {
      required: REQUIRED,
    },
  },
  playerMobile: {
    label: PLAYER_MOBILE_LABEL,
    labelHelper: PLAYER_MOBILE_LABEL_HELPER,
    placeholder: PLAYER_MOBILE_PLACEHOLDER,
  },
  referrer: {
    label: REFERRER_LABEL,
    labelHelper: REFERRER_LABEL_HELPER,
    placeholder: REFERRER_PLACEHOLDER,
  },
  captcha: {
    label: CAPTCHA_LABEL,
  },
  terms: {
    label: TERMS_LABEL,
  },
}
