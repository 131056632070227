import request from "services/request"
import API from "services/Account/FindID/endpoints"

function headers() {
  return {
    "Accept": "application/json",
    "Content-Type": "application/json",
  }
}

function checkMobile(data: Record<string, string>): Promise<unknown> {
  return request({
    url: API.CHECK_MOBILE,
    method: "POST",
    data: data,
    headers: headers(),
  })
}

function getOtpCode(data: Record<string, string>): Promise<unknown> {
  return request({
    url: API.GET_OTP_CODE,
    method: "POST",
    data,
    headers: headers(),
  })
}

function sendOTP(data: Record<string, any>): Promise<unknown> {
  return request({
    url: API.SEND_OTP,
    method: "POST",
    data,
    headers: headers(),
  })
}

function registerUser(data: Record<string, string>): Promise<unknown> {
  return request({
    url: API.SUBMIT_FORM,
    method: "POST",
    data,
    headers: headers(),
  })
}

export default {
  checkMobile,
  getOtpCode,
  sendOTP,
  registerUser,
}
