import React from "react"
import {useEffect} from "react"
import shallow from "zustand/shallow"
import usePusherStore from "stores/usePusherStore"
import EVENT from "provider/pusher/pusherEvents"
import useGlobalAlertStore from "screens/NavHome/store/useGlobalAlertStore"
import {CashbackAlertContent, CashbackEvent} from "components/CashbackAlertContent"
import bus from "utils/events/bus"

const useCashbackEvents = () => {
  const privateNotifChannel = usePusherStore(
    store => store.privateNotifChannel,
    shallow,
  )

  const cashbackMessage = (data: CashbackEvent) => {
    if (data.type === "single") {
      bus.emit("flash", {
        message: "베팅이 수락 되었습니다.",
        type: "success",
        details: "자세한 내용은 베팅 내역에서 확인 가능 합니다.",
      })
    } else {
      const alertStore = useGlobalAlertStore.getState()
      alertStore.setClassname("cashback-popup-modal")
      alertStore.showAlert(<CashbackAlertContent data={data} />)
    }
  }

  useEffect(() => {
    if (!privateNotifChannel) return

    privateNotifChannel.bind(EVENT.BTI_COMMIT_RESERVE, cashbackMessage)

    return () => {
      const pusherStore = usePusherStore.getState()
      const channel = pusherStore.privateNotifChannel
      if (channel) {
        channel.unbind(EVENT.BTI_COMMIT_RESERVE)
      }
    }
  }, [privateNotifChannel])
}

export default useCashbackEvents
