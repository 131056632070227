import {useEffect} from "react"
import shallow from "zustand/shallow"
import {useQueryClient} from "react-query"
import usePusherStore from "stores/usePusherStore"
import EVENT from "provider/pusher/pusherEvents"
import {RECHARGE_HISTORY_QUERY} from "screens/Home/Account/Screens/History/config"
import {MONEY_HISTORY_QUERY} from "screens/Home/MyPage/Screens/MoneyHistory/config"
import Bus from "utils/events/bus"

const useDepositEvents = () => {
  const client = useQueryClient()
  const privateNotifChannel = usePusherStore(
    store => store.privateNotifChannel,
    shallow,
  )

  const flashDepositApproval = (typeId: number) => {
    switch (typeId) {
      case 1:
        return Bus.emit("flash", {
          message: "관리자에 의해 충전 요청 취소 되었습니다.",
          type: "danger",
          details: `정확한 정보 입력 / 재신청 해주시기 바랍니다.`,
        })
      case 2:
        return Bus.emit("flash", {
          message: "신청 금액과 입금 금액이 불일치 하여",
          type: "danger",
          details: `<span style="color: #FA3939;">충전 요청 취소 되었습니다.</span> 재신청 해주시기 바랍니다.`,
        })
      case 3:
        return Bus.emit("flash", {
          message: "등록된 이름과 입금자명이 불일치 하여",
          type: "danger",
          details: `<span style="color: #FA3939;">충전 요청 취소 되었습니다.</span> 재신청 해주시기 바랍니다.`,
        })
      case 4:
        return Bus.emit("flash", {
          message: "신청 유효시간 초과로 충전 요청 취소 되었습니다.",
          type: "danger",
          details: `재신청 해주시기 바랍니다.`,
        })
    }
  }

  const approvedRefreshHistory = data => {
    client.invalidateQueries([RECHARGE_HISTORY_QUERY])
    client.invalidateQueries([MONEY_HISTORY_QUERY])
    Bus.emit("flash", {message: data.title, type: "success", details: data.message})
  }

  const rejectedRefreshHistory = data => {
    client.invalidateQueries([RECHARGE_HISTORY_QUERY])
    client.invalidateQueries([MONEY_HISTORY_QUERY])
    flashDepositApproval(data?.rejected_type_id)
  }

  const cancelledRefreshHistory = data => {
    client.invalidateQueries([RECHARGE_HISTORY_QUERY])
    client.invalidateQueries([MONEY_HISTORY_QUERY])
    Bus.emit("flash", {message: data.title, type: "danger", details: data.message})
  }

  useEffect(() => {
    if (!privateNotifChannel) return

    // console.log("bind depositEvents")

    privateNotifChannel.bind(EVENT.ADMIN_APPROVED_DEPOSIT, approvedRefreshHistory)
    privateNotifChannel.bind(EVENT.ADMIN_REJECTED_DEPOSIT, rejectedRefreshHistory)
    privateNotifChannel.bind(EVENT.ADMIN_CANCEL_DEPOSIT, cancelledRefreshHistory)
    privateNotifChannel.bind(EVENT.ADMIN_EXPIRE_DEPOSIT, cancelledRefreshHistory)
    privateNotifChannel.bind(
      EVENT.ADMIN_BANK_REQUEST_REJECTED,
      cancelledRefreshHistory,
    )
    privateNotifChannel.bind(
      EVENT.ADMIN_BANK_REQUEST_CANCEL,
      cancelledRefreshHistory,
    )

    return () => {
      const pusherStore = usePusherStore.getState()
      const channel = pusherStore.privateNotifChannel
      if (channel) {
        // console.log("unbind depositEvents")
        channel.unbind(EVENT.ADMIN_APPROVED_DEPOSIT)
        channel.unbind(EVENT.ADMIN_REJECTED_DEPOSIT)
        channel.unbind(EVENT.ADMIN_CANCEL_DEPOSIT)
        channel.unbind(EVENT.ADMIN_EXPIRE_DEPOSIT)
        channel.unbind(EVENT.ADMIN_BANK_REQUEST_REJECTED)
        channel.unbind(EVENT.ADMIN_BANK_REQUEST_CANCEL)
      }
    }
  }, [privateNotifChannel])
}

export default useDepositEvents
