// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, {Dispatch, SetStateAction} from "react"

import {iDetails} from "screens/Account/Register/Interface"

export type CtxType = {
  formSuccess: boolean
  formSubmitting: boolean
  isInvalidReferrer: boolean
  referrerSubmitting: boolean
  localTerms: boolean
  localReferrer: string
  registerDetails: iDetails
  localValidReferrer: string
  registerDelays: Record<string, number>
  setDetails: (key: string, value: string | boolean) => void
  setInvalidReferrer: Dispatch<SetStateAction<boolean>>
  setRegisterDetails: Dispatch<SetStateAction<iDetails>>
  setLocalTerms: Dispatch<SetStateAction<boolean>>
  setLocalReferrer: Dispatch<SetStateAction<string>>
  setLocalValidReferrer: Dispatch<SetStateAction<string>>
  handleSubmitForm: () => void
  referrerCallback: (value: string) => void
}

export const Context = React.createContext<Partial<CtxType>>({})
