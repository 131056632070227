// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from "react"

interface iIconCalendar {
  color?: string
}

export default function IconCaelndar({
  color = "#FFFFFF",
}: iIconCalendar): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="24"
      viewBox="0 0 21 24"
    >
      <path
        id="calendar-alt-light"
        d="M18.75,3H16.5V.563A.564.564,0,0,0,15.938,0h-.375A.564.564,0,0,0,15,.563V3H6V.563A.564.564,0,0,0,5.438,0H5.063A.564.564,0,0,0,4.5.563V3H2.25A2.251,2.251,0,0,0,0,5.25v16.5A2.251,2.251,0,0,0,2.25,24h16.5A2.251,2.251,0,0,0,21,21.75V5.25A2.251,2.251,0,0,0,18.75,3ZM2.25,4.5h16.5a.752.752,0,0,1,.75.75V7.5H1.5V5.25A.752.752,0,0,1,2.25,4.5Zm16.5,18H2.25a.752.752,0,0,1-.75-.75V9h18V21.75A.752.752,0,0,1,18.75,22.5ZM6.938,15H5.063a.564.564,0,0,1-.562-.562V12.563A.564.564,0,0,1,5.063,12H6.938a.564.564,0,0,1,.563.563v1.875A.564.564,0,0,1,6.938,15Zm4.5,0H9.563A.564.564,0,0,1,9,14.438V12.563A.564.564,0,0,1,9.563,12h1.875a.564.564,0,0,1,.563.563v1.875A.564.564,0,0,1,11.438,15Zm4.5,0H14.063a.564.564,0,0,1-.562-.562V12.563A.564.564,0,0,1,14.063,12h1.875a.564.564,0,0,1,.563.563v1.875A.564.564,0,0,1,15.938,15Zm-4.5,4.5H9.563A.564.564,0,0,1,9,18.938V17.063a.564.564,0,0,1,.563-.562h1.875a.564.564,0,0,1,.563.563v1.875A.564.564,0,0,1,11.438,19.5Zm-4.5,0H5.063a.564.564,0,0,1-.562-.562V17.063a.564.564,0,0,1,.563-.562H6.938a.564.564,0,0,1,.563.563v1.875A.564.564,0,0,1,6.938,19.5Zm9,0H14.063a.564.564,0,0,1-.562-.562V17.063a.564.564,0,0,1,.563-.562h1.875a.564.564,0,0,1,.563.563v1.875A.564.564,0,0,1,15.938,19.5Z"
        fill={color}
      />
    </svg>
  )
}
