import React, {DetailedHTMLProps, HTMLAttributes} from "react"

export type IconProps = {
  fill?: string
  fillTo?: string
} & DetailedHTMLProps<HTMLAttributes<SVGSVGElement>, SVGSVGElement>

export const LiveChatIcon: React.FC<IconProps> = ({fill = "#fff", ...props}) => (
  <svg
    id="Group_34853"
    data-name="Group 34853"
    xmlns="http://www.w3.org/2000/svg"
    width="37"
    height="30"
    viewBox="0 0 37 30"
    {...props}
  >
    <defs>
      <clipPath id="clip-path-chat">
        <rect
          id="Rectangle_17516"
          data-name="Rectangle 17516"
          width="37"
          height="30"
          fill="none"
        />
      </clipPath>
    </defs>
    <g id="Group_34854" data-name="Group 34854" clipPath="url(#clip-path-chat)">
      <path
        id="Path_66553"
        data-name="Path 66553"
        d="M33.3,10.466h-.316C31.569,4.75,25.623,0,18.5,0S5.431,4.75,4.017,10.466H3.7A3.71,3.71,0,0,0,0,14.179v3.713A3.71,3.71,0,0,0,3.7,21.6h.925a1.851,1.851,0,0,0,1.85-1.856v-6.9C6.61,7.42,11.94,3.059,18.5,3.059c6.542,0,11.862,4.337,12.023,9.741v6.948a1.851,1.851,0,0,0,1.85,1.856H33.3A3.71,3.71,0,0,0,37,17.891V14.179a3.71,3.71,0,0,0-3.7-3.713"
        fill={fill}
      />
      <path
        id="Path_66554"
        data-name="Path 66554"
        d="M170.53,118.466a9.2,9.2,0,0,0,0,18.4v4.729l6.666-7.611h0a9.205,9.205,0,0,0-6.665-15.518m-4.993,10.665A1.466,1.466,0,1,1,167,127.665a1.464,1.464,0,0,1-1.461,1.466m4.993,0a1.466,1.466,0,1,1,1.461-1.466,1.464,1.464,0,0,1-1.461,1.466m5.254,0a1.466,1.466,0,1,1,1.461-1.466,1.464,1.464,0,0,1-1.461,1.466"
        transform="translate(-152.03 -111.594)"
        fill={fill}
      />
    </g>
  </svg>
)
