import React, {useMemo} from "react"
import {CategoryIconType} from "./CategoryIconType"

const HoundSVG = ({
  active,
  className,
  fill1,
  fill2,
}: CategoryIconType): JSX.Element => {
  const fill = useMemo(() => {
    return active ? fill1 : fill2
  }, [active])

  return (
    <svg
      id="Group_25408"
      data-name="Group 25408"
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="23.893"
      viewBox="0 0 45 23.893"
    >
      <g
        id="Group_25407"
        data-name="Group 25407"
        transform="translate(0 0)"
        clip-path="url(#clip-path)"
      >
        <path
          id="Path_62152"
          data-name="Path 62152"
          d="M33.911,3.533,32.62,2.927c.314-.558.643-.809,1.17-.726a4.548,4.548,0,0,0,.473,0A4.133,4.133,0,0,0,36.325,1.8a4.546,4.546,0,0,1,4.928.343c.9.66,1.965.551,2.955.793.374.092.8.1.793.638-.005.379-.385.617-.869.714-.709.143-1.4-.117-2.1-.015-.38.055-.787.025-1,.5.983.208,1.977-.121,2.956.1-.076.542-.463.641-.884.655-.789.027-1.579.032-2.369.045a4.781,4.781,0,0,0-3.355,1.532q-3.94,3.878-7.9,7.737a4.668,4.668,0,0,1-3.144,1.119,18.176,18.176,0,0,0-3.364.492c-.454.118-.792.687-1.161,1.031a3.415,3.415,0,0,0,2.446,1.954c.452.123.649.364.46.882-.686,0-1.4.012-2.118-.011-.131,0-.27-.139-.382-.24-.481-.437-.953-.883-1.458-1.354-.556.607-1.174,1.268-1.771,1.948a.543.543,0,0,0-.085.427,10.084,10.084,0,0,0,.4,1.3.714.714,0,0,0,.394.344c.375.136.768.219,1.158.326v.826c-.748,0-1.48.01-2.211-.012-.092,0-.222-.15-.263-.258-.349-.914-.695-1.83-1.007-2.757a.794.794,0,0,1,.091-.57q.829-1.462,1.7-2.9c.169-.275.109-.434-.073-.683a1.637,1.637,0,0,0-1.9-.651,12.721,12.721,0,0,1-2.865.342,7.543,7.543,0,0,1-2.285-.48c-.261-.089-.4-.535-.589-.809.764-.742.833-.784,1.688-.368a2.409,2.409,0,0,0,1.764.159c.809-.211,1.639-.337,2.5-.508l-.272-.711c-.935.5-2.458.657-2.978-.262a2.057,2.057,0,0,0,.133-.213c.235-.488.56-.775,1.148-.582a1.468,1.468,0,0,0,.472.025.553.553,0,0,0,.544-.766c-.2-.543-.386-1.09-.611-1.621A2.618,2.618,0,0,0,14.474,8.82a4.5,4.5,0,0,1-2.866-4.062.851.851,0,0,0-.316-.621c-.31-.152-.554.086-.712.371-.636,1.15-1.236,2.32-1.916,3.442A8.05,8.05,0,0,1,5.547,11,3.83,3.83,0,0,1,.169,9.247C.053,9-.154,8.693.188,8.506c.376-.2.46.176.583.411a3.148,3.148,0,0,0,3.314,1.9A5.232,5.232,0,0,0,6.877,9.233,13.091,13.091,0,0,0,9.185,5.8a11.765,11.765,0,0,1,2.822-4.075,6.912,6.912,0,0,1,7.9-.876,10.773,10.773,0,0,1,4.046,3.744,13.468,13.468,0,0,0,1.822,2.167,2.49,2.49,0,0,0,2.356.349A8.147,8.147,0,0,0,31.429,5.53c.863-.658,1.692-1.359,2.482-2M20.838,11.555c-1.107.565-1.2.736-.917,1.554.26-.523.911-.769.917-1.554"
          transform="translate(0 0)"
          fill={fill}
        />
        <path
          id="Path_62153"
          data-name="Path 62153"
          d="M2.014,23.288c-.006.785-.658,1.031-.917,1.554-.287-.818-.19-.99.917-1.554"
          transform="translate(-0.417 -10.12)"
          fill={fill}
        />
        <path
          id="Path_62154"
          data-name="Path 62154"
          d="M10.352,27.314H2.66a.424.424,0,1,1,0-.848h7.692a.424.424,0,1,1,0,.848"
          transform="translate(-0.972 -11.501)"
          fill={fill}
        />
        <path
          id="Path_62155"
          data-name="Path 62155"
          d="M13.436,24.742H7.592a.424.424,0,0,1,0-.848h5.844a.424.424,0,1,1,0,.848"
          transform="translate(-3.115 -10.383)"
          fill={fill}
        />
        <path
          id="Path_62156"
          data-name="Path 62156"
          d="M50.2,5.975H45.034a.424.424,0,0,1,0-.848H50.2a.424.424,0,1,1,0,.848"
          transform="translate(-19.386 -2.228)"
          fill={fill}
        />
      </g>
    </svg>
  )
}

export default HoundSVG
