export const GET_SPORTS_URL_QUERY = "GET_SPORTS_URL_QUERY"

// TODO: once BETICA updated their footer, sync the keys
export const SPORTS_FOOTER_LINKS = [
  {
    code: "BETTING_RULES.EZ_BETTING_RULES",
    keyLink: "PRE_MATCH_MANUAL",
    path: "/pre-match-betting",
  },
  {
    code: "BETTING_RULES.SPORTS_BETTING_RULES",
    keyLink: "LIVE_SPORTS_MANUAL",
    path: "/live-sports-betting",
  },
  {
    code: "BETTING_RULES.SPORTS_RESULTS",
    keyLink: "LIVE_SPORTS_MANUAL",
    path: "/live-sports-betting",
  },
  {
    code: "INFO.MEMBERSHIP_BENEFITS",
    keyLink: "LVL_MANUAL",
    path: "/membership-level",
  },
  {
    code: "BETTING_RULES.GAME_USAGE_GUIDE",
    keyLink: "GAME_MANUAL",
    path: "/game-manual",
  },
  {
    code: "BETTING_RULES.SITE_USAGE_GUIDE",
    keyLink: "USER_MANUAL",
    path: "/user-manual",
  },
  {code: "TERMS.TERMS_OF_USE", keyLink: "TERMS_OF_USE", path: "/terms"},
  {
    code: "TERMS.RESPONSIBLE_GAME",
    keyLink: "GAMING_CULTURE",
    path: "/gaming-culture",
  },
  {code: "TERMS.PRIVACY", keyLink: "PRIVACY_POLICY", path: "/privacy-policy"},
  {code: "INFO.COMPANY_INTRO", keyLink: "", path: "/user-manual"},
  {code: "INFO.SITE_USAGE_GUIDE", keyLink: "", path: "/user-manual"},
  {code: "TERMS.ANTI_MONEY_LAUNDERING", keyLink: "AMLA", path: "/amla"},
]
