import React, {FC} from "react"
import cx from "classnames"

import {noop} from "lodash"

import s from "./linkwithfade.module.css"
import useNavBarController from "screens/NavHome/hooks/useNavBarController"
import useGlobalAlertStore from "screens/NavHome/store/useGlobalAlertStore"
import useRegisterModalStore from "components/RegisterButton/hooks/useRegisterModalStore"
import {SIGNUP} from "screens/NavHome/utils/config"
export type Props = {
  id?: string
  to?: string
  className?: string
  style?: Record<string, string | number>
  label?: string
  styledLink?: boolean
  customLinkHandler?(): void
  onClick?(): void
}

const LinkWithFade: FC<Props> = props => {
  const {label, onClick = noop, customLinkHandler} = props

  const {handleNavigateTo} = useNavBarController()

  const handleComingSoon = () => {
    const alertStore = useGlobalAlertStore.getState()
    alertStore.setMessage("COMING SOON")
    alertStore.setIsModalOpen(true)
  }

  const handleOnClick = () => {
    const {to} = props
    const isRegisterEnabled = useRegisterModalStore.getState()?.isEnabled
    if (to === SIGNUP && !isRegisterEnabled) handleComingSoon()
    else handleNavigateTo(to)
    onClick && onClick()
  }

  return (
    <span
      id={props.id}
      style={props.style}
      className={cx(props.className, {
        [s.styledLink]: props.styledLink,
      })}
      onClick={customLinkHandler || handleOnClick}
    >
      {label || props.children}
    </span>
  )
}

export default LinkWithFade
