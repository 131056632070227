const CDN_URL = process.env.REACT_APP_IMG_URL

// desktop banners
const DragonBanner =
  CDN_URL + "/assets/FreeGames/banners/desktop/dragon-tiger-cards.png"
const DicesBanner = CDN_URL + "/assets/FreeGames/banners/desktop/dices-game.png"
const SoccerBanner =
  CDN_URL + "/assets/FreeGames/banners/desktop/soccer-girl-ball.png"
const BaccaratBanner =
  CDN_URL + "/assets/FreeGames/banners/desktop/baccarat-game-cards.png"
const BasketballBanner =
  CDN_URL + "/assets/FreeGames/banners/desktop/basketball-game.png"
const NtryPowerballBanner =
  CDN_URL + "/assets/FreeGames/banners/desktop/ntry-powerball-game.png"

// mobile banners
const DragonBannerMobile =
  CDN_URL + "/assets/FreeGames/banners/mobile/dragon-tiger-cards_mobile.jpg"
const DicesBannerMobile =
  CDN_URL + "/assets/FreeGames/banners/mobile/dices-game_mobile.jpg"
const SoccerBannerMobile =
  CDN_URL + "/assets/FreeGames/banners/mobile/soccer-girl-ball_mobile.jpg"
const BaccaratBannerMobile =
  CDN_URL + "/assets/FreeGames/banners/mobile/baccarat-game-cards_mobile.jpg"
const BasketballBannerMobile =
  CDN_URL + "/assets/FreeGames/banners/mobile/basketball-game_mobile.jpg"
const NtryPowerballBannerMobile =
  CDN_URL + "/assets/FreeGames/banners/mobile/ntry-powerball-game_mobile.jpg"

// Desktop Icons
const DragonTigerIconIdle =
  CDN_URL + "/assets/FreeGames/icons/desktop/Dragon-Tiger_icon_(idle).png"
const DragonTigerIconActive =
  CDN_URL + "/assets/FreeGames/icons/desktop/Dragon-Tiger_icon_(active).png"
const DragonTigerIconPause =
  CDN_URL + "/assets/FreeGames/icons/desktop/Dragon-Tiger_icon_(pause).png"
const BasketballIconIdle =
  CDN_URL + "/assets/FreeGames/icons/desktop/Bet365_Basketball_icon_(idle).png"
const BasketballIconActive =
  CDN_URL + "/assets/FreeGames/icons/desktop/Bet365_Basketball_icon_(active).png"
const BasketballIconPause =
  CDN_URL + "/assets/FreeGames/icons/desktop/Bet365_Basketball_icon_(pause).png"
const SoccerIconActive =
  CDN_URL + "/assets/FreeGames/icons/desktop/Bet365_Soccer_icon_(active).png"
const SoccerIconIdle =
  CDN_URL + "/assets/FreeGames/icons/desktop/Bet365_Soccer_icon_(idle).png"
const SoccerIconPause =
  CDN_URL + "/assets/FreeGames/icons/desktop/Bet365_Soccer_icon_(pause).png"
const EntryPowerballIconIdle =
  CDN_URL + "/assets/FreeGames/icons/desktop/Entry-Powerball_icon_(idle).png"
const EntryPowerballIconActive =
  CDN_URL + "/assets/FreeGames/icons/desktop/Entry-Powerball_icon_(active).png"
const BaccaratIconActive =
  CDN_URL + "/assets/FreeGames/icons/desktop/Skypark-Baccarat_icon_(active).png"
const BaccaratIconIdle =
  CDN_URL + "/assets/FreeGames/icons/desktop/Skypark-Baccarat_icon_(idle).png"
const BaccaratIconPause =
  CDN_URL + "/assets/FreeGames/icons/desktop/Skypark-Baccarat_icon_(pause).png"
const DiceIconActive =
  CDN_URL + "/assets/FreeGames/icons/desktop/Skypark-Dice_icon_(active).png"
const DiceIconIdle =
  CDN_URL + "/assets/FreeGames/icons/desktop/Skypark-Dice_icon_(idle).png"
const DiceIconPause =
  CDN_URL + "/assets/FreeGames/icons/desktop/Skypark-Dice_icon_(pause).png"

// SVG
const DragonTigerHeader = CDN_URL + "/assets/FreeGames/header/dragontiger.svg"
const BaccaratHeader = CDN_URL + "/assets/FreeGames/header/baccarat.svg"
const Soccer = CDN_URL + "/assets/FreeGames/header/soccer.svg"
const BasketBall = CDN_URL + "/assets/FreeGames/header/basketball.svg"
const Dice = CDN_URL + "/assets/FreeGames/header/dice.svg"
const backIcon = CDN_URL + "/assets/FreeGames/header/backIcon.svg"
const pokerIcon = CDN_URL + "/assets/FreeGames/header/pokerIcon.svg"
const coinIcon = CDN_URL + "/assets/FreeGames/header/coinIcon.svg"

export {
  // desktop banners
  DragonBanner,
  DicesBanner,
  SoccerBanner,
  BaccaratBanner,
  BasketballBanner,
  NtryPowerballBanner,

  // mobile banners
  DragonBannerMobile,
  DicesBannerMobile,
  SoccerBannerMobile,
  BaccaratBannerMobile,
  BasketballBannerMobile,
  NtryPowerballBannerMobile,

  // Desktop Icons
  DragonTigerIconIdle,
  DragonTigerIconActive,
  DragonTigerIconPause,
  BasketballIconIdle,
  BasketballIconActive,
  BasketballIconPause,
  SoccerIconActive,
  SoccerIconIdle,
  SoccerIconPause,
  EntryPowerballIconIdle,
  EntryPowerballIconActive,
  BaccaratIconActive,
  BaccaratIconIdle,
  BaccaratIconPause,
  DiceIconActive,
  DiceIconIdle,
  DiceIconPause,

  //SVG
  DragonTigerHeader,
  BaccaratHeader,
  Soccer,
  BasketBall,
  Dice,
  backIcon,
  pokerIcon,
  coinIcon,
}
