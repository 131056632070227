import React, {FC, memo} from "react"

import {Charge} from "types/Bet365/index"

import s from "screens/Bet365/components/BetSlipV2/betSlipV2.module.scss"

interface iSingleBet {
  charge: Charge
  className?: string
}

const SingleBet: FC<iSingleBet> = ({charge}) => {
  const detail = charge.details[0]
  const markets = detail.market
  const odd = detail.odds

  return (
    <div className={s.marketDetails}>
      <div className={s.marketOddsRow}>
        <div className={s.marketOdds}>
          <div className={s.marketName}>{markets?.hangul_name}</div>
          <span className={s.oddsName}>{odd?.hangul_name}</span>
        </div>
        <div className={s.odds}>{odd.odds.toFixed(2)}</div>
      </div>
    </div>
  )
}

export default memo(SingleBet)
