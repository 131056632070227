import {
  TermsConditions,
  Forms,
  OTP,
  CircleCheck,
  Lock,
  ReviewApplication,
} from "screens/MyProfile/icons"
import useUsernameInputController from "./hooks/useUsernameInputController"
import useDistributorNameInputController from "./hooks/useDistributorNameInputController"
import useEmailInputController from "./hooks/useEmailInputController"
import useTelegramInputController from "./hooks/useTelegramInputController"
import useUsernameInputStore from "./store/useUsernameInputStore"
import useDistributorNameInputStore from "./store/useDistributorNameInputStore"
import useEmailInputStore from "./store/useEmailInputStore"
import useTelegramInputStore from "./store/useTelegramInputStore"

export const TOTAL_STEPS = 4
export const STEP_1_DESC = "파트너쉽 제휴 약관"
export const STEP_2_DESC = "입력 정보 세부 정보"
export const STEP_3_DESC =
  "등록된 이메일로 OTP 전송되었습니다.\n이메일을 확인해 주세요."
export const STEP_4_DESC = "EZBET 파트너쉽 지원 완료"
export const CHECK_DETAILS = "신청내역 확인"

export const TERMS_AND_CONDITIONS =
  "EZBET 파트너쉽  등록 및 체결에 앞서 본 제휴 약정을 확인 하고 동의 하여야 하며 파트너쉽에 관련한 모든 혜택 및 권한 을 준수 하고 이를 이행 하여야 합니다.\n\nEZBET 은 언제든지 파트너쉽 제휴에 있어 수정 및 권고, 체결 연장, 변경, 취소 할수 있으며 파트너에게 모든 변경 사항을 통지 하고 이를 이행 하여야 합니다.\n\n파트너쉽 체결이 지속되는 한 정기적인 회원 관리 및 유지에 책임 의무가 있으며 이는 상호 파트너쉽 관리에 포함되어 집니다.\n\nEZBET 은 언제나 쾌적하고 신속한 웹 사용 환경이 될수 있도록 모든 회원 에게 제공 할 것을 약속드리며 파트너 또한 EZBET의 한 일원으로서 활발한 활동을 해주시기 바랍니다."
export const PROCEDURES_LBL = "등록 절차"
export const PROCEDURES_DESC =
  "EZBET 에서 새로운 제휴 계정을 만들기 위한 단계별 절차"
export const ADVERTISE_DESC =
  "어떤 EZBET 제품을 당신의 잠재적 회원들에게 가장 홍보할 것 같습니까? (여러 항목을 선택할 수 있습니다.)"
export const SUBMIT_DESC =
  "지원서 검토 및 승인 관련하여 최대 24시간 이내에 이메일로 알려 드리겠습니다."
export const PENDING_DESC = "파트너쉽 검토중 입니다."
export const PENDING_SUB_DESC =
  "파트너쉽 지원서 검토중 입니다.\n승인 여부는 입력 하신 이메일로 전송 됩니다."
export const APPROVED_DESC = "파트너 코드가 이 계정과 동기화 되었습니다."
export const APPROVED_SUB_DESC =
  "계정 세부 정보가 이메일로 전송되었으며,\n등록한 계정 정보를 사용하여 파트너 페이지를 이용 하실 수 있습니다."
export const AGREE_LBL = "나는 동의한다"
export const AUTH_NUMBER_LBL = "인증번호받기"
export const APPLY_LBL = "적용"
export const CHECK_LBL = "확인"
export const AFFILIATE_PORTAL = "파트너 페이지 바로가기"
export const APPROVED_EMAIL_LBL = "이메일"
export const APPROVED_CODE_LBL = "파트너 코드"
export const APPROVED_DATE_LBL = "가입일"

export const AFFILIATE_URL = "https://ezbet.affiliate.portal.com/"
export const AFFILIATE_SESSION_REQUEST_KEY = "AFFILIATE_SESSION_REQUEST"
export const AFFILIATE_QUESTION_QUERY = "AFFILIATE_QUESTION_QUERY"
export const AFFILIATE_PENDING_OTP_QUERY = "AFFILIATE_PENDING_OTP_QUERY"
export const AFFILIATE_DETAILS_QUERY = "AFFILIATE_DETAILS_QUERY"

export const STEP_1_CONFIG = {
  label: "Step 1",
  description: "약관 수락",
  icon: TermsConditions,
}

export const STEP_2_CONFIG = {
  label: "Step 2",
  description: "세부 정보 입력",
  icon: Forms,
}

export const STEP_3_CONFIG = {
  label: "Step 3",
  description: "이메일 OTP 확인",
  icon: OTP,
}

export const STEP_4_CONFIG = {
  label: "Step 4",
  description: "지원서 검토",
  icon: ReviewApplication,
}

export const STEP_5_CONFIG = {
  label: "Step 5",
  description: "결과 통보",
  icon: CircleCheck,
}

export const STEPS_CONFIG = [
  STEP_1_CONFIG,
  STEP_2_CONFIG,
  STEP_3_CONFIG,
  STEP_4_CONFIG,
  STEP_5_CONFIG,
]

export const INPUT_USERNAME_CONFIG = {
  helper: null,
  label: "아이디",
  isDisabled: true,
  icon: Lock,
  placeholder: "",
  store: useUsernameInputStore,
  controller: useUsernameInputController,
}

export const INPUT_EMAIL_CONFIG = {
  helper: "6자 이상, 숫자 또는 특수 문자",
  label: "이메일",
  isDisabled: false,
  icon: null,
  placeholder: "sample@email.com",
  store: useEmailInputStore,
  controller: useEmailInputController,
}

export const INPUT_DISTRUBUTOR_CONFIG = {
  helper: "1자 이상 15자 이하",
  label: "총판명",
  isDisabled: false,
  icon: null,
  placeholder: "사 이름을 입력해 주세요",
  store: useDistributorNameInputStore,
  controller: useDistributorNameInputController,
}

export const INPUT_TELEGRAM_CONFIG = {
  helper: "활성 텔레그램 ID를 입력하십시오",
  label: "텔레그램",
  isDisabled: false,
  icon: null,
  placeholder: "활성 텔레그램 ID를 입력하십시오",
  store: useTelegramInputStore,
  controller: useTelegramInputController,
}

export const FORM_CONFIG = [
  INPUT_USERNAME_CONFIG,
  INPUT_DISTRUBUTOR_CONFIG,
  INPUT_EMAIL_CONFIG,
  INPUT_TELEGRAM_CONFIG,
]

export enum TypeName {
  Sports = "스포츠",
  Casino = "라이브 카지노",
  LiveSports = "라이브 스포츠",
  VirtualSports = "가상 스포츠",
  Slots = "슬롯",
  MiniGames = "미니 게임",
}
export enum TypeCode {
  Sports = "sports",
  Casino = "live_casino",
  LiveSports = "live_sports",
  VirtualSports = "virtual_sports",
  Slots = "slots",
  MiniGames = "mini_games",
}

export const ADVERTISE_LIST = [
  {label: TypeName.Sports, value: TypeCode.Sports},
  {label: TypeName.Casino, value: TypeCode.Casino},
  {label: TypeName.LiveSports, value: TypeCode.LiveSports},
  {label: TypeName.VirtualSports, value: TypeCode.VirtualSports},
  {label: TypeName.Slots, value: TypeCode.Slots},
  {label: TypeName.MiniGames, value: TypeCode.MiniGames},
]
