import create from "zustand"
import BeticaResponse from "types/Betica/BeticaResponse"

export interface iSideBarStore {
  isOpen: boolean
  isLoading: boolean
  refreshInterval: number
  timer: number
  redirectTo: string
  data: BeticaResponse
  close: () => void
  refresh: () => void
  setIsOpen: (value: boolean) => void
  setIsLoading: (value: boolean) => void
  setRefreshInterval: (value: number) => void
  setTimer: (value: number) => void
  setRedirectTo: (value: string) => void
  setData: (value: BeticaResponse) => void
  setClose: (value: () => void) => void
  setRefresh: (value: () => void) => void
  handleCheckOpen: (callback: () => void) => void
  timerTick: () => void
  resetStore: () => void
}

const useSideBarStore = create<iSideBarStore>((set, get) => ({
  isOpen: false,
  isLoading: false,
  refreshInterval: 30,
  timer: 30,
  redirectTo: null,
  close: null,
  refresh: null,
  data: null,
  setIsOpen: isOpen => set({isOpen}),
  setIsLoading: isLoading => set({isLoading}),
  setClose: close => set({close}),
  setRefresh: refresh => set({refresh}),
  handleCheckOpen: (callback: () => void) => {
    const isOpen = get().isOpen
    const close = get().close
    if (isOpen) {
      close()
      setTimeout(() => callback(), 200)
    } else {
      callback()
    }
  },
  setRefreshInterval: refreshInterval =>
    set({refreshInterval: refreshInterval, timer: refreshInterval}),
  setTimer: timer => set({timer}),
  setRedirectTo: redirectTo => set({redirectTo}),
  setData: data => set({data}),
  timerTick: () => {
    const timer = get().timer
    const second = timer - 1
    if (second >= 0) set({timer: second})
    else {
      const refresh = get().refresh
      const refreshInterval = get().refreshInterval
      set({timer: refreshInterval})
      refresh()
    }
  },
  resetStore: () => {
    set({
      isOpen: false,
      isLoading: false,
      timer: get().refreshInterval,
      data: null,
    })
  },
}))

export default useSideBarStore
