import React from "react"
import cx from "classnames"
import s from "./cashbackAlertContent.module.scss"
import {formatAmount} from "utils/helpers"
import extracombobonus from "assets/MyProfile/extracombobonus.png"
import {CircleCheck} from "screens/MyProfile/icons"

export type CashbackEvent = {
  type: string
  bet_amount: number
  odds: number
  folds: number
  expect_win_amount: number
  combo_bet_bonus_percentage: number
  combo_bet_amount: number
  combo_bet_win_amount: number
  event_combo_bet_bonus_percentage: number
  event_combo_bet_win_amount: number
  timestamp: string
}
interface ICashbackAlertContent {
  data: any
}

enum BetTypeHanguls {
  single = "싱글",
  combobet = "멀티",
}

export const CashbackAlertContent = ({data}: ICashbackAlertContent) => {
  const {
    type,
    bet_amount,
    odds,
    folds,
    combo_bet_bonus_percentage,
    combo_bet_amount,
    combo_bet_win_amount,
    event_combo_bet_bonus_percentage,
    event_combo_bet_win_amount,
    expect_win_amount,
  } = data || {}
  console.log(data, "data")

  const handleFormatAmount = (value: number): string => {
    return formatAmount(String(Math.abs(Math.trunc(value))), true) as string
  }

  return (
    <div className={s.container}>
      <div className={s.cashbackDetails}>
        <CircleCheck width={50} height={50} />
        <div className={s.headerTitle}>베팅이 수락 되었습니다.</div>
        <div className={s.headerDescription}>
          요청 하신 베팅이 정상적 으로 접수 되었습니다. 좋은 결과 있으시길
          응원합니다.
        </div>
        <div className={s.infosContainer}>
          <div className={s.infoItem}>
            <span className={s.label}>베팅 타입 : </span>
            <span className={s.value}>{BetTypeHanguls[type]}</span>
          </div>
          <div className={s.infoItem}>
            <span className={s.label}>배당률 : </span>
            <span className={s.value}>{odds}</span>
          </div>
          {type === "combobet" && (
            <>
              <div className={s.infoItem}>
                <span className={s.label}>멀티 보너스 : </span>
                <span className={s.value}>{combo_bet_bonus_percentage}%</span>
              </div>
              <div className={s.infoItem}>
                <span className={s.label}>보너스 예상 금액 : </span>
                <span className={s.value}>
                  +{handleFormatAmount(combo_bet_win_amount)}
                </span>
              </div>
              <div className={s.infoItem}>
                <span className={s.label}>
                  <img src={extracombobonus} /> :
                </span>
                <span className={cx(s.value, s.goldenText)}>
                  {event_combo_bet_bonus_percentage}%
                </span>
              </div>
              <div className={s.infoItem}>
                <span className={s.label}>보너스 예상 금액 : </span>
                <span className={s.value}>
                  +{handleFormatAmount(event_combo_bet_win_amount)}
                </span>
              </div>
            </>
          )}
          <hr className={s.line} />
          <div className={s.infoItem}>
            <span className={s.label}>총 베팅금액 : </span>
            <span className={s.value}>{handleFormatAmount(bet_amount)} KRW</span>
          </div>
          <div className={s.infoItem}>
            <span className={s.label}>당청 예상 금액 : </span>
            <span className={s.value}>
              {handleFormatAmount(expect_win_amount)} KRW
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}
