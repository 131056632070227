/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, {useState, useEffect, SetStateAction, Dispatch} from "react"
import {colorPalette as cp} from "utils/theme/colors"
import cx from "classnames"
/**
 * Component
 */
import OTPCountdown from "./Countdown"
import OtpInput from "./Component"
import {IconExclamation} from "components/Icons/Forms"
import {MOBILE_OTP_FOOTER_MSG} from "screens/MyProfile/constants"
/**
 * Styles
 */
import s from "./otp.module.scss"

interface iOTP {
  resetOTP: () => void
  setOtpValue: Dispatch<SetStateAction<string[] | string>> | undefined
  otpDetails: any
  otpValue: string
  state: boolean
  isShow: boolean
  hasPendingCode: boolean
  timer: number
  validOTP?: boolean
  footerMessage?: string
}

const otpDefault = ["", "", "", "", "", ""]

function OTP({
  resetOTP,
  setOtpValue,
  otpDetails,
  state,
  otpValue,
  timer,
  isShow,
  validOTP,
  footerMessage = MOBILE_OTP_FOOTER_MSG,
}: iOTP): JSX.Element {
  const [animateIn, setAnimateIn] = useState<boolean>(false)

  const hasError = otpDetails?.message === "player.otp.isNotValid"

  function handleOTP(value: string): void {
    setOtpValue && setOtpValue(value)
  }

  useEffect(() => {
    setTimeout(() => {
      setAnimateIn(isShow)
      // const target = document.querySelector(`.eg-flick-viewport`) as any
      // if (isShow) {
      //   if (target) {
      //     target.style.overflow = `hidden`
      //   }
      // } else {
      //   if (target) {
      //     target.style.overflow = `hidden`
      //   }
      // }
    }, 100)
  }, [isShow])

  return (
    <>
      <div className={cx(s.otpContent, {[s.expand]: animateIn})}>
        <div className={`${s.otpWrapper} ${animateIn ? s.otpWrapperActive : ``}`}>
          <div className={s.otpTitle}>{`인증번호 확인`}</div>
          <div className={s.otpTimer}>
            <div className={s.otpTimerIcon}>
              <img src="/assets/img/otp-code-icon.svg" alt="otp-code-icon" />
            </div>
            <div className={s.otpTimerInfo}>
              <span>{`인증번호가 전송 되었습니다. ( `}</span>
              <span className={s.otpTimerCountdown}>
                {`유효시간 `}
                <OTPCountdown onComplete={resetOTP} timerExpiry={timer} />
              </span>
              <span>{` )`}</span>
            </div>
          </div>
          <div className={s.otpInputs}>
            <OtpInput
              otpValueLocal={otpValue === "" ? otpDefault : otpValue.split("")}
              autoFocus
              isNumberInput
              length={6}
              hasError={hasError}
              className="otpContainer"
              inputClassName={cx(s.otpInput, {
                [s.error]: hasError,
                [s.valid]: state || validOTP,
              })}
              onChangeOTP={(otp: string) => handleOTP(otp)}
            />
          </div>
          <div className={s.otpErrors}>
            <div
              className={`${s.otpErrorsMessage} ${
                hasError ? s.otpErrorsMessageActive : ``
              }`}
            >
              <IconExclamation color={cp.red} />
              <span
                style={{padding: "2px 0px 0px 5px"}}
              >{`인증번호를 다시 확인해 주시기 바랍니다.`}</span>
            </div>
          </div>
          <div className={s.otpMessage}>{footerMessage}</div>
        </div>
      </div>
    </>
  )
}

const MemoOTP = React.memo(OTP)
export default MemoOTP
