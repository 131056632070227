import shallow from "zustand/shallow"
import DefaultInputController from "screens/MyProfile/types/Input/DefaultInputController"
import useDistributorNameInputStore from "screens/MyProfile/screens/Affiliate/store/useDistributorNameInputStore"
import useAffiliateStore from "screens/MyProfile/store/useAffiliateStore"
import {REGEX_HANGUL_ALPHA_NUM} from "screens/MyProfile/config"
import {REQUIRED_FIELD} from "screens/MyProfile/constants"

const useDistributorNameInputController = (): DefaultInputController => {
  const {
    setValue,
    setError,
    setIsDirty,
    setIsTouched,
    setIsFocused,
    setIsValidPattern,
  } = useDistributorNameInputStore(
    store => ({
      setValue: store.setValue,
      setError: store.setError,
      setIsDirty: store.setIsDirty,
      setIsTouched: store.setIsTouched,
      setIsFocused: store.setIsFocused,
      setIsValidPattern: store.setIsValidPattern,
    }),
    shallow,
  )

  const {setDistributorReq} = useAffiliateStore(
    store => ({
      setDistributorReq: store.setDistributorReq,
    }),
    shallow,
  )

  const handleValidate = (value: string) => {
    const isValidLength = value?.length >= 1
    const isHangul = /^[가-힣a-zA-Z0-9\s]+$/

    if (value?.length === 0) {
      setIsValidPattern(false)
      setIsDirty(true)
      setError({
        status_code: -2,
        message: REQUIRED_FIELD,
      })

      return false
      // setValidName(false)
      // setError(playerName.label, {
      //   type: "required",
      //   message: "필수 정보입니다.",
      // })
    } else if (!isHangul.test(value)) {
      return false
      // setError(playerName.label, {
      //   type: "invalidFormat",
      //   message: "정확한 정보 입력이 필요합니다.",
      // })
    } else if (!isValidLength) {
      // setValidName(false)
      // setError(playerName.label, {
      //   type: "min",
      //   message: "이름은 반드시 2글자 이상 입력해야합니다.",
      // })
    } else {
      return true
      // setValidName(true)
    }
  }

  const handleReset = () => {
    setDistributorReq(null)
    setValue("")
    setIsValidPattern(false)
    setIsDirty(false)
    setError({
      status_code: 0,
      message: "",
    })
  }

  const handleOnFocus = () => {
    setIsFocused(true)
    setIsTouched(true)
    handleReset()
  }

  const handleOnBlur = (value: string) => {
    const isHangul = /^[가-힣a-zA-Z0-9\s]+$/
    const isValidLength = value?.length >= 1
    setIsFocused(false)

    // const isValid = handleValidate(value)

    if (value?.length === 0) {
      setIsValidPattern(false)
      setIsDirty(true)
      setError({
        status_code: -2,
        message: REQUIRED_FIELD,
      })
    } else if (!isHangul.test(value)) {
      setIsValidPattern(false)
      setIsDirty(true)
      setError({
        status_code: -2,
        message: "정확한 정보 입력이 필요합니다.",
      })
    } else if (!isValidLength) {
      setIsValidPattern(false)
      setIsDirty(true)
      setError({
        status_code: -2,
        message: "이름은 반드시 1글자 이상 입력해야합니다.",
      })
    } else {
      setDistributorReq(value)
      setIsValidPattern(true)
      setError({
        status_code: 1,
        message: "",
      })
    }
  }

  const handleOnChange = (value: string) => {
    setIsDirty(true)

    const stringVal = value.match(/^[가-힣a-zA-Z0-9\s]+$/)
    const formatted = value.replaceAll(REGEX_HANGUL_ALPHA_NUM, "")
    const hangulName = stringVal?.join("")

    setValue(formatted)

    if (hangulName) {
      if (hangulName.length >= 1) {
        setIsValidPattern(true)
        setIsTouched(false)
        setError({
          status_code: 0,
          message: "match",
        })
      }
    }

    // const isValid = handleValidate(value)
    // if (isValid) {
    //   setIsValidPattern(true)
    //   setIsTouched(false)
    //   setError({
    //     status_code: 0,
    //     message: "match",
    //   })
    // } else {
    //   setIsValidPattern(false)
    //   setIsDirty(true)
    //   setError({
    //     status_code: -2,
    //     message: "영문 이름은 가입이 불가능 합니다.",
    //   })
    // }
  }

  return {
    handleOnBlur,
    handleOnChange,
    handleOnFocus,
    handleValidate,
  }
}

export default useDistributorNameInputController
