// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from "react"
/**
 * Utils
 */
import {encryptQuery} from "utils/url"
/**
 * Components
 */
import {lazyWithRetry} from "utils/lazyWithRetry"
const TermsAndConditions = lazyWithRetry(
  () => import("screens/FooterLinks/TermsAndConditions"),
)
const GamingCulture = lazyWithRetry(
  () => import("screens/FooterLinks/GamingCulture"),
)
const PrivacyPolicy = lazyWithRetry(
  () => import("screens/FooterLinks/PrivacyPolicy"),
)
const LiveBettingRules = lazyWithRetry(
  () => import("screens/FooterLinks/LiveBettingRules"),
)
const PrematchBettingRules = lazyWithRetry(
  () => import("screens/FooterLinks/PrematchBettingRules"),
)

const MembershipLevel = lazyWithRetry(
  () => import("screens/FooterLinks/MembershipLevel"),
)

const AmlPolicy = lazyWithRetry(() => import("screens/FooterLinks/AmlPolicy"))

const GameGuide = lazyWithRetry(() => import("screens/FooterLinks/GameGuide"))
const SiteGuide = lazyWithRetry(() => import("screens/FooterLinks/SiteGuide"))

const PageUnderDevelopment = lazyWithRetry(
  () => import("screens/PageUnderDevelopment"),
)

export interface IFooterMenu {
  path: string
  name: string
  component: React.ComponentType
}

const BETTING_RULES = [
  {
    path: encryptQuery("pre-match-betting", "/"),
    name: "프리매치 베팅규정",
    exact: true,
    component: PrematchBettingRules,
  },
  {
    path: encryptQuery("live-sports-betting", "/"),
    name: "라이브 배팅 규정",
    exact: true,
    component: LiveBettingRules,
  },
]

const SERVICE_INFO = [
  {
    path: encryptQuery("membership-level", "/"),
    name: "회원 등급별 주요 혜택 안내",
    exact: true,
    component: MembershipLevel,
  },
  {
    path: encryptQuery("game-manual", "/"),
    name: "게임 이용 가이드",
    exact: true,
    component: GameGuide,
  },
  {
    path: encryptQuery("user-manual", "/"),
    name: "사이트 이용 가이드",
    exact: true,
    component: SiteGuide,
  },
]

const TERMS_CONDITIONS = [
  {
    path: encryptQuery("terms", "/"),
    name: "이용약관",
    exact: true,
    component: TermsAndConditions,
  },
  {
    path: encryptQuery("gaming-culture", "/"),
    name: "건전한 게임 문화",
    exact: true,
    component: GamingCulture,
  },
  {
    path: encryptQuery("privacy-policy", "/"),
    name: "PrivacyPolicy",
    exact: true,
    component: PrivacyPolicy,
  },
  {
    path: encryptQuery("amla", "/"),
    name: "자금 세탁 방지 정책",
    exact: true,
    component: AmlPolicy,
  },
]

export const FooterMenu = [...BETTING_RULES, ...SERVICE_INFO, ...TERMS_CONDITIONS]
