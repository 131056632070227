export const HOME = "홈"
export const BTI_SPORTS = "BTI 스포츠"
export const LIVE_SPORTS = "라이브스포츠"
export const SPORTS = "스포츠"
export const LIVE_CASINO = "라이브카지노"
export const SLOTS = "슬롯"
export const MINI_GAMES = "파워볼"
export const BET365 = "BET365"
export const SKY_PARK = "스카이파크"
export const FREE_GAMES = "무료 게임"
export const PROMOTIONS = "이벤트"
export const MY_PAGE = "MY"
export const RECHARGE = "충전/환전"
export const SIGNUP = "회원가입"
export const LOGIN = "로그인"
export const FIND_ID = "아이디찾기"
export const FIND_PASSWORD = "비밀번호찾기"
export const NAV_HOME_SELECTED_PAGE = "navHomeSelectedPage"
export const SELECTED_PAGE = "prevSelectedPage"
export const CASINO_PATH = "live-casino"

export const HOME_PATH = "/"

export const PAGE_MAPPING = {
  "BT1 스포츠": "bti_sports",
  "스포츠": "sports",
  "라이브스포츠": "live_sports",
  "라이브카지노": "casino",
  "슬롯": "slots",
  "파워볼": "mini_game",
  "BET365": "bet365",
  "스카이파크": "skypark",
}

export const PUBLIC_PAGES = [SIGNUP, LOGIN, FIND_ID, FIND_PASSWORD]
