import create from "zustand"
import DefaultInputStore from "screens/MyProfile/types/Input/DefaultInputStore"

const useDistributorNameInputStore = create<DefaultInputStore>(set => ({
  key: "distributorInput",
  order: null,
  isTouched: false,
  isFocused: false,
  isDirty: false,
  isValidPattern: false,
  isAvailable: false,
  isLoading: false,
  value: null,
  currentError: {
    status_code: 0,
    message: "",
  },
  maxLength: 15,
  handleValidate: null,
  setMaxLength: maxLength => set({maxLength}),
  setIsTouched: isTouched => set({isTouched}),
  setIsFocused: isFocused => set({isFocused}),
  setIsDirty: isDirty => set({isDirty}),
  setIsValidPattern: isValidPattern => set({isValidPattern}),
  setIsAvailable: isAvailable => set({isAvailable}),
  setIsLoading: isLoading => set({isLoading}),
  setValue: value => set({value}),
  setError: currentError => set({currentError}),
  setHandleValidate: handleValidate => set({handleValidate}),
  resetStore: () => {
    set({
      isTouched: false,
      isFocused: false,
      isDirty: false,
      isValidPattern: false,
      isAvailable: false,
      isLoading: false,
      value: null,
      currentError: {
        status_code: 0,
        message: "",
      },
      maxLength: 10,
      handleValidate: null,
    })
  },
}))

export default useDistributorNameInputStore
