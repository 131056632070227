// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, {useState, useEffect} from "react"
import Countdown, {zeroPad} from "react-countdown"

/**
 * Interface
 */
interface iOTPCountdown {
  onComplete: () => void
  timerExpiry: number
}

function OTPCountDown({onComplete, timerExpiry}: iOTPCountdown): JSX.Element {
  const [timerDate, setTimerDate] = useState(Date.now() + timerExpiry)

  useEffect(() => {
    setTimerDate(Date.now() + timerExpiry)
  }, [timerExpiry])

  function renderer({minutes, seconds}) {
    return (
      <div style={{display: "flex", width: "47px", justifyContent: "center"}}>
        <div style={{display: "flex", width: "18px", justifyContent: "center"}}>
          {zeroPad(minutes)}
        </div>
        :
        <div style={{display: "flex", width: "18px", justifyContent: "center"}}>
          {zeroPad(seconds)}
        </div>
      </div>
    )
  }

  return <Countdown date={timerDate} renderer={renderer} onComplete={onComplete} />
}

const MemoOTPCountDown = React.memo(OTPCountDown)
export default MemoOTPCountDown
