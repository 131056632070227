import React, {useEffect, useRef, useState} from "react"
import s from "./style.module.css"
import {pathType, useNavigate} from "hooks/useNavigate"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"

const Homebanner = ({bannerImg, apiBanner}) => {
  const {handleNavigate} = useNavigate()
  const [playCount, setPlayCount] = useState(0)
  const videoRef = useRef(null)
  const [isLoading, setIsLoading] = useState(true)

  const handleNavigateTo = (path: pathType) => {
    handleNavigate(path)
  }
  const CDN_URL = process.env.REACT_APP_IMG_URL

  const bannerSrc = `${CDN_URL}${apiBanner}`
  let isVideo = /\.(mp4|webm)$/i.test(apiBanner)

  const handleImageError = (event: any) => {
    const image = event.target
    image.src = bannerImg.src
  }

  const handleVideoEnded = () => {
    if (playCount < 4) {
      setPlayCount(playCount + 1)
      videoRef.current.play()
    }
  }

  const handleVideoError = (event: any) => {
    const image = event.target
    image.style.display = "none"
    const container = document.querySelector("#bannerContainer")
    const imgElement = document.createElement("img")
    imgElement.src = bannerImg.src
    imgElement.className = s.fullWidthImage
    container.appendChild(imgElement)
  }

  useEffect(() => {
    if (isVideo && videoRef.current) {
      const videoElement = videoRef.current
      videoElement.addEventListener("ended", handleVideoEnded)

      return () => {
        if (videoElement) {
          videoElement.removeEventListener("ended", handleVideoEnded)
        }
      }
    }
  }, [isVideo, playCount])

  const imageLoaded = (id: string) => {
    const image = document.getElementById(id) as HTMLImageElement
    if (image) {
      if (image.complete) {
        setIsLoading(false)
      }
    }
  }

  const videoLoaded = (id: string) => {
    const video = document.getElementById(id) as HTMLImageElement
    if (video) {
      video.addEventListener("canplaythrough", () => {
        setIsLoading(false)
      })
    }
  }
  return (
    <div
      className={s.container}
      onClick={() => handleNavigateTo(bannerImg.path)}
      id="bannerContainer"
    >
      {isLoading && (
        <Skeleton className={s.skeleton} containerClassName={s.skeletonContainer} />
      )}

      {isVideo ? (
        <video
          id="video_banner"
          ref={videoRef}
          src={bannerSrc}
          className={s.fullWidthImage}
          autoPlay
          muted
          playsInline
          onError={handleVideoError}
          onLoadedData={() => videoLoaded("video_banner")}
        />
      ) : (
        <img
          id="banner_img"
          src={bannerSrc}
          alt={bannerImg.caption}
          className={s.fullWidthImage}
          onError={handleImageError}
          onLoad={() => imageLoaded("banner_img")}
        />
      )}
    </div>
  )
}

export default Homebanner
