import create from "zustand"
import {persist} from "zustand/middleware"
import InboxList from "screens/MyProfile/types/InboxList"
import Inbox from "screens/MyProfile/types/Inbox"
import {READ} from "screens/MyProfile/constants"

interface iInboxStore {
  inbox: InboxList
  isLoading: boolean
  page: number
  selectedInbox: Inbox
  unreadCount: number
  isModalOpen: boolean
  setInbox: (value: InboxList) => void
  setIsLoading: (value: boolean) => void
  setPage: (value: number) => void
  setSelectedInbox: (value: Inbox) => void
  setReadAt: (value: string) => void
  setUnreadCount: (value: number) => void
  decUnreadCount: () => void
  setIsModalOpen: (value: boolean) => void
  updateList: (value: Inbox) => void
  resetStore: () => void
}

const useInboxStore = create<iInboxStore>((set, get) => ({
  inbox: null,
  isLoading: false,
  page: 1,
  selectedInbox: null,
  unreadCount: 0,
  isModalOpen: false,
  setInbox: inbox => set({inbox}),
  setIsLoading: isLoading => set({isLoading}),
  setPage: page => set({page}),
  setSelectedInbox: selectedInbox => set({selectedInbox}),
  setReadAt: (value: string) => {
    const item = get().selectedInbox
    const updated = {...item, read_at: value}
    set({selectedInbox: updated})
  },
  setUnreadCount: unreadCount => set({unreadCount}),
  decUnreadCount: () => {
    const count = get().unreadCount
    const decreased = count - 1
    set({unreadCount: decreased >= 0 ? decreased : 0})
  },
  setIsModalOpen: isModalOpen => set({isModalOpen}),
  updateList: (value: Inbox) => {
    const list = get().inbox
    const row = list.data?.find(inbox => inbox?.id === value.id)
    row.confirmation = READ
  },
  resetStore: () => {
    set({
      inbox: null,
      isLoading: false,
      page: 1,
      selectedInbox: null,
      isModalOpen: false,
    })
  },
}))

export default useInboxStore
