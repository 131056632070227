import shallow from "zustand/shallow"
import useFadeOverlayStore from "components/FadeOverlay/store/useFadeOverlayStore"

type HookType = {
  handlePageTransition(callback?: () => void): void
}

type Props = {
  duration?: number
}

const usePageTransition = (props?: Props): HookType => {
  const duration = props?.duration ?? 500
  const {setIsShowed} = useFadeOverlayStore(
    store => ({
      setIsShowed: store.setIsShowed,
    }),
    shallow,
  )

  const handlePageTransition = (callback: () => void) => {
    setIsShowed(true)
    setTimeout(() => {
      window.scrollTo({top: 0})
      callback && callback()
    }, duration)
  }

  return {handlePageTransition}
}

export default usePageTransition
