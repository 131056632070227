import React, {useMemo} from "react"
import {CategoryIconType} from "./CategoryIconType"

const BasketballSVG = ({
  active,
  className,
  fill1,
  fill2,
}: CategoryIconType): JSX.Element => {
  const fill = useMemo(() => {
    return active ? fill1 : fill2
  }, [active])

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24.001"
      viewBox="0 0 24 24.001"
      className={className && className}
    >
      <g id="Group_25462" data-name="Group 25462" transform="translate(-86 -182)">
        <g
          id="Group_13675"
          data-name="Group 13675"
          transform="translate(104.354 186.582)"
        >
          <g id="Group_13674" data-name="Group 13674">
            <path
              id="Path_30645"
              data-name="Path 30645"
              d="M395.8,98.159a10.615,10.615,0,0,0-3.037,6.764h5.647A12.039,12.039,0,0,0,395.8,98.159Z"
              transform="translate(-392.759 -98.159)"
              fill={fill}
            />
          </g>
        </g>
        <g
          id="Group_13677"
          data-name="Group 13677"
          transform="translate(98.586 182)"
        >
          <g id="Group_13676" data-name="Group 13676">
            <path
              id="Path_30646"
              data-name="Path 30646"
              d="M271,0V11.413h4.345a12.035,12.035,0,0,1,3.516-7.834A12.241,12.241,0,0,0,271,0Z"
              transform="translate(-271)"
              fill={fill}
            />
          </g>
        </g>
        <g
          id="Group_13679"
          data-name="Group 13679"
          transform="translate(104.354 194.652)"
        >
          <g id="Group_13678" data-name="Group 13678">
            <path
              id="Path_30647"
              data-name="Path 30647"
              d="M392.76,271a10.737,10.737,0,0,0,3.037,6.812,12.186,12.186,0,0,0,2.61-6.812Z"
              transform="translate(-392.76 -271)"
              fill={fill}
            />
          </g>
        </g>
        <g
          id="Group_13681"
          data-name="Group 13681"
          transform="translate(89.551 182)"
        >
          <g id="Group_13680" data-name="Group 13680">
            <path
              id="Path_30648"
              data-name="Path 30648"
              d="M75,3.578a12.035,12.035,0,0,1,3.516,7.834h4.345V0A12.241,12.241,0,0,0,75,3.578Z"
              transform="translate(-75)"
              fill={fill}
            />
          </g>
        </g>
        <g
          id="Group_13683"
          data-name="Group 13683"
          transform="translate(98.586 194.588)"
        >
          <g id="Group_13682" data-name="Group 13682">
            <path
              id="Path_30649"
              data-name="Path 30649"
              d="M275.345,271H271v11.413a12.191,12.191,0,0,0,7.861-3.531A12.155,12.155,0,0,1,275.345,271Z"
              transform="translate(-271 -271)"
              fill={fill}
            />
          </g>
        </g>
        <g
          id="Group_13685"
          data-name="Group 13685"
          transform="translate(89.551 194.588)"
        >
          <g id="Group_13684" data-name="Group 13684">
            <path
              id="Path_30650"
              data-name="Path 30650"
              d="M78.516,271A12.154,12.154,0,0,1,75,278.882a12.191,12.191,0,0,0,7.861,3.531V271Z"
              transform="translate(-75 -271)"
              fill={fill}
            />
          </g>
        </g>
        <g
          id="Group_13687"
          data-name="Group 13687"
          transform="translate(86 194.652)"
        >
          <g id="Group_13686" data-name="Group 13686">
            <path
              id="Path_30651"
              data-name="Path 30651"
              d="M0,271a12.185,12.185,0,0,0,2.61,6.812A10.737,10.737,0,0,0,5.647,271Z"
              transform="translate(0 -271)"
              fill={fill}
            />
          </g>
        </g>
        <g
          id="Group_13689"
          data-name="Group 13689"
          transform="translate(86 186.582)"
        >
          <g id="Group_13688" data-name="Group 13688">
            <path
              id="Path_30652"
              data-name="Path 30652"
              d="M2.61,98.159A12.039,12.039,0,0,0,0,104.923H5.647A10.615,10.615,0,0,0,2.61,98.159Z"
              transform="translate(0 -98.159)"
              fill={fill}
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default BasketballSVG
