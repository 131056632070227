import CasinoStore from "screens/Casino/types/CasinoStore"
import create from "zustand"
/**
 * Types
 */

export const useCasinoStore = create<CasinoStore>(
  // persist(
  set => ({
    isActive: false,
    isGameOpen: false,
    isGamesUrlLoading: true,
    isIFrameLoaded: false,
    isSummaryLoading: true,
    isMounted: false,
    isModalOpen: false,
    gameCategories: [],
    selectedGame: null,
    gameUrl: null,
    gameId: null,
    tableId: null,
    sliderRef: null,
    closeGame: null,
    setCloseGame: closeGame => set({closeGame}),
    setGameCategories: gameCategories =>
      set({
        gameCategories,
      }),
    setIsActive: isActive => set({isActive}),
    setIsGameOpen: isGameOpen => set({isGameOpen}),
    setIsGamesUrlLoading: isGamesUrlLoading => set({isGamesUrlLoading}),
    setIsIFrameLoaded: isIFrameLoaded => set({isIFrameLoaded}),
    setIsSummaryLoading: isSummaryLoading => set({isSummaryLoading}),
    setIsMounted: isMounted => set({isMounted}),
    setIsModalOpen: isModalOpen => set({isModalOpen}),
    setSelectedGame: selectedGame =>
      set({
        selectedGame,
      }),
    setGameUrl: gameUrl => set({gameUrl}),
    setGameId: gameId => set({gameId}),
    setTableId: tableId => set({tableId}),
    setSliderRef: sliderRef => set({sliderRef}),
  }),
  //   {
  //     name: "casino_games",
  //   },
  // ),
)
