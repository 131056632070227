import React, {useContext, useState} from "react"
import shallow from "zustand/shallow"
import cx from "classnames"
import useLoginModalStore from "components/LoginButton/hooks/useLoginModalStore"
import {LOGIN, FIND_ID, FORGOT_PASSWORD} from "components/LoginButton/contants"
import s from "components/LoginButton/Banner/banner.module.scss"
import {ThemeContext} from "context/ThemeContext"

const Banner = () => {
  const [banner1Loaded, setIsBanner1Loaded] = useState(false)
  const {theme} = useContext(ThemeContext)

  const {title} = useLoginModalStore(
    store => ({
      title: store.selectedTab,
    }),
    shallow,
  )

  return (
    <div className={s.container}>
      <div className={s.banner1}>
        <img
          onLoad={() => setIsBanner1Loaded(true)}
          src={theme.images.login_BG}
          className={cx({
            [s.login]: banner1Loaded && LOGIN === title,
            [s.findId]: banner1Loaded && FIND_ID === title,
            [s.findPassword]: banner1Loaded && FORGOT_PASSWORD === title,
          })}
        />
      </div>
    </div>
  )
}

export default Banner
