import {useEffect} from "react"
import shallow from "zustand/shallow"
import {useQueryClient} from "react-query"
import {useUserStore} from "stores/userStore"
import usePusherStore from "stores/usePusherStore"
import useGlobalAlertStore from "screens/NavHome/store/useGlobalAlertStore"
import EVENT from "provider/pusher/pusherEvents"
import {RECENTLY_PLAYED_QUERY_NAME} from "screens/Slots/utils/Config"
import {RECENTLY_PLAYED_QUERY_NAME as CASINO_RECENTLY_PLAYED_QUERY_NAME} from "screens/Casino/utils/Config"
import {
  REWARD_MSG,
  WinningModalMsg,
  getGameTitle,
} from "screens/Home/FreeGames/config"
import {formatValueToKoreanAbbreviation} from "utils/amount"

const usePlayerEvents = () => {
  const client = useQueryClient()
  const privateNotifChannel = usePusherStore(
    store => store.privateNotifChannel,
    shallow,
  )

  const refresh = () => {
    client.invalidateQueries([RECENTLY_PLAYED_QUERY_NAME])
    client.invalidateQueries([CASINO_RECENTLY_PLAYED_QUERY_NAME])

    const user = useUserStore.getState()
    user.getUserDetails()
  }

  const forceLogout = data => {
    const alertStore = useGlobalAlertStore.getState()
    const type = data?.type

    if (type !== "session-expired") {
      alertStore.setType(type)
      alertStore.setMessage(data?.message)
      setTimeout(() => alertStore.setIsModalOpen(true), 400)
    }

    const userStore = useUserStore.getState()
    userStore.logout()
  }

  const openRewardModal = (data: WinningModalMsg) => {
    const gameTitle = getGameTitle(data?.game_code)
    const koreanAmount = formatValueToKoreanAbbreviation(data?.reward_amount)
    const rewardMsg = REWARD_MSG.replace("rewardAmount", koreanAmount).replace(
      "gameTitle",
      gameTitle,
    )
    const alertStore = useGlobalAlertStore.getState()
    alertStore.setMessage(rewardMsg)
    alertStore.setIsModalOpen(true)
  }

  useEffect(() => {
    if (!privateNotifChannel) return

    // console.log("bind playerEvents")

    privateNotifChannel.bind(EVENT.PLAYER_BALANCE_UPDATE, refresh)
    privateNotifChannel.bind(EVENT.PLAYER_FORCE_LOGOUT, forceLogout)
    privateNotifChannel.bind(EVENT.ADMIN_FORCE_LOGOUT, forceLogout)
    privateNotifChannel.bind(EVENT.PLAYER_FREE_NTRY_REWARD, openRewardModal)
    privateNotifChannel.bind(EVENT.PLAYER_FREE_BET365_SOCCER_REWARD, openRewardModal)
    privateNotifChannel.bind(
      EVENT.PLAYER_FREE_BET365_BASKETBALL_REWARD,
      openRewardModal,
    )

    return () => {
      const pusherStore = usePusherStore.getState()
      const channel = pusherStore.privateNotifChannel
      if (channel) {
        // console.log("unbind playerEvents")
        channel.unbind(EVENT.PLAYER_BALANCE_UPDATE)
        channel.unbind(EVENT.PLAYER_FORCE_LOGOUT)
        channel.unbind(EVENT.ADMIN_FORCE_LOGOUT)
        channel.unbind(EVENT.PLAYER_FREE_NTRY_REWARD)
        channel.unbind(EVENT.PLAYER_FREE_BET365_SOCCER_REWARD)
        channel.unbind(EVENT.PLAYER_FREE_BET365_BASKETBALL_REWARD)
      }
    }
  }, [privateNotifChannel])
}

export default usePlayerEvents
