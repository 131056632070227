import create from "zustand"
import Flicking from "@egjs/react-flicking/declaration/Flicking"

export interface iBannerStore {
  sliderRef: Flicking
  timer: number
  countDown: number
  index: number
  type: string
  isOnHold: boolean
  setIsOnHold: (value: boolean) => void
  setSliderRef: (value: Flicking) => void
  setTimer: (value: number) => void
  setCountDown: (value: number) => void
  setIndex: (value: number) => void
  setType: (value: string) => void
  timerTick: () => void
  resetStore: () => void
  resetTimer: () => void
}

const useBannerStore = create<iBannerStore>((set, get) => ({
  sliderRef: null,
  isOpen: false,
  timer: 5,
  countDown: 5,
  index: 0,
  type: "",
  isOnHold: false,
  close: null,
  setIsOnHold: isOnHold => set({isOnHold}),
  setSliderRef: sliderRef => set({sliderRef}),
  setTimer: timer => set({timer}),
  setCountDown: countDown => set({countDown}),
  setIndex: index => set({index}),
  setType: type => set({type}),
  timerTick: () => {
    const timer = get().timer
    const second = timer - 1
    if (second >= 0) set({timer: second})
    else {
      const countDown = get().countDown
      const slider = get().sliderRef
      setTimeout(() => slider.next(), 1000)
      set({timer: countDown})
    }
  },
  resetStore: () => {
    set({
      timer: 5,
      countDown: 5,
      index: 0,
      isOnHold: false,
      type: "",
    })
  },
  resetTimer: () => {
    const countDown = get().countDown
    set({
      timer: countDown - 1,
    })
  },
}))

export default useBannerStore
