// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from "react"

/**
 * Styles
 */
import s from "./loader.module.css"

interface iLoader {
  color?: "WHITE" | "DEFAULT"
}

function Loader({color = "DEFAULT"}: iLoader): JSX.Element {
  const loaderClass = color === "DEFAULT" ? s.ldsSpinner : s.ldsSpinnerWhite

  return (
    <div className={loaderClass}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}

export default Loader
