import create from "zustand"

type Store = {
  activeGame: {provider: string; gameCode: string}
  setActiveGame: (provider: string, game: string) => void

  openSlotGamesFn: (args: any, tag: any) => void
  setOpenSlotGamesFn: (fn: any) => void

  openCasinoGamesFn: (args: any, tag: any) => void
  setOpenCasinoGamesFn: (fn: any) => void
}

const initialState = {
  activeGame: {provider: "", gameCode: ""},
  openSlotGamesFn: function () {},
  openCasinoGamesFn: function () {},
}

export const useHomeV2Store = create<Store>((set, get) => ({
  ...initialState,
  setActiveGame: (provider, gameCode) => set({activeGame: {provider, gameCode}}),
  setOpenSlotGamesFn: (openSlotGamesFn: any) => set({openSlotGamesFn}),
  setOpenCasinoGamesFn: (openCasinoGamesFn: any) => set({openCasinoGamesFn}),
}))
