import React, {useContext} from "react"
import Homebanner from "./banner"
import {pathType} from "hooks/useNavigate"
import HomeCarouselMobile from "./carouselMobile"
import {ThemeContext} from "context/ThemeContext"

interface ImageData {
  src: string
  caption: string
  path: pathType
}

interface BannerImageData {
  bottom_banner_mobile: BannerImageData
  top_banner_mobile: BannerImageData
  desktopSrc: string
  mobileSrc: string
  caption: string
  path: pathType
}

interface HomeCarouselProps {
  carousel_images: ImageData[]
  banners_images: BannerImageData
}

const MobileView: React.FC<HomeCarouselProps> = ({
  carousel_images,
  banners_images,
}) => {
  const {topMobileBanner, bottomMobileBanner} = useContext(ThemeContext)

  const top_banner: BannerImageData = banners_images.top_banner_mobile
  const bottom_banner: BannerImageData = banners_images.bottom_banner_mobile
  return (
    <>
      <Homebanner bannerImg={top_banner} apiBanner={topMobileBanner} />
      <HomeCarouselMobile images={carousel_images} />
      <Homebanner bannerImg={bottom_banner} apiBanner={bottomMobileBanner} />
    </>
  )
}

export default MobileView
