// PRIVATE CHANNEL
export const PLAYER_PRIVATE_CHANNEL = "private-player-notifications"

// PLAYER EVENTS
export const PLAYER_VIRTUAL_HISTORY_DRAGONTIGER_UPDATE =
  "player.virtual-Dragon_Tiger-history.update"
export const PLAYER_VIRTUAL_HISTORY_BACCARAT_UPDATE =
  "player.virtual-Baccarat-history.update"
export const PLAYER_VIRTUAL_HISTORY_DICE_UPDATE =
  "player.virtual-Dice-history.update"
export const PLAYER_VIRTUAL_NTRY_BALANCE_UPDATE =
  "player.virtual-ntry-balance.update"
export const PLAYER_VIRTUAL_NTRY_HISTORY_UPDATE =
  "player.virtual-ntry-history.update"
export const PLAYER_VIRTUAL_BET365_SOCCER_BALANCE_UPDATE =
  "player.virtual-bet365_soccer-balance.update"
export const PLAYER_VIRTUAL_BET365_SOCCER_HISTORY_UPDATE =
  "player.virtual-bet365_soccer-history.update"
export const PLAYER_VIRTUAL_BET365_BASKETBALL_BALANCE_UPDATE =
  "player.virtual-bet365_basketball-balance.update"
export const PLAYER_VIRTUAL_BET365_BASKETBALL_HISTORY_UPDATE =
  "player.virtual-bet365_basketball-history.update"
export const PLAYER_BALANCE_UPDATE = "player.account-balance.update"
export const PLAYER_FORCE_LOGOUT = "player.force-logout.player"
export const ADMIN_FORCE_LOGOUT = "admin.force-logout.player"
export const PLAYER_FREE_NTRY_REWARD = "player.ntry.win"
export const PLAYER_FREE_BET365_SOCCER_REWARD = "player.bet365_soccer.win"
export const PLAYER_FREE_BET365_BASKETBALL_REWARD = "player.bet365_basketball.win"

// BANK CHANGE/REGISTER
export const ADMIN_APPROVED_BANK_LOG = "admin.bank-account-log.approved"
export const ADMIN_REJECTED_BANK_LOG = "admin.bank-account-log.rejected"

// PHONE NUMBER CHANGE
export const ADMIN_APPROVED_PHONE_NUMBER_CHANGE = "admin.mobile-change-log.approved"
export const ADMIN_REJECTED_PHONE_NUMBER_CHANGE = "admin.mobile-change-log.rejected"

// DEPOSIT
export const ADMIN_BANK_REQUEST_APPROVED = "admin.bank-request.approved"
export const ADMIN_BANK_REQUEST_REJECTED = "admin.bank-request.rejected"
export const ADMIN_BANK_REQUEST_CANCEL = "admin.bank-request.cancelled"
export const ADMIN_BANK_REQUEST_SMS = "admin.bank-request.callback-sms"
export const ADMIN_APPROVED_DEPOSIT = "admin.deposit.approved"
export const ADMIN_REJECTED_DEPOSIT = "admin.deposit.rejected"
export const ADMIN_CANCEL_DEPOSIT = "admin.deposit.cancelled"
export const ADMIN_EXPIRE_DEPOSIT = "admin.deposit.expire"

// WITHDRAW
export const ADMIN_APPROVED_WITHDRAW = "admin.withdraw.approved"
export const ADMIN_REJECTED_WITHDRAW = "admin.withdraw.rejected"
export const ADMIN_CANCEL_WITHDRAW = "admin.withdraw.cancelled"
export const ADMIN_EXPIRE_WITHDRAW = "admin.withdraw.expires"
export const ADMIN_DONE_WITHDRAW = "admin.withdraw.transfer-done"
export const ADMIN_ROLLBACK_WITHDRAW = "admin.withdraw.rollback"

// ADMIN EVENTS
export const ADMIN_PLAYER_UPDATE = "admin.player.update"
export const ADMIN_SEND_MESSAGE = "admin.send.player.message"
export const ADMIN_ANSWER_INQUIRY = "admin.inquiry.answer"
export const ADMIN_BET_LIMIT_UPDATE = "admin.update.player.bet.limit"

// PUBLIC GAME EVENTS
export const GAME_UPDATE = "updated"
export const GAME_ACTIVATED = "activated"
export const GAME_DEACTIVATED = "deactivated"
export const GAME_SETTINGS = "settings.updated"
export const LEVEL_SETINGS = "level.updated"
export const FREE_NTRY_RELAUNCH = "ntry.relaunch"
export const FREE_DRAGONTIGER_RELAUNCH = "Dragon_Tiger.relaunch"
export const FREE_BACCARAT_RELAUNCH = "Baccarat.relaunch"
export const FREE_DICE_RELAUNCH = "Dice.relaunch"
export const FREE_BET365_SOCCER_RELAUNCH = "bet365_soccer.relaunch"
export const FREE_BET365_BASKETBALL_RELAUNCH = "bet365_basketball.relaunch"

// PUBLIC CHANNEL
export const PUBLIC_GAME_CHANNEL = "public.game"
export const POWERBALL_TIMER_CHANNEL = "powerball.timer"
export const EOS_POWERBALL_CHANNEL = "eos-powerball"
export const BET365_CHANNEL = "game.bet365.result"
export const SKYPARK_CHANNEL = "public.game.skypark"

// BET365 EVENT
export const BET365_EVENT = "public.game.bet365"

// SKYPARK EVENTS
export const SKYPARK_ROUND = "game.skypark.round"
export const SKYPARK_OUTCOME = "game.skypark.outcome"
export const SKYPARK_BET_HISTORY = "page.betting-history.skypark.body.update"

export const BTI_COMMIT_RESERVE = "player.bti.bet.commit_reserve"

export default {
  // PRIVATE CHANNEL
  PRIVATE_CHANNEL: PLAYER_PRIVATE_CHANNEL,

  // PUBLIC CHANNEL
  PUBLIC_GAME_CHANNEL: PUBLIC_GAME_CHANNEL,
  POWERBALL_TIMER_CHANNEL: POWERBALL_TIMER_CHANNEL,
  EOS_POWERBALL_CHANNEL: EOS_POWERBALL_CHANNEL,
  BET365_CHANNEL: BET365_CHANNEL,
  SKYPARK_CHANNEL: SKYPARK_CHANNEL,

  // PLAYER EVENTS
  PLAYER_VIRTUAL_NTRY_BALANCE_UPDATE: PLAYER_VIRTUAL_NTRY_BALANCE_UPDATE,
  PLAYER_VIRTUAL_NTRY_HISTORY_UPDATE: PLAYER_VIRTUAL_NTRY_HISTORY_UPDATE,
  PLAYER_VIRTUAL_BET365_SOCCER_BALANCE_UPDATE:
    PLAYER_VIRTUAL_BET365_SOCCER_BALANCE_UPDATE,
  PLAYER_VIRTUAL_BET365_SOCCER_HISTORY_UPDATE:
    PLAYER_VIRTUAL_BET365_SOCCER_HISTORY_UPDATE,
  PLAYER_VIRTUAL_BET365_BASKETBALL_BALANCE_UPDATE:
    PLAYER_VIRTUAL_BET365_BASKETBALL_BALANCE_UPDATE,
  PLAYER_VIRTUAL_BET365_BASKETBALL_HISTORY_UPDATE:
    PLAYER_VIRTUAL_BET365_BASKETBALL_HISTORY_UPDATE,
  PLAYER_BALANCE_UPDATE: PLAYER_BALANCE_UPDATE,
  PLAYER_FORCE_LOGOUT: PLAYER_FORCE_LOGOUT,
  ADMIN_FORCE_LOGOUT: ADMIN_FORCE_LOGOUT,
  PLAYER_FREE_NTRY_REWARD: PLAYER_FREE_NTRY_REWARD,
  PLAYER_FREE_BET365_SOCCER_REWARD: PLAYER_FREE_BET365_SOCCER_REWARD,
  PLAYER_FREE_BET365_BASKETBALL_REWARD: PLAYER_FREE_BET365_BASKETBALL_REWARD,

  // PUBLIC GANE EVENTS
  GAME_UPDATE: GAME_UPDATE,
  GAME_ACTIVATED: GAME_ACTIVATED,
  GAME_DEACTIVATED: GAME_DEACTIVATED,
  GAME_SETTINGS: GAME_SETTINGS,
  LEVEL_SETINGS: LEVEL_SETINGS,
  FREE_NTRY_RELAUNCH: FREE_NTRY_RELAUNCH,
  FREE_DRAGONTIGER_RELAUNCH: FREE_DRAGONTIGER_RELAUNCH,
  FREE_BACCARAT_RELAUNCH: FREE_BACCARAT_RELAUNCH,
  FREE_DICE_RELAUNCH: FREE_DICE_RELAUNCH,
  FREE_BET365_SOCCER_RELAUNCH: FREE_BET365_SOCCER_RELAUNCH,
  FREE_BET365_BASKETBALL_RELAUNCH: FREE_BET365_BASKETBALL_RELAUNCH,

  // BANK CHANGE/REGISTER
  ADMIN_APPROVED_BANK_LOG: ADMIN_APPROVED_BANK_LOG,
  ADMIN_REJECTED_BANK_LOG: ADMIN_REJECTED_BANK_LOG,

  // PHONE NUMBER CHANGE
  ADMIN_APPROVED_PHONE_NUMBER_CHANGE: ADMIN_APPROVED_PHONE_NUMBER_CHANGE,
  ADMIN_REJECTED_PHONE_NUMBER_CHANGE: ADMIN_REJECTED_PHONE_NUMBER_CHANGE,

  // DEPOSIT
  ADMIN_BANK_REQUEST_APPROVED: ADMIN_BANK_REQUEST_APPROVED,
  ADMIN_BANK_REQUEST_REJECTED: ADMIN_BANK_REQUEST_REJECTED,
  ADMIN_BANK_REQUEST_SMS: ADMIN_BANK_REQUEST_SMS,
  ADMIN_BANK_REQUEST_CANCEL: ADMIN_BANK_REQUEST_CANCEL,
  ADMIN_APPROVED_DEPOSIT: ADMIN_APPROVED_DEPOSIT,
  ADMIN_REJECTED_DEPOSIT: ADMIN_REJECTED_DEPOSIT,
  ADMIN_CANCEL_DEPOSIT: ADMIN_CANCEL_DEPOSIT,
  ADMIN_EXPIRE_DEPOSIT: ADMIN_EXPIRE_DEPOSIT,

  // WITHDRAW
  ADMIN_APPROVED_WITHDRAW: ADMIN_APPROVED_WITHDRAW,
  ADMIN_REJECTED_WITHDRAW: ADMIN_REJECTED_WITHDRAW,
  ADMIN_CANCEL_WITHDRAW: ADMIN_CANCEL_WITHDRAW,
  ADMIN_EXPIRE_WITHDRAW: ADMIN_EXPIRE_WITHDRAW,
  ADMIN_DONE_WITHDRAW: ADMIN_DONE_WITHDRAW,
  ADMIN_ROLLBACK_WITHDRAW: ADMIN_ROLLBACK_WITHDRAW,

  // ADMIN EVENTS
  ADMIN_PLAYER_UPDATE: ADMIN_PLAYER_UPDATE,
  ADMIN_SEND_MESSAGE: ADMIN_SEND_MESSAGE,
  ADMIN_ANSWER_INQUIRY: ADMIN_ANSWER_INQUIRY,
  ADMIN_BET_LIMIT_UPDATE: ADMIN_BET_LIMIT_UPDATE,

  // BET365_EVENT
  BET365_EVENT: BET365_EVENT,

  // SKYPARK_EVENTS
  SKYPARK_ROUND: SKYPARK_ROUND,
  SKYPARK_OUTCOME: SKYPARK_OUTCOME,
  SKYPARK_BET_HISTORY: SKYPARK_BET_HISTORY,
  PLAYER_VIRTUAL_HISTORY_DRAGONTIGER_UPDATE:
    PLAYER_VIRTUAL_HISTORY_DRAGONTIGER_UPDATE,
  PLAYER_VIRTUAL_HISTORY_BACCARAT_UPDATE: PLAYER_VIRTUAL_HISTORY_BACCARAT_UPDATE,
  PLAYER_VIRTUAL_HISTORY_DICE_UPDATE: PLAYER_VIRTUAL_HISTORY_DICE_UPDATE,

  BTI_COMMIT_RESERVE: BTI_COMMIT_RESERVE,
}
