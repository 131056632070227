import React, {FC, memo, useMemo} from "react"
import cx from "classnames"

import {Charge} from "types/Bet365/index"
import s from "screens/Bet365/components/BetSlipV2/betSlipV2.module.scss"

interface iSummary {
  charge: Charge
  className?: string
}

const Summary: FC<iSummary> = ({charge, className}) => {
  const isForecast = charge.details.length > 1
  const expectedWinning = useMemo(() => {
    const totalAmount = Math.abs(charge.amount)
    const totalOdds = charge.odds
    return Math.trunc(totalAmount * totalOdds).toLocaleString()
  }, [charge])

  return (
    <div className={cx(s.expectedWinning, className)}>
      <div className={s.header}>
        <span>{`당첨 예상 금액 :`}</span>
        <span>{expectedWinning}</span>
      </div>

      {isForecast && <div className={s.odds}>{charge.odds.toFixed(2)}</div>}
    </div>
  )
}
export default memo(Summary)
