import React, {FC, memo} from "react"

import {Charge} from "types/Bet365/index"

import s from "screens/Bet365/components/BetSlipV2/betSlipV2.module.scss"

interface iMultiBet {
  charge: Charge
  className?: string
}

const MultiBet: FC<iMultiBet> = ({charge}) => {
  // return <>multi</>
  const details = charge.details

  return (
    <div className={s.marketDetails}>
      <div className={s.title}>{`단승식 / 쌍승식`}</div>
      {details.map((item, index) => {
        const odd = item.odds

        const oddName = `${odd.market_value}. ${odd?.hangul_name}`

        return (
          <div className={s.marketOddsRow}>
            <div className={s.marketOdds}>
              <div className={s.marketName}>{index === 0 ? "1st" : "2nd"}</div>
              <span className={s.oddsName}>{oddName}</span>
            </div>
            <div className={s.odds}>{odd.odds.toFixed(2)}</div>
          </div>
        )
      })}
    </div>
  )
}

export default memo(MultiBet)
