import React, {DetailedHTMLProps, HTMLAttributes} from "react"

export type IconProps = {
  fill?: string
  fillTo?: string
} & DetailedHTMLProps<HTMLAttributes<SVGSVGElement>, SVGSVGElement>

export const NextIcon: React.FC<IconProps> = ({...props}) => (
  <svg
    width="27"
    height="88"
    viewBox="0 0 27 88"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2 86L25 44L2 2"
      stroke="white"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)
