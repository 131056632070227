import create from "zustand"
import {persist} from "zustand/middleware"
import Cookie from "js-cookie"

export function setCookie(key: string, value: string | number): void {
  const domain = process.env.REACT_APP_BASE_DOMAIN
  return Cookie.set(key, value, {domain: domain})
}

export function getCookie(getKey: string): string {
  return Cookie.get(getKey)
}

export function removeCookie(getKey: string): string {
  return Cookie.remove(getKey)
}

export function hasToken(): boolean {
  return Cookie.get("token") !== undefined && Cookie.get("token") !== ""
}

// type CookieStore = {
//   virtualCookies: Record<string, string>
//   setCookie: (key: string, value: string | number) => void
//   getCookie: (key: string) => string
//   removeCookie: (key: string) => string
//   hasToken: () => boolean
// }

// export const useCookieStore = create<CookieStore>(
//   persist(
//     (set, get) => ({
//       virtualCookies: {},
//       setCookie: (key: string, value: string | number) => {
//         const {virtualCookies} = get()
//         set({
//           virtualCookies: {...virtualCookies, [key]: String(value)},
//         })
//       },
//       getCookie: (key: string) => {
//         const {virtualCookies} = get()
//         return virtualCookies[key]
//       },
//       removeCookie: (key: string) => {
//         const {virtualCookies} = get()
//         const value = virtualCookies[key]
//         delete virtualCookies[key]
//         return value
//       },
//       hasToken: () => {
//         const {virtualCookies} = get()
//         return virtualCookies["token"] !== undefined
//       },
//     }),
//     {
//       name: "cookie-store",
//     },
//   ),
// )

// const {getState, subscribe} = useCookieStore

// export const getCookie = getState().getCookie
// export const setCookie = getState().setCookie
// export const removeCookie = getState().removeCookie
// export const hasToken = getState().hasToken
// export const virtualCookies = getState().virtualCookies
// export const subscribeToCookies = subscribe
