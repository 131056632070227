// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, {useState} from "react"
import {colorPalette as cp} from "utils/theme/colors"
import {isMobileSafari, isIOS} from "react-device-detect"
import Fade from "react-reveal/Fade"
import {REGEX_ALPHA_NUM_SPECIAL_CHAR} from "screens/MyProfile/config"
/**
 * Component
 */
import {
  IconPassword,
  IconPasswordShow,
  IconExclamation,
} from "components/Icons/Forms"
/**
 * Utils
 */
import {tabElement} from "utils/helpers"
/**
 * Styles
 */
import s from "./confirm.module.css"

interface IFormConfirmInput {
  name: string
  label: string
  stateValue: string
  passwordValue: string
  defaultValue?: string
  labelHelper?: string
  placeholder?: string
  disabled?: boolean
  register: any
  errors?: any
  onChange?: any
  onFocus?: () => void
  onBlur?: () => void
  resetValue?: () => void
}

const FormPasswordConfirmInput = ({
  label,
  name,
  placeholder,
  errors,
  stateValue,
  disabled = false,
  register,
  onChange,
  onFocus,
  onBlur,
  resetValue,
}: IFormConfirmInput): JSX.Element => {
  const [isFocused, setFocus] = useState(false)
  const [isShow, setShow] = useState(false)
  const [textPosition, setTextPosition] = useState(0)
  const [isTouched, setIsTouched] = useState(false)
  const [isToggledClicked, setIsToggledClicked] = useState(null)

  const formInput = `forminput-${name}`

  const ErrorRequired = errors !== undefined
  const FocusErrorRequired = isFocused && ErrorRequired
  const NotFocusErrorRequired = !isFocused && ErrorRequired
  const HasNoError = errors === undefined && stateValue !== ""
  const errorMsg = "비밀번호가 일치 하지 않습니다."

  function handleFocus(e: React.ChangeEvent<HTMLInputElement>) {
    setIsTouched(true)
    setFocus(true)

    if (isMobileSafari || isIOS) {
      if (e.currentTarget.value.length === 0) {
        onFocus && onFocus()
      } else {
        e.target.setSelectionRange(
          e.currentTarget.value.length,
          e.currentTarget.value.length,
        )
      }
    } else {
      if (isToggledClicked || isToggledClicked === null) {
        if (textPosition === 0) {
          onFocus && onFocus()
        } else {
          e.target.setSelectionRange(textPosition, textPosition)
        }
      }
    }
  }

  function handleBlur(e: React.ChangeEvent<HTMLInputElement>) {
    setFocus(false)
    setIsToggledClicked(null)

    if (isMobileSafari || isIOS) {
      setTextPosition(e.target.selectionStart)
      onBlur && onBlur()
    } else {
      setTextPosition(e.target.selectionStart)
      setIsToggledClicked(true)
      onBlur && onBlur()
    }

    if (errors === undefined && stateValue !== "") {
      return null
    }
  }

  function showPassword() {
    const inputID = document.getElementById("register-password-confirm-input")

    if (isMobileSafari || isIOS) {
      inputID.focus()
      setShow(!isShow)
    } else {
      setIsToggledClicked(true)
      inputID.focus()
      setShow(!isShow)
    }
  }

  const borderClass = `
    ${s.inputBordered}
    ${FocusErrorRequired ? s.inputBorderedActive : ``}
    ${NotFocusErrorRequired ? s.inputBorderedError : ``}
    ${isFocused ? s.inputBorderedActive : ``}
    ${HasNoError ? s.inputBorderedValid : ``} 
  `

  const messageClass = `
    ${s.confirmMessage}
    ${FocusErrorRequired ? s.confirmMessageDefault : ``}
    ${NotFocusErrorRequired ? s.confirmMessageError : ``}
    ${isFocused ? s.confirmMessageDefault : ``} 
    ${HasNoError ? s.confirmMessageValid : ``} 
  `

  return (
    <div>
      <div className={borderClass}>
        <div className={s.labelContainer}>
          <div className={s.label}>{label}</div>
        </div>
        <input
          key={label}
          autoCapitalize="off"
          autoCorrect="off"
          autoComplete="new-password"
          name={label}
          maxLength={20}
          className={isIOS ? s.formInputIOS : s.formInput}
          disabled={disabled}
          type={isShow ? "text" : "password"}
          onKeyUp={e => {
            tabElement(e, true)
          }}
          onClick={() => resetValue()}
          onFocus={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleFocus(e)
          }}
          onBlur={(e: React.ChangeEvent<HTMLInputElement>) => handleBlur(e)}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const value = (e as any).target.value
            const val = value.replaceAll(REGEX_ALPHA_NUM_SPECIAL_CHAR, "")
            onChange(val)
          }}
          ref={register}
          placeholder={placeholder}
          id={"register-password-confirm-input"}
        />
        <div
          className={`${formInput}-password-toggle`}
          role="button"
          onClick={() => showPassword()}
        >
          {isShow ? <IconPasswordShow /> : <IconPassword />}
          <style jsx>{`
            .${formInput}-password-toggle {
              position: absolute;
              top: 0;
              right: 0;
              flex: 1;
              width: 60px;
              height: 54px;
              display: flex;
              align-items: center;
              justify-content: center;
              z-index: 0;
            }
            .${formInput}-password-toggle svg {
              position: relative;
              z-index: 1;
              padding-top: 2px;
            }
          `}</style>
        </div>
      </div>

      <div>
        {isIOS ? (
          <div className={messageClass}>
            {isShow && (
              <Fade
                top
                opposite
                when={
                  (NotFocusErrorRequired &&
                    stateValue !== "" &&
                    errors === "valid") ||
                  (NotFocusErrorRequired && stateValue !== "" && errors !== "valid")
                }
              >
                <div className={`${s.confirmHelper}`}>
                  <div className={s.icon}>
                    <IconExclamation color={cp.red} />
                  </div>
                  <span className={s.confirmHelperTextError}>{errorMsg}</span>
                </div>
              </Fade>
            )}

            {!isShow && (
              <Fade
                top
                opposite
                when={
                  (NotFocusErrorRequired &&
                    stateValue !== "" &&
                    errors === "valid") ||
                  (NotFocusErrorRequired && stateValue !== "" && errors !== "valid")
                }
              >
                <div className={`${s.confirmHelper}`}>
                  <div className={s.icon}>
                    <IconExclamation color={cp.red} />
                  </div>
                  <span className={s.confirmHelperTextError}>{errorMsg}</span>
                </div>
              </Fade>
            )}

            {isShow && (
              <Fade
                top
                opposite
                when={
                  NotFocusErrorRequired && stateValue === "" && errors !== "valid"
                }
              >
                <div className={`${s.confirmHelper}`}>
                  <div className={s.icon}>
                    <IconExclamation color={cp.red} />
                  </div>
                  <span
                    className={s.confirmHelperTextError}
                  >{`필수 정보입니다.`}</span>
                </div>
              </Fade>
            )}

            {!isShow && (
              <Fade
                top
                opposite
                when={
                  NotFocusErrorRequired && stateValue === "" && errors !== "valid"
                }
              >
                <div className={`${s.confirmHelper}`}>
                  <div className={s.icon}>
                    <IconExclamation color={cp.red} />
                  </div>
                  <span
                    className={s.confirmHelperTextError}
                  >{`필수 정보입니다.`}</span>
                </div>
              </Fade>
            )}

            <Fade top opposite when={HasNoError}>
              <div className={`${s.confirmHelper} ${s.confirmHelperValid}`}>
                <span
                  className={s.confirmHelperTextValid}
                >{`입력하신 비밀번호와 일치합니다.`}</span>
              </div>
            </Fade>
          </div>
        ) : (
          <div className={messageClass}>
            {isShow && (
              <Fade
                top
                opposite
                when={
                  (NotFocusErrorRequired &&
                    stateValue !== "" &&
                    errors === "valid") ||
                  (NotFocusErrorRequired && stateValue !== "" && errors !== "valid")
                }
              >
                <div className={`${s.confirmHelper}`}>
                  <div className={s.icon}>
                    <IconExclamation color={cp.red} />
                  </div>
                  <span className={s.confirmHelperTextError}>{errorMsg}</span>
                </div>
              </Fade>
            )}

            {!isShow && (
              <Fade
                top
                opposite
                when={
                  (NotFocusErrorRequired &&
                    stateValue !== "" &&
                    errors === "valid") ||
                  (NotFocusErrorRequired && stateValue !== "" && errors !== "valid")
                }
              >
                <div className={`${s.confirmHelper}`}>
                  <div className={s.icon}>
                    <IconExclamation color={cp.red} />
                  </div>
                  <span className={s.confirmHelperTextError}>{errorMsg}</span>
                </div>
              </Fade>
            )}

            {isShow && (
              <Fade
                top
                opposite
                when={
                  NotFocusErrorRequired && stateValue === "" && errors !== "valid"
                }
              >
                <div className={`${s.confirmHelper}`}>
                  <div className={s.icon}>
                    <IconExclamation color={cp.red} />
                  </div>
                  <span
                    className={s.confirmHelperTextError}
                  >{`필수 정보입니다.`}</span>
                </div>
              </Fade>
            )}

            {!isShow && (
              <Fade
                top
                opposite
                when={
                  NotFocusErrorRequired && stateValue === "" && errors !== "valid"
                }
              >
                <div className={`${s.confirmHelper}`}>
                  <div className={s.icon}>
                    <IconExclamation color={cp.red} />
                  </div>
                  <span
                    className={s.confirmHelperTextError}
                  >{`필수 정보입니다.`}</span>
                </div>
              </Fade>
            )}

            <Fade top opposite when={HasNoError}>
              <div className={`${s.confirmHelper} ${s.confirmHelperValid}`}>
                <span
                  className={s.confirmHelperTextValid}
                >{`입력하신 비밀번호와 일치합니다.`}</span>
              </div>
            </Fade>
          </div>
        )}
      </div>
    </div>
  )
}

export default FormPasswordConfirmInput
