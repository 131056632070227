import React, {FC, memo, useEffect, Suspense, CSSProperties, useContext} from "react"
import cx from "classnames"
import {useModal} from "react-modal-hook"
import shallow from "zustand/shallow"
import {SIGNUP} from "screens/NavHome/utils/config"
import ModalRegister from "components/RegisterButton/ModalRegister"
import useRegisterModalStore from "components/RegisterButton/hooks/useRegisterModalStore"
import useConfirmModal from "hooks/useConfirmModal"
import useRegisterModalController from "components/RegisterButton/hooks/useRegisterModalController"
import s from "components/RegisterButton/registerButton.module.scss"
import {showComingSoon} from "screens/NavHome/components/GlobalAlert/utils"
import Register from "screens/Account/Register"
import {ThemeContext} from "context/ThemeContext"

interface iRegisterButton {
  className?: string
}
interface CSSCustomProperties extends CSSProperties {
  "--buttonActiveTextColor"?: string
  "--buttonActivebottomLine"?: string
  "--headerHoverColor"?: string
  "--headerActiveColor"?: string
  
}
const RegisterButton: FC<iRegisterButton> = ({className}): JSX.Element => {
  const {theme} = useContext(ThemeContext)

  const custom_colors: CSSCustomProperties = {
    "--buttonActiveTextColor": theme?.colors.buttonActiveTextColor,
    "--buttonActivebottomLine": theme?.colors.buttonActivebottomLine,
    "--headerHoverColor": theme?.colors.headerHoverColor,
    "--headerActiveColor": theme?.colors.headerActiveColor,
    
  }
  const {showConfirmModal} = useConfirmModal()
  const {isOpen, setShowConfirm, setIsOpen} = useRegisterModalStore(
    store => ({
      isOpen: store.isOpen,
      setShowConfirm: store.setShowConfirm,
      setIsOpen: store.setIsOpen,
    }),
    shallow,
  )

  const [showModal, hideModal] = useModal(() => {
    return (
      <ModalRegister
        content={
          <Suspense fallback={<></>}>
            <Register isModal />
          </Suspense>
        }
        hideModal={hideModal}
      />
    )
  }, [])

  const {handleToggleModal} = useRegisterModalController()

  useEffect(() => {
    const canRegister = useRegisterModalStore.getState().isEnabled

    if (!canRegister && isOpen) {
      showComingSoon()
      setIsOpen(false)
    } else if (isOpen) showModal()
  }, [isOpen])

  useEffect(() => {
    setShowConfirm(showConfirmModal)
  }, [showConfirmModal])

  return (
    <button
      style={custom_colors}
      className={cx(s.container, className, {[s.active]: isOpen})}
      onClick={() => handleToggleModal(!isOpen)}
    >
      <div className={s.label}>{SIGNUP}</div>
    </button>
  )
}

export default memo(RegisterButton)
