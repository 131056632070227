import {useEffect} from "react"
import shallow from "zustand/shallow"
import usePusherStore from "stores/usePusherStore"
import PUSHER_EVENTS from "provider/pusher/pusherEvents"
import usePublicGameEvents from "provider/pusher/events/usePublicGameEvents"

const useGameActivityChannel = () => {
  const [pusher, setPublicGameNotifChannel] = usePusherStore(
    store => [store.pusher, store.setPublicGameNotifChannel],
    shallow,
  )

  useEffect(() => {
    if (!pusher) return

    // console.log("init public game channel")
    const publicChannel = pusher.subscribe(PUSHER_EVENTS.PUBLIC_GAME_CHANNEL)
    setPublicGameNotifChannel(publicChannel)

    return () => {
      const pusherStore = usePusherStore.getState()
      const channel = pusherStore.publicGameNotifChannel
      if (channel) {
        // console.log("destroy public Channnel")
        channel.unbind_all()
        channel.unsubscribe()
        channel.disconnect()
      }
    }
  }, [pusher])

  usePublicGameEvents()
}

export default useGameActivityChannel
