import React, {createContext, useState} from "react"

export const ThemeContext = createContext(null)

export const ThemeProvider = ({children}) => {
  const [theme, setTheme] = useState(null) // Initial theme (or get from storage)
  const [topDesktopBanner, setTopDesktopBanner] = useState(null)
  const [bottomDesktopBanner, setBottomDesktopBanner] = useState(null)
  const [topMobileBanner, setTopMobileBanner] = useState(null)
  const [bottomMobileBanner, setBottomMobileBanner] = useState(null)

  return (
    <ThemeContext.Provider
      value={{
        theme,
        setTheme,
        topDesktopBanner,
        setTopDesktopBanner,
        bottomDesktopBanner,
        setBottomDesktopBanner,
        topMobileBanner,
        setTopMobileBanner,
        bottomMobileBanner,
        setBottomMobileBanner,
      }}
    >
      {children}
    </ThemeContext.Provider>
  )
}
