import React, {useMemo} from "react"
import {CategoryIconType} from "./CategoryIconType"

const BaseballSVG = ({
  active,
  className,
  fill1,
  fill2,
}: CategoryIconType): JSX.Element => {
  const fill = useMemo(() => {
    return active ? fill1 : fill2
  }, [active])

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={className && className}
    >
      <path
        fill={fill}
        d="M0,12A11.952,11.952,0,0,1,3.114,3.944a12.172,12.172,0,0,1,.881,1.093l-.667.34a.711.711,0,1,0,.645,1.267l.786-.4a11.98,11.98,0,0,1,.757,1.678l-.923.219A.711.711,0,0,0,4.92,9.526l.994-.236a12.187,12.187,0,0,1,.286,2H5.067a.711.711,0,0,0,0,1.422H6.2a11.984,11.984,0,0,1-.274,1.946L4.92,14.419A.711.711,0,0,0,4.593,15.8l.94.223a11.859,11.859,0,0,1-.752,1.685L3.973,17.3a.711.711,0,0,0-.645,1.268l.7.354a11.826,11.826,0,0,1-.909,1.135A11.95,11.95,0,0,1,0,12"
      />
      <path
        fill={fill}
        d="M4.133,21.05A13.467,13.467,0,0,0,5.3,19.574l1.034.526a.711.711,0,0,0,.645-1.267l-.934-.476a13.171,13.171,0,0,0,.873-2l.957.226A.711.711,0,0,0,8.207,15.2l-.9-.212a13.431,13.431,0,0,0,.316-2.274h.819a.711.711,0,0,0,0-1.422H7.625A13.518,13.518,0,0,0,7.3,8.963l.909-.216a.711.711,0,0,0-.328-1.384l-.973.231a13.33,13.33,0,0,0-.878-2l.955-.486a.711.711,0,0,0-.645-1.268l-1.06.54A13.575,13.575,0,0,0,4.133,2.95a11.965,11.965,0,0,1,15.734,0,13.5,13.5,0,0,0-1.141,1.433l-1.058-.539a.712.712,0,0,0-.646,1.268l.954.485a13.188,13.188,0,0,0-.881,2l-.969-.23A.711.711,0,0,0,15.8,8.747l.9.214a13.459,13.459,0,0,0-.328,2.328h-.814a.711.711,0,0,0,0,1.422h.813a13.545,13.545,0,0,0,.316,2.275L15.8,15.2a.711.711,0,0,0,.328,1.384l.951-.225a13.2,13.2,0,0,0,.874,2l-.929.473a.711.711,0,1,0,.646,1.267l1.027-.523a13.445,13.445,0,0,0,1.172,1.473,11.965,11.965,0,0,1-15.734,0"
      />
      <path
        fill={fill}
        d="M20.886,20.056a12.03,12.03,0,0,1-.909-1.131l.7-.357a.711.711,0,1,0-.645-1.267l-.815.414a11.952,11.952,0,0,1-.751-1.688l.946-.224a.711.711,0,0,0-.328-1.384l-1.011.24a12.008,12.008,0,0,1-.274-1.948h1.139a.711.711,0,0,0,0-1.422H17.8a12.109,12.109,0,0,1,.287-2l1,.237a.711.711,0,1,0,.328-1.384l-.927-.22a11.764,11.764,0,0,1,.759-1.678l.788.4a.711.711,0,0,0,.645-1.268l-.672-.342a12,12,0,0,1,.879-1.092,11.977,11.977,0,0,1,0,16.113"
      />
    </svg>
  )
}

export default BaseballSVG
