import React, {FC, memo, Suspense} from "react"
import shallow from "zustand/shallow"
import {lazyWithRetry} from "utils/lazyWithRetry"
import {useUserStore} from "stores/userStore"
const Private = lazyWithRetry(() => import("./Private"))
const Public = lazyWithRetry(() => import("./Public"))

interface iNavSlider {
  className?: string
}
const MobileNavSlider: FC<iNavSlider> = ({className}): JSX.Element => {
  const authToken = useUserStore(store => store.authToken, shallow)

  if (authToken)
    return (
      <Suspense fallback={<></>}>
        <Private className={className} />
      </Suspense>
    )

  return (
    <Suspense fallback={<></>}>
      <Public className={className} />
    </Suspense>
  )
}

export default memo(MobileNavSlider)
