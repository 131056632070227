import React, {DetailedHTMLProps, HTMLAttributes} from "react"

export type IconProps = {
  fill?: string
  fillTo?: string
} & DetailedHTMLProps<HTMLAttributes<SVGSVGElement>, SVGSVGElement>

export const LogoIcon: React.FC<IconProps> = ({fill = "#fff", ...props}) => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="122.769"
    height="21"
    viewBox="0 0 122.769 21"
    {...props}
  >
    <g id="Group_7249" data-name="Group 7249" transform="translate(-20 -861)">
      <g
        id="Group_6607"
        data-name="Group 6607"
        transform="translate(52.268 863.324)"
      >
        <g id="CustomFont" transform="translate(0 0)">
          <path
            id="Path_20451"
            data-name="Path 20451"
            d="M1065.633,148.029h-13.28a3,3,0,0,1-3-3v-9.816a3,3,0,0,1,3-3h13.28v3.163h-10.958q-1.775,0-1.775,1.582v1.581h9.215V141.7H1052.9v2.083a1.08,1.08,0,0,0,1.08,1.08h11.652Z"
            transform="translate(-1049.353 -132.212)"
            fill="#fff"
          />
          <path
            id="Path_20452"
            data-name="Path 20452"
            d="M1080.405,144.874l10.847-9.5H1080.4v-3.163h14.787a1.8,1.8,0,0,1,1.187,3.154l-10.829,9.5h10.823v3.163h-14.78A1.8,1.8,0,0,1,1080.405,144.874Z"
            transform="translate(-1061.526 -132.212)"
            fill="#fff"
          />
          <path
            id="Path_20453"
            data-name="Path 20453"
            d="M1126.521,143.284a4.171,4.171,0,0,1-.418,1.853,4.827,4.827,0,0,1-1.141,1.5,5.475,5.475,0,0,1-1.685,1.017,5.72,5.72,0,0,1-2.079.373h-10.645V132.212H1121.2a5.746,5.746,0,0,1,2.066.373,5.457,5.457,0,0,1,1.7,1.016,4.8,4.8,0,0,1,1.141,1.514,4.2,4.2,0,0,1,.418,1.842,4.314,4.314,0,0,1-1.369,3.163A4.315,4.315,0,0,1,1126.521,143.284Zm-3.549-6.326a1.449,1.449,0,0,0-.519-1.119,1.824,1.824,0,0,0-1.255-.463h-7.1v3.163h7.1a1.821,1.821,0,0,0,1.255-.463A1.446,1.446,0,0,0,1122.972,136.958Zm0,6.326a1.448,1.448,0,0,0-.519-1.118,1.824,1.824,0,0,0-1.255-.463h-7.1v3.163h7.1a1.822,1.822,0,0,0,1.255-.463A1.447,1.447,0,0,0,1122.972,143.284Z"
            transform="translate(-1073.231 -132.212)"
            fill="#fff"
          />
          <path
            id="Path_20454"
            data-name="Path 20454"
            d="M1157.949,148.029h-13.281a3,3,0,0,1-3-3v-9.816a3,3,0,0,1,3-3h13.281v3.163h-10.958q-1.775,0-1.774,1.582v1.581h8.615V141.7h-8.615v2.083a1.08,1.08,0,0,0,1.08,1.08h11.652Z"
            transform="translate(-1085.677 -132.212)"
            fill="#fff"
          />
          <path
            id="Path_20455"
            data-name="Path 20455"
            d="M1182.327,135.375v12.653h-3.549V135.375h-6.21v-3.163h15.968v3.163Z"
            transform="translate(-1098.036 -132.212)"
            fill="#fff"
          />
        </g>
      </g>
      <g id="SymArrow" transform="translate(20 861)">
        <path
          id="Path_20415"
          data-name="Path 20415"
          d="M872.1,184.534l-10.158,4.238-3.073-3.984h1.686a3.383,3.383,0,0,0,2.7-.779c.928-1.1,5.265-6.528,1.839-7.229-4.131-.844-12.362,5.327-13.129,5.9-.324.241-1.274.228-1.013-.013q.939-.872,2.038-1.747a31.1,31.1,0,0,1,4.245-2.835c.22-.118.427-.236.665-.353l.347-.177.367-.177c.238-.118.512-.235.773-.353.135-.059.281-.116.422-.171s.285-.117.443-.171a18.068,18.068,0,0,1,2.134-.676c.422-.1.887-.2,1.394-.284.247-.043.52-.07.78-.1s.532-.042.8-.059c.256,0,.518-.011.758,0l.347.018c.116.007.232.014.328.026a6.748,6.748,0,0,1,1.053.19,3.9,3.9,0,0,1,1.245.551l.111.075.093.076c.061.052.126.1.18.155a2.856,2.856,0,0,1,.283.315l.12.16.1.162a2.51,2.51,0,0,1,.163.326,4.587,4.587,0,0,1,.139,2.7,14.715,14.715,0,0,1-1.5,3.624c-.218.369-.146.6.354.6Z"
          transform="translate(-845.504 -175.554)"
          fill="#fff"
        />
        <path
          id="Path_20416"
          data-name="Path 20416"
          d="M815.727,230.376l10.115-4.246,3.091,3.99h-1.727c-1.348,0-2.1.138-2.681.774-.929,1.1-5.264,6.528-1.839,7.229,4.131.844,12.362-5.327,13.129-5.9.324-.241,1.274-.228,1.013.014-.626.581-1.3,1.163-2.038,1.746a31.14,31.14,0,0,1-4.245,2.835c-.219.118-.427.236-.665.353l-.347.177-.367.177c-.238.118-.512.235-.773.352-.135.059-.281.117-.422.176s-.286.117-.443.171a17.852,17.852,0,0,1-2.134.676c-.422.1-.887.2-1.394.284-.247.043-.52.07-.78.1s-.532.042-.8.059a6.809,6.809,0,0,1-.758,0l-.347-.018c-.116-.006-.232-.013-.328-.026a6.692,6.692,0,0,1-1.053-.19,3.912,3.912,0,0,1-1.245-.55l-.111-.075-.093-.077c-.061-.051-.127-.1-.18-.154a3.043,3.043,0,0,1-.284-.315l-.12-.16-.1-.161a2.623,2.623,0,0,1-.164-.327,4.592,4.592,0,0,1-.138-2.7,12.262,12.262,0,0,1,1.5-3.624c.217-.369-.043-.6-.627-.6Z"
          transform="translate(-815.727 -218.359)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
)
