import React, {Suspense, memo} from "react"
import {useLocation} from "react-router-dom"
import LandingPage from "components/LandingPage"
import {lazyWithRetry} from "utils/lazyWithRetry"
import useLiveChatProvider from "components/LiveChat/useLiveChatProvider"
import Container from "layout/Container"
import Header from "./Header"
import Content from "./Content"
// import s from "screens/NavHome/navHome.module.scss"

const FadeOverlay = lazyWithRetry(() => import("components/FadeOverlay"))
const FadeToBlack = lazyWithRetry(() => import("components/FadeToBlack"))

const ChatButton = lazyWithRetry(() => import("components/DraggableChatButton"))

const Layout = () => {
  const location = useLocation()

  useLiveChatProvider()
  return (
    <>
      <Suspense fallback={<LandingPage />}>
        <Container path={location.pathname}>
          <section className="route-section">
            <Header />
            <Content />
          </section>
          <FadeOverlay />
          <FadeToBlack />
        </Container>
        <ChatButton />
      </Suspense>
    </>
  )
}

export default memo(Layout)
