import {lazy} from "react"

// const urlWithRndQueryParam = (url, paramName) => {
//   const ulrArr = url.split("#")
//   const urlQry = ulrArr[0].split("?")
//   const usp = new URLSearchParams(urlQry[1] || "")
//   usp.set(paramName || "_z", `${Date.now()}`)
//   urlQry[1] = usp.toString()
//   ulrArr[0] = urlQry.join("?")
//   return ulrArr.join("#")
// }

// const handleHardReload = async url => {
//   await fetch(url, {
//     headers: {
//       Pragma: "no-cache",
//       Expires: "-1",
//       "Cache-Control": "no-cache",
//     },
//   })
//   window.location.href = urlWithRndQueryParam(url, "refresh")
//   // This is to ensure reload with url's having '#'
//   window.location.reload()
// }

// export const lazyWithRetry = componentImport =>
//   lazy(async () => {
//     const pageHasAlreadyBeenForceRefreshed = JSON.parse(
//       window.localStorage.getItem("page-has-been-force-refreshed") || "false",
//     )

//     try {
//       const component = await componentImport()

//       window.localStorage.setItem("page-has-been-force-refreshed", "false")

//       return component
//     } catch (error) {
//       console.log(error)
//       console.log(
//         "pageHasAlreadyBeenForceRefreshed, ",
//         pageHasAlreadyBeenForceRefreshed,
//       )
//       if (!pageHasAlreadyBeenForceRefreshed) {
//         // Assuming that the user is not on the latest version of the application.
//         // Let's refresh the page immediately.
//         window.localStorage.setItem("page-has-been-force-refreshed", "true")
//         return await handleHardReload(window.location.href)
//       }

//       // The page has already been reloaded
//       // Assuming that user is already using the latest version of the application.
//       // Let's let the application crash and raise the error.
//       throw error
//     }
//   })

export const lazyWithRetry = componentImport =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem("page-has-been-force-refreshed") || "false",
    )
    try {
      const component = await componentImport()

      window.localStorage.setItem("page-has-been-force-refreshed", "false")
      return component
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        window.localStorage.setItem("page-has-been-force-refreshed", "true")
        // alert("새 버전이 검색되었습니다. 페이지가 다시 로드됩니다. 감사해요.");
        // return window.location.reload()
      }
      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      throw error
    }
  })
