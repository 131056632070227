export const EOS_ONE = "eos-pb-1"
export const EOS_TWO = "eos-pb-2"
export const EOS_THREE = "eos-pb-3"
export const EOS_FOUR = "eos-pb-4"
export const EOS_FIVE = "eos-pb-5"
export const ONE_MIN = "1m"
export const TWO_MIN = "2m"
export const THREE_MIN = "3m"
export const FOUR_MIN = "4m"
export const FIVE_MIN = "5m"

export const RESULT_EVENT = "results"
export const NEW_ROUND_EVENT = "new-round"

export const EOS_GAME_RESULTS_QUERY = "EOS_GAME_RESULTS_QUERY"
export const EOS_GAME_BET_HISTORY_QUERY = "EOS_GAME_BET_HISTORY_QUERY"
export const EOS_GAME_DETAILS_QUERY = "EOS_GAME_DETAILS_QUERY"
export const EOS_GAME_LAUNCHER_QUERY = "EOS_GAME_LAUNCHER_QUERY"

export const REQUIRED_LOGIN_MSG = "로그인이 필요한 서비스 입니다."
export const CLOSE_BETTING_TIME_MSG = "베팅 가능 시간이 마감 되었습니다."
export const BET_REQUIRED_MSG = "구간 선택을 먼져 하셔야 합니다."
export const IN_PROGRESS_ROUND_MSG = "회차가 진행중 입니다."
export const NO_ROUND_MSG = "사용 가능한 라운드 없음."
