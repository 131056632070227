import shallow from "zustand/shallow"
import DefaultInputController from "screens/MyProfile/types/Input/DefaultInputController"
import useTelegramInputStore from "screens/MyProfile/screens/Affiliate/store/useTelegramInputStore"
import useAffiliateStore from "screens/MyProfile/store/useAffiliateStore"
import {REQUIRED_FIELD} from "screens/MyProfile/constants"

const useTelegramInputController = (): DefaultInputController => {
  const {
    setValue,
    setError,
    setIsDirty,
    setIsTouched,
    setIsFocused,
    setIsValidPattern,
    handleValidateTelegram,
  } = useTelegramInputStore(
    store => ({
      setValue: store.setValue,
      setError: store.setError,
      setIsDirty: store.setIsDirty,
      setIsTouched: store.setIsTouched,
      setIsFocused: store.setIsFocused,
      setIsValidPattern: store.setIsValidPattern,
      handleValidateTelegram: store.handleValidate,
    }),
    shallow,
  )

  const setTelegramReq = useAffiliateStore(store => store.setTelegramReq, shallow)

  const handleValidate = (value: string) => {
    return value?.length >= 6
  }

  const handleReset = () => {
    setValue("")
    setIsValidPattern(false)
    setIsDirty(false)
    setError({
      status_code: 0,
      message: "",
    })
    setTelegramReq(null)
  }

  const handleOnFocus = () => {
    setIsFocused(true)
    setIsTouched(true)
    handleReset()
  }

  const handleOnBlur = (value: string) => {
    setIsFocused(false)
    const isValid = handleValidate(value)
    if (value?.length === 0) {
      setIsValidPattern(false)
      setIsDirty(true)
      setError({
        status_code: -2,
        message: REQUIRED_FIELD,
      })
    } else if (!isValid) {
      setIsValidPattern(false)
      setIsDirty(true)
      setError({
        status_code: -1,
        message: REQUIRED_FIELD,
      })
    } else {
      handleValidateTelegram(value)
    }
  }

  const handleOnChange = (value: string) => {
    setIsDirty(true)
    setValue(value)
  }

  return {
    handleOnBlur,
    handleOnChange,
    handleOnFocus,
    handleValidate,
  }
}

export default useTelegramInputController
