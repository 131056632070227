import NavStore from "screens/NavHome/types/NavStore"
import create from "zustand"
import {HOME} from "screens/NavHome/utils/config"

const navStore = create<NavStore>((set, get) => ({
  selectedSlideTitle: HOME,
  selectedIndex: 0,
  sliderIndex: 0,
  prevIndex: 0,
  renderedIndexes: [0, 1, 2],
  isDragging: false,
  bodyRef: null,
  sliderRef: null,
  maxScroll: 0,
  activeButtonRef: null,
  setSelectedTitle: selectedSlideTitle => set({selectedSlideTitle}),
  setSelectedIndex: selectedIndex => {
    const prevIndex = get().selectedIndex
    set({
      prevIndex,
      selectedIndex,
    })
  },
  setSliderIndex: sliderIndex => set({sliderIndex}),
  setPrevIndex: prevIndex => set({prevIndex}),
  setRenderedIndexes: renderedIndexes => set({renderedIndexes}),
  setIsDragging: isDragging => set({isDragging}),
  setBodyRef: bodyRef => set({bodyRef}),
  setSliderRef: sliderRef => set({sliderRef}),
  setMaxScroll: maxScroll => set({maxScroll}),
  setActiveButtonRef: activeButtonRef => set({activeButtonRef}),
}))

// export const navStore = create<NavStore>(
//     persist(
//       (set, get) => ({
//         selectedSlideTitle: HOME,
//         selectedIndex: 0,
//         prevIndex: 0,
//         renderedIndexes: [0, 1, 2],
//         isDragging: false,
//         bodyRef: null,
//         sliderRef: null,
//         setSelectedTitle: selectedSlideTitle => set({selectedSlideTitle}),
//         setSelectedIndex: selectedIndex => {
//             const prevIndex = get().selectedIndex;
//             set({
//                 prevIndex,
//                 selectedIndex,
//             })
//         },
//         setPrevIndex: prevIndex => set({prevIndex}),
//         setRenderedIndexes: renderedIndexes => set({renderedIndexes}),
//         setIsDragging: isDragging => set({isDragging}),
//         setBodyRef: bodyRef => set({bodyRef}),
//         setSliderRef: sliderRef => set({sliderRef})
//       }),
//       {
//         name: "navHome-store", // unique name
//       },
//     ),
//   )

export default navStore
