// eslint-disable-next-line @typescript-eslint/no-use-before-define
import {ThemeContext} from "context/ThemeContext"
import React, {useContext} from "react"

/**
 * Utils
 */
import {colorPalette as $cp} from "utils/theme/colors"

interface iCheckbox {
  keyName: string
  checked: boolean
  label: string
  labelSize?: number
  labelWeight?: number
  onChange: () => void
  children?: React.ReactNode
}

function Checkbox({
  keyName,
  checked,
  label,
  onChange,
  labelSize = 12,
  labelWeight = 300,
  children,
}: iCheckbox): JSX.Element {
  const {theme} = useContext(ThemeContext)

  return (
    <div className={`default-checkbox-wrapper`}>
      <div className={`default}-checkbox-container`}>
        <div className={`default-checkbox-container-input`}>
          <div className={`default-checkbox-bordered`}>
            <div className={`default-checkbox-body`}>
              <div>
                <span>
                  <label style={{display: "flex", alignItems: "center"}}>
                    <div className={`default-checkbox-content`}>
                      <input
                        type="checkbox"
                        className={`default-checkbox-input`}
                        onChange={onChange}
                        checked={checked}
                      />
                      <div className={`default-checkbox-styled`}>
                        <svg viewBox="0 0 24 24">
                          <polyline points="20 6 9 17 4 12"></polyline>
                        </svg>
                      </div>
                    </div>
                    {children ? (
                      children
                    ) : (
                      <div className={`default-checkbox-label`}>{label}</div>
                    )}
                  </label>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        .default-checkbox-wrapper {
          width: auto;
          height: 100%;
          position: relative;
          align-items: center;
          padding: 0px;
          margin: 0px;
        }
        .default-checkbox-wrapper:last-child {
          margin-bottom: 0px;
        }
        .default-checkbox-container {
          position: relative;
          width: 100%;
          height: 100%;
          padding: 0px;
          box-sizing: border-box;
          transition: margin-bottom ${0.6}s ease-in-out;
        }
        .default-checkbox-container-input {
          flex: 1 1 0%;
        }
        .default-checkbox-bordered {
          position: relative;
          padding: 0px;
          margin: 0px;
          width: 100%;
          border-radius: 4px;
          transition: all 0.6s ease-in-out 0s;
          box-shadow: transparent 0px 0px 0px 1px inset;
        }
        .default-checkbox-body {
          display: flex;
          justify-content: space-between;
          padding: 0px;
          margin: 0px;
        }

        .default-checkbox-content {
          display: inline-block;
          vertical-align: middle;
        }
        .default-checkbox-input {
          border: 0;
          clip: rect(0 0 0 0);
          clippath: inset(50%);
          height: 1px;
          margin: -1px;
          overflow: hidden;
          padding: 0;
          position: absolute;
          white-space: nowrap;
          width: 1px;
        }
        .default-checkbox-styled {
          width: 20px;
          height: 20px;
          background: ${checked ? $cp.aquablue : theme.colors.loginInputFieldBG};
          box-shadow: inset 0 0 0 1px ${checked ? $cp.aquablue : $cp.grey2};
          border-radius: 2px;
          transition: all ${0.3}s ease-in-out;
        }

        .default-checkbox-styled svg {
          visibility: ${checked ? "visible" : "hidden"};
          fill: none;
          stroke: white;
          stroke-width: 2px;
        }

        .default-checkbox-label {
          font-size: ${labelSize}px;
          font-weight: ${labelWeight};
          color: ${$cp.grey2};
          font-family: "AppleSDGothicNeo-Regular";
          padding-left: 10px;
          padding-right: 10px;
          height: 100%;
        }

        .default-checkbox-label a {
          font-size: 12px;
          color: ${$cp.grey2};
        }
      `}</style>
    </div>
  )
}

export default Checkbox
