// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from "react"

export default function IconCopy(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="24"
      viewBox="0 0 21 24"
    >
      <path
        id="copy-light"
        d="M20.341,3.091,17.909.659A2.25,2.25,0,0,0,16.318,0H8.25A2.25,2.25,0,0,0,6,2.25V4.5H2.25A2.25,2.25,0,0,0,0,6.75v15A2.25,2.25,0,0,0,2.25,24h10.5A2.25,2.25,0,0,0,15,21.75V19.5h3.75A2.25,2.25,0,0,0,21,17.25V4.682a2.25,2.25,0,0,0-.659-1.591ZM16.5,1.523a.744.744,0,0,1,.348.2L19.28,4.152a.745.745,0,0,1,.2.348H16.5Zm-3,20.227a.751.751,0,0,1-.75.75H2.25a.751.751,0,0,1-.75-.75v-15A.751.751,0,0,1,2.25,6H6V17.25A2.25,2.25,0,0,0,8.25,19.5H13.5Zm6-4.5a.751.751,0,0,1-.75.75H8.25a.751.751,0,0,1-.75-.75v-15a.751.751,0,0,1,.75-.75H15V4.875A1.128,1.128,0,0,0,16.125,6H19.5Z"
        fill="#525252"
      />
    </svg>
  )
}
