import React, {FC, memo} from "react"
import cx from "classnames"
import LogoName from "components/SideBarButton/icons/LogoName"

import s from "./landingPage.module.scss"

interface iLandingPage {
  className?: string
}

const LandingPage: FC<iLandingPage> = ({className}): JSX.Element => {
  return (
    <div className={cx(s.container, className)}>
      <div className={s.logoContainer}>
        <img className={s.logoIcon} src={"assets/img/logo.webp"} />
        <LogoName />
      </div>
    </div>
  )
}

export default memo(LandingPage)
