import create from "zustand"
import Bank from "screens/MyProfile/types/Bank/Bank"
import BankStatus from "screens/MyProfile/types/Bank/BankStatus"
import BankHistory from "screens/MyProfile/types/Bank/BankHistory"
import BankResponse from "screens/MyProfile/types/Bank/BankResponse"
import ChangeBankRequestResponse from "screens/MyProfile/types/Bank/ChangeBankRequestResponse"

export interface iChangeBankStore {
  isSaved: boolean
  isSelectActive: boolean
  isLoading: boolean
  resetBankList: boolean
  hasPendingRequest: boolean
  hasPendingWithdraw: boolean
  hasPendingDeposit: boolean
  selectedBank: Bank
  bankList: Bank[]
  bankAccountStatus: BankStatus
  bankHistoryList: BankHistory[]
  bankResponse: BankResponse
  changeRequestResp: ChangeBankRequestResponse
  setIsSelectActive: (value: boolean) => void
  setResetBankList: (value: boolean) => void
  setIsLoading: (value: boolean) => void
  setHasPendingRequest: (value: boolean) => void
  setHasPendingWithdraw: (value: boolean) => void
  setHasPendingDeposit: (value: boolean) => void
  setSelectedBank: (value: Bank) => void
  setBankList: (value: Bank[]) => void
  setBankAccountStatus: (value: BankStatus) => void
  setBankHistoryList: (value: BankHistory[]) => void
  setBankResponse: (value: BankResponse) => void
  setIsSaved: (value: boolean) => void
  setChangeRequestResp: (value: ChangeBankRequestResponse) => void
}

const useChangeBankStore = create<iChangeBankStore>(set => ({
  isSaved: false,
  isSelectActive: false,
  isLoading: false,
  resetBankList: false,
  hasPendingRequest: false,
  hasPendingWithdraw: false,
  hasPendingDeposit: false,
  selectedBank: null,
  bankList: [],
  bankAccountStatus: null,
  bankHistoryList: [],
  bankResponse: {
    status_code: 0,
    message: "",
  },
  changeRequestResp: null,
  setIsSelectActive: isSelectActive => set({isSelectActive}),
  setResetBankList: resetBankList => set({resetBankList}),
  setIsLoading: isLoading => set({isLoading}),
  setHasPendingRequest: hasPendingRequest => set({hasPendingRequest}),
  setHasPendingWithdraw: hasPendingWithdraw => set({hasPendingWithdraw}),
  setHasPendingDeposit: hasPendingDeposit => set({hasPendingDeposit}),
  setSelectedBank: selectedBank => set({selectedBank}),
  setBankList: bankList => set({bankList}),
  setBankAccountStatus: bankAccountStatus => set({bankAccountStatus}),
  setBankHistoryList: bankHistoryList => set({bankHistoryList}),
  setBankResponse: bankResponse => set({bankResponse}),
  setIsSaved: isSaved => set({isSaved}),
  setChangeRequestResp: changeRequestResp => set({changeRequestResp}),
}))

export default useChangeBankStore
