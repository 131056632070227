import React, {FC, memo, useMemo, useEffect, useContext, CSSProperties} from "react"
import shallow from "zustand/shallow"
import cx from "classnames"
import {useWindowSize} from "react-use"
import {useLocation} from "react-router-dom"
import {compact, includes} from "lodash"
import {isDesktop} from "react-device-detect"

import {
  MINI_GAMES,
  MY_PAGE,
  RECHARGE,
  PAGE_MAPPING,
  BET365,
  PUBLIC_PAGES,
  FREE_GAMES,
  HOME,
  SKY_PARK,
  LIVE_CASINO,
  SLOTS,
  BTI_SPORTS,
  PROMOTIONS,
} from "screens/NavHome/utils/config"
import s from "screens/NavHome/components/NavBody/navBody.module.scss"
import navStore from "screens/NavHome/store/navStore"
import useMyProfileStore from "screens/MyProfile/store/useMyProfileStore"
import {useMinigameStore} from "stores/miniGameStore"
import useBeticaStore from "stores/useBeticaStore"
import {useFreeGameStore} from "stores/freeGameStore"
import {useHomeV2Store} from "stores/homeV2store"
import {useSkyparkStore} from "screens/SkyPark/store"
import {useSlotsStore} from "screens/Slots/store/useSlotsStore"
import {useCasinoStore} from "screens/Casino/store/useCasinoStore"
import useBtiStore from "stores/useBtiStore"
import {ThemeContext} from "context/ThemeContext"

interface iCenterLayout {
  className?: string
  children?: React.ReactNode
}

interface CSSCustomProperties extends CSSProperties {
  "--bodyBackground"?: string
}

const CenterLayout: FC<iCenterLayout> = ({className, children}): JSX.Element => {
  const {theme} = useContext(ThemeContext)
  const custom_colors: CSSCustomProperties = {
    "--bodyBackground": theme?.colors.bodyBackground,
  }
  const location = useLocation()
  const {width, height} = useWindowSize()
  const {selectedTitle} = navStore(
    store => ({
      selectedTitle: store.selectedSlideTitle,
    }),
    shallow,
  )

  const [featureRestriction] = useMyProfileStore(
    store => [store.featureRestriction],
    shallow,
  )

  const {activeGame} = useMinigameStore(
    store => ({
      activeGame: store.activeTab,
    }),
    shallow,
  )
  const activeFreeGame = useFreeGameStore(store => store.activeGame, shallow)
  const activeHomeGame = useHomeV2Store(store => store.activeGame, shallow)

  const {isBeticaBetSlipOpen} = useBeticaStore(
    store => ({
      isBeticaBetSlipOpen: store.isBetSlipOpen,
    }),
    shallow,
  )

  const isBtiActive = useBtiStore(store => store.isActive, shallow)
  const isSkyParkActive = useSkyparkStore(store => store.isSkyParkActive, shallow)
  const isCasinoActive = useCasinoStore(store => store.isActive, shallow)
  const isSlotActive = useSlotsStore(store => store.isActive, shallow)

  const hasBetSlip = useMemo(() => {
    return width <= 842
  }, [width])

  const isRestricted = useMemo(() => {
    const currentPage = PAGE_MAPPING[selectedTitle]
    return featureRestriction.includes(currentPage)
  }, [selectedTitle, featureRestriction])

  const isBtiAndDisabled = useMemo(() => {
    const isCurrent = selectedTitle === BTI_SPORTS
    return isCurrent && !isBtiActive
  }, [selectedTitle, isBtiActive])

  const isSkyparkAndDisabled = useMemo(() => {
    const isCurrent = selectedTitle === SKY_PARK
    return isCurrent && !isSkyParkActive
  }, [selectedTitle, isSkyParkActive])

  const isCasinoAndDisabled = useMemo(() => {
    const isCurrent = selectedTitle === LIVE_CASINO
    return isCurrent && !isCasinoActive
  }, [selectedTitle, isCasinoActive])

  const isSlotAndDisabled = useMemo(() => {
    const isCurrent = selectedTitle === SLOTS
    return isCurrent && !isSlotActive
  }, [selectedTitle, isSlotActive])

  const isSetToUnsetHeight = useMemo(() => {
    if (
      isBtiAndDisabled ||
      isSlotAndDisabled ||
      isCasinoAndDisabled ||
      isSkyparkAndDisabled ||
      isRestricted
    )
      return true
    const isNotHome =
      PUBLIC_PAGES.includes(selectedTitle) || location.pathname !== "/"
    const isDesktop2560 = isDesktop && width <= 2560
    const UNSET_HEIGHT_PAGES = compact([
      HOME,
      MY_PAGE,
      RECHARGE,
      isDesktop2560 && MINI_GAMES,
      FREE_GAMES,
      BET365,
    ])
    return isNotHome || includes(UNSET_HEIGHT_PAGES, selectedTitle)
  }, [
    isBtiAndDisabled,
    isSlotAndDisabled,
    isCasinoAndDisabled,
    isSkyparkAndDisabled,
    isRestricted,
    selectedTitle,
    width,
    isDesktop,
    location?.pathname,
  ])

  const computedHeight = useMemo(() => {
    const isTablet = width < 842
    if (selectedTitle === FREE_GAMES) return height

    const isDesktop = width <= 2560
    if (isTablet) return height - 56 - 54 - 12
    else if (isDesktop) return height - 56
    else {
      const footerVisible = selectedTitle === MINI_GAMES || selectedTitle === MY_PAGE
      if (footerVisible) return height - 405
      else return height - 56
    }
  }, [width, height, selectedTitle])

  const minHeight = useMemo(() => {
    if (isSetToUnsetHeight) return "unset"
    return `${computedHeight}px`
  }, [computedHeight, isSetToUnsetHeight])

  const isFreeBet365 = useMemo(() => {
    return activeFreeGame === "soccer" || activeFreeGame === "basketball"
  }, [activeFreeGame])

  useEffect(() => {
    const layout = document.getElementById(
      "center-layout-container",
    ) as HTMLDivElement
    layout.style.minHeight = minHeight
  }, [minHeight])

  // useEffect(() => {
  //   window.scrollTo({top: 0})
  // }, [])

  return (
    <>
      <div className={cx(s.bodyBg)} style={custom_colors}></div>
      <div
        id="center-layout-container"
        className={cx(s.container, className, {
          [s.miniGames]: selectedTitle === MINI_GAMES,
          [s.openGame]:
            activeHomeGame.provider !== "" ||
            activeGame !== 0 ||
            activeFreeGame !== "",
          [s.freeBet365]: isFreeBet365,
          [s.notHome]:
            PUBLIC_PAGES.includes(selectedTitle) || location.pathname !== "/",
          [s.openBetSlip]: hasBetSlip && isBeticaBetSlipOpen,
          [s.promotions]: selectedTitle === PROMOTIONS,
          [s.slotsPage]: selectedTitle === SLOTS || selectedTitle === LIVE_CASINO,
          [s.myPage]: selectedTitle === MY_PAGE,
          [s.homePage]: selectedTitle === HOME,
        })}
        style={custom_colors}
      >
        {children && children}
      </div>
    </>
  )
}

export default memo(CenterLayout)
