// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from "react"

interface iIconCheck {
  color?: string
}

export default function IconCheck({color = "#959595"}: iIconCheck): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        id="Fill_39"
        data-name="Fill 39"
        d="M14,16H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0H14a2,2,0,0,1,2,2V14A2,2,0,0,1,14,16ZM2,1A1,1,0,0,0,1,2V14a1,1,0,0,0,1,1H14a1,1,0,0,0,1-1V2a1,1,0,0,0-1-1ZM7.263,11.556A.5.5,0,0,1,6.9,11.4L4.614,9.29a.5.5,0,0,1,.023-.7.5.5,0,0,1,.707.02l1.789,1.65,3.2-5.54a.5.5,0,0,1,.433-.248.5.5,0,0,1,.249.068.5.5,0,0,1,.183.68c-.035.061-3.529,6.151-3.59,6.2A.5.5,0,0,1,7.263,11.556Z"
        fill={color}
      />
    </svg>
  )
}
