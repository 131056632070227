import create from "zustand"

type SearchStore = {
  isSearchOpen: boolean
  isDropDownOpen: boolean
  searchKey: string
  tag: string
  suggestions: Array<string>
  recentSearches: Array<string>
  setIsSearchOpen: (value: boolean) => void
  setIsDropDownOpen: (value: boolean) => void
  setSuggestions: (value: Array<string>) => void
  setRecentSearches: (value: Array<string>) => void
  setSearchKey: (value: string) => void
  setTag: (value: string) => void
  handleUpdateRecentSearch: (value: string) => void
}

export const useSearchStore = create<SearchStore>((set, get) => ({
  isSearchOpen: false,
  isDropDownOpen: false,
  searchKey: null,
  tag: "전체게임",
  suggestions: [],
  recentSearches: [],
  setIsSearchOpen: isSearchOpen => set({isSearchOpen}),
  setIsDropDownOpen: isDropDownOpen => set({isDropDownOpen}),
  setSuggestions: suggestions => set({suggestions}),
  setRecentSearches: recentSearches => set({recentSearches}),
  setSearchKey: searchKey => set({searchKey}),
  setTag: tag => set({tag}),
  handleUpdateRecentSearch: (value: string) => {
    const searches = get().recentSearches
    const index = searches.indexOf(value)
    if (index < 0) {
      const newSearch = [value, ...searches]
      if (newSearch.length > 5) newSearch.pop()

      localStorage.setItem("casino_recent_searches", JSON.stringify(newSearch))
      set({recentSearches: newSearch})
    } else {
      searches.splice(index, 1)
      const newSearch = [value, ...searches]

      localStorage.setItem("casino_recent_searches", JSON.stringify(newSearch))
      set({recentSearches: newSearch})
    }
  },
}))
