import shallow from "zustand/shallow"
import useRegisterModalStore from "components/RegisterButton/hooks/useRegisterModalStore"
import useLoginModalStore from "components/LoginButton/hooks/useLoginModalStore"

const useLoginModalController = () => {
  const {setIsOpen, close} = useLoginModalStore(
    store => ({
      setIsOpen: store.setIsOpen,
      close: store.close,
    }),
    shallow,
  )

  const {handleCheckOpenRegister} = useRegisterModalStore(
    store => ({
      handleCheckOpenRegister: store.handleCheckOpen,
    }),
    shallow,
  )

  const handleToggleModal = (value: boolean) => {
    if (!value) {
      close()
    } else {
      handleCheckOpenRegister(() => setIsOpen(true))
    }
  }

  return {
    handleToggleModal,
  }
}

export default useLoginModalController
