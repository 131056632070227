/* eslint-disable */
import forge from "node-forge"
import ConverterType from "./config.json"

/**
 *
 * Decrypt url query/parameter to its original value
 * @param value
 * @returns {*}
 */
export const decryptQuery = value => {
  return value
}

/**
 *
 * Encrypt url query/parameter to SHA256 format
 * @param value
 * @returns {*}
 */
export function encryptQuery(value, prefix = "") {
  if (process.env.REACT_APP_NODE_ENV === "production") {
    const name = ConverterType.encryptName
    if (value !== "") {
      return prefix + eval(name)(value)
    }
  }
  return prefix + value
}

/**
 *
 * Check url if the query/parameter is encrypted
 * @param value
 * @returns {*}
 */
export const urlChecker = value => {
  return value
}

/**
 *
 * @param url
 * @returns {string}
 */
const encodeUrlQuery = url => {
  return encodeURIComponent(url)
}

/**
 * [aes], encrypt url to AES
 * @param {string} strUrl url in original form
 */
const encryptAES = strUrl => {
  const key = process.env.REACT_APP_API_URL_KEY
  const iv = process.env.REACT_APP_API_URL_IV

  const cipher = forge.rc2.createEncryptionCipher(key)
  cipher.start(iv)
  cipher.update(forge.util.createBuffer(strUrl))
  cipher.finish()
  const encrypted = cipher.output

  return encodeUrlQuery(encrypted.toHex())
}

/**
 *
 * @param value
 * @returns {string}
 */
const encryptRSA = value => {
  // encrypt data with a public key (defaults to RSAES PKCS#1 v1.5)
  // let rsaKey = generateRSAkey(value)
  const test = process.env.REACT_APP_PUBLIC_RSA_KEY
  const publicKey = forge.pki.publicKeyFromPem(test)

  // let encrypted = publicKey.encrypt(value);
  return encryptAES(publicKey) + encryptAES(value)
}

/**
 *
 * Get original value of encrypted RSA
 * @param encrypted
 * @returns {*}
 */
const decryptRSA = encrypted => {
  const privateKey = forge.pki.privateKeyFromPem(
    process.env.REACT_APP_PRIVATE_RSA_KEY,
  )

  // decrypt data with a private key (defaults to RSAES PKCS#1 v1.5)
  const decrypted = privateKey.decrypt(encrypted, "RSA-OAEP", {
    md: forge.md.sha256.create(),
  })

  return decrypted
}

/**
 *
 * @param value
 * @returns {{publicKeyPem: *, privateKeyPem: *}}
 */
const generateRSAkey = value => {
  const pair = forge.pki.rsa.generateKeyPair(1024, 0x10001)

  // raw private key DER bytes
  const pk = forge.asn1.toDer(forge.pki.privateKeyToAsn1(pair.privateKey)).getBytes()

  // converting pk back from DER bytes
  const privateKey = forge.pki.privateKeyFromAsn1(forge.asn1.fromDer(pk))

  // getting public key from private key
  const publicKey = forge.pki.rsa.setPublicKey(privateKey.n, privateKey.e)

  const privateKeyPem = forge.pki.privateKeyToPem(pair.privateKey)

  const publicKeyPem = forge.pki.publicKeyToPem(publicKey)

  return {privateKeyPem: privateKeyPem, publicKeyPem: publicKeyPem}
}

/**
 * [sha256], encrypt url to SHA256
 * @param {string} strUrl url in original form
 */
const encryptSha256 = strUrl => {
  const md = forge.md.sha256.create()
  md.update(strUrl + process.env.REACT_APP_API_URL_KEY)
  strUrl = md.digest().toHex()

  return encodeUrlQuery(strUrl)
}

/**
 *
 * @param url
 * @returns {string}
 */
const decodeUrlQuery = url => {
  return decodeURIComponent(url)
}
