import request from "services/request"
import API from "services/Account/Login/endpoints"
import {useUserStore} from "stores/userStore"

const headers = () => {
  return {
    "Accept": "application/json",
    "Content-Type": "application/json",
  }
}

const authHeaders = () => {
  const {authToken} = useUserStore.getState()

  return {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${authToken}`,
  }
}

const checkSession = (): Promise<unknown> => {
  return request({
    url: API.CHECK_SESSION,
    method: "POST",

    headers: authHeaders(),
  })
}

const login = (
  username: string,
  password: string,
  captcha?: string,
): Promise<unknown> => {
  const timeoutEnv = parseInt(process.env.REACT_APP_LOGIN_TIMEOUT_DURATION)
  const duration = timeoutEnv ? timeoutEnv : 5000

  return request({
    url: API.LOGIN,
    method: "POST",
    data: {
      username: username,
      password: password,
      captcha: captcha,
    },
    headers: headers(),
    timeout: duration,
  })
}

const logout = (): Promise<any> => {
  return request({
    url: API.LOGOUT,
    method: "POST",
    headers: authHeaders(),
  })
}

export default {
  checkSession,
  login,
  logout,
}
