import React, {useEffect} from "react"

import s from "./ongoingbets.module.scss"

import cx from "classnames"
import {useQuery} from "react-query"
import shallow from "zustand/shallow"
import service from "services/Bet365"
import {Charge} from "types/Bet365"
import OnGoingBetSlip from "../OnGoingBet"
import {useUserStore} from "stores/userStore"

export const ONGOING_BETS_QUERY = ["ongoing-bet"]

const OnGoingBets = ({close}: {close: () => void}): JSX.Element => {
  const authToken = useUserStore(store => store.authToken, shallow)
  const {data: bets} = useQuery(
    ONGOING_BETS_QUERY,
    () => {
      return service.getOpenBets()
    },
    {
      enabled: !!authToken,
      keepPreviousData: true,
      staleTime: 360000,
      onError: () => {
        console.log("error")
      },
    },
  )

  if (!bets || !bets.length) {
    return (
      <div className={s.noSlips}>
        <span>보류 중인 베팅은 없습니다.</span>
        <button onClick={close} className={s.continue}>
          확인
        </button>
      </div>
    )
  }

  return (
    <div className={s.container}>
      {bets.map((charge: Charge) => {
        return <OnGoingBetSlip key={charge.transaction_id} charge={charge} />
      })}
    </div>
  )
}

export default OnGoingBets
