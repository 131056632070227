import {useEffect} from "react"
import shallow from "zustand/shallow"
import {useQueryClient} from "react-query"
import {useUserStore} from "stores/userStore"
import {useInquiryStore} from "screens/Home/MyPage/Screens/CustomerCenter/store"
import {useSkyparkStore} from "screens/SkyPark/store"
import {useBet365Store} from "screens/Bet365/store"
import usePusherStore from "stores/usePusherStore"
import useInboxStore from "screens/MyProfile/store/useInboxStore"
import EVENT from "provider/pusher/pusherEvents"
import {
  INBOX_QUERY,
  SMS_SETTINGS_QUERY,
  SELF_RESRTRICT_QUERY,
} from "screens/MyProfile/constants"
import {GET_SPORTS_URL_QUERY} from "screens/BTI/config"
import {ONGOING_BETS_QUERY} from "screens/Bet365/components/BetSlips/OngoingBets"

const useAdminEvents = () => {
  const client = useQueryClient()
  const privateNotifChannel = usePusherStore(
    store => store.privateNotifChannel,
    shallow,
  )

  const refreshDetails = () => {
    client.invalidateQueries([SMS_SETTINGS_QUERY])
    client.invalidateQueries([SELF_RESRTRICT_QUERY])

    const user = useUserStore.getState()
    user.getUserDetails()
  }

  const refreshInbox = data => {
    client.invalidateQueries([INBOX_QUERY])

    const inbox = useInboxStore.getState()
    inbox.setUnreadCount(data?.data?.inbox_message_count)
  }

  const refreshCustomerSupport = () => {
    client.invalidateQueries(["inquiries"])

    const inquiry = useInquiryStore.getState()
    inquiry.setHasNotification(true)
  }

  const refreshBTI = () => {
    client.invalidateQueries([GET_SPORTS_URL_QUERY])
  }

  const refreshSkypark = () => {
    const skypark = useSkyparkStore.getState()
    client.invalidateQueries(["skyPark/games"])
    client.invalidateQueries(["skyPark/status"])
    skypark.setRefresh(true)
  }

  const refreshBet365 = () => {
    const bet365 = useBet365Store.getState()
    client.invalidateQueries(["bet365/status"])
    client.invalidateQueries([ONGOING_BETS_QUERY])
    client.invalidateQueries(["bet365/presets"])
    client.invalidateQueries(["charge-history"])
    bet365.setIsRefresh(true)
  }

  const refreshBetLimits = () => {
    refreshBTI()
    refreshSkypark()
    refreshBet365()
  }

  useEffect(() => {
    if (!privateNotifChannel) return

    // console.log("bind adminEvents")

    privateNotifChannel.bind(EVENT.ADMIN_PLAYER_UPDATE, refreshDetails)
    privateNotifChannel.bind(EVENT.ADMIN_SEND_MESSAGE, refreshInbox)
    privateNotifChannel.bind(EVENT.ADMIN_ANSWER_INQUIRY, refreshCustomerSupport)
    privateNotifChannel.bind(EVENT.ADMIN_BET_LIMIT_UPDATE, refreshBetLimits)

    return () => {
      const pusherStore = usePusherStore.getState()
      const channel = pusherStore.privateNotifChannel
      if (channel) {
        // console.log("unbind adminEvents")
        channel.unbind(EVENT.ADMIN_PLAYER_UPDATE)
        channel.unbind(EVENT.ADMIN_SEND_MESSAGE)
        channel.unbind(EVENT.ADMIN_ANSWER_INQUIRY)
        channel.unbind(EVENT.ADMIN_BET_LIMIT_UPDATE)
      }
    }
  }, [privateNotifChannel])
}

export default useAdminEvents
