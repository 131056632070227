import PlayerBet from "./PlayerBet"
export type OddKeys =
  | "powerball_odd"
  | "powerball_even"
  | "powerball_under"
  | "powerball_over"
  | "normal_ball_odd"
  | "normal_ball_even"
  | "normal_ball_under"
  | "normal_ball_over"
  | "by_section_large_81_130"
  | "by_section_medium_65_80"
  | "by_section_small_15_64"

export const DefaultMarket = {
  "powerball_odd": null,
  "powerball_even": null,
  "powerball_under": null,
  "powerball_over": null,
  "normal_ball_odd": null,
  "normal_ball_even": null,
  "normal_ball_under": null,
  "normal_ball_over": null,
  "by_section_large_81_130": null,
  "by_section_medium_65_80": null,
  "by_section_small_15_64": null,
}

type MarketConfig = {
  [key in OddKeys]: PlayerBet
}

export default MarketConfig
