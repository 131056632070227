import create from "zustand"
import Flicking from "@egjs/react-flicking/declaration/Flicking"
import MiniGame from "screens/Home/MiniGame/types/MiniGame"

type Store = {
  activeTab: number
  enableEvoPowerball: boolean
  openState: {
    ntryPowerball: boolean
    powerball: boolean
    powerLadder: boolean
  }
  details: MiniGame[]
  openTab: number
  isUpdating: boolean
  selected: string
  sliderRef: Flicking
  setIsUpdating: (value: boolean) => void
  setEnableEvoPowerball: (value: boolean) => void
  setSelected: (value: string) => void
  setOpenTab: (value: number) => void
  setActiveTab?: (arg: number) => void
  setNtryPowerballOpenState?: (arg: boolean) => void
  setPowerballOpenState?: (arg: boolean) => void
  clearOpenState: () => void
  setDetails: (value: MiniGame[]) => void
  setSliderRef: (value: Flicking) => void
}

const initialState = {
  activeTab: 0,
  enableEvoPowerball: false,
  openState: {
    ntryPowerball: false,
    powerball: false,
    powerLadder: false,
  },
  openTab: 0,
  isUpdating: false,
  selected: "EOS",
  details: [],
  sliderRef: null,
}

export const useMinigameStore = create<Store>((set, get) => ({
  ...initialState,
  setEnableEvoPowerball: enableEvoPowerball => set({enableEvoPowerball}),
  setActiveTab: (newTab: number) => set({activeTab: newTab}),
  setNtryPowerballOpenState: (val: boolean) =>
    set({
      openState: {
        ...get().openState,
        ntryPowerball: val,
      },
    }),
  setPowerballOpenState: (val: boolean) => {
    set({
      openState: {
        ...get().openState,
        powerball: val,
      },
    })
  },
  clearOpenState: () => set({openState: initialState.openState}),
  setDetails: (value: MiniGame[]) => set({details: value}),
  setOpenTab: openTab => set({openTab}),
  setSelected: selected => set({selected}),
  setIsUpdating: isUpdating => set({isUpdating}),
  setSliderRef: sliderRef => set({sliderRef}),
}))
