// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from "react"

export default function IconWithdraw(): JSX.Element {
  return (
    <svg width="28.126" height="25" viewBox="0 0 28.126 25">
      <defs>
        <linearGradient
          id="linear-gradient"
          x2="1.44"
          y2="2.605"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#00acee" />
          <stop offset="1" stopColor="#6c71b4" />
        </linearGradient>
      </defs>
      <path
        id="inbox-out-solid"
        d="M28.126,18.019v4.649a2.344,2.344,0,0,1-2.344,2.344H2.344A2.344,2.344,0,0,1,0,22.667V18.019a2.346,2.346,0,0,1,.513-1.465l4.609-5.762a.586.586,0,0,1,.884-.034L7.354,12.2a.586.586,0,0,1,.029.767L5.254,15.636H9.766l1.563,3.125H16.8l1.563-3.125h4.512l-2.163-2.705a.583.583,0,0,1,.039-.776l1.372-1.411a.584.584,0,0,1,.879.044l4.619,5.772A2.357,2.357,0,0,1,28.126,18.019ZM16.729,14.8V7.823h3.023a.977.977,0,0,0,.708-1.65L14.908.313A.974.974,0,0,0,13.511.3l-5.83,5.86a.975.975,0,0,0,.688,1.665H11.7V14.8a.835.835,0,0,0,.835.835h3.35A.839.839,0,0,0,16.729,14.8Z"
        transform="translate(0 -0.011)"
        fill="#00ACEE"
      />
    </svg>
  )
}
