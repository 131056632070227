import create from "zustand"

interface iFooterStore {
  selected: string
  setSelected: (value: string) => void
  resetStore: () => void
}

const useFooterStore = create<iFooterStore>(set => ({
  selected: null,
  setSelected: selected => set({selected}),
  resetStore: () => {
    set({
      selected: null,
    })
  },
}))

export default useFooterStore
