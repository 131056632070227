import React, {
  CSSProperties,
  FC,
  memo,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import cx from "classnames"
import shallow from "zustand/shallow"
import navStore from "screens/NavHome/store/navStore"
import s from "screens/NavHome/components/NavItem/navItem.module.scss"
import {ThemeContext} from "context/ThemeContext"

interface iNavItem {
  className?: string
  label: string
  hasLabel?: boolean
  isActive?: boolean
  isNew?: boolean
  isFree?: boolean
  handleClick?: (label: string) => void
}

interface CSSCustomProperties extends CSSProperties {
  "--headerHoverColor"?: string
  "--buttonActivebottomLine"?: string
}

const NavItem: FC<iNavItem> = ({
  className,
  hasLabel = true,
  isActive = false,
  isNew = false,
  isFree = false,
  label,
  handleClick,
}): JSX.Element => {
  const {theme} = useContext(ThemeContext)

  const custom_colors: CSSCustomProperties = {
    "--headerHoverColor": theme?.colors.headerHoverColor,
    "--buttonActivebottomLine": theme?.colors.buttonActivebottomLine,
  }

  const [isMounted, setIsMounted] = useState(false)
  const btnRef = useRef<HTMLButtonElement>()
  const {sliderRef} = navStore(
    store => ({
      sliderRef: store.sliderRef,
    }),
    shallow,
  )
  useEffect(() => {
    if (btnRef?.current && sliderRef) {
      setIsMounted(true)
    }
  }, [btnRef?.current, sliderRef])

  useEffect(() => {
    if (isActive && isMounted) {
      handleClick(label)
    }
  }, [isMounted])

  return (
    <div
      className={cx(s.container, className, {["active-slider-item"]: isActive})}
      style={custom_colors}
    >
      {isNew && <div className={s.isNew}>NEW</div>}
      {isFree && (
        <div className={s.isFree}>
          <span>FREE</span>
        </div>
      )}
      <button
        ref={btnRef}
        className={s.navButton}
        onClick={() => handleClick(label)}
      >
        {hasLabel && label}
      </button>
    </div>
  )
}

export default memo(NavItem)
