import React, {FC, memo} from "react"
import cx from "classnames"
import {Charge} from "types/Bet365/index"

import {GameIcon} from "screens/Bet365/components/GamesTab"

import s from "screens/Bet365/components/BetSlipV2/betSlipV2.module.scss"

interface iHeaderRow {
  charge: Charge
  className?: string
}

const HeaderRow: FC<iHeaderRow> = ({charge, className}) => {
  const gameCode = charge.game.code
  const details = charge.details[0]
  const league = details?.league
  const leagueName = league?.hangul_name ?? league?.name

  const timer = charge.timer
  const roundHr = timer.slice(0, 2)
  const roundMin = timer.slice(2, 4)
  const roundTimer = `${roundHr}:${roundMin}`
  return (
    <div className={cx(s.headerRow, className)}>
      <div
        className={cx(s.header, {
          [s.soccer]: gameCode === "soccer",
          [s.basketball]: gameCode === "basketball",
          [s.greyhounds]: gameCode === "greyhounds",
          [s.horseracing]: gameCode === "horseracing",
          [s.baseball]: gameCode === "baseball",
        })}
      >
        <GameIcon gameCode={gameCode} isActive fill1={`#00acee`} fill2={`#8d8d8d`} />
        <span className={s.leagueName}>{leagueName}</span>
      </div>

      <span className={s.timer}>{`회차 : ${roundTimer}`}</span>
    </div>
  )
}

export default memo(HeaderRow)
