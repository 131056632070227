const API = {
  DETAILS: `user/player/get-details`,
  REFRESH_TOKEN: `refresh`,
  ACTIVITY_LOG: `user/player/activity-log`,
  LOGIN_RESTRICTION: `player/restriction/login`,
  LOGIN_RESTRICTION_REACTIVATE: `player/restriction/login/reactivate`,
  LOGIN_RESTRICTION_DEACTIVATE: `player/restriction/login/deactivate`,
  CALIBRATE_PTS: `user/player/level/calibrate`,
  CLAIMED_PROMO: `user/player/promotions`,
  CAN_REGISTER: `can-register`,
  CAN_PLAY_FREE_GAMES: `/freegame/can/play`,
  GET_REFERRAL: `/user/player/get-code`,
  IN_GAME_STATUS: `/user/player/check-in-progress-bet`,
}

export default API
