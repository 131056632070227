import {alertMessage} from "./Config"

export function showAlertFindID(resCode: number): string {
  switch (resCode) {
    case 1:
      return alertMessage.findID.ok
    case -8:
      return alertMessage.findID.blocked
    case -10:
      return alertMessage.findID.failed
    default:
      return
  }
}

export function showAlertRegister(resCode: number): string {
  switch (resCode) {
    case 1:
      return alertMessage.registerPlayer.ok
    case -8:
      return alertMessage.registerPlayer.blocked
    case -9:
      return alertMessage.registerPlayer.bannedUser
    case -10:
      return alertMessage.registerPlayer.failed
    default:
      return
  }
}

export function showAlertFindPassword(resCode: number): string {
  switch (resCode) {
    case 1:
      return alertMessage.forgotPassword.ok
    case -8:
      return alertMessage.forgotPassword.blocked
    case -10:
      return alertMessage.forgotPassword.failed
    default:
      return
  }
}

export function showAlertChangeMobileNumber(resCode: number): string {
  switch (resCode) {
    case 1:
      return alertMessage.playerChangeMobileNumber.ok
    default:
      return
  }
}

export const ConfigAlertMobileExist = {
  "FIND_ID": showAlertFindID,
  "FORGOT_PASSWORD": showAlertFindPassword,
  "REGISTER_PLAYER": showAlertRegister,
  "PLAYER_CHANGE_MOBILE_NUMBER": showAlertChangeMobileNumber,
}
