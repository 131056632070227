// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, {CSSProperties, Fragment, useContext} from "react"
import Flash from "components/Flash"
/**
 * Context
 */
/**
 * styles
 */
import "./container.css"
import {ThemeContext} from "context/ThemeContext"
/**
 * Atom
 */
interface CSSCustomProperties extends CSSProperties {
  "--loginInputColor"?: string
}

const Container = ({children, path}: any): JSX.Element => {
  const {theme} = useContext(ThemeContext)

  const custom_colors: CSSCustomProperties = {
    "--loginInputColor": theme?.colors.loginInputColor,
  }
  return (
    <Fragment>
      <div id="wrapper" style={custom_colors}>
        <div id="content">{children}</div>
        <div className="flash">
          <Flash />
        </div>
      </div>
    </Fragment>
  )
}

export default Container
