import React from "react"

export default function UserCircleIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
    >
      <path
        id="user-circle-solid"
        d="M13,8A13,13,0,1,0,26,21,13,13,0,0,0,13,8Zm0,5.032a4.613,4.613,0,1,1-4.613,4.613A4.613,4.613,0,0,1,13,13.032Zm0,18.032A10.045,10.045,0,0,1,5.321,27.49a5.844,5.844,0,0,1,5.163-3.135,1.283,1.283,0,0,1,.372.058A6.94,6.94,0,0,0,13,24.774a6.914,6.914,0,0,0,2.144-.362,1.283,1.283,0,0,1,.372-.058,5.844,5.844,0,0,1,5.163,3.135A10.045,10.045,0,0,1,13,31.065Z"
        transform="translate(0 -8)"
        fill="#fff"
      />
    </svg>
  )
}
