// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from "react"

interface iIconCheck {
  color?: string
  opacity?: string
}

export default function IconReset({
  color = "#959595",
  opacity = "0.4",
}: iIconCheck): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <path
        id="times-circle-light"
        d="M18,8A10,10,0,1,0,28,18,10,10,0,0,0,18,8Zm0,18.71A8.71,8.71,0,1,1,26.71,18,8.709,8.709,0,0,1,18,26.71Zm3.823-11.5L19.028,18l2.794,2.794a.484.484,0,0,1,0,.685l-.343.343a.484.484,0,0,1-.685,0L18,19.028l-2.794,2.794a.484.484,0,0,1-.685,0l-.343-.343a.484.484,0,0,1,0-.685L16.972,18l-2.794-2.794a.484.484,0,0,1,0-.685l.343-.343a.484.484,0,0,1,.685,0L18,16.972l2.794-2.794a.484.484,0,0,1,.685,0l.343.343A.492.492,0,0,1,21.823,15.206Z"
        transform="translate(-8 -8)"
        fill={color}
        opacity={opacity}
      />
    </svg>
  )
}
