import React, {FC, memo, Suspense, useEffect} from "react"
import shallow from "zustand/shallow"
import ReactGA from "react-ga4"
import cx from "classnames"
import {LOGIN, FIND_ID, FORGOT_PASSWORD} from "components/LoginButton/contants"
import useLoginModalStore from "components/LoginButton/hooks/useLoginModalStore"
import s from "components/LoginButton/Content/content.module.scss"
import {lazyWithRetry} from "utils/lazyWithRetry"

const Login = lazyWithRetry(() => import(`components/LoginButton/Layout/Login`))

const FindId = lazyWithRetry(() => import(`components/LoginButton/Layout/FindId`))

const FindPassword = lazyWithRetry(
  () => import(`components/LoginButton/Layout/FindPassword`),
)

interface iContent {
  className?: string
  handleOpenLogin: () => void
  handleOpenRegister: () => void
  handleOpenFindId: () => void
  handleOpenForgotPassword: () => void
}

const Body = ({
  title,
  handleOpenLogin,
  handleOpenRegister,
  handleOpenFindId,
  handleOpenForgotPassword,
}) => {
  switch (title) {
    case LOGIN:
      return (
        <Suspense fallback={<></>}>
          <Login
            handleOpenRegister={handleOpenRegister}
            handleOpenFindId={handleOpenFindId}
            handleOpenForgotPassword={handleOpenForgotPassword}
          />
        </Suspense>
      )
    case FIND_ID:
      return (
        <Suspense fallback={<></>}>
          <FindId
            handleOpenRegister={handleOpenRegister}
            handleOpenLogin={handleOpenLogin}
            handleOpenForgotPassword={handleOpenForgotPassword}
          />
        </Suspense>
      )
    case FORGOT_PASSWORD:
      return (
        <Suspense fallback={<></>}>
          <FindPassword
            handleOpenRegister={handleOpenRegister}
            handleOpenLogin={handleOpenLogin}
            handleOpenFindId={handleOpenFindId}
          />
        </Suspense>
      )
    default:
      return <></>
  }
}

const Content: FC<iContent> = props => {
  const {title, findIdSuccess, findPasswordSuccess} = useLoginModalStore(
    store => ({
      title: store.selectedTab,
      findIdSuccess: store.findIdSuccess,
      findPasswordSuccess: store.findPasswordSuccess,
    }),
    shallow,
  )
  useEffect(() => {
    try {
      console.log("PageView | User Navigate to Page - label - ", title)
      ReactGA.event({
        category: "PageView",
        action: "User Navigate to Page",
        label: title,
      })
    } catch (err) {
      console.error(err)
    }
  }, [title])
  return (
    <div
      className={cx(s.body, props.className, {
        [s.login]: LOGIN === title,
        [s.findId]: FIND_ID === title,
        [s.findPassword]: FORGOT_PASSWORD === title,
        [s.findIdSuccess]: findIdSuccess,
        [s.findPasswordSuccess]: findPasswordSuccess,
      })}
    >
      <Body title={title} {...props} />
    </div>
  )
}

export default memo(Content)
