// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from "react"

function IconLock(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.5"
      height="20"
      viewBox="0 0 17.5 20"
      color="#D5D5D5"
    >
      <path
        id="lock-alt-light"
        d="M8.75,16.405a.784.784,0,0,1-.781-.781v-2.5a.781.781,0,0,1,1.562,0v2.5A.784.784,0,0,1,8.75,16.405Zm8.75-5.781v7.5A1.875,1.875,0,0,1,15.625,20H1.875A1.875,1.875,0,0,1,0,18.124v-7.5A1.875,1.875,0,0,1,1.875,8.749H2.5v-2.5A6.25,6.25,0,1,1,15,6.308V8.749h.625A1.875,1.875,0,0,1,17.5,10.624ZM3.75,8.749h10v-2.5a5,5,0,0,0-10,0Zm12.5,9.375v-7.5A.627.627,0,0,0,15.625,10H1.875a.627.627,0,0,0-.625.625v7.5a.627.627,0,0,0,.625.625h13.75A.627.627,0,0,0,16.25,18.124Z"
        transform="translate(0 0.001)"
        fill="#d5d5d5"
      ></path>
    </svg>
  )
}

export default IconLock
