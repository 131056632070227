type Level = {
  color: string
  ribbon: string
  name: string
  stamp: string
}

const TAB_PAGE_MY_INFORMATION = "my_information"
const TAB_PAGE_BETTING_HISTORY = "betting_history"
const TAB_PAGE_MONEY_HISTORY = "money_history"
const TAB_PAGE_REWARDS = "rewards"
const TAB_PAGE_ACCOUNT_CHANGE = "account_change"
const TAB_PAGE_CRYPTO = "crypto"
const TAB_PAGE_PASSWORD = "password"
const TAB_PAGE_CELLPHONE = "cellphone"
const TAB_PAGE_SMS = "sms"
const TAB_PAGE_PROHIBIT = "prohibit"
const TAB_PAGE_CUSTOMER_SUPPORT = "customer_support"
const TAB_PAGE_INBOX = "inbox"
const TAB_PAGE_AFFILIATE = "affiliate"
const TAB_PAGE_NONE = "none"

export const TabPage = {
  myInformation: TAB_PAGE_MY_INFORMATION,
  bettingHistory: TAB_PAGE_BETTING_HISTORY,
  moneyHistory: TAB_PAGE_MONEY_HISTORY,
  rewards: TAB_PAGE_REWARDS,
  accountChange: TAB_PAGE_ACCOUNT_CHANGE,
  crypto: TAB_PAGE_CRYPTO,
  password: TAB_PAGE_PASSWORD,
  cellphone: TAB_PAGE_CELLPHONE,
  sms: TAB_PAGE_SMS,
  prohibit: TAB_PAGE_PROHIBIT,
  customerSupport: TAB_PAGE_CUSTOMER_SUPPORT,
  inbox: TAB_PAGE_INBOX,
  affiliate: TAB_PAGE_AFFILIATE,
  none: TAB_PAGE_NONE,
}

export const Levels: Record<string, Level> = {
  "bronze": {
    color: "#797979",
    ribbon: "/assets/img/mypage/Bronze-Ribbon.svg",
    name: "bronze",
    stamp: "/assets/img/mypage/Bronze-Stamp.png",
  },
  "silver": {
    color: "#797979",
    ribbon: "/assets/img/mypage/Silver-Ribbon.svg",
    name: "silver",
    stamp: "/assets/img/mypage/Silver-Stamp.png",
  },
  "gold": {
    color: "#FAC400",
    ribbon: "/assets/img/mypage/Gold-Ribbon.svg",
    name: "gold",
    stamp: "/assets/img/mypage/Gold-Stamp.png",
  },
  "diamond": {
    color: "#188C7A",
    ribbon: "/assets/img/mypage/Diamond-Ribbon.svg",
    name: "diamond",
    stamp: "/assets/img/mypage/Diamond-Stamp.png",
  },
  "vvip": {
    color: "#CCA4CA",
    ribbon: "/assets/img/mypage/VVIP-Ribbon.svg",
    name: "vvip",
    stamp: "/assets/img/mypage/VVIP-Stamp.png",
  },
}

export default Levels
