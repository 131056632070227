import React, {ReactNode} from "react"
import s from "./gameCategories.module.scss"
import cx from "classnames"

type GameCategoriesTabType = {
  children?: ReactNode
  className?: string
}

const GameCategoriesTab = ({
  children,
  className,
}: GameCategoriesTabType): JSX.Element => {
  return (
    <div
      className={cx(s.container, {
        [className]: Boolean(className),
      })}
    >
      {children}
    </div>
  )
}

export default GameCategoriesTab
