import React, {useMemo} from "react"
import {Charge} from "types/Bet365"
import s from "./ongoing.module.scss"
import cx from "classnames"

import {format} from "date-fns"
import MarketDetails from "./MarketDetails"
import HomeAway from "./HomeAway"
import HeaderRow from "./HeaderRow"
import Summary from "./Summary"

type OnGoingBetSlipProps = {
  charge?: Charge
}

const OnGoingBetSlip = ({charge}: OnGoingBetSlipProps): JSX.Element => {
  //******  MEMO FOR BET DETAIL ********
  const {betAmount, bet_date, bet_time} = useMemo(() => {
    const {amount, charged_at} = charge

    // BET DATE AND TIME
    const charge_at_date = new Date(charged_at.replace(/-/g, "/"))
    const bet_date = format(charge_at_date, "MM-dd")
    const bet_time = format(charge_at_date, "HH:mm")

    return {
      betAmount: `${Math.abs(amount).toLocaleString()} KRW`,
      bet_date,
      bet_time,
    }
  }, [charge])

  if (!charge?.details?.length) return <></>

  return (
    <div className={s.container}>
      <div className={s.header}>
        {/* SINGLE */}
        <span>{charge.game.hangul_name}</span>

        {/* BETTING DATE */}
        <div className={s.dateTime}>
          <span>베팅시간</span>
          <span>{bet_date}</span>
          <span>{bet_time}</span>
        </div>
      </div>
      <div className={s.body}>
        <div className={s.circle} />
        <div className={cx(s.line, {[s.forecast]: charge.details.length > 1})} />

        <HeaderRow charge={charge} />
        <HomeAway charge={charge} />
        <MarketDetails charge={charge} />

        {/* BET AMOUNT */}
        <div className={s.bet}>
          <span className={s.label}>베팅금액 :</span>
          <div className={s.amount}>{betAmount}</div>
        </div>
        <Summary charge={charge} />
      </div>
    </div>
  )
}

export default OnGoingBetSlip
