import React, {FC, memo, useEffect, useRef, useCallback} from "react"
import cx from "classnames"
import shallow from "zustand/shallow"
import {useWindowSize} from "react-use"
import useBannerStore from "components/RegisterButton/hooks/useBannerStore"
import {REGISTER_BANNERS} from "components/RegisterButton/constants"
import s from "./banner.module.scss"
import {lazyWithRetry} from "utils/lazyWithRetry"
const PieChart = lazyWithRetry(() => import("components/PieChart"))
const Slider = lazyWithRetry(() => import("./Slider"))

const Banner: FC = (): JSX.Element => {
  const tickRef = useRef<NodeJS.Timeout>()
  const {width} = useWindowSize()
  const [timer, countDown, index, isOnHold, timerTick, resetStore] = useBannerStore(
    store => [
      store.timer,
      store.countDown,
      store.index,
      store.isOnHold,
      store.timerTick,
      store.resetStore,
    ],
    shallow,
  )

  useEffect(() => {
    return () => {
      clearInterval(tickRef.current)
      resetStore()
    }
  }, [])

  useEffect(() => {
    if (isOnHold) clearInterval(tickRef.current)
    else {
      tickRef.current = setInterval(() => {
        timerTick()
      }, 1000)
    }
  }, [isOnHold])

  const handleClick = useCallback((index: number) => {
    const slider = useBannerStore.getState()?.sliderRef
    slider.moveTo(index)
  }, [])

  return (
    <div className={s.container}>
      <Slider items={REGISTER_BANNERS} />
      {width > 842 && (
        <PieChart
          className={s.countDown}
          strokeWidth={2}
          value={timer}
          totalValue={countDown}
          size={44}
          containerColor={"#ffffff80"}
          progressColor={"#fff"}
        />
      )}
      <div className={s.stepperContainer}>
        {Array.from({length: REGISTER_BANNERS.length}).map((_, i) => (
          <div
            key={`banner-popup-${i}`}
            onClick={() => handleClick(i)}
            className={cx(s.stepper, {[s.active]: index === i})}
          >
            <div className={s.circle} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default memo(Banner)
