// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, {useContext, useState} from "react"
import {colorPalette as cp} from "utils/theme/colors"
import {isMobileSafari, isIOS} from "react-device-detect"
import {REGEX_ALPHA_NUM_SPECIAL_CHAR} from "screens/MyProfile/config"
/**
 * Component
 */
import {IconCheck, IconPassword, IconPasswordShow} from "components/Icons/Forms"
/**
 * Utils
 */
import {tabElement} from "utils/helpers"
/**
 * Styles
 */
import s from "./register.module.scss"
import {ThemeContext} from "context/ThemeContext"

interface IFormPasswordInput {
  name: string
  label: string
  stateValue: string
  defaultValue?: string
  labelHelper?: string
  placeholder?: string
  disabled?: boolean
  register: any
  errors?: any
  onChange?: any
  onKeyup?: any
  onFocus?: () => void
  onBlur?: () => void
  resetValue?: () => void
}

const FormPasswordInput = ({
  label,
  labelHelper,
  name,
  placeholder,
  errors,
  stateValue,
  register,
  onChange,
  onKeyup,
  onFocus,
  onBlur,
  resetValue,
  disabled = false,
}: IFormPasswordInput): JSX.Element => {
  const {theme} = useContext(ThemeContext)
  const regexHangulText = /(?=.*[가-힣])/g
  const regexEnglishLower = /(?=.*[a-z])/g
  const regexEnglishUpper = /(?=.*[A-Z])/g
  const regexNumber = /(?=.*[0-9])/g
  const regexpSpecialCharacters = /(?=.*[$&+,:;=?@#|'"{}_[\]\\/`~<>.^*()%!-])/g

  const charsValid =
    !regexHangulText.test(stateValue) &&
    regexEnglishLower.test(stateValue) &&
    regexEnglishUpper.test(stateValue) &&
    regexNumber.test(stateValue) &&
    regexpSpecialCharacters.test(stateValue)

  const formInput = `forminput-${name}`
  const hasError = errors !== undefined
  const isValid = !hasError && stateValue !== ""
  const [isFocused, setFocus] = useState(false)
  const [isShow, setShow] = useState(false)
  const [textPosition, setTextPosition] = useState(0)
  const [isToggledClicked, setIsToggledClicked] = useState(null)

  const isActive = hasError || isFocused

  const isDefaultErrors =
    errors?.type === "required" ||
    errors?.type === "minLength" ||
    errors?.type === "maxLength"

  function checkMinMax() {
    if (!hasError && stateValue === "") {
      return {
        status: "default",
        color: cp.lightgrey2,
      }
    }

    if (isDefaultErrors) {
      return {
        status: "error",
        color: theme.colors.inputFieldErrorColor,
      }
    }

    if (!isDefaultErrors && stateValue !== "") {
      return {
        status: "valid",
        color: cp.aquablue,
      }
    }

    return {
      status: "default",
      color: cp.lightgrey2,
    }
  }

  function checkPattern() {
    if (hasError) {
      return {
        status: "error",
        color: theme.colors.inputFieldErrorColor,
      }
    }

    if (errors?.type === "pattern") {
      return {
        status: "error",
        color: theme.colors.inputFieldErrorColor,
      }
    }

    if (errors === undefined && stateValue !== "") {
      return {
        status: "valid",
        color: cp.aquablue,
      }
    }

    if (errors === undefined && stateValue === "") {
      return {
        status: "default",
        color: cp.lightgrey2,
      }
    }

    // if (charsValid && stateValue !== "") {
    //   return {
    //     status: "valid",
    //     color: cp.aquablue,
    //   }
    // }

    // if (!charsValid && stateValue !== "") {
    //   return {
    //     status: "invalid",
    //     color: cp.red,
    //   }
    // }

    return {
      status: "default",
      color: cp.lightgrey2,
    }
  }

  function handleFocus(e: React.ChangeEvent<HTMLInputElement>) {
    setFocus(true)

    if (isMobileSafari || isIOS) {
      if (e.currentTarget.value.length === 0) {
        onFocus && onFocus()
      } else {
        e.target.setSelectionRange(
          e.currentTarget.value.length,
          e.currentTarget.value.length,
        )
      }
    } else {
      if (isToggledClicked || isToggledClicked === null) {
        if (textPosition === 0) {
          onFocus && onFocus()
        } else {
          e.target.setSelectionRange(textPosition, textPosition)
        }
      }
    }
  }

  function handleBlur(e: React.ChangeEvent<HTMLInputElement>) {
    setFocus(false)

    if (isMobileSafari || isIOS) {
      setTextPosition(e.target.selectionStart)
      onBlur && onBlur()
    } else {
      setTextPosition(e.target.selectionStart)
      setIsToggledClicked(true)
      onBlur && onBlur()
    }

    if (errors === undefined && stateValue !== "") {
      return null
    }
  }

  function showPassword() {
    const inputID = document.getElementById("register-password-input")

    if (isMobileSafari || isIOS) {
      inputID.focus()
      setShow(!isShow)
    } else {
      setIsToggledClicked(true)
      setTimeout(() => {
        inputID.focus()
      }, 100)
      setShow(!isShow)
    }
  }

  const borderClass = `
    ${s.inputBordered}
    ${isFocused ? s.inputBorderedActive : ``}
    ${!isFocused && hasError ? s.inputBorderedError : ``}
    ${!isFocused && isValid ? s.inputBorderedValid : ``}
  `

  const messageClass = `
    ${s.confirmMessage}
    ${isFocused ? s.confirmMessageActive : ``}
    ${hasError ? s.confirmMessageActive : ``} 
    ${isValid ? s.confirmMessageDefault : ``}
  `

  const labelClass = `
    ${s.labelInfo}
    ${isFocused ? s.labelInfoActive : ``}
    ${!isFocused && hasError ? s.labelInfoError : ``}
    ${!isFocused && isValid ? s.labelInfoValid : ``}
  `

  return (
    <div>
      <div className={borderClass}>
        <div className={s.labelContainer}>
          <div className={s.label}>{label}</div>
          {labelHelper && (
            <div className={labelClass}>
              <span className="message">
                <span className={s.parenStyle}>{`( `}</span>
                {labelHelper}
                <span className={s.parenStyle}>{` )`}</span>
              </span>
            </div>
          )}
        </div>
        <input
          key={label}
          autoCapitalize="off"
          autoCorrect="off"
          autoComplete="new-password"
          autoFocus={false}
          className={isIOS ? s.formInputIOS : s.formInput}
          name={label}
          maxLength={20}
          ref={register}
          disabled={disabled}
          placeholder={placeholder}
          type={isShow ? "text" : "password"}
          onKeyUp={e => {
            tabElement(e, true)
            onKeyup()
          }}
          onClick={() => resetValue()}
          onFocus={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleFocus(e)
          }}
          onBlur={(e: React.ChangeEvent<HTMLInputElement>) => handleBlur(e)}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const value = (e as any).target.value
            const val = value.replaceAll(REGEX_ALPHA_NUM_SPECIAL_CHAR, "")
            onChange(val)
          }}
          id={"register-password-input"}
        />
        <div
          className={`${formInput}-password-toggle`}
          role="button"
          onClick={() => showPassword()}
        >
          {isShow ? <IconPasswordShow classStyle={"test"} /> : <IconPassword />}
          <style jsx>{`
            .${formInput}-password-toggle {
              position: absolute;
              top: 0;
              right: 0;
              flex: 1;
              width: 60px;
              height: 54px;
              display: flex;
              align-items: center;
              justify-content: center;
              z-index: 0;
            }
            .${formInput}-password-toggle svg {
              position: relative;
              z-index: 1;
              padding-top: 2px;
            }
          `}</style>
        </div>
      </div>

      <div>
        <div className={messageClass}>
          <div
            className={`${s.confirmHelper} ${isActive ? s.confirmHelperError : ``}`}
          >
            <span>암호 필수 조건</span>
            <div className={s.row}>
              <div className={s.icon}>
                <IconCheck color={checkMinMax()?.color} />
              </div>
              <div
                style={{color: checkMinMax()?.color}}
              >{`8자 이상 20자 이하로 입력하세요.`}</div>
            </div>
            <div className={s.row}>
              <div className={s.icon}>
                {stateValue === "" ? (
                  hasError ? (
                    <IconCheck color={theme.colors.inputFieldErrorColor} />
                  ) : (
                    <IconCheck color={cp.lightgrey2} />
                  )
                ) : (
                  <IconCheck
                    color={
                      charsValid ? cp.aquablue : theme.colors.inputFieldErrorColor
                    }
                  />
                )}
              </div>
              <div style={{color: checkPattern()?.color}}>
                <span
                  className={
                    !regexHangulText.test(stateValue) &&
                    regexEnglishLower.test(stateValue) &&
                    regexEnglishUpper.test(stateValue)
                      ? s.textValid
                      : ``
                  }
                >{`영문 `}</span>
                <span
                  className={
                    regexEnglishLower.test(stateValue) &&
                    regexEnglishUpper.test(stateValue)
                      ? s.textValid
                      : ``
                  }
                >{` ( `}</span>
                <span
                  className={regexEnglishUpper.test(stateValue) ? s.textValid : ``}
                >{`대문자`}</span>
                <span
                  className={
                    regexEnglishLower.test(stateValue) &&
                    regexEnglishUpper.test(stateValue)
                      ? s.textValid
                      : ``
                  }
                >{`, `}</span>
                <span
                  className={regexEnglishLower.test(stateValue) ? s.textValid : ``}
                >{`소문자`}</span>
                <span
                  className={
                    regexEnglishLower.test(stateValue) &&
                    regexEnglishUpper.test(stateValue)
                      ? s.textValid
                      : ``
                  }
                >{` ), `}</span>
                <span
                  className={regexNumber.test(stateValue) ? s.textValid : ``}
                >{`숫자, `}</span>
                <span
                  className={
                    regexpSpecialCharacters.test(stateValue) ? s.textValid : ``
                  }
                >{`특수문자를 모두 사용하세요.`}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FormPasswordInput
