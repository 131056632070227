import React, {Suspense, useEffect} from "react"
import shallow from "zustand/shallow"
import {useModal} from "react-modal-hook"
import usePopupBannerStore from "components/Banner/PopupV4/usePopupBannerStore"
import Modal from "./Modal"
import Popup from "components/Banner/PopupV4"

const usePopupBanner = () => {
  const {isOpen, setIsOpen} = usePopupBannerStore(
    store => ({
      isOpen: store.isOpen,
      setIsOpen: store.setIsOpen,
    }),
    shallow,
  )

  const [showModal, hideModal] = useModal(() => {
    const handleCloseModal = () => {
      hideModal()
    }
    return (
      <Suspense fallback={<>Loading...</>}>
        <Modal
          handleExit={handleCloseModal}
          handleQuickExit={() => setIsOpen(false)}
        >
          <Popup />
        </Modal>
      </Suspense>
    )
  }, [])

  useEffect(() => {
    if (isOpen) showModal()
  }, [isOpen])
}

export default usePopupBanner
