/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, {Dispatch, SetStateAction, useEffect, useState} from "react"
import InputMask from "react-input-mask"
import {isIOS} from "react-device-detect"
import Fade from "react-reveal/Fade"
import {useModal} from "react-modal-hook"
import shallow from "zustand/shallow"
import cx from "classnames"
/**
 * Component
 */
import OTP from "components/OTP"
import {IconExclamation, IconCheck} from "components/Icons/Forms"
import {IconLock} from "components/Icons/Forms"
import LoaderEllipsis from "components/Loader/ellipsis"
import AlertModal from "components/ModalAlert"
/**
 * Service
 */
import serviceRegister from "services/Account/Register"
import serviceFindID from "services/Account/FindID"
import serviceFindPassword from "services/Account/FindPassword"
import servicePlayerChangeMobileNumber from "services/Account/ChangeMobileNumber"
/**
 * Config
 */
import {ConfigAlertMobileExist} from "./Alert"
import {categoryType} from "./Config"
/**
 * Utils
 */
import {stripChar, tabElement} from "utils/helpers"
/**
 * Hooks
 */
import {useLocalStorage} from "utils/hooks/useLocalStorage"
/**
 * Style
 */
import s from "./mobile.module.scss"
import useRegisterModalStore from "components/RegisterButton/hooks/useRegisterModalStore"
interface IFormMobileNumInput {
  delay?: number
  username?: string
  name: string
  category: string
  label: string
  value: string | undefined
  labelHelper?: string
  placeholder?: string
  errors?: any
  onChange?: any
  inputRef: any
  status?: string
  isFormMobileDisabled?: boolean
  onFocus?: () => void
  onBlur?: () => void
  getOTP?: (otpValue: string) => void
  onSetValue?: (mobileVal: string) => void
  onGetDetailsFromOTP?: (response) => void
  setIsValidOtp?: Dispatch<SetStateAction<boolean>>
}

const FormMobileNumberInput = ({
  delay, // from props
  username,
  label,
  category,
  placeholder,
  labelHelper,
  errors,
  value,
  onChange,
  isFormMobileDisabled = false,
  onFocus,
  onBlur,
  onSetValue,
  setIsValidOtp,
  getOTP,
  onGetDetailsFromOTP,
}: IFormMobileNumInput): JSX.Element => {
  const defaultOTPDetails = {status_code: 0, message: ""}
  const [otpDetails, setOtpDetails] = useState(defaultOTPDetails)
  const [otpValue, setOtpValue] = useState<string>("")
  const [isFocused, setFocus] = useState<boolean>(false)
  const [otpExpired, setOtpExpired] = useState<boolean>(false)
  const [isResend, setResend] = useState<boolean>(false)
  const [showOTP, setShowOTP] = useState<boolean>(false)
  const [animateInOTP, setAnimateOTPIn] = useState<boolean>(false)
  const [isVerified, setIsVerified] = useState<boolean>(false)
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [isBlock, setBlock] = useState<boolean>(false)
  const [isLoading, setLoading] = useState<boolean>(false)
  const [pendingTimer, setPendingTimer] = useState<string>("")
  const [delayTime, setDelayTime] = useState<number>(0)

  const [alertMessage, setAlertMessage] = useState<string>(null)
  const [showModal, hideModal] = useModal(
    () => (
      <AlertModal
        message={alertMessage}
        action={() => [hideModal(), setAlertMessage(null)]}
      />
    ),
    [alertMessage],
  )

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [registerRequestCount, setRegisterRequestCount] = useLocalStorage(
    "registerRequestCount",
    0,
  )

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [otpKey, setOtpKey] = useState(Math.random())
  const [apiError, setApiError] = useState("")
  const {setIsOtpSent} = useRegisterModalStore(
    store => ({
      setIsOtpSent: store.setIsOtpSent,
    }),
    shallow,
  )

  const isFindID = category === categoryType.findID
  const isForgotPassword = category === categoryType.forgotPassword
  const isRegister = category === categoryType.registerPlayer
  const isPlayerChangeMobileNumber =
    category === categoryType.playerChangeMobileNumber

  const diffTime = pendingTimer !== "" ? Number(pendingTimer) : 180000

  const otpMessage = otpDetails?.message
  const disabled = showOTP || isVerified
  const mobileNum = value !== "" && value !== undefined ? stripChar("-", value) : ""
  const EditDisabled = disabled && !otpExpired

  // Errors
  const ErrorRequired = errors === "required"
  const ErrorInvalid = errors === "invalid"
  const ErrorValid = errors === "valid"
  const ErrorMaxRequest = apiError === "player.otp.maxRequest.otpCode"

  const FocusErrorRequired = isFocused && ErrorRequired
  const FocusErrorInvalid = isFocused && ErrorInvalid
  const FocusValidNoAuth = isFocused && ErrorValid && otpMessage === ""
  const NotFocusErrorRequired = !isFocused && ErrorRequired
  const NotFocusErrorInvalid = !isFocused && ErrorInvalid
  const NotFocusValidNoAuth = !isFocused && ErrorValid && otpMessage === ""
  const NotFocusMaxRequest =
    !isFocused && ErrorValid && otpMessage === "player.otp.maxRequest.otpCode"
  const HasApiError =
    apiError !== "player.otp.maxRequest.otpCode" &&
    apiError !== "player.otp.hasPendingOTPCode" &&
    apiError !== "findIdThrottle.invalidLimit.mobile" &&
    apiError !== ""

  const ErrorNoOTP =
    NotFocusErrorRequired || NotFocusErrorInvalid || NotFocusValidNoAuth

  const ErrorHasValidValue =
    !isFocused && value !== undefined && mobileNum.length === 11

  const canSubmit = mobileNum !== "" && mobileNum.length === 11

  function mobileAlert(sCode: number): void {
    const mAlert = ConfigAlertMobileExist[category]

    if (sCode === 0) {
      return
    }

    if (mAlert !== undefined) {
      setAlertMessage(mAlert(sCode))
    }
    return
  }

  function handleFocus() {
    setOtpDetails(defaultOTPDetails)
    setApiError("")
    setOtpExpired(false)
    setFocus(true)
    setSubmitting(false)
    onFocus && onFocus()
  }

  function handleBlur() {
    onBlur()

    submitting ? setFocus(true) : setFocus(false)
    setSubmitting(false)
  }

  function service(categoryName: string) {
    switch (categoryName) {
      case categoryType.registerPlayer:
        return serviceRegister
      case categoryType.findID:
        return serviceFindID
      case categoryType.forgotPassword:
        return serviceFindPassword
      case categoryType.playerChangeMobileNumber:
        return servicePlayerChangeMobileNumber
      default:
        return
    }
  }

  function resetOTP() {
    setOtpValue("")
    setOtpExpired(true)
    setResend(true)
    setShowOTP(false)
    setPendingTimer(String(180000))
  }

  function delayCall(remainingTime: number) {
    return remainingTime
  }

  /**
   * API CALLS
   */
  async function handleGetOtpCode(): Promise<any> {
    const payloadFindPassword = {
      category,
      mobile: mobileNum,
      username,
    }

    const payloadDefault = {
      category,
      mobile: mobileNum,
    }

    const payload = isForgotPassword ? payloadFindPassword : payloadDefault

    try {
      const response = await service(category).getOtpCode(payload)
      const resData = (response as any)?.data
      const resCode = resData?.status_code
      setOtpDetails((response as any)?.data)
      setShowOTP(true)
      setOtpExpired(false)
      setSubmitting(false)
      setLoading(false)
      mobileAlert(Number(resCode))
      return response
    } catch (e) {
      let eReturn = (e as any)?.data?.mobile
      if (eReturn) eReturn = eReturn[0]
      else eReturn = (e as any)?.data
      const eReturnCode = eReturn?.status_code
      mobileAlert(Number(eReturnCode))
      setOtpDetails((e as any)?.data)
      setSubmitting(false)
      setLoading(false)
      setIsOtpSent(false)
      return e
    }
  }

  async function handleMobileExists(e: any): Promise<unknown> {
    e.preventDefault()
    setSubmitting(true)
    setLoading(true)

    if (mobileNum !== undefined && mobileNum.length !== 11) {
      return null
    }

    const payloadFindPassword = {
      category,
      mobile: mobileNum,
      username,
    }

    const payloadDefault = {
      category,
      mobile: mobileNum,
    }

    const payload = isForgotPassword ? payloadFindPassword : payloadDefault

    try {
      const response = await service(category).checkMobile(payload)
      const resData = (response as any)?.data
      setOtpDetails(resData)
      handleGetOtpCode()

      return resData
    } catch (e) {
      let eReturn = (e as any)?.data?.mobile
      if (eReturn) eReturn = eReturn[0]
      else eReturn = (e as any)?.data
      const eReturnCode = eReturn?.status_code

      if (eReturn?.remaining_time !== null) {
        setDelayTime(eReturn?.remaining_time)
      }

      mobileAlert(Number(eReturnCode))

      if (isRegister) {
        setRegisterRequestCount(Number(eReturn?.count))
      }

      if (isFindID || isForgotPassword) {
        if (Number(eReturnCode) === -8) {
          setBlock(true)
        }
      }

      setOtpDetails(eReturn)
      setApiError(eReturn?.message)

      if (eReturn?.message === "player.otp.hasPendingOTPCode") {
        setShowOTP(true)
        setPendingTimer(eReturn?.expired_at)
      }

      setSubmitting(false)
      setLoading(false)
      return e
    }
  }

  async function handleSendOTP(): Promise<any> {
    const payloadFindPassword = {
      category,
      mobile: mobileNum,
      username,
      otp: otpValue,
    }

    const payloadDefault = {
      category,
      mobile: mobileNum,
      otp: otpValue,
    }

    document.body.focus()

    const payload = isForgotPassword ? payloadFindPassword : payloadDefault

    try {
      const response = await service(category).sendOTP(payload)

      const docActive = document as any
      docActive.activeElement.blur()

      getOTP(otpValue.toString().split(",").join(""))
      setOtpDetails((response as any)?.data)
      setResend(false)
      setTimeout(() => {
        setShowOTP(false)
        setIsVerified(true)
        setTimeout(() => {
          setIsValidOtp(true)
        }, 500)
      }, 750)

      if (isFindID) {
        onGetDetailsFromOTP((response as any)?.data)
      }

      return response
    } catch (e) {
      setShowOTP(true)
      setOtpDetails((e as any)?.data?.otp[0])
      setTimeout(() => {
        setOtpDetails(defaultOTPDetails)
        setOtpKey(Math.random())
        setOtpValue("")
      }, 1000)
      return e
    }
  }

  function registerCallback(evt: any) {
    setLoading(true)
    setIsOtpSent(true)
    setSubmitting(true)
    setTimeout(
      () => handleMobileExists(evt),
      delayCall(delay !== 0 ? delay : delayTime),
    )
  }

  function requestOTP(evt: any) {
    setSubmitting(true)
    evt.preventDefault()
    if (isRegister) {
      registerCallback(evt)
    } else {
      handleMobileExists(evt)
    }
  }

  useEffect(() => {
    if (otpValue.length === 6) {
      handleSendOTP()
    } else {
      return
    }
  }, [otpValue])

  useEffect(() => {
    const timer = showOTP ? 0 : 2000

    setTimeout(() => {
      setAnimateOTPIn(showOTP)
    }, timer)
  }, [showOTP])

  useEffect(() => {
    if (alertMessage === null) {
      return
    }

    showModal()
  }, [alertMessage])

  const labelClass = `${s.labelInfo} 
    ${isVerified ? s.labelInfoDefault : ""}
    ${showOTP ? s.labelInfoActive : ""}
    ${isFocused ? s.labelInfoActive : ""}
    ${FocusErrorRequired ? s.labelInfoActive : ""}
    ${FocusErrorInvalid ? s.labelInfoActive : ""}
    ${FocusValidNoAuth ? s.labelInfoActive : ""}
    ${NotFocusErrorRequired ? s.labelInfoError : ""}
    ${NotFocusErrorInvalid ? s.labelInfoError : ""}
    ${NotFocusValidNoAuth ? s.labelInfoError : ""}
    ${ErrorMaxRequest ? s.labelInfoActive : ""}
    ${isRegister && HasApiError ? s.labelInfoError : ""}
    ${isRegister && submitting ? s.labelInfoActive : ""}
    ${!isRegister && submitting ? s.labelInfoActive : ""}
    ${!isRegister && HasApiError && !isBlock ? s.labelInfoError : ""}
    ${!isRegister && HasApiError && isBlock ? s.labelInfoDefaultImportant : ""}
  `

  const borderClass = `${s.inputBordered}
    ${isVerified ? s.inputBorderedActive : ""}
    ${isFocused ? s.inputBorderedActive : ""}
    ${FocusErrorRequired ? s.inputBorderedActive : ""}
    ${FocusErrorInvalid ? s.inputBorderedActive : ""}
    ${FocusValidNoAuth ? s.inputBorderedActive : ""}
    ${NotFocusErrorRequired ? s.inputBorderedError : ""}
    ${NotFocusErrorInvalid ? s.inputBorderedError : ""}
    ${NotFocusValidNoAuth ? s.inputBorderedError : ""}

    ${submitting && NotFocusErrorInvalid ? `${s.inputBorderedActive} 222` : ""}

    ${NotFocusMaxRequest ? s.inputBorderedActive : ""}
    ${ErrorMaxRequest ? s.inputBorderedActive : ""}
    ${HasApiError && showOTP ? s.inputBorderedActive : ""}
    ${HasApiError && !showOTP ? s.inputBorderedError : ""}
    ${showOTP ? s.inputBorderedActive : ""}
    ${!showOTP && otpExpired ? s.inputBorderedError : ""}
    ${isBlock ? s.inputBorderedActive : ""}
    ${canSubmit && submitting ? s.inputBorderedActive : ""}
  `

  // Error Display
  const AllPage =
    isFindID || isForgotPassword || isRegister || isPlayerChangeMobileNumber

  const AllPageNotBlock = AllPage && !isBlock
  const AllRequired =
    AllPage &&
    !submitting &&
    !showOTP &&
    !otpExpired &&
    !ErrorHasValidValue &&
    !isBlock &&
    ErrorNoOTP

  const AllValidValue =
    AllPage &&
    !showOTP &&
    !otpExpired &&
    !isBlock &&
    ErrorHasValidValue &&
    ErrorNoOTP

  const AllValidNotVerified =
    AllPageNotBlock && !submitting && showOTP && !otpExpired
  const AllValidOtpExpired = AllPageNotBlock && !submitting && !showOTP && otpExpired
  const AllHasApiError = AllPageNotBlock && HasApiError && !submitting
  const AllVerified = AllPageNotBlock && isVerified

  const mobileMessageClass = `${s.mobileMessage}
    ${
      AllRequired || AllValidOtpExpired || AllValidValue || AllHasApiError
        ? s.mobileMessageError
        : ""
    }
    ${AllValidNotVerified ? s.mobileMessageError : ""}
    ${showOTP ? s.mobileMessageDefault : ""}
    ${AllVerified ? s.mobileMessageValid : ""}
    ${submitting ? s.mobileMessageSubmitting : ``}
  `

  const mobileSubmitClass = `${isIOS ? s.mobileSubmitIOS : s.mobileSubmit}
    ${isVerified ? s.mobileSubmitFocused : ""}
    ${FocusErrorInvalid ? s.mobileSubmitFocused : ""}
    ${FocusValidNoAuth ? `${s.mobileSubmitValid} 222222` : ""}
    ${!submitting && NotFocusErrorRequired ? s.mobileSubmitError : ""}
    ${!submitting && NotFocusErrorInvalid ? s.mobileSubmitError : ""}
    ${!submitting && NotFocusValidNoAuth ? s.mobileSubmitErrorDark : ""}
    ${ErrorMaxRequest ? s.mobileSubmitValid : ""}
    ${HasApiError && showOTP ? s.mobileSubmitValid : ""}
    ${HasApiError && !showOTP ? s.mobileSubmitError : ""}
    ${showOTP ? s.mobileSubmitFocused : ""}
    ${!showOTP && otpExpired ? s.mobileSubmitErrorDark : ""}
    ${FocusErrorRequired ? s.mobileSubmitValid : ""}
    ${isBlock ? s.mobileSubmitValid : ""}
    ${canSubmit && submitting ? s.mobileSubmitValid : ""}
  `

  return (
    <div>
      <div className={s.mobileContainer}>
        <div className={s.mobileInputContainer}>
          <div className={borderClass}>
            <div className={s.labelContainer}>
              <div className={s.label}>{label}</div>
              {labelHelper && (
                <div className={labelClass}>
                  <span className="message">
                    <span className={s.parenStyle}>{`( `}</span>
                    {labelHelper}
                    <span className={s.parenStyle}>{` )`}</span>
                  </span>
                </div>
              )}
            </div>

            <InputMask
              type="tel"
              disabled={EditDisabled || showOTP || isFormMobileDisabled || isLoading}
              mask="010-9999-9999"
              maskPlaceholder={""}
              placeholder={placeholder}
              onKeyUp={e => tabElement(e, true)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                onSetValue(e?.target?.value)
                onChange(e?.target?.value)
              }}
              onFocus={() => handleFocus()}
              onBlur={() => handleBlur()}
              value={value}
              className={`${isIOS ? s.formInputIOS : s.formInput} mobile-input`}
            />

            {EditDisabled && (
              <div className={s.mobileLock}>
                <IconLock />
              </div>
            )}

            {canSubmit && submitting && (
              <div className={s.mobileLock}>
                <IconLock />
              </div>
            )}

            {isFormMobileDisabled && (
              <div className={s.mobileLock}>
                <IconLock />
              </div>
            )}
          </div>
        </div>

        <div className={s.mobileButtonContainer}>
          {isBlock ? (
            <button
              tabIndex={1}
              id="mobile-submit"
              className={mobileSubmitClass}
              onFocus={() => null}
              onClick={(e: any) => requestOTP(e)}
            >
              {isResend ? `재전송` : `인증번호받기`}
            </button>
          ) : (
            <button
              tabIndex={1}
              id="mobile-submit"
              className={mobileSubmitClass}
              onFocus={() => null}
              disabled={
                isLoading || EditDisabled || !canSubmit || HasApiError || submitting
              }
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => requestOTP(e)}
            >
              {isLoading ? <LoaderEllipsis /> : isResend ? `재전송` : `인증번호받기`}
            </button>
          )}
        </div>
      </div>

      <div>
        <div className={mobileMessageClass}>
          <Fade top opposite when={AllRequired}>
            <div className={`${s.mobileHelper} ${s.mobileHelperError}`}>
              <div className={s.icon}>
                <IconExclamation color={"#FA3939"} />
              </div>
              <div className={s.mobileHelperTextError}>{`필수 정보입니다.`}</div>
            </div>
          </Fade>

          <Fade top opposite when={AllHasApiError}>
            <div className={`${s.mobileHelper} ${s.mobileHelperError}`}>
              <div className={s.icon}>
                <IconExclamation color={"#FA3939"} />
              </div>
              <div className={s.mobileHelperTextError}>{apiError}</div>
            </div>
          </Fade>

          <Fade top opposite when={AllValidOtpExpired}>
            <div className={`${s.mobileHelper} ${s.mobileHelperError}`}>
              <div className={s.icon}>
                <IconExclamation color={"#FA3939"} />
              </div>
              <div className={s.mobileHelperTextError}>{`인증이 필요합니다.`}</div>
            </div>
          </Fade>

          <Fade top opposite when={AllValidNotVerified}>
            <div className={`${s.mobileHelper} ${s.mobileHelperValid}`}>
              <div
                className={s.mobileHelperTextValid}
              >{`인증번호가 전송 되었습니다.`}</div>
            </div>
          </Fade>

          <Fade top opposite when={AllVerified}>
            <div className={`${s.mobileHelper} ${s.mobileHelperValid}`}>
              <div className={s.icon}>
                <IconCheck color={"#00acee"} />
              </div>
              <div className={s.mobileHelperTextValid} style={{paddingLeft: "4px"}}>
                {`인증완료`}
              </div>
            </div>
          </Fade>

          <Fade top opposite when={!isFocused && AllValidValue}>
            <div className={`${s.mobileHelper} ${s.mobileHelperValid}`}>
              {!submitting ? (
                <>
                  <div className={s.icon}>
                    <IconExclamation color={"#FA3939"} />
                  </div>
                  <div
                    className={s.mobileHelperTextError}
                  >{`인증이 필요합니다.`}</div>
                </>
              ) : (
                <div style={{height: "0px"}} />
              )}
            </div>
          </Fade>
        </div>
      </div>

      <div className={cx(s.otpContainer, {[s.expand]: showOTP && !otpExpired})}>
        {animateInOTP && (
          <OTP
            otpValue={otpValue}
            resetOTP={resetOTP}
            setOtpValue={setOtpValue}
            otpDetails={otpDetails}
            isShow={showOTP}
            timer={diffTime}
            state={otpDetails?.message === "otp.sendOtpCode.success"}
            hasPendingCode={apiError === "player.otp.hasPendingOTPCode"}
          />
        )}
      </div>

      <style jsx>{`
        .mobile-input[disabled] {
          color: #525252;
          opacity: 1;
        }
      `}</style>
    </div>
  )
}

export default FormMobileNumberInput
