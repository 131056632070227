// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, {memo, useRef, useLayoutEffect} from "react"
import usePrevious from "./usePrevious"

export interface SingleOTPInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  focus?: boolean
  orderBy: string
  inputID: string
}

export function SingleOTPInputComponent(props: SingleOTPInputProps): any {
  const {focus, autoFocus, inputID, ...rest} = props
  const inputRef = useRef<HTMLInputElement>(null)
  const prevFocus = usePrevious(!!focus)

  useLayoutEffect(() => {
    if (inputRef.current) {
      if (focus && autoFocus) {
        inputRef.current.focus()
      }
      if (focus && autoFocus && focus !== prevFocus) {
        inputRef.current.focus()
        inputRef.current.select()
      }
    }
  }, [autoFocus, focus, prevFocus])

  return <input ref={inputRef} {...rest} type="tel" id={inputID} />
}

const SingleOTPInput = memo(SingleOTPInputComponent)
export default SingleOTPInput
