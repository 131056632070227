import create from "zustand"
import {LOGIN} from "components/LoginButton/contants"

export interface iLoginModalStore {
  isOpen: boolean
  isLogin: boolean
  findIdSuccess: boolean
  findPasswordSuccess: boolean
  selectedTab: string
  close: () => void
  setIsOpen: (value: boolean) => void
  setIsLogin: (value: boolean) => void
  setFindIdSuccess: (value: boolean) => void
  setFindPasswordSuccess: (value: boolean) => void
  setSelectedTab: (value: string) => void
  setClose: (value: () => void) => void
  handleCheckOpen: (callback: () => void) => void
}

const useLoginModalStore = create<iLoginModalStore>((set, get) => ({
  isOpen: false,
  isLogin: false,
  findIdSuccess: false,
  findPasswordSuccess: false,
  selectedTab: LOGIN,
  close: null,
  setIsOpen: isOpen => set({isOpen}),
  setIsLogin: isLogin => set({isLogin}),
  setFindIdSuccess: findIdSuccess => set({findIdSuccess}),
  setFindPasswordSuccess: findPasswordSuccess => set({findPasswordSuccess}),
  setSelectedTab: selectedTab => set({selectedTab}),
  setClose: close => set({close}),
  handleCheckOpen: (callback: () => void) => {
    const isOpen = get().isOpen
    const close = get().close
    if (isOpen) {
      close()
      setTimeout(() => callback(), 200)
    } else {
      callback()
    }
  },
}))

export default useLoginModalStore
