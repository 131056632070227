import create from "zustand"
import {WidgetInstance} from "@livechat/widget-core"
import {showComingSoon} from "screens/NavHome/components/GlobalAlert/utils"
type Visibility = "hidden" | "maximized" | "minimized"

interface iLiveChatStore {
  isEnabled: boolean
  visibility: Visibility
  chatWidget: WidgetInstance
  setVisibility: (value: Visibility) => void
  setChatWidget: (value: WidgetInstance) => void
  setIsEnabled: (value: boolean) => void
  toggleChat: () => void
  resetStore: () => void
}

const useLiveChatStore = create<iLiveChatStore>((set, get) => ({
  isEnabled: true,
  visibility: "hidden",
  chatWidget: null,
  setVisibility: visibility => set({visibility}),
  setChatWidget: chatWidget => set({chatWidget}),
  setIsEnabled: isEnabled => set({isEnabled}),
  toggleChat: () => {
    const visibility = get().visibility
    const isEnabled = get().isEnabled

    if (isEnabled)
      set({
        visibility: visibility !== "maximized" ? "maximized" : "minimized",
      })
    else showComingSoon()
  },
  resetStore: () => {
    set({
      isEnabled: true,
      visibility: "hidden",
      chatWidget: null,
    })
  },
}))

export default useLiveChatStore
