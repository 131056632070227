import React, {CSSProperties, Suspense, useContext, useState} from "react"
import styles from "./styles.module.css"
import SwiperCore, {Pagination, Scrollbar, A11y} from "swiper"
import {Swiper, SwiperSlide} from "swiper/react"
import "./style.css"
import "swiper/swiper-bundle.min.css"
import "swiper/swiper.min.css"
import "swiper/components/navigation/navigation.scss"
import "swiper/components/scrollbar/scrollbar.scss"
import {pathType, useNavigate} from "hooks/useNavigate"
import {ThemeContext} from "context/ThemeContext"
import navStore from "screens/NavHome/store/navStore"
import shallow from "zustand/shallow"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"

// install Swiper modules
SwiperCore.use([Pagination, Scrollbar, A11y])

interface ImageData {
  src: string
  caption: string
  path: pathType
}

interface HomeCarouselProps {
  images: ImageData[]
}

interface CSSCustomProperties extends CSSProperties {
  "--bulletBackground"?: string
}

const HomeCarouselMobile: React.FC<HomeCarouselProps> = ({images}) => {
  const {theme} = useContext(ThemeContext)
  const [isLoading, setIsLoading] = useState(true)
  const custom_colors: CSSCustomProperties = {
    "--bulletBackground": theme?.colors.bulletBackground,
  }
  const {handleNavigate} = useNavigate()
  const handleNavigateTo = (path: pathType) => {
    handleNavigate(path)
  }
  const imagesToShow = []

  for (let i = 0; i < images.length; i += 2) {
    const firstElement = images[i]
    const secondElement = images[i + 1] || null // Handle potential undefined value

    imagesToShow.push({
      image1: firstElement,
      image2: secondElement,
    })
  }
  const {bodyRef} = navStore(
    store => ({
      bodyRef: store.bodyRef,
    }),
    shallow,
  )
  const disableFlicking = () => {
    bodyRef.disableInput()
  }

  const enableFlicking = () => {
    bodyRef.enableInput()
  }

  const imageLoaded = (id: string) => {
    const image = document.getElementById(id) as HTMLImageElement
    if (image) {
      if (image.complete) {
        setIsLoading(false)
      }
    }
  }

  return (
    <div className={styles.swiperContainer} style={custom_colors}>
      <Suspense fallback={<></>}>
        <Swiper
          observer
          slidesPerView={2}
          spaceBetween={8}
          allowTouchMove={true}
          className={`${styles.swiper} homeSwiperV3`}
          pagination={{
            clickable: true,
          }}
          onTouchStart={disableFlicking}
          onTouchEnd={enableFlicking}
        >
          {imagesToShow.length > 0 &&
            imagesToShow.map((image, index) => (
              <SwiperSlide key={index} className={styles.swiperSlide}>
                <div className={styles.carouselItem}>
                  <Skeleton
                    className={styles.skeleton}
                    containerClassName={styles.skeletonContainer}
                  />
                  <img
                    id={"carousel_img"}
                    src={image.image1.src}
                    alt={image.image1.caption}
                    onClick={() => handleNavigateTo(image.image1.path)}
                    onLoad={() => imageLoaded("carousel_img")}
                  />
                  {image.image2 != null && ( 
                    <>
                      <Skeleton
                        className={styles.skeleton}
                        containerClassName={styles.skeletonContainer2}
                      />
                      <img
                        id={"carousel_img2"}
                        src={image.image2.src}
                        alt={image.image2.caption}
                        onClick={() => handleNavigateTo(image.image2.path)}
                        onLoad={() => imageLoaded("carousel_img2")}
                      />
                    </>
                  )}
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
      </Suspense>
    </div>
  )
}

export default HomeCarouselMobile
