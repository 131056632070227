import request from "services/request"
import API from "services/Account/Register/endpoints"

function headers() {
  return {
    "Accept": "application/json",
    "Content-Type": "application/json",
  }
}

function checkDelay(
  type: "mobile" | "referrer" | "username",
  category = "REGISTER_PLAYER",
): Promise<unknown> {
  let endpoint = ``

  if (type === "mobile") {
    endpoint = API.CHECK_DELAY_MOBILE_EXIST
  } else if (type === "referrer") {
    endpoint = API.CHECK_DELAY_REFERRER
  } else {
    endpoint = API.CHECK_DELAY_USERNAME
  }

  return request({
    url: endpoint,
    method: "POST",
    data: {
      category,
    },
    headers: headers(),
  })
}

function checkMobile(data: Record<string, string>): Promise<unknown> {
  return request({
    url: API.CHECK_MOBILE,
    method: "POST",
    data,
    headers: headers(),
  })
}

function checkReferrer(referrable: string): Promise<unknown> {
  return request({
    url: API.CHECK_REFERRER,
    method: "POST",
    data: {
      referrable,
    },
    headers: headers(),
  })
}

function checkUsername(category: string, username: string): Promise<unknown> {
  return request({
    url: API.CHECK_USERNAME,
    method: "POST",
    data: {
      category,
      username,
    },
    headers: headers(),
  })
}

function getOtpCode(data: Record<string, string>): Promise<unknown> {
  return request({
    url: API.GET_OTP_CODE,
    method: "POST",
    data,
    headers: headers(),
  })
}

function sendOTP(data: Record<string, any>): Promise<unknown> {
  return request({
    url: API.SEND_OTP,
    method: "POST",
    data,
    headers: headers(),
  })
}

function registerUser(data: Record<string, any>): Promise<unknown> {
  return request({
    url: API.SUBMIT_FORM,
    method: "POST",
    data,
    headers: headers(),
  })
}

// function testPUT(data: Record<string, unknown>): AxiosPromise {

export default {
  checkUsername,
  checkMobile,
  checkReferrer,
  getOtpCode,
  sendOTP,
  registerUser,
  checkDelay,
}
