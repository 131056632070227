import create from "zustand"
import Promotion from "screens/Promotions/types/Promotion"
import HeightConfig from "screens/Promotions/types/HeightConfig"
import {
  PRVIVATE_PROMO_LIST,
  PUBLIC_PROMO_LIST,
  EZ_PRIVATE_PROMO_LIST,
  EZ_PUBLIC_PROMO_LIST,
} from "screens/Promotions/config"
import {UseMeasureRect} from "react-use/lib/useMeasure"
import {useUserStore} from "stores/userStore"

interface iPromotionsStore {
  selected: Promotion
  currentViewed: number
  items: Promotion[]
  navRef: React.MutableRefObject<HTMLDivElement>
  navlistRef: React.MutableRefObject<HTMLDivElement>
  promoRefList: React.MutableRefObject<HTMLDivElement>[]
  promotionLoaded: number
  isLoaded: boolean
  heightConfig: HeightConfig
  totalStickyItems: number
  promoListTopMargin: number
  isScrollingTop: boolean
  navEntryPoint: number
  isTop: boolean
  stickyItems: Promotion[]
  scrollToOnLoad: string
  setScrollToOnLoad: (value: string) => void
  setStickyItems: (value: Promotion[]) => void
  setIsTop: (value: boolean) => void
  setNavEntryPoint: (value: number) => void
  setIsScrollingTop: (value: boolean) => void
  setPromoListTopMargin: (value: number) => void
  setTotalStickyItems: (value: number) => void
  setHeightConfig: (value: HeightConfig) => void
  setIsLoaded: (value: boolean) => void
  setPromotionLoaded: (value: number) => void
  setSelected: (value: Promotion) => void
  setCurrentViewed: (value: number) => void
  setItems: (value: Promotion[]) => void
  setNavRef: (value: React.MutableRefObject<HTMLDivElement>) => void
  setNavListRef: (value: React.MutableRefObject<HTMLDivElement>) => void
  appendPromoRef: (value: React.MutableRefObject<HTMLDivElement>) => void
  appendedPromoLoaded: () => void
  updateHeightConfig: (key: string, height: UseMeasureRect) => void
  refresh: () => void
  resetStore: () => void
}

const getList = () => {
  const isEz = window.location.host === "ezbet.com"
  const token = useUserStore.getState().authToken
  const EZ_LIST = token ? EZ_PRIVATE_PROMO_LIST : EZ_PUBLIC_PROMO_LIST
  const PROMO_LIST = token ? PRVIVATE_PROMO_LIST : PUBLIC_PROMO_LIST
  return isEz ? EZ_LIST : PROMO_LIST
}

const usePromotionsStore = create<iPromotionsStore>((set, get) => ({
  selected: getList()[0],
  currentViewed: 0,
  items: getList(),
  navRef: null,
  navlistRef: null,
  promoRefList: [],
  promotionLoaded: 0,
  isLoaded: false,
  heightConfig: {},
  totalStickyItems: 4,
  promoListTopMargin: 0,
  isScrollingTop: false,
  navEntryPoint: 0,
  isTop: true,
  stickyItems: null,
  scrollToOnLoad: "",
  setScrollToOnLoad: scrollToOnLoad => set({scrollToOnLoad}),
  setStickyItems: stickyItems => set({stickyItems}),
  setIsTop: isTop => set({isTop}),
  setNavEntryPoint: navEntryPoint => set({navEntryPoint}),
  setIsScrollingTop: isScrollingTop => set({isScrollingTop}),
  setPromoListTopMargin: promoListTopMargin => set({promoListTopMargin}),
  setTotalStickyItems: totalStickyItems => set({totalStickyItems}),
  setHeightConfig: heightConfig => set({heightConfig}),
  setIsLoaded: isLoaded => set({isLoaded}),
  setPromotionLoaded: promotionLoaded => set({promotionLoaded}),
  setSelected: selected => set({selected}),
  setCurrentViewed: currentViewed => set({currentViewed}),
  setItems: items => set({items}),
  setNavRef: navRef => set({navRef}),
  setNavListRef: navlistRef => set({navlistRef}),
  appendPromoRef: (value: React.MutableRefObject<HTMLDivElement>) => {
    const promoRefList = get().promoRefList
    set({promoRefList: [...promoRefList, value]})
  },
  appendedPromoLoaded: () => {
    const promotionLoaded = get().promotionLoaded
    set({promotionLoaded: promotionLoaded + 1})
  },
  updateHeightConfig: (key: string, measure: UseMeasureRect) => {
    const heightConfig = get().heightConfig
    heightConfig[key] = measure
    set({
      heightConfig: {...heightConfig},
    })
  },
  refresh: () => {
    const updated = getList()
    const reset = get().resetStore
    set({items: [...updated], selected: updated[0]})
    reset()
  },
  resetStore: () => {
    set({
      selected: getList()[0],
      currentViewed: 0,
      items: getList(),
      navRef: null,
      navlistRef: null,
      heightConfig: {},
      promoRefList: [],
      promotionLoaded: 0,
      isLoaded: false,
      promoListTopMargin: 0,
      navEntryPoint: 0,
      isScrollingTop: false,
      isTop: true,
      stickyItems: null,
      totalStickyItems: 4,
      scrollToOnLoad: "",
    })
  },
}))

export default usePromotionsStore
