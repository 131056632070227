// import DepositBanner from "assets/Promotions/Banner/promobanner-new-first-deposit.webp"
// import FirstDepositBanner from "assets/Promotions/Banner/promobanner-first-deposit.webp"
// import MultiBetBanner from "assets/Promotions/Banner/promobanner-multi-bet.webp"
// import RewardBanner from "assets/Promotions/Banner/promobanner-reward-levelup.webp"
// import CashOutBanner from "assets/Promotions/Banner/promobanner-cashout.webp"
// import ReviewEvent from "assets/Promotions/Banner/promobanner-review-event.webp"
// import SecondDepositBanner from "assets/Promotions/Banner/promobanner-second-deposit.webp"
// import SportsBonusBanner from "assets/Promotions/Banner/promobanner-sports-bonus.webp"
// import EvolutionBanner from "assets/Promotions/Banner/promobanner-evolution.webp"
// import SportsMaintenanceBanner from "assets/Promotions/Banner/promobanner-sports-maintenance.webp"
// import NewSportBanner from "assets/Promotions/Banner/newsports.webp"

// note: used as banner icon, should we just use the icons from icons folder? - commenting out for now..
// import SportsBanner from "assets/Promotions/Banner/promobanner-sports.webp"
// import SportsEventBanner from "assets/Promotions/Banner/promobanner-sports-event.webp"

// import DepositIcon from "assets/Promotions/Icons/promotitle-new-first-deposit.webp"
// import FirstDepositIcon from "assets/Promotions/Icons/promotitle-first-deposit.webp"
// import MultiBetIcon from "assets/Promotions/Icons/promotitle-multi-bet.webp"
// import RewardIcon from "assets/Promotions/Icons/promotitle-reward-levelup.webp"
// import CashOutIcon from "assets/Promotions/Icons/promotitle-cashout.webp"
// import ReviewEventIcon from "assets/Promotions/Icons/promotitle-review-event.webp"
// import EvolutionIcon from "assets/Promotions/Icons/promotitle-evolution.webp"
// import SportsIcon from "assets/Promotions/Icons/promotitle-sports-cashback.webp"
// import SportsEventIcon from "assets/Promotions/Icons/promotitle-sports-event.webp"
// import ReferFriendIcon from "assets/Promotions/Icons/promotitle-refer-friend.webp"
// import PayBackIcon from "assets/Promotions/Icons/promotitle-payback.webp"
// import SecondDepositIcon from "assets/Promotions/Icons/second-deposit.webp"
// import SportsBonusIcon from "assets/Promotions/Icons/sports-bonus.webp"
// import SportsMaintenanceIcon from "assets/Promotions/Icons/sports-maintenance.webp"
// import NewSportIcon from "assets/Promotions/Icons/newsports.webp"

// import Promo1ContentMobile from "assets/Promotions/Contents/Promo/375.webp"
// import Promo1ContentTablet from "assets/Promotions/Contents/Promo/538.webp"
// import Promo1ContentDesktop from "assets/Promotions/Contents/Promo/976.webp"

// import Promo2ContentMobile from "assets/Promotions/Contents/Promo2/375.webp"
// import Promo2ContentTablet from "assets/Promotions/Contents/Promo2/538.webp"
// import Promo2ContentDesktop from "assets/Promotions/Contents/Promo2/976.webp"

// import Promo3ContentMobile from "assets/Promotions/Contents/Promo3/375.webp"
// import Promo3ContentTablet from "assets/Promotions/Contents/Promo3/538.webp"
// import Promo3ContentDesktop from "assets/Promotions/Contents/Promo3/976.webp"

// import Promo4ContentMobile from "assets/Promotions/Contents/Promo4/375.webp"
// import Promo4ContentTablet from "assets/Promotions/Contents/Promo4/538.webp"
// import Promo4ContentDesktop from "assets/Promotions/Contents/Promo4/976.webp"

// import Promo5ContentMobile from "assets/Promotions/Contents/Promo5/375.webp"
// import Promo5ContentTablet from "assets/Promotions/Contents/Promo5/538.webp"
// import Promo5ContentDesktop from "assets/Promotions/Contents/Promo5/976.webp"

// import Promo6ContentMobile from "assets/Promotions/Contents/Promo6/375.webp"
// import Promo6ContentTablet from "assets/Promotions/Contents/Promo6/538.webp"
// import Promo6ContentDesktop from "assets/Promotions/Contents/Promo6/976.webp"

// import Promo7ContentMobile from "assets/Promotions/Contents/Promo7/375.webp"
// import Promo7ContentTablet from "assets/Promotions/Contents/Promo7/538.webp"
// import Promo7ContentDesktop from "assets/Promotions/Contents/Promo7/976.webp"

// import Promo8ContentMobile from "assets/Promotions/Contents/Promo8/375.webp"
// import Promo8ContentTablet from "assets/Promotions/Contents/Promo8/538.webp"
// import Promo8ContentDesktop from "assets/Promotions/Contents/Promo8/976.webp"

// import Promo9ContentMobile from "assets/Promotions/Contents/Promo9/375.webp"
// import Promo9ContentTablet from "assets/Promotions/Contents/Promo9/538.webp"
// import Promo9ContentDesktop from "assets/Promotions/Contents/Promo9/976.webp"

// import Promo10ContentMobile from "assets/Promotions/Contents/Promo10/375.webp"
// import Promo10ContentTablet from "assets/Promotions/Contents/Promo10/538.webp"
// import Promo10ContentDesktop from "assets/Promotions/Contents/Promo10/976.webp"

// import Promo11ContentMobile from "assets/Promotions/Contents/Promo11/375.webp"
// import Promo11ContentTablet from "assets/Promotions/Contents/Promo11/538.webp"
// import Promo11ContentDesktop from "assets/Promotions/Contents/Promo11/976.webp"

// import Promo12ContentMobile from "assets/Promotions/Contents/Promo12/375.webp"
// import Promo12ContentTablet from "assets/Promotions/Contents/Promo12/538.webp"
// import Promo12ContentDesktop from "assets/Promotions/Contents/Promo12/976.webp"

// import Promo13ContentMobile from "assets/Promotions/Contents/Promo13/375.webp"
// import Promo13ContentTablet from "assets/Promotions/Contents/Promo13/538.webp"
// import Promo13ContentDesktop from "assets/Promotions/Contents/Promo13/976.webp"

// import Promo14ContentMobile from "assets/Promotions/Contents/Promo14/375.webp"
// import Promo14ContentTablet from "assets/Promotions/Contents/Promo14/538.webp"
// import Promo14ContentDesktop from "assets/Promotions/Contents/Promo14/976.webp"

/**Home Desktop Banners */
// import EventImgDesktop1 from "assets/HomeV2/desktop/banners/event_banner_01.png"
// import EventImgDesktop2 from "assets/HomeV2/desktop/banners/event_banner_02.png"
// import EventImgDesktop3 from "assets/HomeV2/desktop/banners/event_banner_03.png"
// import EventImgDesktop4 from "assets/HomeV2/desktop/banners/event_banner_04.png"
// import EventImgDesktop5 from "assets/HomeV2/desktop/banners/event_banner_05.png"
// import EventImgDesktop6 from "assets/HomeV2/desktop/banners/event_banner_06.png"
// import EventImgDesktop7 from "assets/HomeV2/desktop/banners/event_banner_07.png"
// import EventImgDesktop9 from "assets/HomeV2/desktop/banners/event_banner_09.png"
/**Home Mobile Banners */
// import EventImgMobile1 from "assets/HomeV2/mobile/events/Event-Banner_01.jpg"
// import EventImgMobile2 from "assets/HomeV2/mobile/events/Event-Banner_02.jpg"
// import EventImgMobile3 from "assets/HomeV2/mobile/events/Event-Banner_03.jpg"
// import EventImgMobile4 from "assets/HomeV2/mobile/events/Event-Banner_04.jpg"
// import EventImgMobile5 from "assets/HomeV2/mobile/events/Event-Banner_05.jpg"
// import EventImgMobile6 from "assets/HomeV2/mobile/events/Event-Banner_06.jpg"
// import EventImgMobile7 from "assets/HomeV2/mobile/events/Event-Banner_07.jpg"
// import EventImgMobile9 from "assets/HomeV2/mobile/events/Event-Banner_09.png"

import {
  PROMO_CODE_3,
  PROMO_CODE_4,
  PROMO_CODE_5,
  // PROMO_CODE_6,
  PROMO_CODE_7,
  // PROMO_CODE_8,
  // PROMO_CODE_9,
  // PROMO_CODE_10,
  // PROMO_CODE_11,
  // PROMO_CODE_12,
  PROMO_CODE_13,
  PROMO_CODE_14,
  PROMO_CODE_15,
  PROMO_CODE_16,
  PROMO_CODE_17,
  PROMO_CODE_18,
  PROMO_CODE_19,
  PROMO_CODE_20,
  PROMO_CODE_21,
  PROMO_CODE_22,
  PROMO_CODE_23,
  PROMO_CODE_24,
} from "screens/Promotions/constant"

const CDN_URL = process.env.REACT_APP_IMG_URL

const MultiBetBannerPath = "/assets/Promotions/Banner/promobanner-multi-bet.webp"
const RewardBannerPath = "/assets/Promotions/Banner/promobanner-reward-levelup.webp"
const CashOutBannerPath = "/assets/Promotions/Banner/promobanner-cashout.webp"
const SportsBonusBannerPath =
  "/assets/Promotions/Banner/promobanner-sports-bonus.webp"
const NewSportsBannerPath = "/assets/Promotions/Banner/newsports.webp"
const EvolutionBannerPath = "/assets/Promotions/Banner/promobanner-evolution.webp"
const SlotsBannerPath = "/assets/Promotions/Banner/slotbang.webp"
const CasinoDepositBannerPath = "/assets/Promotions/Banner/icon-casino-big.webp"
const Bet365DepositBannerPath = "/assets/Promotions/Banner/icon-bet365-big.webp"
const EvoBetWinBannerPath = "/assets/Promotions/Banner/betnwinicon-big.webp"
const ChickenCokeBannerPath = "/assets/Promotions/Banner/newsignupicon-big.webp"
const UefaBannerPath = "/assets/Promotions/Banner/uefa2-big.webp"
const MilageBannerPath = "/assets/Promotions/Banner/milageaccum-big.webp"
const ComboBannerPath = "/assets/Promotions/Banner/combo-bonus-big.webp"
const FreeGameBannerPath = "/assets/Promotions/Banner/free-game-big.webp"
const NewMembersBannerPath = "/assets/Promotions/Banner/new-members-big.webp"

const MultiBetIconPath = "/assets/Promotions/Icons/promotitle-multi-bet.webp"
const RewardIconPath = "/assets/Promotions/Icons/promotitle-reward-levelup.webp"
const CashOutIconPath = "/assets/Promotions/Icons/promotitle-cashout.webp"
const SportsBonusIconPath = "/assets/Promotions/Icons/sports-bonus.webp"
const NewSportsIconPath = "/assets/Promotions/Icons/newsports.webp"
const EvolutionIconPath = "/assets/Promotions/Icons/promotitle-evolution.webp"
const SlotsIconPath = "/assets/Promotions/Icons/slotbang-icon.webp"
const CasinoDepositIconPath = "/assets/Promotions/Icons/icon-casino-small.webp"
const Bet365DepositIconPath = "/assets/Promotions/Icons/icon-bet365-small.webp"
const EvoBetWinIconPath = "/assets/Promotions/Icons/betnwinicon-small.webp"
const ChickenCokeIconPath = "/assets/Promotions/Icons/newsignupicon-small.webp"
const UefaIconPath = "/assets/Promotions/Icons/uefa2-small.webp"
const MilageIconPath = "/assets/Promotions/Icons/milageaccum-small.webp"
const ComboIconPath = "/assets/Promotions/Icons/combo-bonus-small.webp"
const FreeGameIconPath = "/assets/Promotions/Icons/free-game-small.webp"
const NewMembersIconPath = "/assets/Promotions/Icons/new-members-small.webp"

const Promo3ContentMobilePath = "/assets/Promotions/Contents/Promo3/375.webp"
const Promo3ContentTabletPath = "/assets/Promotions/Contents/Promo3/538.webp"
const Promo3ContentDesktopPath = "/assets/Promotions/Contents/Promo3/976.webp"

const Promo4ContentMobilePath = "/assets/Promotions/Contents/Promo4/375.webp"
const Promo4ContentTabletPath = "/assets/Promotions/Contents/Promo4/538.webp"
const Promo4ContentDesktopPath = "/assets/Promotions/Contents/Promo4/976.webp"

const Promo5ContentMobilePath = "/assets/Promotions/Contents/Promo5/375.webp"
const Promo5ContentTabletPath = "/assets/Promotions/Contents/Promo5/538.webp"
const Promo5ContentDesktopPath = "/assets/Promotions/Contents/Promo5/976.webp"

const Promo13ContentMobilePath = "/assets/Promotions/Contents/Promo13/375.webp"
const Promo13ContentTabletPath = "/assets/Promotions/Contents/Promo13/538.webp"
const Promo13ContentDesktopPath = "/assets/Promotions/Contents/Promo13/976.webp"

const Promo7ContentMobilePath = "/assets/Promotions/Contents/Promo7/375.webp"
const Promo7ContentTabletPath = "/assets/Promotions/Contents/Promo7/538.webp"
const Promo7ContentDesktopPath = "/assets/Promotions/Contents/Promo7/976.webp"

const Promo14ContentMobilePath = "/assets/Promotions/Contents/Promo14/375.webp"
const Promo14ContentTabletPath = "/assets/Promotions/Contents/Promo14/538.webp"
const Promo14ContentDesktopPath = "/assets/Promotions/Contents/Promo14/976.webp"

const Promo15ContentMobilePath = "/assets/Promotions/Contents/Promo15/375.webp"
const Promo15ContentTabletPath = "/assets/Promotions/Contents/Promo15/538.webp"
const Promo15ContentDesktopPath = "/assets/Promotions/Contents/Promo15/976.webp"

const Promo16ContentMobilePath = "/assets/Promotions/Contents/Promo16/375.webp"
const Promo16ContentTabletPath = "/assets/Promotions/Contents/Promo16/538.webp"
const Promo16ContentDesktopPath = "/assets/Promotions/Contents/Promo16/976.webp"

const Promo17ContentMobilePath = "/assets/Promotions/Contents/Promo17/375.webp"
const Promo17ContentTabletPath = "/assets/Promotions/Contents/Promo17/538.webp"
const Promo17ContentDesktopPath = "/assets/Promotions/Contents/Promo17/976.webp"

const Promo18ContentMobilePath = "/assets/Promotions/Contents/Promo18/375.webp"
const Promo18ContentTabletPath = "/assets/Promotions/Contents/Promo18/538.webp"
const Promo18ContentDesktopPath = "/assets/Promotions/Contents/Promo18/976.webp"

const Promo19ContentMobilePath = "/assets/Promotions/Contents/Promo19/375.webp"
const Promo19ContentTabletPath = "/assets/Promotions/Contents/Promo19/538.webp"
const Promo19ContentDesktopPath = "/assets/Promotions/Contents/Promo19/976.webp"

const Promo20ContentMobilePath = "/assets/Promotions/Contents/Promo20/375.webp"
const Promo20ContentTabletPath = "/assets/Promotions/Contents/Promo20/538.webp"
const Promo20ContentDesktopPath = "/assets/Promotions/Contents/Promo20/976.webp"

const Promo21ContentMobilePath = "/assets/Promotions/Contents/Promo21/375.webp"
const Promo21ContentTabletPath = "/assets/Promotions/Contents/Promo21/538.webp"
const Promo21ContentDesktopPath = "/assets/Promotions/Contents/Promo21/976.webp"

const Promo22ContentMobilePath = "/assets/Promotions/Contents/Promo22/375.webp"
const Promo22ContentTabletPath = "/assets/Promotions/Contents/Promo22/538.webp"
const Promo22ContentDesktopPath = "/assets/Promotions/Contents/Promo22/976.webp"

const Promo23ContentMobilePath = "/assets/Promotions/Contents/Promo23/375.webp"
const Promo23ContentTabletPath = "/assets/Promotions/Contents/Promo23/538.webp"
const Promo23ContentDesktopPath = "/assets/Promotions/Contents/Promo23/976.webp"

const Promo24ContentMobilePath = "/assets/Promotions/Contents/Promo24/375.webp"
const Promo24ContentTabletPath = "/assets/Promotions/Contents/Promo24/538.webp"
const Promo24ContentDesktopPath = "/assets/Promotions/Contents/Promo24/976.webp"

const EventImgDesktopPath5 = "/assets/HomeV2/desktop/banners/event_banner_05.png"
const EventImgDesktopPath6 = "/assets/HomeV2/desktop/banners/event_banner_06.png"
const EventImgDesktopPath7 = "/assets/HomeV2/desktop/banners/event_banner_07.png"
const EventImgDesktopPath9 = "/assets/HomeV2/desktop/banners/event_banner_09.png"
const EventImgDesktopPath10 = "/assets/HomeV2/desktop/banners/event_banner_10.png"
const EventImgDesktopPath11 = "/assets/HomeV2/desktop/banners/event_banner_11.png"

const EventImgMobilePath5 = "/assets/HomeV2/mobile/events/Event-Banner_05.jpg"
const EventImgMobilePath6 = "/assets/HomeV2/mobile/events/Event-Banner_06.jpg"
const EventImgMobilePath7 = "/assets/HomeV2/mobile/events/Event-Banner_07.jpg"
const EventImgMobilePath9 = "/assets/HomeV2/mobile/events/Event-Banner_09.png"
const EventImgMobilePath10 = "/assets/HomeV2/mobile/events/Event-Banner_10.png"
const EventImgMobilePath11 = "/assets/HomeV2/mobile/events/Event-Banner_11.jpg"

const GiftBgPath = "/assets/Promotions/Background/gift.webp"
const GiftBodyPath = "/assets/Promotions/Background/gift-body.webp"
const GiftTopPath = "/assets/Promotions/Background/gift-top.webp"

const MultiBetBanner = `${CDN_URL}${MultiBetBannerPath}`
const RewardBanner = `${CDN_URL}${RewardBannerPath}`
const CashOutBanner = `${CDN_URL}${CashOutBannerPath}`
const SportsBonusBanner = `${CDN_URL}${SportsBonusBannerPath}`
const NewSportBanner = `${CDN_URL}${NewSportsBannerPath}`
const EvolutionBanner = `${CDN_URL}${EvolutionBannerPath}`
const SlotsBanner = `${CDN_URL}${SlotsBannerPath}`
const CasinoDepositBanner = `${CDN_URL}${CasinoDepositBannerPath}`
const Bet365DepositBanner = `${CDN_URL}${Bet365DepositBannerPath}`
const EvoBetWinBanner = `${CDN_URL}${EvoBetWinBannerPath}`
const ChickenCokeBanner = `${CDN_URL}${ChickenCokeBannerPath}`
const UefaBanner = `${CDN_URL}${UefaBannerPath}`
const MilageBanner = `${CDN_URL}${MilageBannerPath}`
const ComboBanner = `${CDN_URL}${ComboBannerPath}`
const FreeGameBanner = `${CDN_URL}${FreeGameBannerPath}`
const NewMembersBanner = `${CDN_URL}${NewMembersBannerPath}`

const MultiBetIcon = `${CDN_URL}${MultiBetIconPath}`
const RewardIcon = `${CDN_URL}${RewardIconPath}`
const CashOutIcon = `${CDN_URL}${CashOutIconPath}`
const SportsBonusIcon = `${CDN_URL}${SportsBonusIconPath}`
const NewSportIcon = `${CDN_URL}${NewSportsIconPath}`
const EvolutionIcon = `${CDN_URL}${EvolutionIconPath}`
const SlotsIcon = `${CDN_URL}${SlotsIconPath}`
const CasinoDepositIcon = `${CDN_URL}${CasinoDepositIconPath}`
const Bet365DepositIcon = `${CDN_URL}${Bet365DepositIconPath}`
const EvoBetWinIcon = `${CDN_URL}${EvoBetWinIconPath}`
const ChickenCokeIcon = `${CDN_URL}${ChickenCokeIconPath}`
const UefaIcon = `${CDN_URL}${UefaIconPath}`
const MilageIcon = `${CDN_URL}${MilageIconPath}`
const ComboIcon = `${CDN_URL}${ComboIconPath}`
const FreeGameIcon = `${CDN_URL}${FreeGameIconPath}`
const NewMembersIcon = `${CDN_URL}${NewMembersIconPath}`

const Promo3ContentMobile = `${CDN_URL}${Promo3ContentMobilePath}`
const Promo3ContentTablet = `${CDN_URL}${Promo3ContentTabletPath}`
const Promo3ContentDesktop = `${CDN_URL}${Promo3ContentDesktopPath}`

const Promo4ContentMobile = `${CDN_URL}${Promo4ContentMobilePath}`
const Promo4ContentTablet = `${CDN_URL}${Promo4ContentTabletPath}`
const Promo4ContentDesktop = `${CDN_URL}${Promo4ContentDesktopPath}`

const Promo5ContentMobile = `${CDN_URL}${Promo5ContentMobilePath}`
const Promo5ContentTablet = `${CDN_URL}${Promo5ContentTabletPath}`
const Promo5ContentDesktop = `${CDN_URL}${Promo5ContentDesktopPath}`

const Promo7ContentMobile = `${CDN_URL}${Promo7ContentMobilePath}`
const Promo7ContentTablet = `${CDN_URL}${Promo7ContentTabletPath}`
const Promo7ContentDesktop = `${CDN_URL}${Promo7ContentDesktopPath}`

const Promo13ContentMobile = `${CDN_URL}${Promo13ContentMobilePath}`
const Promo13ContentTablet = `${CDN_URL}${Promo13ContentTabletPath}`
const Promo13ContentDesktop = `${CDN_URL}${Promo13ContentDesktopPath}`

const Promo14ContentMobile = `${CDN_URL}${Promo14ContentMobilePath}`
const Promo14ContentTablet = `${CDN_URL}${Promo14ContentTabletPath}`
const Promo14ContentDesktop = `${CDN_URL}${Promo14ContentDesktopPath}`

const Promo15ContentMobile = `${CDN_URL}${Promo15ContentMobilePath}`
const Promo15ContentTablet = `${CDN_URL}${Promo15ContentTabletPath}`
const Promo15ContentDesktop = `${CDN_URL}${Promo15ContentDesktopPath}`

const Promo16ContentMobile = `${CDN_URL}${Promo16ContentMobilePath}`
const Promo16ContentTablet = `${CDN_URL}${Promo16ContentTabletPath}`
const Promo16ContentDesktop = `${CDN_URL}${Promo16ContentDesktopPath}`

const Promo17ContentMobile = `${CDN_URL}${Promo17ContentMobilePath}`
const Promo17ContentTablet = `${CDN_URL}${Promo17ContentTabletPath}`
const Promo17ContentDesktop = `${CDN_URL}${Promo17ContentDesktopPath}`

const Promo18ContentMobile = `${CDN_URL}${Promo18ContentMobilePath}`
const Promo18ContentTablet = `${CDN_URL}${Promo18ContentTabletPath}`
const Promo18ContentDesktop = `${CDN_URL}${Promo18ContentDesktopPath}`

const Promo19ContentMobile = `${CDN_URL}${Promo19ContentMobilePath}`
const Promo19ContentTablet = `${CDN_URL}${Promo19ContentTabletPath}`
const Promo19ContentDesktop = `${CDN_URL}${Promo19ContentDesktopPath}`

const Promo20ContentMobile = `${CDN_URL}${Promo20ContentMobilePath}`
const Promo20ContentTablet = `${CDN_URL}${Promo20ContentTabletPath}`
const Promo20ContentDesktop = `${CDN_URL}${Promo20ContentDesktopPath}`

const Promo21ContentMobile = `${CDN_URL}${Promo21ContentMobilePath}`
const Promo21ContentTablet = `${CDN_URL}${Promo21ContentTabletPath}`
const Promo21ContentDesktop = `${CDN_URL}${Promo21ContentDesktopPath}`

const Promo22ContentMobile = `${CDN_URL}${Promo22ContentMobilePath}`
const Promo22ContentTablet = `${CDN_URL}${Promo22ContentTabletPath}`
const Promo22ContentDesktop = `${CDN_URL}${Promo22ContentDesktopPath}`

const Promo23ContentMobile = `${CDN_URL}${Promo23ContentMobilePath}`
const Promo23ContentTablet = `${CDN_URL}${Promo23ContentTabletPath}`
const Promo23ContentDesktop = `${CDN_URL}${Promo23ContentDesktopPath}`

const Promo24ContentMobile = `${CDN_URL}${Promo24ContentMobilePath}`
const Promo24ContentTablet = `${CDN_URL}${Promo24ContentTabletPath}`
const Promo24ContentDesktop = `${CDN_URL}${Promo24ContentDesktopPath}`

const EventImgDesktop5 = `${CDN_URL}${EventImgDesktopPath5}`
const EventImgDesktop6 = `${CDN_URL}${EventImgDesktopPath6}`
const EventImgDesktop7 = `${CDN_URL}${EventImgDesktopPath7}`
const EventImgDesktop9 = `${CDN_URL}${EventImgDesktopPath9}`
const EventImgDesktop10 = `${CDN_URL}${EventImgDesktopPath10}`
const EventImgDesktop11 = `${CDN_URL}${EventImgDesktopPath11}`

const EventImgMobile5 = `${CDN_URL}${EventImgMobilePath5}`
const EventImgMobile6 = `${CDN_URL}${EventImgMobilePath6}`
const EventImgMobile7 = `${CDN_URL}${EventImgMobilePath7}`
const EventImgMobile9 = `${CDN_URL}${EventImgMobilePath9}`
const EventImgMobile10 = `${CDN_URL}${EventImgMobilePath10}`
const EventImgMobile11 = `${CDN_URL}${EventImgMobilePath11}`

export const GiftBg = `${CDN_URL}${GiftBgPath}`
export const GiftBody = `${CDN_URL}${GiftBodyPath}`
export const GiftTop = `${CDN_URL}${GiftTopPath}`

// const SECOND_DEPOSIT_CONFIG = {
//   code: PROMO_CODE_12,
//   label: "무한 매충",
//   bannerImg: SecondDepositBanner,
//   icon: SecondDepositIcon,
//   background: "linear-gradient(0deg, #FFCD9A, #E88413)",
//   content: {
//     mobile: Promo12ContentMobile,
//     tablet: Promo12ContentTablet,
//     desktop: Promo12ContentDesktop,
//     homeDesktop: EventImgDesktop1,
//     homeMobile: EventImgMobile1,
//   },
// }

// const DEPOSIT_CONFIG = {
//   code: PROMO_CODE_1,
//   label: "신규 첫충 이벤트",
//   bannerImg: DepositBanner,
//   icon: DepositIcon,
//   background: "linear-gradient(0deg, #0082B4, #0BB3F4)",
//   content: {
//     mobile: Promo1ContentMobile,
//     tablet: Promo1ContentTablet,
//     desktop: Promo1ContentDesktop,
//   },
// }

// const FIRST_DEPOSIT_CONFIG = {
//   code: PROMO_CODE_2,
//   label: "첫충 이벤트",
//   bannerImg: FirstDepositBanner,
//   icon: FirstDepositIcon,
//   background: "linear-gradient(0deg, #6FE1EA, #00ACEE)",
//   content: {
//     mobile: Promo2ContentMobile,
//     tablet: Promo2ContentTablet,
//     desktop: Promo2ContentDesktop,
//     homeDesktop: EventImgDesktop2,
//     homeMobile: EventImgMobile2,
//   },
// }

const MULTI_BET_CONFIG = {
  code: PROMO_CODE_3,
  label: "한폴낙 이벤트",
  bannerImg: MultiBetBanner,
  icon: MultiBetIcon,
  background: "linear-gradient(0deg, #658AAA, #338061)",
  content: {
    mobile: Promo3ContentMobile,
    tablet: Promo3ContentTablet,
    desktop: Promo3ContentDesktop,
    homeDesktop: EventImgDesktop5,
    homeMobile: EventImgMobile5,
  },
}

const REWARD_CONFIG = {
  code: PROMO_CODE_4,
  label: "마일리지 / 레벨업 보너스",
  bannerImg: RewardBanner,
  icon: RewardIcon,
  background: "linear-gradient(0deg, #6FE1EA, #00ACEE)",
  content: {
    mobile: Promo4ContentMobile,
    tablet: Promo4ContentTablet,
    desktop: Promo4ContentDesktop,
    homeDesktop: EventImgDesktop6,
    homeMobile: EventImgMobile6,
  },
}

const CASH_OUT_CONFIG = {
  code: PROMO_CODE_5,
  label: "캐시아웃",
  bannerImg: CashOutBanner,
  icon: CashOutIcon,
  background: "linear-gradient(0deg, #FF9A5C, #FDD9B4)",
  content: {
    mobile: Promo5ContentMobile,
    tablet: Promo5ContentTablet,
    desktop: Promo5ContentDesktop,
    homeDesktop: EventImgDesktop7,
    homeMobile: EventImgMobile7,
  },
}

const EVOLUTION_CONFIG = {
  code: PROMO_CODE_7,
  label: "에불루션 카지노 루징금 이벤트",
  bannerImg: EvolutionBanner,
  icon: EvolutionIcon,
  background: "linear-gradient(0deg, #6FE1EA, #00ACEE)",
  content: {
    mobile: Promo7ContentMobile,
    tablet: Promo7ContentTablet,
    desktop: Promo7ContentDesktop,
  },
}

const SLOTS_CONFIG = {
  code: PROMO_CODE_15,
  label: "슬롯 팡팡 이벤트",
  bannerImg: SlotsBanner,
  icon: SlotsIcon,
  background: "linear-gradient(0deg, #015593, #2681BA, #00ACEE, #D9F9FF)",
  content: {
    mobile: Promo15ContentMobile,
    tablet: Promo15ContentTablet,
    desktop: Promo15ContentDesktop,
    homeDesktop: EventImgDesktop11,
    homeMobile: EventImgMobile11,
  },
}

const CASINO_DEPOSIT_CONFIG = {
  code: PROMO_CODE_16,
  label: "카지노 무한 충전 이벤트",
  bannerImg: CasinoDepositBanner,
  icon: CasinoDepositIcon,
  background: "linear-gradient(0deg, #E87604, #F8D854)",
  content: {
    mobile: Promo16ContentMobile,
    tablet: Promo16ContentTablet,
    desktop: Promo16ContentDesktop,
  },
}

const BET365_DEPOSIT_CONFIG = {
  code: PROMO_CODE_17,
  label: "벳 365 무한 충전 이벤트",
  bannerImg: Bet365DepositBanner,
  icon: Bet365DepositIcon,
  background: "linear-gradient(0deg, #005C80, #46A9B5, #ACF8C0)",
  content: {
    mobile: Promo17ContentMobile,
    tablet: Promo17ContentTablet,
    desktop: Promo17ContentDesktop,
  },
}

const EVO_BET_WIN_CONFIG = {
  code: PROMO_CODE_18,
  label: "에불루션 벳 & WIN 캐쉬 드롭 이벤트",
  bannerImg: EvoBetWinBanner,
  icon: EvoBetWinIcon,
  background: "linear-gradient(0deg, #4EB6F7, #2681BA, #015593)",
  content: {
    mobile: Promo18ContentMobile,
    tablet: Promo18ContentTablet,
    desktop: Promo18ContentDesktop,
  },
}

const CHICKEN_COKE_CONFIG = {
  code: PROMO_CODE_19,
  label: "신규 가입 기프티콘 지급 이벤트",
  bannerImg: ChickenCokeBanner,
  icon: ChickenCokeIcon,
  background: "linear-gradient(0deg, #E87604, #F8D854)",
  content: {
    mobile: Promo19ContentMobile,
    tablet: Promo19ContentTablet,
    desktop: Promo19ContentDesktop,
  },
}

const UEFA_CONFIG = {
  code: PROMO_CODE_20,
  label: "유럽 챔피언스 리그 결승 돌발 이벤트",
  bannerImg: UefaBanner,
  icon: UefaIcon,
  background: "linear-gradient(0deg, #23A741, #ACF8C0)",
  content: {
    mobile: Promo20ContentMobile,
    tablet: Promo20ContentTablet,
    desktop: Promo20ContentDesktop,
  },
}

const MILAGE_CONFIG = {
  code: PROMO_CODE_21,
  label: "마일리지 적립",
  bannerImg: MilageBanner,
  icon: MilageIcon,
  background: "linear-gradient(0deg, #00ACEE, #0BB3F4)",
  content: {
    mobile: Promo21ContentMobile,
    tablet: Promo21ContentTablet,
    desktop: Promo21ContentDesktop,
  },
}

const COMBO_BONUS_CONFIG = {
  code: PROMO_CODE_22,
  label: "멀티 베팅 보너스",
  bannerImg: ComboBanner,
  icon: ComboIcon,
  background: "linear-gradient(0deg, #451AA6, #1E54B0)",
  content: {
    mobile: Promo22ContentMobile,
    tablet: Promo22ContentTablet,
    desktop: Promo22ContentDesktop,
  },
}

const FREE_BET_CONFIG = {
  code: PROMO_CODE_23,
  label: "무료 체험 게임 이벤트",
  bannerImg: FreeGameBanner,
  icon: FreeGameIcon,
  background: "linear-gradient(0deg, #992B02, #B74A06, #DB933E)",
  content: {
    mobile: Promo23ContentMobile,
    tablet: Promo23ContentTablet,
    desktop: Promo23ContentDesktop,
  },
}

const NEW_MEMBERS_CONFIG = {
  code: PROMO_CODE_24,
  label: "스포츠 신규 회원 최대 100% 보상 이벤트",
  bannerImg: NewMembersBanner,
  icon: NewMembersIcon,
  background: "linear-gradient(0deg, #035D94, #0581BD, #022A5C)",
  content: {
    mobile: Promo24ContentMobile,
    tablet: Promo24ContentTablet,
    desktop: Promo24ContentDesktop,
  },
}

// const REVIEW_EVENT_CONFIG = {
//   code: PROMO_CODE_6,
//   label: "후기 이벤트",
//   bannerImg: ReviewEvent,
//   icon: ReviewEventIcon,
//   background: "linear-gradient(0deg, #6FE1EA, #00ACEE)",
//   content: {
//     mobile: Promo6ContentMobile,
//     tablet: Promo6ContentTablet,
//     desktop: Promo6ContentDesktop,
//   },
// }

// const SPORTS_CONFIG = {
//   code: PROMO_CODE_8,
//   label: "스포츠 멀티 베팅 루징 이벤트 ",
//   bannerImg: SportsIcon,
//   icon: SportsIcon,
//   background: "linear-gradient(0deg, #658AAA, #338061)",
//   content: {
//     mobile: Promo8ContentMobile,
//     tablet: Promo8ContentTablet,
//     desktop: Promo8ContentDesktop,
//   },
// }

// const SPORTS_EVENT_CONFIG = {
//   code: PROMO_CODE_9,
//   label: "369 단폴 연승 이벤트",
//   bannerImg: SportsEventIcon,
//   icon: SportsEventIcon,
//   background: "linear-gradient(0deg, #A8E5FF, #6FD4FF)",
//   content: {
//     mobile: Promo9ContentMobile,
//     tablet: Promo9ContentTablet,
//     desktop: Promo9ContentDesktop,
//     homeDesktop: EventImgDesktop4,
//     homeMobile: EventImgMobile4,
//   },
// }

// const REFER_FRIEND_CONFIG = {
//   code: PROMO_CODE_10,
//   label: "친구 추천 이벤트",
//   bannerImg: ReferFriendIcon,
//   icon: ReferFriendIcon,
//   background: "linear-gradient(0deg, #04ABED, #693ED8)",
//   content: {
//     mobile: Promo10ContentMobile,
//     tablet: Promo10ContentTablet,
//     desktop: Promo10ContentDesktop,
//   },
// }

// const PAY_BACK_CONFIG = {
//   code: PROMO_CODE_11,
//   label: "페이백(PAY BACK) 이벤트",
//   bannerImg: PayBackIcon,
//   icon: PayBackIcon,
//   background: "linear-gradient(0deg, #3372FF, #75FAA8)",
//   content: {
//     mobile: Promo11ContentMobile,
//     tablet: Promo11ContentTablet,
//     desktop: Promo11ContentDesktop,
//     homeDesktop: EventImgDesktop3,
//     homeMobile: EventImgMobile3,
//   },
// }

const SPORTS_BONUS_CONFIG = {
  code: PROMO_CODE_13,
  label: "스포츠 무한 충전 이벤트",
  bannerImg: SportsBonusBanner,
  icon: SportsBonusIcon,
  background: "linear-gradient(0deg, #0D9374, #00ACEE)",
  disabled: true,
  content: {
    mobile: Promo13ContentMobile,
    tablet: Promo13ContentTablet,
    desktop: Promo13ContentDesktop,
    homeDesktop: EventImgDesktop9,
    homeMobile: EventImgMobile9,
  },
}

export const NEW_SPORT_CONFIG = {
  code: PROMO_CODE_14,
  label: "스포츠 컨텐츠 업데이트 완료 안내",
  bannerImg: NewSportBanner,
  icon: NewSportIcon,
  background: "linear-gradient(0deg, #046CB9, #4E9CF7)",
  content: {
    mobile: Promo14ContentMobile,
    tablet: Promo14ContentTablet,
    desktop: Promo14ContentDesktop,
    // homeDesktop: EventImgDesktop10,
    // homeMobile: EventImgMobile10,
  },
}

export const PRVIVATE_PROMO_LIST = [
  // DEPOSIT_CONFIG,
  // SECOND_DEPOSIT_CONFIG,
  // FIRST_DEPOSIT_CONFIG,
  // PAY_BACK_CONFIG,
  // REFER_FRIEND_CONFIG,
  // SPORTS_EVENT_CONFIG,
  // SPORTS_CONFIG,
  // EVOLUTION_CONFIG,
  // SPORTS_BONUS_CONFIG,
  NEW_MEMBERS_CONFIG,
  FREE_BET_CONFIG,
  COMBO_BONUS_CONFIG,
  MILAGE_CONFIG,
  // UEFA_CONFIG,
  CHICKEN_COKE_CONFIG,
  CASINO_DEPOSIT_CONFIG,
  BET365_DEPOSIT_CONFIG,
  EVO_BET_WIN_CONFIG,
  SLOTS_CONFIG,
  NEW_SPORT_CONFIG,
  MULTI_BET_CONFIG,
  // REWARD_CONFIG,
  CASH_OUT_CONFIG,
  // REVIEW_EVENT_CONFIG,
]

export const PUBLIC_PROMO_LIST = [
  // DEPOSIT_CONFIG,
  // SECOND_DEPOSIT_CONFIG,
  // FIRST_DEPOSIT_CONFIG,
  // PAY_BACK_CONFIG,
  // REFER_FRIEND_CONFIG,
  // SPORTS_EVENT_CONFIG,
  // SPORTS_CONFIG,
  // EVOLUTION_CONFIG,
  // SPORTS_BONUS_CONFIG,
  NEW_MEMBERS_CONFIG,
  FREE_BET_CONFIG,
  COMBO_BONUS_CONFIG,
  MILAGE_CONFIG,
  // UEFA_CONFIG,
  CHICKEN_COKE_CONFIG,
  CASINO_DEPOSIT_CONFIG,
  EVO_BET_WIN_CONFIG,
  SLOTS_CONFIG,
  NEW_SPORT_CONFIG,
  MULTI_BET_CONFIG,
  // REWARD_CONFIG,
  CASH_OUT_CONFIG,
  // REVIEW_EVENT_CONFIG,
]

export const HOME_PROMO_LIST = [
  // DEPOSIT_CONFIG,
  // SECOND_DEPOSIT_CONFIG,
  // FIRST_DEPOSIT_CONFIG,
  // PAY_BACK_CONFIG,
  // REFER_FRIEND_CONFIG,
  // SPORTS_EVENT_CONFIG,
  // SPORTS_CONFIG,
  // EVOLUTION_CONFIG,
  // SPORTS_BONUS_CONFIG,
  // NEW_SPORT_CONFIG,
  SLOTS_CONFIG,
  MULTI_BET_CONFIG,
  // REWARD_CONFIG,
  CASH_OUT_CONFIG,
  // REVIEW_EVENT_CONFIG,
]

export const EZ_PRIVATE_PROMO_LIST = [
  // DEPOSIT_CONFIG,
  // SECOND_DEPOSIT_CONFIG,
  // FIRST_DEPOSIT_CONFIG,
  // PAY_BACK_CONFIG,
  // REFER_FRIEND_CONFIG,
  // SPORTS_EVENT_CONFIG,
  // SPORTS_CONFIG,
  // EVOLUTION_CONFIG,
  // SPORTS_BONUS_CONFIG,
  // NEW_SPORT_CONFIG,
  // COMBO_BONUS_CONFIG,
  FREE_BET_CONFIG,
  MILAGE_CONFIG,
  // UEFA_CONFIG,
  CHICKEN_COKE_CONFIG,
  CASINO_DEPOSIT_CONFIG,
  BET365_DEPOSIT_CONFIG,
  EVO_BET_WIN_CONFIG,
  SLOTS_CONFIG,
  MULTI_BET_CONFIG,
  // REWARD_CONFIG,
  // CASH_OUT_CONFIG,
  // REVIEW_EVENT_CONFIG,
]

export const EZ_PUBLIC_PROMO_LIST = [
  // DEPOSIT_CONFIG,
  // SECOND_DEPOSIT_CONFIG,
  // FIRST_DEPOSIT_CONFIG,
  // PAY_BACK_CONFIG,
  // REFER_FRIEND_CONFIG,
  // SPORTS_EVENT_CONFIG,
  // SPORTS_CONFIG,
  // EVOLUTION_CONFIG,
  // SPORTS_BONUS_CONFIG,
  // NEW_SPORT_CONFIG,
  // COMBO_BONUS_CONFIG,
  FREE_BET_CONFIG,
  MILAGE_CONFIG,
  // UEFA_CONFIG,
  CHICKEN_COKE_CONFIG,
  CASINO_DEPOSIT_CONFIG,
  EVO_BET_WIN_CONFIG,
  SLOTS_CONFIG,
  MULTI_BET_CONFIG,
  // REWARD_CONFIG,
  // CASH_OUT_CONFIG,
  // REVIEW_EVENT_CONFIG,
]

export const EZ_HOME_PROMO_LIST = [
  // DEPOSIT_CONFIG,
  // SECOND_DEPOSIT_CONFIG,
  // FIRST_DEPOSIT_CONFIG,
  // PAY_BACK_CONFIG,
  // REFER_FRIEND_CONFIG,
  // SPORTS_EVENT_CONFIG,
  // SPORTS_CONFIG,
  // EVOLUTION_CONFIG,
  // SPORTS_BONUS_CONFIG,
  // NEW_SPORT_CONFIG,
  SLOTS_CONFIG,
  MULTI_BET_CONFIG,
  // REWARD_CONFIG,
  // CASH_OUT_CONFIG,
  // REVIEW_EVENT_CONFIG,
]

export const PROMO_PARALAX_CONFIG = []

export const PROMO_LABEL_HEIGHT = 64

export const NAV_SLIDER_HEIGHT = 52

export const NAV_BAR_ENTRY_POINT = {
  XXXL: 240,
  XXL: 260,
  XL: 280,
  L: 240,
  M: 150,
  S: 340,
  XS: 290,
}

export const PROMO_LIST_MARGIN_TOP = {
  XXXL: 306,
  XXL: 298,
  XL: 294,
  L: 348,
  M: 326,
  S: 498,
  XS: 382,
  XXS: 362,
}
