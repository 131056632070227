import {
  EOS_ONE,
  EOS_TWO,
  EOS_THREE,
  EOS_FOUR,
  EOS_FIVE,
} from "screens/EOSPowerball/constant"

export const POWERBALL_ODD_EVEN_LOCKS = ["powerball_under", "powerball_over"]
export const POWERBALL_UNDER_OVER_LOCKS = ["powerball_odd", "powerball_even"]
export const NORMALBALL_ODD_EVEN_LOCKS = [
  "normal_ball_under",
  "normal_ball_over",
  "by_section_large_81_130",
  "by_section_medium_65_80",
  "by_section_small_15_64",
]
export const NORMALBALL_UNDER_OVER_LOCKS = [
  "normal_ball_odd",
  "normal_ball_even",
  "by_section_large_81_130",
  "by_section_medium_65_80",
  "by_section_small_15_64",
]
export const SECTION_LOCKS = [
  "normal_ball_odd",
  "normal_ball_even",
  "normal_ball_under",
  "normal_ball_over",
]

export const POWERBALL_ODD_EVEN_REMOVE = ["powerball_odd", "powerball_even"]
export const POWERBALL_UNDER_OVER_REMOVE = ["powerball_under", "powerball_over"]
export const NORMALBALL_ODD_EVEN_REMOVE = ["normal_ball_odd", "normal_ball_even"]
export const NORMALBALL_UNDER_OVER_REMOVE = ["normal_ball_under", "normal_ball_over"]
export const SECTION_REMOVE = [
  "by_section_large_81_130",
  "by_section_medium_65_80",
  "by_section_small_15_64",
]

export const ModalType = {
  ALERT: "alert",
  CONFIRM: "confirm",
  CALCULATOR: "calculator",
  INVALID: "invalid",
  IN_PROGRESS: "in_progress",
}

const EOS_ONE_MIN_CONFIG = {
  disableBet: {
    start: 10,
    end: 30,
  },
  machineRoll: {
    start: 60,
    end: 30,
  },
  results: {
    start: 48,
    end: 30,
  },
  doorClose: {
    start: 1,
    end: 60,
  },
  bettingTime: {
    start: 30,
    end: 10,
  },
}

const EOS_TWO_MIN_CONFIG = {
  disableBet: {
    start: 20,
    end: 90,
  },
  machineRoll: {
    start: 120,
    end: 90,
  },
  results: {
    start: 108,
    end: 90,
  },
  doorClose: {
    start: 1,
    end: 120,
  },
  bettingTime: {
    start: 90,
    end: 20,
  },
}

const EOS_THREE_MIN_CONFIG = {
  disableBet: {
    start: 20,
    end: 150,
  },
  machineRoll: {
    start: 180,
    end: 150,
  },
  results: {
    start: 168,
    end: 150,
  },
  doorClose: {
    start: 1,
    end: 180,
  },
  bettingTime: {
    start: 150,
    end: 20,
  },
}

const EOS_FOUR_MIN_CONFIG = {
  disableBet: {
    start: 20,
    end: 210,
  },
  machineRoll: {
    start: 240,
    end: 210,
  },
  results: {
    start: 228,
    end: 210,
  },
  doorClose: {
    start: 1,
    end: 240,
  },
  bettingTime: {
    start: 210,
    end: 20,
  },
}

const EOS_FIVE_MIN_CONFIG = {
  disableBet: {
    start: 20,
    end: 270,
  },
  machineRoll: {
    start: 300,
    end: 270,
  },
  results: {
    start: 288,
    end: 270,
  },
  doorClose: {
    start: 1,
    end: 300,
  },
  bettingTime: {
    start: 270,
    end: 20,
  },
}

export const TIMING_CONFIGS = {
  [EOS_ONE]: EOS_ONE_MIN_CONFIG,
  [EOS_TWO]: EOS_TWO_MIN_CONFIG,
  [EOS_THREE]: EOS_THREE_MIN_CONFIG,
  [EOS_FOUR]: EOS_FOUR_MIN_CONFIG,
  [EOS_FIVE]: EOS_FIVE_MIN_CONFIG,
}
