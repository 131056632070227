const API = {
  GET_OTP_CODE: `user/player/sms/get-otp-code`,
  CHECK_MOBILE: `user/player/sms/is-mobile-exist`,
  CHECK_REFERRER: `user/player/check-referrer`,
  CHECK_USERNAME: `user/player/check-username`,
  SEND_OTP: `user/player/sms/send-otp-code`,
  SUBMIT_FORM: `user/player`,
  CHECK_DELAY_USERNAME: `user/player/check-delay-username`,
  CHECK_DELAY_REFERRER: `user/player/check-delay-referrer`,
  CHECK_DELAY_MOBILE_EXIST: `user/player/sms/check-delay-is-mobile-exist`,
}

export default API
