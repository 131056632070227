import create from "zustand"
import Swiper from "swiper/types/swiper-class"
import {POPUP_BANNER_TYPES} from "./constants"

export interface iPopupBannerStore {
  sliderRef: Swiper
  isOpen?: boolean
  index: number
  type: string
  isOnHold: boolean
  setIsOnHold: (value: boolean) => void
  close: () => void
  setSliderRef: (value: Swiper) => void
  setIsOpen?: (value: boolean) => void
  setIndex: (value: number) => void
  setType: (value: string) => void
  setClose: (value: () => void) => void
  resetStore: () => void
}

const usePopupBannerStore = create<iPopupBannerStore>((set, get) => ({
  sliderRef: null,
  isOpen: false,
  index: 0,
  type: POPUP_BANNER_TYPES.NEWSPORTS,
  isOnHold: false,
  close: null,
  setIsOnHold: isOnHold => set({isOnHold}),
  setSliderRef: sliderRef => set({sliderRef}),
  setIsOpen: isOpen => set({isOpen}),
  setIndex: index => set({index}),
  setType: type => set({type}),
  setClose: close => set({close}),
  resetStore: () => {
    set({
      isOpen: false,
      index: 0,
      isOnHold: false,
      type: POPUP_BANNER_TYPES.NEWSPORTS,
    })
  },
}))

export default usePopupBannerStore
