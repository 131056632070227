import shallow from "zustand/shallow"
import {REGEX_EMAIL} from "screens/MyProfile/config"
import DefaultInputController from "screens/MyProfile/types/Input/DefaultInputController"
import useEmailInputStore from "screens/MyProfile/screens/Affiliate/store/useEmailInputStore"
import useAffiliateStore from "screens/MyProfile/store/useAffiliateStore"
import {REQUIRED_FIELD, INVALID_EMAIL} from "screens/MyProfile/constants"

const useEmailInputController = (): DefaultInputController => {
  const {
    setValue,
    setError,
    setIsDirty,
    setIsTouched,
    setIsFocused,
    setIsValidPattern,
    handleValidateEmail,
  } = useEmailInputStore(
    store => ({
      setValue: store.setValue,
      setError: store.setError,
      setIsDirty: store.setIsDirty,
      setIsTouched: store.setIsTouched,
      setIsFocused: store.setIsFocused,
      setIsValidPattern: store.setIsValidPattern,
      handleValidateEmail: store.handleValidate,
    }),
    shallow,
  )
  const setEmailReq = useAffiliateStore(store => store.setEmailReq, shallow)

  const handleValidate = (value: string) => {
    const validLength = value?.length >= 6
    const validFormat = REGEX_EMAIL.test(value)
    return validLength && validFormat
  }

  const handleReset = () => {
    setValue("")
    setIsValidPattern(false)
    setIsDirty(false)
    setError({
      status_code: 0,
      message: "",
    })
    setEmailReq(null)
  }

  const handleOnFocus = () => {
    setIsFocused(true)
    setIsTouched(true)
    handleReset()
  }

  const handleOnBlur = (value: string) => {
    setIsFocused(false)
    const isValid = handleValidate(value)
    if (value?.length === 0) {
      setIsValidPattern(false)
      setIsDirty(true)
      setError({
        status_code: -2,
        message: REQUIRED_FIELD,
      })
    } else if (!isValid) {
      setIsValidPattern(false)
      setIsDirty(true)
      setError({
        status_code: -1,
        message: INVALID_EMAIL,
      })
    } else {
      handleValidateEmail(value)
    }
  }

  const handleOnChange = (value: string) => {
    setIsDirty(true)
    setValue(value)
  }

  return {
    handleOnBlur,
    handleOnChange,
    handleOnFocus,
    handleValidate,
  }
}

export default useEmailInputController
