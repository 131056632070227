import {
  DragonBanner,
  DicesBanner,
  SoccerBanner,
  BaccaratBanner,
  BasketballBanner,
  NtryPowerballBanner,
  DragonBannerMobile,
  DicesBannerMobile,
  SoccerBannerMobile,
  BaccaratBannerMobile,
  BasketballBannerMobile,
  NtryPowerballBannerMobile,
  DragonTigerIconIdle,
  DragonTigerIconActive,
  DragonTigerIconPause,
  BasketballIconIdle,
  BasketballIconActive,
  BasketballIconPause,
  SoccerIconActive,
  SoccerIconIdle,
  SoccerIconPause,
  EntryPowerballIconIdle,
  EntryPowerballIconActive,
  BaccaratIconActive,
  BaccaratIconIdle,
  BaccaratIconPause,
  DiceIconActive,
  DiceIconIdle,
  DiceIconPause,
} from "screens/Home/FreeGames/assets"

export const handleFreeGamesSlideData = (
  isLarge,
  handleSelectGame,
  handleLaunchNTRY,
  hasToken,
) => {
  const publicGames = [
    // {
    //   code: "ntry-pb",
    //   logoIdle: EntryPowerballIconIdle,
    //   logoActive: EntryPowerballIconActive,
    //   banner: isLarge ? NtryPowerballBanner : NtryPowerballBannerMobile,
    //   onPlayClick: () => handleLaunchNTRY(),
    //   description:
    //     "회원분들을 위해 오직 EZBet 에서만 제공하는 \nNTRY 파워볼 무료 체험 공간을 소개해드립니다. \n\nNTRY 파워볼 무료 체험존 이용 가능 시간은 \n매일 startTime 시부터 다음날 endTime 시까지 이며 \n지급되는 무료 체험 금액을 이용하여 \n설정된 “목표 보유머니” 도달 시 \n당첨 상금을 실머니로 회원님의 보유머니에 \n지급해드리고 있습니다. \nEZBet 에서 제공하는 무료 체험과 함께 \n행운을 찾아보시기 바랍니다. \n\n“당첨금 지급은 목표 보유머니 도달시 지급됩니다.”",
    // },
    {
      code: "baccarat",
      logoIdle: BaccaratIconIdle,
      logoActive: BaccaratIconActive,
      logoPause: BaccaratIconPause,
      banner: isLarge ? BaccaratBanner : BaccaratBannerMobile,
      onPlayClick: () => handleSelectGame(4, "baccarat"),
      description:
        "EZbet 회원분들을 위해 준비 되었습니다.\n라이브 딜러와 함께 하는 CASINO 무료 체험 공간을\n소개해 드립니다.\n\n스카이파크 바카라 무료 체험존 이용 가능 시간은\n매일 startTime 시부터 endTime 시까지 이며\n최근 deposit_consecutive_days 일간 충전금액이 총 deposit_min_amount 이상인 회원분들께\nvirtual_balance 원의 무료 체험 금액이 지급됩니다.\n지급해 드리는 무료 체험 금액을 이용하여\n설정된 “목표 보유머니” 도달 시\n당첨 상금을 실머니로 회원님의 보유머니에\n“도달 즉시” 지급해 드리고 있습니다.\n오직 EZbet 에서만 제공하는 무료 체험과 함께\n행운을 찾아보시기 바랍니다.\n\n“당첨금 지급은 목표 보유머니 도달시 지급됩니다.”",
    },
    {
      code: "dragontiger",
      logoIdle: DragonTigerIconIdle,
      logoActive: DragonTigerIconActive,
      logoPause: DragonTigerIconPause,
      banner: isLarge ? DragonBanner : DragonBannerMobile,
      onPlayClick: () => handleSelectGame(5, "dragontiger"),
      description:
        "EZbet 회원분들을 위해 준비 되었습니다.\n라이브 딜러와 함께 하는 CASINO 무료 체험 공간을\n소개해 드립니다.\n\n스카이파크 드래곤 타이거 무료 체험존 이용 가능 시간은\n매일 startTime 시부터 endTime 시까지 이며\n최근 deposit_consecutive_days 일간 충전금액이 총 deposit_min_amount 이상인 회원분들께\nvirtual_balance 원의 무료 체험 금액이 지급됩니다.\n지급해 드리는 무료 체험 금액을 이용하여\n설정된 “목표 보유머니” 도달 시\n당첨 상금을 실머니로 회원님의 보유머니에\n“도달 즉시” 지급해 드리고 있습니다.\n오직 EZbet 에서만 제공하는 무료 체험과 함께\n행운을 찾아보시기 바랍니다.\n\n“당첨금 지급은 목표 보유머니 도달시 지급됩니다.”",
    },
    {
      code: "dice",
      logoIdle: DiceIconIdle,
      logoActive: DiceIconActive,
      logoPause: DiceIconPause,
      banner: isLarge ? DicesBanner : DicesBannerMobile,
      onPlayClick: () => handleSelectGame(6, "dice"),
      description:
        "EZbet 회원분들을 위해 준비 되었습니다.\n라이브 딜러와 함께 하는 CASINO 무료 체험 공간을\n소개해 드립니다.\n\n스카이파크 주사위 무료 체험존\n이용 가능 시간은 매일 startTime 시부터 endTime 시까지 이며\n최근 deposit_consecutive_days 일간 충전금액이 총 deposit_min_amount 이상인 회원분들께\nvirtual_balance 원의 무료 체험 금액이 지급됩니다.\n지급해 드리는 무료 체험 금액을 이용하여\n설정된 “목표 보유머니” 도달 시\n당첨 상금을 실머니로 회원님의 보유머니에\n“도달 즉시” 지급해 드리고 있습니다.\n오직 EZbet 에서만 제공하는 무료 체험과 함께\n행운을 찾아보시기 바랍니다\n\n“당첨금 지급은 목표 보유머니 도달시 지급됩니다.”",
    },
  ]
  const privateGames = [
    {
      code: "soccer",
      logoIdle: SoccerIconIdle,
      logoActive: SoccerIconActive,
      logoPause: SoccerIconPause,
      banner: isLarge ? SoccerBanner : SoccerBannerMobile,
      onPlayClick: () => handleSelectGame(2, "soccer"),
      description:
        "EZbet 회원분들을 위해 준비 되었습니다.\n가상게임 BET365 무료 체험 공간을\n소개해 드립니다.\n\nBET365 가상축구 무료 체험존 이용 가능 시간은\n매일 startTime 시부터 endTime 시까지 이며\n최근 deposit_consecutive_days 일간 충전금액이 총 deposit_min_amount 이상인 회원분들께\nvirtual_balance 원의 무료 체험 금액이 지급됩니다.\n지급해 드리는 무료 체험 금액을 이용하여\n설정된 “목표 보유머니” 도달 시\n당첨 상금을 실머니로 회원님의 보유머니에\n“도달 즉시” 지급해 드리고 있습니다.\n오직 EZbet 에서만 제공하는 무료 체험과 함께\n행운을 찾아보시기 바랍니다.\n\n“당첨금 지급은 목표 보유머니 도달시 지급됩니다.”",
    },
    {
      code: "basketball",
      logoIdle: BasketballIconIdle,
      logoActive: BasketballIconActive,
      logoPause: BasketballIconPause,
      banner: isLarge ? BasketballBanner : BasketballBannerMobile,
      onPlayClick: () => handleSelectGame(3, "basketball"),
      description:
        "EZbet 회원분들을 위해 준비 되었습니다.\n가상게임 BET365 무료 체험 공간을\n소개해 드립니다.\n\nBET365 가상농구 무료 체험존 이용 가능 시간은\n매일 startTime 시부터 endTime 시까지 이며\n최근 deposit_consecutive_days 일간 충전금액이 총 deposit_min_amount 이상인 회원분들께\nvirtual_balance 원의 무료 체험 금액이 지급됩니다.\n지급해 드리는 무료 체험 금액을 이용하여\n설정된 “목표 보유머니” 도달 시\n당첨 상금을 실머니로 회원님의 보유머니에\n“도달 즉시” 지급해 드리고 있습니다.\n오직 EZbet 에서만 제공하는 무료 체험과 함께\n행운을 찾아보시기 바랍니다.\n\n“당첨금 지급은 목표 보유머니 도달시 지급됩니다.”",
    },
  ]

  const gameSlidesData = hasToken ? [...publicGames, ...privateGames] : publicGames

  return gameSlidesData
}

export const SKYPARK_REWARD_MSG = `축하합니다.\n\nEZBET 무료 체험존\n목표 금액을 달성하여\n rewardAmount의 보너스 상금이\n회원님의 보유머니로 지급 되었습니다.\n\n보너스 금액은 롤링 100% 이후\n환전이 가능합니다.`
export const ALREADY_WIN_PRIZE_MSG = `EZBET 무료 체험존\n\n목표 금액을 달성하여\n당첨 상금이 지급 되었습니다.\n다른 무료 게임 및 실머니 게임을\n이용해 보실수 있습니다`

export const TIME_OUT_TEXT =
  "무료 체험존 이용 가능 시간이 종료 되었습니다. 익일 startTime 시에 재개 되어 집니다"

export const REWARD_MSG = `축하합니다.\n\nEZBET 무료 체험존\ngameTitle 목표 금액을 달성하여\n rewardAmount의 보너스 상금이\n회원님의 보유머니로 지급 되었습니다.\n\n보너스 금액은 롤링 100% 이후\n환전이 가능합니다.`

export type WinningModalMsg = {
  game_code: "ntry-pb" | "soccer" | "basketball"
  target_balance: number
  reward_amount: number
}

export const getGameTitle = (code: string) => {
  switch (code) {
    case "soccer":
      return "벳365 - 축구"
    case "basketball":
      return "벳365 - 농구"
    case "baccarat":
      return "스카이파크 - 바카라"
    case "dragontiger":
      return "스카이파크 - 드래곤타이거"
    case "dice":
      return "스카이파크 - 주사위"
    default:
      return ""
  }
}

export const BET365_GAME_CODES = ["soccer", "basketball"]
