import React from "react"

type NewIconType = {
  className?: string
}

const NewIcon = ({className}: NewIconType): JSX.Element => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="36.951"
      height="36.457"
      viewBox="0 0 36.951 36.457"
    >
      <g
        id="Group_25458"
        data-name="Group 25458"
        transform="translate(8268.424 32573.355) rotate(30)"
      >
        <path
          id="Path_62145"
          data-name="Path 62145"
          d="M13.772,0,9.516,8.623,0,10.006l6.886,6.712L5.26,26.2l8.511-4.475L22.283,26.2l-1.625-9.477,6.886-6.712L18.027,8.623Z"
          transform="translate(-23436 -24081.693)"
          fill="#fd8f1f"
        />
        <text
          id="NEW"
          transform="translate(-23422 -24066)"
          fill="#fff"
          font-size="6"
          font-family="AppleSDGothicNeo-ExtraBold, Apple SD Gothic Neo"
          font-weight="800"
          letter-spacing="-0.04em"
        >
          <tspan x="-6.261" y="0">
            NEW
          </tspan>
        </text>
      </g>
    </svg>
  )
}

export default NewIcon
