import React, {FC, memo} from "react"
import shallow from "zustand/shallow"
import format from "date-fns/format"
import s from "./popup.module.scss"
import usePopupBannerStore from "components/Banner/PopupV4/usePopupBannerStore"
import {changeTimeZone} from "screens/Casino/utils/helper"
import Layout from "components/Banner/PopupV4/Layout"
import ExitButton from "./icon/ExitButton"
import ArrowDown from "./icon/ArrowDown"

interface iPopup {
  className?: string
  selectedPage?: string
}

const Popup: FC<iPopup> = (): JSX.Element => {
  const [close, sliderRef] = usePopupBannerStore(
    store => [store.close, store.sliderRef],
    shallow,
  )

  const handleCloseForToday = () => {
    close()
    const koreanDateNow = changeTimeZone(new Date(), "Asia/Seoul")
    const dateNow = format(koreanDateNow, "yyyy-MM-dd")
    localStorage.setItem("home_banner_v4_ignore", dateNow)
  }

  return (
    <div className={s.container}>
      <Layout />
      <div className={s.actionBar}>
        {/* <div role="button" onClick={() => sliderRef?.slideNext()} className={s.icon}>
          <ArrowDown />
        </div> */}
        <div
          role="button"
          className={s.closeBtn}
          onClick={() => handleCloseForToday()}
        >
          <span>오늘하루 이 창을 열지 않음</span>
        </div>
        <div role="button" onClick={() => close()} className={s.icon}>
          <ExitButton />
        </div>
      </div>
    </div>
  )
}

export default memo(Popup)
