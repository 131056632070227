import React, {useMemo} from "react"
import {CategoryIconType} from "./CategoryIconType"

const FootballSVG = ({
  active,
  className,
  fill1,
  fill2,
}: CategoryIconType): JSX.Element => {
  const fill = useMemo(() => {
    return active ? fill1 : fill2
  }, [active])

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      className={className}
    >
      <g
        id="Group_26354"
        data-name="Group 26354"
        transform="translate(4756.158 1124)"
      >
        <g
          id="Group_13920"
          data-name="Group 13920"
          transform="translate(-4756.158 -1109.849)"
        >
          <g id="Group_13919" data-name="Group 13919" transform="translate(0)">
            <path
              id="Path_31059"
              data-name="Path 31059"
              d="M11.446,310.909l-9.465-8.966a.229.229,0,0,0-.38.11c-.08.372-.142.714-.19,1.02-.5,3.208-.137,6.246.891,7.388.895.993,3.006,1.214,4.185,1.258q.293.011.6.011a20.97,20.97,0,0,0,4.246-.46A.211.211,0,0,0,11.446,310.909Z"
              transform="translate(-1.187 -301.88)"
              fill={fill}
            />
          </g>
        </g>
        <g
          id="Group_13922"
          data-name="Group 13922"
          transform="translate(-4741.738 -1124)"
        >
          <g id="Group_13921" data-name="Group 13921" transform="translate(0 0)">
            <path
              id="Path_31060"
              data-name="Path 31060"
              d="M301.9,5.5c-.011-.992-.151-3.406-1.222-4.382-1.127-1.027-4.32-1.4-7.767-.9-.482.069-1.05.168-1.679.309a.211.211,0,0,0-.108.36l9.834,9.316a.23.23,0,0,0,.379-.1A18.516,18.516,0,0,0,301.9,5.5Z"
              transform="translate(-291.062 0)"
              fill={fill}
            />
          </g>
        </g>
        <g
          id="Group_13924"
          data-name="Group 13924"
          transform="translate(-4755.188 -1122.895)"
        >
          <g id="Group_13923" data-name="Group 13923">
            <path
              id="Path_31061"
              data-name="Path 31061"
              d="M43.91,34.383l-11.4-10.74a.236.236,0,0,0-.234-.051,18.535,18.535,0,0,0-6.978,4.04,17.183,17.183,0,0,0-4.435,7.084.206.206,0,0,0,.056.216L32.026,45.4a.236.236,0,0,0,.231.052,19.116,19.116,0,0,0,7.419-4.229A17.271,17.271,0,0,0,43.964,34.6.206.206,0,0,0,43.91,34.383ZM37.584,32.73a.775.775,0,0,1-1.055,0l-.433-.409a.236.236,0,0,0-.32,0l-.494.467a.206.206,0,0,0,0,.3l.417.394a.689.689,0,0,1,.018,1.007.775.775,0,0,1-1.055,0l-.433-.409a.236.236,0,0,0-.32,0l-.527.5a.206.206,0,0,0,0,.3l.4.377a.689.689,0,0,1,.018,1.007.775.775,0,0,1-1.055,0l-.575-.544-.654.618a.206.206,0,0,0,0,.3l.4.377a.689.689,0,0,1,.018,1.007.775.775,0,0,1-1.055,0l-.415-.392a.236.236,0,0,0-.32,0l-.459.434a.206.206,0,0,0,0,.3l.417.394a.689.689,0,0,1,.018,1.007.775.775,0,0,1-1.055,0l-.438-.414a.228.228,0,0,0-.309,0l-.021.02a.774.774,0,0,1-1.086-.034.689.689,0,0,1,.05-.975.206.206,0,0,0,0-.3l-.418-.395a.688.688,0,0,1-.04-.984.774.774,0,0,1,1.077-.025l.432.409a.236.236,0,0,0,.32,0l.459-.434a.206.206,0,0,0,0-.3l-.435-.411a.688.688,0,0,1-.04-.984.774.774,0,0,1,1.077-.025l.45.425a.236.236,0,0,0,.32,0l.494-.467a.206.206,0,0,0,0-.3l-.435-.411a.688.688,0,0,1-.04-.984.774.774,0,0,1,1.077-.025l.45.425a.236.236,0,0,0,.32,0l.527-.5a.206.206,0,0,0,0-.3L32.44,32.4a.688.688,0,0,1-.04-.984.774.774,0,0,1,1.077-.025l.433.409a.236.236,0,0,0,.32,0l.494-.467a.206.206,0,0,0,0-.3l-.418-.395a.688.688,0,0,1-.04-.984.774.774,0,0,1,1.077-.025l.433.409a.236.236,0,0,0,.32,0l.241-.228a.79.79,0,0,1,1.041-.037.677.677,0,0,1,.026,1.018l-.256.242a.206.206,0,0,0,0,.3l.417.394A.689.689,0,0,1,37.584,32.73Z"
              transform="translate(-20.857 -23.58)"
              fill={fill}
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default FootballSVG
