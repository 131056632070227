enum GameType {
  All = "full-game",
  TopRated = "top-rated",
  Baccarat = "baccarat",
  Roulette = "roulette",
  Poker = "poker",
  DragonTiger = "sic-bo-&-dragon-tiger",
  Blackjack = "blackjack",
  GameShows = "game-shows",
  FirstPerson = "first-person",
  RecentlyPlayed = "recently-played",
  Recommended = "recommended",
  Search = "search",
}

export default GameType
