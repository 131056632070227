import React, {CSSProperties, FC, memo, useContext} from "react"
import shallow from "zustand/shallow"
import Banner from "components/LoginButton/Banner"
import Content from "components/LoginButton/Content"
import {iconClose} from "screens/Home/MyPage/Screens/BetHistory/components/BetDetails/icons"
import useLoginModalStore from "components/LoginButton/hooks/useLoginModalStore"
import useRegisterModalStore from "components/RegisterButton/hooks/useRegisterModalStore"
import {LOGIN, FIND_ID, FORGOT_PASSWORD} from "components/LoginButton/contants"
import R19 from "layout/Footer/icons/R19"
import s from "components/LoginButton/Layout/layout.module.scss"
import {lazyWithRetry} from "utils/lazyWithRetry"
import {ThemeContext} from "context/ThemeContext"
import {getCustomColors} from "types/Theme/colors"
import {ThemeInterface} from "types/Theme"
const Thumbnail = lazyWithRetry(() => import("components/Thumbnail"))

interface CSSCustomProperties extends CSSProperties, ThemeInterface {}

const Layout: FC = () => {
  const {theme} = useContext(ThemeContext)
  const custom_colors: CSSCustomProperties = getCustomColors(theme)

  const {close, setSelectedTab, setFindIDSuccess, setFindPasswordSuccess} =
    useLoginModalStore(
      store => ({
        close: store.close,
        setSelectedTab: store.setSelectedTab,
        setFindIDSuccess: store.setFindIdSuccess,
        setFindPasswordSuccess: store.setFindPasswordSuccess,
      }),
      shallow,
    )

  const {setOpenRegister} = useRegisterModalStore(
    store => ({
      setOpenRegister: store.setIsOpen,
    }),
    shallow,
  )

  const handleOpenRegister = () => {
    setSelectedTab(LOGIN)
    close()
    setTimeout(() => setOpenRegister(true), 200)
  }

  const handleOpenLogin = () => {
    setFindIDSuccess(false)
    setFindPasswordSuccess(false)
    setSelectedTab(LOGIN)
  }

  const handleOpenFindId = () => {
    setSelectedTab(FIND_ID)
  }

  const handleOpenForgotPassword = () => {
    setSelectedTab(FORGOT_PASSWORD)
  }
  const isSafari = () => {
    const ua = navigator.userAgent.toLowerCase()
    return ua.indexOf("safari") !== -1 && ua.indexOf("chrome") === -1
  }

  const videoLink = isSafari()
    ? "/assets/login/ezbet_login_video.mp4"
    : "/assets/login/ezbet_login_video.webm"
  return (
    <div className={s.container} style={custom_colors}>
      <Banner />
      <div className={s.content}>
        <div className={s.closeContainer}>
          <R19 className={s.ageRestrict} />
          <button className={s.closeBtn} onClick={() => close()}>
            {iconClose}
          </button>
        </div>
        <Content
          className={s.colBody}
          handleOpenLogin={handleOpenLogin}
          handleOpenRegister={handleOpenRegister}
          handleOpenFindId={handleOpenFindId}
          handleOpenForgotPassword={handleOpenForgotPassword}
        />
      </div>
      <div className={s.animatedFooterPlaceholder} />
      <Thumbnail
        className={s.animatedFooter}
        placeholder={"/assets/login/splash.png"}
        imageUrl={"/assets/login/splash.png"}
        videoUrl={videoLink}
        hasVideo
        isLoop
      />
    </div>
  )
}

export default memo(Layout)
