import {useCallback} from "react"
import useNavBarController from "screens/NavHome/hooks/useNavBarController"
import navStore from "screens/NavHome/store/navStore"
import useBtiStore from "stores/useBtiStore"
import shallow from "zustand/shallow"
import {
  BTI_SPORTS,
  LIVE_CASINO,
  SLOTS,
  MINI_GAMES,
  BET365,
  SKY_PARK,
  PROMOTIONS,
  MY_PAGE,
  FREE_GAMES,
  RECHARGE,
} from "screens/NavHome/utils/config"

export type pathType =
  | "pre-match-sports"
  | "casino"
  | "live-sports"
  | "slots"
  | "casual-games"
  | "bet-365"
  | "skypark"
  | "gift"
  | "my-page"
  | "login"
  | "freegames"
  | "recharge"

export const useNavigate = () => {
  const {isDragging} = navStore(
    store => ({
      isDragging: store.isDragging,
    }),
    shallow,
  )
  const {handleClick, redirectToLogin} = useNavBarController()

  const handleNavigate = useCallback(
    (path: pathType) => {
      if (!isDragging) {
        switch (path) {
          case "pre-match-sports":
            handleClick(BTI_SPORTS)
            useBtiStore.getState().setIsLive(false)
            break
          case "casino":
            handleClick(LIVE_CASINO)
            break
          case "live-sports":
            handleClick(BTI_SPORTS)
            useBtiStore.getState().setIsLive(true)
            break
          case "slots":
            handleClick(SLOTS)
            break
          case "casual-games":
            handleClick(MINI_GAMES)
            break
          case "bet-365":
            handleClick(BET365)
            break
          case "skypark":
            handleClick(SKY_PARK)
            break
          case "gift": // this is promotion
            handleClick(PROMOTIONS)
            break
          case "my-page":
            handleClick(MY_PAGE)
            break
          case "freegames":
            handleClick(FREE_GAMES)
            break
          case "login":
            redirectToLogin()
            break
          case "recharge":
            handleClick(RECHARGE)
            break
        }
      }
    },
    [isDragging],
  )
  return {handleNavigate}
}
