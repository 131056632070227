import {useEffect} from "react"
import shallow from "zustand/shallow"
import usePusherStore, {CreatePusher} from "stores/usePusherStore"
import {useUserStore} from "stores/userStore"
import useGameActivityChannel from "provider/pusher/channels/useGameActivityChannel"
import usePlayerPrivateNotifChannel from "provider/pusher/channels/usePlayerPrivateNotifChannel"

const usePusherProvider = () => {
  const [authToken] = useUserStore(store => [store.authToken], shallow)

  const initPusher = () => {
    const pusherStore = usePusherStore.getState()
    pusherStore.resetStore()
    const pusher = CreatePusher()
    pusherStore.setPusher(pusher)
  }

  useEffect(() => {
    window.addEventListener(`beforeunload`, () => {
      const pusherStore = usePusherStore.getState()
      pusherStore.resetStore()
    })

    window.addEventListener(`unload`, () => {
      const pusherStore = usePusherStore.getState()
      pusherStore.resetStore()
    })

    window.addEventListener(`onunload`, () => {
      const pusherStore = usePusherStore.getState()
      pusherStore.resetStore()
    })
  }, [])

  useEffect(() => {
    initPusher()

    return () => {
      const pusherStore = usePusherStore.getState()
      pusherStore.resetStore()
    }
  }, [authToken])

  useGameActivityChannel()
  usePlayerPrivateNotifChannel()
}

export default usePusherProvider
