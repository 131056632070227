import create from "zustand"
import Pusher, {Channel} from "pusher-js"
import {useUserStore} from "stores/userStore"

interface iPusherStore {
  socketId: string
  pusher: Pusher | null
  privateNotifChannel: Channel | null
  publicGameNotifChannel: Channel | null
  setSocketId: (value: string) => void
  setPusher: (value: Pusher) => void
  setPrivateNotifChannel: (value: Channel) => void
  setPublicGameNotifChannel: (value: Channel) => void
  bindPrivateEvent: (value: string, callback: (arg: any) => void) => void
  unbindPrivateEvent: (value: string, callback?: (arg: any) => void) => void
  bindPublicGameEvent: (value: string, callback: (arg: any) => void) => void
  unbindPublicGameEvent: (value: string, callback?: (arg: any) => void) => void
  resetStore: () => void
}

const usePusherStore = create<iPusherStore>((set, get) => ({
  socketId: null,
  pusher: null,
  privateNotifChannel: null,
  publicGameNotifChannel: null,
  setSocketId: socketId => set({socketId}),
  setPusher: pusher => set({pusher}),
  setPrivateNotifChannel: privateNotifChannel => set({privateNotifChannel}),
  setPublicGameNotifChannel: publicGameNotifChannel => set({publicGameNotifChannel}),
  bindPrivateEvent: (eventName, callback) => {
    const privateChannel = get().privateNotifChannel
    if (privateChannel) {
      privateChannel.bind(eventName, callback)
    }
  },
  unbindPrivateEvent: (eventName, callback) => {
    const privateChannel = get().privateNotifChannel
    if (privateChannel) {
      privateChannel.unbind(eventName, callback)
    }
  },
  bindPublicGameEvent: (eventName, callback) => {
    const publicChannel = get().publicGameNotifChannel
    if (publicChannel) {
      publicChannel.bind(eventName, callback)
    }
  },
  unbindPublicGameEvent: (eventName, callback) => {
    const publicChannel = get().publicGameNotifChannel
    if (publicChannel) {
      publicChannel.unbind(eventName, callback)
    }
  },
  resetStore: () => {
    const pusher = get().pusher
    if (pusher) {
      pusher.unbind_all()
      pusher.disconnect()

      // console.log("disconnecting pusher")
    }

    // console.log("destroy pusher")
    set({
      socketId: null,
      pusher: null,
      privateNotifChannel: null,
      publicGameNotifChannel: null,
    })
  },
}))

export const CreatePusher = (): Pusher => {
  const authToken = useUserStore.getState().authToken
  const pusherKey = process.env.REACT_APP_PUSHER_KEY //decrypt(import.meta.env.VITE_PUSHER_APP_KEY as string);
  const cluster = process.env.REACT_APP_PUSHER_CLUSTER
  const authEndpoint = `${process.env.REACT_APP_BASE_API_URL}pusher/auth`
  const headers = {
    Authorization: `Bearer ${authToken}`,
  }

  const pusher = new Pusher(pusherKey, {
    // forceTLS: false,  for local debuging
    cluster: cluster,
    authEndpoint: authEndpoint,
    auth: {
      headers: headers,
    },
  })

  pusher.connection.bind("error", (e: any) => {
    // console.log("error: ", e)
    pusher.connect()
  })

  pusher.connection.bind("connected", (e: any) => {
    // console.log("connected: ", e)
    usePusherStore.getState().setSocketId(e?.socket_id)
  })

  pusher.connection.bind("disconnected", (e: any) => {
    // console.log("disconnected: ", e)
  })

  pusher.connection.bind("state_change", states => {
    // console.log(states)
  })

  return pusher
}

export default usePusherStore
