// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, {CSSProperties, FC, Suspense, useContext} from "react"

import Provider from "screens/Account/Register/Context/Provider"
import {lazyWithRetry} from "utils/lazyWithRetry"
import ModalView from "components/RegisterButton/Layout"
import {ThemeInterface} from "types/Theme"
import {ThemeContext} from "context/ThemeContext"
import {getCustomColors} from "types/Theme/colors"

const MobileView = lazyWithRetry(
  () => import(`screens/Account/Register/Context/View`),
)

interface iRegister {
  isModal?: boolean
}

interface CSSCustomProperties extends CSSProperties, ThemeInterface {}

const Register: FC<iRegister> = ({isModal}): JSX.Element => {
  const {theme} = useContext(ThemeContext)
  const custom_colors: CSSCustomProperties = getCustomColors(theme)

  return (
    <Provider>
      <div style={custom_colors}>
        {!isModal && (
          <Suspense fallback={<></>}>
            <MobileView />
          </Suspense>
        )}
        {isModal && (
          <Suspense fallback={<></>}>
            <ModalView />
          </Suspense>
        )}
      </div>
    </Provider>
  )
}

export default Register
