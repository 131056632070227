import {useEffect, useRef} from "react"
import shallow from "zustand/shallow"
import {EventHandlerPayload} from "@livechat/widget-react"
import {createWidget, WidgetInstance} from "@livechat/widget-core"
import useLiveChatStore from "./useLiveChatStore"

const useLiveChatProvider = () => {
  const chatWidget = useRef<WidgetInstance>(null)
  const [visibility, setVisibility, setChatWidget] = useLiveChatStore(
    store => [store.visibility, store.setVisibility, store.setChatWidget],
    shallow,
  )

  const handleNewEvent = (event: EventHandlerPayload<"onNewEvent">) => {
    // console.log("LiveChatWidget.onNewEvent", event)
  }

  const handleOnVisibilityChanged = (
    event: EventHandlerPayload<"onVisibilityChanged">,
  ) => {
    // console.log("LiveChatWidget.onVisibilityChangeds", event)
    if (event.visibility === "minimized") {
      setTimeout(() => {
        setVisibility("hidden")
        const chatWidget = useLiveChatStore.getState().chatWidget
        chatWidget.updateVisibility("hidden")
      }, 200)
    }
  }

  const handleReady = e => {
    const state = e.state.visibility
    if (state === "minimized") {
      const chatWidget = useLiveChatStore.getState().chatWidget
      chatWidget.updateVisibility("hidden")
    }
  }

  useEffect(() => {
    const chatWidget = useLiveChatStore.getState().chatWidget
    if (!chatWidget) return
    chatWidget.updateVisibility(visibility)
  }, [visibility])

  useEffect(() => {
    chatWidget.current = createWidget({
      license: `${process.env.REACT_APP_LIVE_CHAT_LICENSE}`,
      group: `${process.env.REACT_APP_LIVE_CHAT_GROUP}`,
      visibility: "hidden",
      chatBetweenGroups: false,
      onVisibilityChanged: handleOnVisibilityChanged,
      onNewEvent: handleNewEvent,
      onReady: handleReady,
    })
    chatWidget.current.init()
    chatWidget.current.updateVisibility("hidden")
  }, [])

  useEffect(() => {
    if (!chatWidget?.current) return
    const widget = chatWidget?.current
    setChatWidget(widget)
  }, [chatWidget?.current])
}

export default useLiveChatProvider
