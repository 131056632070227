import {MyPageTab, MyPageUser} from "types/MyPage"
import create from "zustand"
import {persist} from "zustand/middleware"

type MyPageStore = {
  user: MyPageUser | null
  activeTab: MyPageTab
  navigating: boolean

  setActiveTab: (tab) => void
  setChangeUser: (changeset: Partial<MyPageUser>) => void
  toggleNavigate: () => void
}

export const useMyPageStore = create<MyPageStore>((set, get) => ({
  user: null,
  activeTab: "none",
  navigating: false,

  setActiveTab: (tab) => {
    set({activeTab: tab})
  },
  toggleNavigate: () => {
    const {navigating} = get()

    set({navigating: !navigating})
  },
  setChangeUser: (changeset: Partial<MyPageUser>) => {
    const {user} = get()

    set({user: {...user, ...changeset}})
  },
}))
