export type ThemeAssets = {
  PATH: string
  TOP_BANNER_M: string
  TOP_BANNER: string
  BOT_BANNER_M: string
  BOT_BANNER: string
  CASINO: string
  EVENTS: string
  SPORTS: string
  SKYPARK: string
  SLOTS: string
}

const CDN_URL = process.env.REACT_APP_IMG_URL

// Theme 1
const V1 = `${CDN_URL}/assets/ui-theme/themeV1`
const V1_TOP_BANNER_M = `${V1}/top-banner-mobile/ez_top_banner_mobile.jpg`
const V1_TOP_BANNER = `${V1}/top-banner-desktop/ez_top_banner.jpg`
const V1_BOT_BANNER_M = `${V1}/bottom-banner-mobile/ez_bottom_banner_mobile.jpg`
const V1_BOT_BANNER = `${V1}/bottom-banner-desktop/ez_bottom_banner.jpg`
const V1_CASINO = `${V1}/carousel/casino-min.png`
const V1_EVENTS = `${V1}/carousel/events-min.png`
const V1_SPORTS = `${V1}/carousel/live_sports-min.png`
const V1_SKYPARK = `${V1}/carousel/sky_park-min.png`
const V1_SLOTS = `${V1}/carousel/slots-min.png`

// Theme 2
const V2 = `${CDN_URL}/assets/ui-theme/themeV2`
const V2_TOP_BANNER_M = `${V2}/top-banner-mobile/top_banner_mobile-min.jpg`
const V2_TOP_BANNER = `${V2}/top-banner-desktop/top_banner_desktop-min.jpg`
const V2_BOT_BANNER_M = `${V2}/bottom-banner-mobile/bottom_banner_mobile-min.jpg`
const V2_BOT_BANNER = `${V2}/bottom-banner-desktop/bottom_banner_desktop-min.jpg`
const V2_CASINO = `${V2}/carousel/v2_casino-min.png`
const V2_EVENTS = `${V2}/carousel/v2_events-min.png`
const V2_SPORTS = `${V2}/carousel/v2_sports-min.png`
const V2_SKYPARK = `${V2}/carousel/v2_skypark-min.png`
const V2_SLOTS = `${V2}/carousel/v2_slots-min.png`

// Theme 3
const V3 = `${CDN_URL}/assets/ui-theme/themeV3`
const V3_TOP_BANNER_M = `${V3}/top-banner-mobile/v3_top_banner_mobile-min.jpg`
const V3_TOP_BANNER = `${V3}/top-banner-desktop/v3_top_banner_desktop-min.jpg`
const V3_BOT_BANNER_M = `${V3}/bottom-banner-mobile/v3_bottom_banner_mobile-min.jpg`
const V3_BOT_BANNER = `${V3}/bottom-banner-desktop/v3_bottom_banner_desktop-min.jpg`
const V3_CASINO = `${V3}/carousel/v3_casino-min.png`
const V3_EVENTS = `${V3}/carousel/v3_events-min.png`
const V3_SPORTS = `${V3}/carousel/v3_sports-min.png`
const V3_SKYPARK = `${V3}/carousel/v3_sky_park-min.png`
const V3_SLOTS = `${V3}/carousel/v3_slots-min.png`

export const THEME1: ThemeAssets = {
  PATH: V1,
  TOP_BANNER_M: V1_TOP_BANNER_M,
  TOP_BANNER: V1_TOP_BANNER,
  BOT_BANNER_M: V1_BOT_BANNER_M,
  BOT_BANNER: V1_BOT_BANNER,
  CASINO: V1_CASINO,
  EVENTS: V1_EVENTS,
  SPORTS: V1_SPORTS,
  SKYPARK: V1_SKYPARK,
  SLOTS: V1_SLOTS,
}

export const THEME2: ThemeAssets = {
  PATH: V2,
  TOP_BANNER_M: V2_TOP_BANNER_M,
  TOP_BANNER: V2_TOP_BANNER,
  BOT_BANNER_M: V2_BOT_BANNER_M,
  BOT_BANNER: V2_BOT_BANNER,
  CASINO: V2_CASINO,
  EVENTS: V2_EVENTS,
  SPORTS: V2_SPORTS,
  SKYPARK: V2_SKYPARK,
  SLOTS: V2_SLOTS,
}

export const THEME3: ThemeAssets = {
  PATH: V3,
  TOP_BANNER_M: V3_TOP_BANNER_M,
  TOP_BANNER: V3_TOP_BANNER,
  BOT_BANNER_M: V3_BOT_BANNER_M,
  BOT_BANNER: V3_BOT_BANNER,
  CASINO: V3_CASINO,
  EVENTS: V3_EVENTS,
  SPORTS: V3_SPORTS,
  SKYPARK: V3_SKYPARK,
  SLOTS: V3_SLOTS,
}
