import React, {FC} from "react"
import {IconProps} from "screens/MyProfile/icons"

const R19: FC<IconProps> = ({fill = "#DE1515", fillTo = "#DE1515", ...props}) => (
  <svg
    id="Layer_2"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="35"
    viewBox="0 0 35 35"
    {...props}
  >
    <g id="Layer_1-2" data-name="Layer 1">
      <g id="Group_37474" data-name="Group 37474">
        <path
          id="Path_20487"
          data-name="Path 20487"
          fill={fill}
          className="cls-1"
          d="m35,17.5c0,9.66-7.84,17.5-17.5,17.5S0,27.16,0,17.5,7.84,0,17.5,0s17.5,7.84,17.5,17.5ZM17.5,3.65c-7.64,0-13.83,6.21-13.83,13.85,0,7.64,6.21,13.83,13.85,13.83,7.64,0,13.83-6.21,13.83-13.85,0-3.67-1.46-7.18-4.05-9.77-2.6-2.6-6.12-4.05-9.79-4.05Z"
        />
        <path
          id="Path_20499"
          data-name="Path 20499"
          fill={fill}
          className="cls-1"
          d="m7.14,10.78c1.36-.21,2.58-.93,3.44-2h1.84v12.69h-3.11v-8.29s0-1-1.29-1h-.88v-1.4Z"
        />
        <path
          id="Path_20488"
          data-name="Path 20488"
          fill={fill}
          className="cls-1"
          d="m22.81,13.73v1.55h2.34v2.32h1.53v-2.34h2.33v-1.53h-2.31v-2.35h-1.51v2.35h-2.38Z"
        />
        <path
          fill={fill}
          className="cls-1"
          d="m14,18.16h3.17c0,.59,0,.96.02,1.12.02.15.08.28.19.39.11.11.27.16.46.16.16,0,.3-.05.4-.15.1-.1.16-.23.18-.38.01-.16.02-.47.02-.95v-2.02c-.16.34-.39.59-.71.76-.31.17-.7.25-1.17.25-.58,0-1.09-.16-1.54-.47s-.73-.69-.85-1.13-.18-1.03-.18-1.78v-1.01c0-.89.04-1.55.11-1.98.07-.43.25-.83.54-1.2.29-.37.7-.65,1.21-.86.52-.2,1.12-.31,1.8-.31.85,0,1.54.12,2.08.35.54.24.96.57,1.26,1.02.3.44.47.91.52,1.39.05.49.07,1.43.07,2.82v1.76c0,1.51-.02,2.52-.06,3.03-.04.51-.22.99-.54,1.45-.32.46-.76.8-1.31,1.02-.55.23-1.18.34-1.9.34-.89,0-1.62-.14-2.19-.43-.57-.29-.97-.69-1.22-1.21-.25-.52-.37-1.18-.37-1.98Zm3.17-3.96c0,.53.07.86.21.99.14.13.28.19.43.19.22,0,.38-.08.48-.24.1-.16.15-.47.15-.94v-2.3c0-.56-.05-.93-.14-1.1s-.26-.26-.48-.26c-.14,0-.28.07-.42.2-.15.13-.22.49-.22,1.08v2.38Z"
        />
        <g>
          <path
            fill={fill}
            className="cls-1"
            d="m13.2,25.77h-.64l-.09.3h-.57l.68-1.82h.61l.68,1.82h-.59l-.09-.3Zm-.12-.39l-.2-.65-.2.65h.4Z"
          />
          <path
            fill={fill}
            className="cls-1"
            d="m14.06,24.25h.84c.16,0,.3.02.4.07s.19.11.25.19c.07.08.11.18.14.29.03.11.04.23.04.35,0,.19-.02.34-.07.45-.04.11-.11.2-.18.27s-.16.12-.25.14c-.12.03-.23.05-.33.05h-.84v-1.82Zm.56.41v1h.14c.12,0,.2-.01.25-.04.05-.03.09-.07.12-.14.03-.07.04-.17.04-.32,0-.19-.03-.32-.09-.4s-.17-.11-.31-.11h-.14Z"
          />
          <path
            fill={fill}
            className="cls-1"
            d="m17.22,24.25h.56v1.08c0,.11-.02.21-.05.3-.03.09-.09.18-.16.25s-.15.12-.23.15c-.11.04-.24.06-.39.06-.09,0-.19,0-.29-.02-.1-.01-.19-.04-.26-.07s-.13-.09-.19-.16c-.06-.07-.1-.14-.12-.21-.04-.12-.05-.22-.05-.31v-1.08h.56v1.11c0,.1.03.18.08.23s.13.08.23.08.17-.03.23-.08c.05-.06.08-.13.08-.23v-1.11Z"
          />
          <path
            fill={fill}
            className="cls-1"
            d="m18.15,24.25h.56v1.37h.88v.45h-1.44v-1.82Z"
          />
          <path
            fill={fill}
            className="cls-1"
            d="m19.59,24.25h1.71v.45h-.57v1.37h-.56v-1.37h-.57v-.45Z"
          />
          <path
            fill={fill}
            className="cls-1"
            d="m21.45,25.47l.54-.03c.01.09.04.15.07.2.06.07.14.11.25.11.08,0,.14-.02.19-.06.04-.04.07-.08.07-.13s-.02-.09-.06-.13-.14-.07-.29-.11c-.25-.06-.42-.13-.53-.22-.11-.09-.16-.21-.16-.35,0-.09.03-.18.08-.26.05-.08.14-.15.25-.2.11-.05.26-.07.45-.07.23,0,.41.04.53.13.12.09.19.22.22.41l-.53.03c-.01-.08-.04-.14-.09-.18s-.11-.06-.19-.06c-.07,0-.11.01-.15.04-.03.03-.05.06-.05.1,0,.03.01.05.04.08.03.02.09.05.19.07.25.05.42.11.53.16.11.05.18.12.23.2.05.08.07.17.07.27,0,.12-.03.22-.1.32s-.16.17-.27.22c-.12.05-.26.08-.44.08-.31,0-.52-.06-.64-.18s-.19-.27-.2-.46Z"
          />
        </g>
        <g>
          <path
            fill={fill}
            className="cls-1"
            d="m13.7,27.69c0-.3.08-.53.25-.69.17-.17.4-.25.69-.25s.54.08.7.24c.16.16.25.39.25.68,0,.21-.04.39-.11.52-.07.14-.17.24-.31.32-.13.08-.3.11-.5.11s-.37-.03-.51-.1c-.13-.06-.24-.17-.33-.31s-.13-.32-.13-.53Zm.56,0c0,.18.03.32.1.4.07.08.16.12.28.12s.21-.04.28-.12c.07-.08.1-.22.1-.42,0-.17-.03-.3-.1-.38-.07-.08-.16-.12-.28-.12s-.2.04-.27.12c-.07.08-.1.21-.1.4Z"
          />
          <path
            fill={fill}
            className="cls-1"
            d="m15.89,26.78h.52l.68,1.01v-1.01h.53v1.82h-.53l-.68-1v1h-.53v-1.82Z"
          />
          <path
            fill={fill}
            className="cls-1"
            d="m18.01,26.78h.56v1.37h.88v.45h-1.44v-1.82Z"
          />
          <path
            fill={fill}
            className="cls-1"
            d="m19.32,26.78h.62l.37.61.37-.61h.62l-.71,1.06v.76h-.56v-.76l-.71-1.06Z"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default R19
