import GameType from "screens/Casino/types/GameType"
import GameCategory from "screens/Casino/types/v2/GameCategory"
import GameFilter from "screens/Casino/types/GameFilter"
import format from "date-fns/format"
import {OperationHours} from "screens/Casino/types/CasinoInstance"
import {
  KOREAN_SPEAKING_SPEED_BACCARAT_1,
  KOREAN_SPEAKING_SPEED_BACCARAT_2,
  KOREAN_SPEED_BACCARAT_A,
  BACCARAT_SQUEEZE,
  BACCARAT_CONTROL_SQUEEZE,
  KOREAN_SPEAKING_SPEED_BACCARAT_1_LABEL,
  KOREAN_SPEAKING_SPEED_BACCARAT_2_LABEL,
  KOREAN_SPEED_BACCARAT_A_LABEL,
  BACCARAT_SQUEEZE_LABEL,
  BACCARAT_CONTROL_SQUEEZE_LABEL,
} from "screens/Casino/utils/Config"

// export const parseCategory = (
//   type: GameType,
//   categories: GameCategory[],
//   headers: GameFilter[],
// ): GameCategory => {
//   const dbData = categories?.find(item => item.name === type)
//   const configData = headers.find(item => item.value === type)
//   return {
//     ...dbData,
//     hangul_name: configData?.name,
//     name: type,
//   }
// }

export const parseCategory = (
  type: GameType,
  categories: GameCategory[],
  headers: GameFilter[],
): GameCategory => {
  const dbData = categories.find(item => item.name === type)
  const configData = headers.find(item => item.value === type)
  return {
    ...dbData,
    hangul_name: configData?.name,
    name: type,
  }
}

export const changeTimeZone = (date: Date, timeZone: string): Date => {
  if (typeof date === "string") {
    return new Date(
      new Date(date).toLocaleString("en-US", {
        timeZone,
      }),
    )
  }

  return new Date(
    date.toLocaleString("en-US", {
      timeZone,
    }),
  )
}

export const createUTCDate = (date: Date, time: string): Date => {
  if (!time) return new Date()

  const times = time.split(":")

  return new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      Number(times[0]),
      Number(times[1]),
      0,
      0,
    ),
  )
}

export const createUTCTime = (time: string): string => {
  const currentDate = new Date()
  const startDateTime = createUTCDate(currentDate, time)
  const koreanTime = changeTimeZone(startDateTime, "Asia/Seoul")
  return format(koreanTime, "HH:mm")
}

export const getCharCode = (index: number): string => {
  const maxLetter = 25

  if (index > maxLetter) {
    const current = index - maxLetter
    return `A${String.fromCharCode(current + 65)}`
  } else {
    return String.fromCharCode(index + 65)
  }
}

export const checkOperationHours = (operation: OperationHours): boolean => {
  if (!operation || operation.type !== "Bounded") return false

  const {startTime, endTime} = operation.value
  const currentDate = new Date()
  const koreanTime = changeTimeZone(currentDate, "Asia/Seoul")
  const startDateTime = createUTCDate(new Date(), startTime)
  const endDateTime = createUTCDate(new Date(), endTime)
  const koreanStart = changeTimeZone(startDateTime, "Asia/Seoul")
  const koreanEnd = changeTimeZone(endDateTime, "Asia/Seoul")

  if (koreanTime >= koreanStart && koreanTime <= koreanEnd) {
    return false
  } else {
    return true
  }
}

export const trimTableLabel = (code: string): string => {
  switch (code) {
    case KOREAN_SPEAKING_SPEED_BACCARAT_1:
      return KOREAN_SPEAKING_SPEED_BACCARAT_1_LABEL
    case KOREAN_SPEAKING_SPEED_BACCARAT_2:
      return KOREAN_SPEAKING_SPEED_BACCARAT_2_LABEL
    case KOREAN_SPEED_BACCARAT_A:
      return KOREAN_SPEED_BACCARAT_A_LABEL
    case BACCARAT_SQUEEZE:
      return BACCARAT_SQUEEZE_LABEL
    case BACCARAT_CONTROL_SQUEEZE:
      return BACCARAT_CONTROL_SQUEEZE_LABEL
    default:
      break
  }
}

export const iniKoreantMidnight = () => {
  const koreanDateNow = changeTimeZone(new Date(), "Asia/Seoul")
  const koreanDateMidngiht = changeTimeZone(new Date(), "Asia/Seoul")
  const time1 = koreanDateNow.getTime()
  const time2 = koreanDateMidngiht.setHours(24, 0, 0, 0)

  return (time2 - time1) / 1000
}

export const iniKoreantEndTime = (startTime: number, endTime: number) => {
  const koreanDateNow = changeTimeZone(new Date(startTime), "Asia/Seoul")
  const koreanDateMidngiht = changeTimeZone(new Date(endTime), "Asia/Seoul")
  const time1 = koreanDateNow.getTime()
  const time2 = koreanDateMidngiht.getTime()

  return (time2 - time1) / 1000
}

export const toKoreanTime = (time: number): Date => {
  return changeTimeZone(new Date(time), "Asia/Seoul")
}
