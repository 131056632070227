import {AxiosResponse} from "axios"
import request from "services/request"

const getThemeDetails = (): Promise<AxiosResponse> => {
  return request({
    url: `system-config`,
    method: "GET",
  })
}

export default {
  getThemeDetails,
}
