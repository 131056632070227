import useBreakpoint from "hooks/useBreakpoints"
import React from "react"

import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"

import s from "screens/Bet365/components/Skeleton/skeleton.module.scss"
import useWindowDimensions from "utils/hooks/useWindowDimensions"

export const NoRoundsLoader = (): JSX.Element => {
  return (
    <div className={s.noRounds}>
      <Skeleton className={s.skeleton} />
    </div>
  )
}

export const GamesTabLoader = (): JSX.Element => {
  return (
    <div className={s.gamesTab}>
      <Skeleton className={s.skeleton} />
      <Skeleton className={s.skeleton} />
      <Skeleton className={s.greyhoundsSkeleton} />
      <Skeleton className={s.horseSkeleton} />
    </div>
  )
}

export const LeagueTabLoader = (): JSX.Element => {
  return (
    <div className={s.legueTab}>
      <Skeleton className={s.skeleton} />
    </div>
  )
}

export const VideoplayerLoader = (): JSX.Element => {
  return (
    <div className={s.videoplayer}>
      <Skeleton className={s.skeleton} />
    </div>
  )
}

export const RoundsTabLoader = (): JSX.Element => {
  const min1024 = useBreakpoint(`(min-width: 1024px)`)

  return (
    <div className={s.roundsTab}>
      {!min1024 && (
        <div className={s.resultsContainer}>
          <Skeleton className={s.resultsButton} />
        </div>
      )}
      <div className={s.roundsContainer}>
        <div className={s.roundContainer}>
          <Skeleton className={s.roundsButton} />
        </div>
        <div className={s.roundContainer}>
          <Skeleton className={s.roundsButton} />
        </div>
        <div className={s.roundContainer}>
          <Skeleton className={s.roundsButton} />
        </div>
        <div className={s.roundContainer}>
          <Skeleton className={s.roundsButton} />
        </div>
      </div>
    </div>
  )
}

export const BetTimerLoader = (): JSX.Element => {
  return (
    <div className={s.betTimer}>
      <Skeleton className={s.skeleton} />
    </div>
  )
}

export const CurrentMatchLoader = (): JSX.Element => {
  return (
    <div className={s.currentMatch}>
      <Skeleton className={s.skeleton} />
    </div>
  )
}

export const BettingPanelLoader = (): JSX.Element => {
  return (
    <div className={s.bettingPanel}>
      <div className={s.table}>
        <div className={s.header}>
          <Skeleton className={s.skeleton} />
        </div>
        <div className={s.body}>
          <div className={s.bodyTab}>
            <Skeleton className={s.skeleton} />
            <Skeleton className={s.skeleton} />
          </div>
          <div className={s.bodyItem}>
            <Skeleton className={s.skeleton} />
          </div>
        </div>
      </div>
    </div>
  )
}

export const FooterLoader = (): JSX.Element => {
  return (
    <div className={s.resultsFooter}>
      <div className={s.info}>
        <Skeleton className={s.skeleton} />
      </div>
      <div className={s.bet365}>
        <Skeleton className={s.skeleton} />
      </div>
    </div>
  )
}

export const ResultsLoader = (): JSX.Element => {
  return (
    <div className={s.resultsBody}>
      <div className={s.resultsDetail}>
        <Skeleton className={s.circleSkeleton} />
        <Skeleton className={s.skeleton} />
        <Skeleton
          className={s.skeleton}
          style={{
            width: `30px`,
          }}
        />
        <Skeleton className={s.skeleton} />
      </div>
      <div className={s.resultsFrame}>
        <div className={s.resultsItem}>
          <Skeleton
            className={s.skeleton}
            style={{
              width: `140px`,
            }}
          />
          <Skeleton
            className={s.skeleton}
            style={{
              width: `100px`,
            }}
          />
        </div>
        <div className={s.resultsItem}>
          <Skeleton
            className={s.skeleton}
            style={{
              width: `120px`,
            }}
          />
          <Skeleton
            className={s.skeleton}
            style={{
              width: `160px`,
            }}
          />
        </div>
        <div className={s.resultsItem}>
          <Skeleton
            className={s.skeleton}
            style={{
              width: `110px`,
            }}
          />
          <Skeleton
            className={s.skeleton}
            style={{
              width: `160px`,
            }}
          />
        </div>
        <div className={s.resultsItem}>
          <Skeleton
            className={s.skeleton}
            style={{
              width: `105px`,
            }}
          />
        </div>
      </div>
      <FooterLoader />
    </div>
  )
}

export const DetailsLoader = (): JSX.Element => {
  return (
    <div className={s.details}>
      <div className={s.detailsHeader}>
        <Skeleton className={s.skeleton} />
      </div>
      <div className={s.detailsTab}>
        <div className={s.resultsTab}>
          <Skeleton className={s.skeleton} />
        </div>
        <div className={s.historyTab}>
          <Skeleton className={s.skeleton} />
        </div>
      </div>
      <ResultsLoader />
    </div>
  )
}

export const HistoryResultsLoader = (): JSX.Element => {
  const {width} = useWindowDimensions()

  return (
    <div className={s.historySkeleton}>
      <div className={s.historyPlayerBet}>
        <div className={s.historyRow}>
          <div className={s.historyRowItem}>
            <Skeleton className={s.circleSkeleton} />
            <Skeleton
              className={s.skeleton}
              style={{
                width: `120px`,
              }}
            />
          </div>
          <div className={s.historyRowItem}>
            <Skeleton
              className={s.skeleton}
              style={{
                width: `70px`,
              }}
            />
          </div>
        </div>
        <div className={s.historyRow}>
          <div className={s.historyRowItem}>
            <Skeleton
              className={s.skeleton}
              style={{
                width: `145px`,
                height: `24px`,
              }}
            />
          </div>
          <div className={s.historyRowItem}>
            <Skeleton
              className={s.skeleton}
              style={{
                width: `56px`,
                height: `24px`,
              }}
            />
          </div>
        </div>
        <div className={s.historyRow}>
          <div className={s.historyRowItem}>
            <Skeleton
              className={s.skeleton}
              style={{
                width: `100px`,
                height: `16px`,
              }}
            />
          </div>
          <div className={s.historyRowItem}>
            <Skeleton
              className={s.skeleton}
              style={{
                width: `42px`,
                height: `24px`,
              }}
            />
          </div>
        </div>
        <div className={s.historyRow}>
          <div className={s.historyRowItem}>
            <Skeleton
              className={s.skeleton}
              style={{
                width: `90px`,
              }}
            />
          </div>
        </div>
        <div className={s.historyRow}>
          <div className={s.historyRowItem}>
            <Skeleton
              className={s.skeleton}
              style={{
                width: `120px`,
              }}
            />
          </div>
        </div>
        <div
          className={s.historyRow}
          style={{
            paddingTop: `22px`,
          }}
        >
          <div className={s.historyRowItem}>
            <Skeleton
              className={s.skeleton}
              style={{
                width: `23px`,
              }}
            />
          </div>
          <div className={s.historyRowItem}>
            <Skeleton
              className={s.skeleton}
              style={{
                width: `45px`,
              }}
            />
          </div>
        </div>
        <div className={s.historyRow}>
          <div className={s.historyRowItem}>
            <Skeleton
              className={s.skeleton}
              style={{
                width: `45px`,
              }}
            />
          </div>
          <div className={s.historyRowItem}>
            <Skeleton
              className={s.skeleton}
              style={{
                width: `52px`,
              }}
            />
          </div>
        </div>
      </div>
      <div className={s.historyPlayerBet}>
        <div className={s.historyRow}>
          <div className={s.historyRowItem}>
            <Skeleton className={s.circleSkeleton} />
            <Skeleton
              className={s.skeleton}
              style={{
                width: `120px`,
              }}
            />
          </div>
          <div className={s.historyRowItem}>
            <Skeleton
              className={s.skeleton}
              style={{
                width: `70px`,
              }}
            />
          </div>
        </div>
        <div className={s.historyRow}>
          <div className={s.historyRowItem}>
            <Skeleton
              className={s.skeleton}
              style={{
                width: `145px`,
                height: `24px`,
              }}
            />
          </div>
          <div className={s.historyRowItem}>
            <Skeleton
              className={s.skeleton}
              style={{
                width: `56px`,
                height: `24px`,
              }}
            />
          </div>
        </div>
        <div className={s.historyRow}>
          <div className={s.historyRowItem}>
            <Skeleton
              className={s.skeleton}
              style={{
                width: `100px`,
                height: `16px`,
              }}
            />
          </div>
          <div className={s.historyRowItem}>
            <Skeleton
              className={s.skeleton}
              style={{
                width: `42px`,
                height: `24px`,
              }}
            />
          </div>
        </div>
        <div className={s.historyRow}>
          <div className={s.historyRowItem}>
            <Skeleton
              className={s.skeleton}
              style={{
                width: `90px`,
              }}
            />
          </div>
        </div>
        <div className={s.historyRow}>
          <div className={s.historyRowItem}>
            <Skeleton
              className={s.skeleton}
              style={{
                width: `120px`,
              }}
            />
          </div>
        </div>
        <div
          className={s.historyRow}
          style={{
            paddingTop: `22px`,
          }}
        >
          <div className={s.historyRowItem}>
            <Skeleton
              className={s.skeleton}
              style={{
                width: `23px`,
              }}
            />
          </div>
          <div className={s.historyRowItem}>
            <Skeleton
              className={s.skeleton}
              style={{
                width: `45px`,
              }}
            />
          </div>
        </div>
        <div className={s.historyRow}>
          <div className={s.historyRowItem}>
            <Skeleton
              className={s.skeleton}
              style={{
                width: `45px`,
              }}
            />
          </div>
          <div className={s.historyRowItem}>
            <Skeleton
              className={s.skeleton}
              style={{
                width: `52px`,
              }}
            />
          </div>
        </div>
      </div>
      <div className={s.historyPlayerBet}>
        <div className={s.historyRow}>
          <div className={s.historyRowItem}>
            <Skeleton className={s.circleSkeleton} />
            <Skeleton
              className={s.skeleton}
              style={{
                width: `120px`,
              }}
            />
          </div>
          <div className={s.historyRowItem}>
            <Skeleton
              className={s.skeleton}
              style={{
                width: `70px`,
              }}
            />
          </div>
        </div>
        <div className={s.historyRow}>
          <div className={s.historyRowItem}>
            <Skeleton
              className={s.skeleton}
              style={{
                width: `145px`,
                height: `24px`,
              }}
            />
          </div>
          <div className={s.historyRowItem}>
            <Skeleton
              className={s.skeleton}
              style={{
                width: `56px`,
                height: `24px`,
              }}
            />
          </div>
        </div>
        <div className={s.historyRow}>
          <div className={s.historyRowItem}>
            <Skeleton
              className={s.skeleton}
              style={{
                width: `100px`,
                height: `16px`,
              }}
            />
          </div>
          <div className={s.historyRowItem}>
            <Skeleton
              className={s.skeleton}
              style={{
                width: `42px`,
                height: `24px`,
              }}
            />
          </div>
        </div>
        <div className={s.historyRow}>
          <div className={s.historyRowItem}>
            <Skeleton
              className={s.skeleton}
              style={{
                width: `90px`,
              }}
            />
          </div>
        </div>
        <div className={s.historyRow}>
          <div className={s.historyRowItem}>
            <Skeleton
              className={s.skeleton}
              style={{
                width: `120px`,
              }}
            />
          </div>
        </div>
        <div
          className={s.historyRow}
          style={{
            paddingTop: `22px`,
          }}
        >
          <div className={s.historyRowItem}>
            <Skeleton
              className={s.skeleton}
              style={{
                width: `23px`,
              }}
            />
          </div>
          <div className={s.historyRowItem}>
            <Skeleton
              className={s.skeleton}
              style={{
                width: `45px`,
              }}
            />
          </div>
        </div>
        <div className={s.historyRow}>
          <div className={s.historyRowItem}>
            <Skeleton
              className={s.skeleton}
              style={{
                width: `45px`,
              }}
            />
          </div>
          <div className={s.historyRowItem}>
            <Skeleton
              className={s.skeleton}
              style={{
                width: `52px`,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export const LeagueDetailLoader = (): JSX.Element => {
  const min842 = useBreakpoint(`(min-width: 842px)`)

  return (
    <div className={s.leagueDetailLoader}>
      {!min842 && <RoundsTabLoader />}
      {/* <BetTimerLoader /> */}
      <CurrentMatchLoader />
      <BettingPanelLoader />
      <BettingPanelLoader />
    </div>
  )
}
