import {useCallback} from "react"
import Flicking, {HoldStartEvent, ChangedEvent} from "@egjs/react-flicking"
import shallow from "zustand/shallow"

import navStore from "screens/NavHome/store/navStore"
import NavBodyType from "screens/NavHome/types/NavBody"
import useNavBarController from "screens/NavHome/hooks/useNavBarController"
import {useMinigameStore} from "stores/miniGameStore"
import {useSearchStore as useCasinoSearch} from "screens/Casino/store/useSearchStore"
import {useSearchStore as useSlotSearch} from "screens/Slots/store/useSearchStore"
import {useUserStore} from "stores/userStore"
import {
  PRIVATE_MOBILE_SLIDER,
  PUBLIC_MOBILE_SLIDER,
} from "screens/NavHome/utils/routes"
interface iNavHomeController {
  isLarge: boolean
  activeGame: number
  navItems: NavBodyType[]
  handleClick: (value: string) => void
  handleOnHoldStart: (e: HoldStartEvent<Flicking>) => void
  handleSwipeChange: (e: ChangedEvent<Flicking>) => void
  handleRenderedSlidesListener: (index: number, items: NavBodyType[]) => void
  setSelectedTitle: (value: string) => void
  setSelectedIndex: (value: number) => void
  setSliderIndex: (value: number) => void
}

const useNavHomeController = (): iNavHomeController => {
  const {navItems, isLarge, handleClick, doNavigate} = useNavBarController()
  const {
    setSelectedTitle,
    setSelectedIndex,
    setRenderedIndexes,
    setIsDragging,
    setSliderIndex,
  } = navStore(
    store => ({
      setSelectedTitle: store.setSelectedTitle,
      setSelectedIndex: store.setSelectedIndex,
      setRenderedIndexes: store.setRenderedIndexes,
      setIsDragging: store.setIsDragging,
      setSliderIndex: store.setSliderIndex,
    }),
    shallow,
  )

  const {activeGame} = useMinigameStore(
    store => ({
      activeGame: store.openTab,
    }),
    shallow,
  )

  const setCasinoSearch = useCasinoSearch(store => store.setIsSearchOpen, shallow)
  const setSlotSearch = useSlotSearch(store => store.setIsSearchOpen, shallow)

  const handleRenderedSlidesListener = useCallback(
    (index: number, items: NavBodyType[]) => {
      const lastIndex = items.length - 1
      const nextItem = index + 1
      const lastItem = nextItem <= lastIndex ? nextItem : 0
      const prevItem = index - 1
      const firstItem = prevItem >= 0 ? prevItem : lastIndex
      const rendered = [firstItem, index, lastItem]
      setRenderedIndexes(rendered)
    },
    [setRenderedIndexes],
  )

  const handleSwipe = useCallback(
    (index: number): void => {
      const label = navItems[index].title
      setSliderIndex(index)
      setCasinoSearch(false)
      setSlotSearch(false)
      doNavigate(label, index)
      setSelectedTitle(label)
    },
    [setSliderIndex, setCasinoSearch, setSlotSearch, setSelectedTitle, doNavigate],
  )

  const handleOnHoldStart = useCallback(e => {
    const isGameOpened =
      document.getElementsByClassName("mini-game-opened")[0] !== undefined
    if (isGameOpened) e.stop()
  }, [])

  const handleSwipeChange = useCallback(
    (e: ChangedEvent<Flicking>) => {
      window.scrollTo({top: 0})
      const prevIndex = e.prevIndex
      const current = e.index
      const isNext = current - 1 === prevIndex
      const direction = isNext ? "NEXT" : "PREV"
      const token = useUserStore.getState().authToken
      const sliderItems = token ? PRIVATE_MOBILE_SLIDER : PUBLIC_MOBILE_SLIDER
      const lastIndex = sliderItems.length - 1
      const currentIndex = e.index
      const lastItem = currentIndex > lastIndex ? lastIndex : currentIndex
      const firstItem = currentIndex > lastItem ? 0 : currentIndex
      const index = direction === "PREV" ? lastItem : firstItem

      if (e.isTrusted) handleSwipe(index)
      setIsDragging(false)
    },
    [handleSwipe, setIsDragging],
  )

  return {
    isLarge,
    activeGame,
    navItems,
    handleClick,
    handleOnHoldStart,
    handleSwipeChange,
    handleRenderedSlidesListener,
    setSelectedTitle,
    setSelectedIndex,
    setSliderIndex,
  }
}

export default useNavHomeController
