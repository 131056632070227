import GameCategoriesTab from "components/GameCategoriesTab"
import BasketballSVG from "components/GameCategoriesTab/CategoryIcon/BasketballSVG"
import SoccerSVG from "components/GameCategoriesTab/CategoryIcon/SoccerSVG"
import GameCategoryButton from "components/GameCategoriesTab/GameCategoryButton"
import React from "react"
import shallow from "zustand/shallow"
import {useBet365Store} from "screens/Bet365/store"
import {Bet365Game} from "types/Bet365"
import HoundSVG from "components/GameCategoriesTab/CategoryIcon/HoundSVG"
import HorseSVG from "components/GameCategoriesTab/CategoryIcon/HorseSVG"
import s from "./gamestab.module.scss"

import {GamesTabLoader} from "screens/Bet365/components/Skeleton"
import FootballSVG from "components/GameCategoriesTab/CategoryIcon/FootballSVG"
import BaseballSVG from "components/GameCategoriesTab/CategoryIcon/BaseballSVG"

export const BASKETBALL_GAME_CODE = "basketball"
export const SOCCER_GAME_CODE = "soccer"
export const HOUND_GAME_CODE = "greyhounds"
export const HORSE_GAME_CODE = "horseracing"
export const FOOTBALL_GAME_CODE = "american_football"
export const BASEBALL_GAME_CODE = "baseball"

export const GameIcon = ({
  gameCode,
  fill1 = "#ffffff",
  fill2 = "#00acee",
  isActive,
}: {
  gameCode: string
  fill1?: string
  fill2?: string
  isActive: boolean
}): JSX.Element => {
  switch (gameCode) {
    case BASKETBALL_GAME_CODE:
      return <BasketballSVG active={isActive} fill1={fill1} fill2={fill2} />
    case SOCCER_GAME_CODE:
      return (
        <SoccerSVG
          active={isActive}
          fill1={fill1 === "#ffffff" ? fill2 : fill1}
          fill2={fill2}
        />
      )
    case HOUND_GAME_CODE:
      return <HoundSVG active={isActive} fill1={fill1} fill2={fill2} />
    case HORSE_GAME_CODE:
      return <HorseSVG active={isActive} fill1={fill1} fill2={fill2} />
    case BASEBALL_GAME_CODE:
      return <BaseballSVG active={isActive} fill1={fill1} fill2={fill2} />
    default:
      return <FootballSVG active={isActive} fill1={fill1} fill2={fill2} />
  }
}

const GamesTab = (): JSX.Element => {
  const {gameId, games, setSelectedGame, setSelectedleague, isGamesLoading} =
    useBet365Store(
      store => ({
        gameId: store.selectedGame?.id,
        games: store.games,
        setSelectedGame: store.setSelectedGame,
        setSelectedleague: store.setSelectedleague,
        isGamesLoading: store.isGamesLoading,
      }),
      shallow,
    )

  const onClickGame = (game: Bet365Game) => {
    if (game.id === gameId) {
      return
    }
    setSelectedGame(game)
    setSelectedleague(game.leagues[0].code)
  }

  if (isGamesLoading) return <GamesTabLoader />

  return (
    <GameCategoriesTab className={s.gameTabsContainer}>
      {games.map(gm => {
        const isActive = gameId === gm.id
        return (
          <GameCategoryButton
            key={gm.id}
            onClick={() => onClickGame(gm)}
            active={gameId === gm.id}
            isNew={false}
            className={s[gm.code]}
          >
            <GameIcon gameCode={gm.code} isActive={isActive} />
            <span style={{marginTop: 4, fontSize: 11, whiteSpace: "nowrap"}}>
              {gm?.hangul_name ?? gm?.name}
            </span>
          </GameCategoryButton>
        )
      })}
    </GameCategoriesTab>
  )
}

export default React.memo(GamesTab)
