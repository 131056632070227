import React, {FC, memo, useMemo, useCallback} from "react"
import shallow from "zustand/shallow"
import {iconClose} from "screens/Home/MyPage/Screens/BetHistory/components/BetDetails/icons"
import usePopupBannerStore from "components/Banner/PopupV4/usePopupBannerStore"
import useNavBarController from "screens/NavHome/hooks/useNavBarController"
import {PROMOTIONS} from "screens/NavHome/utils/config"
import {HOME_BANNERS, EZ_HOME_BANNERS} from "components/Banner/PopupV4/constants"
import s from "./layout.module.scss"
import {lazyWithRetry} from "utils/lazyWithRetry"
import { WholeBannerImage } from "screens/Home/MainV2/assets"

const Slider = lazyWithRetry(() => import("components/Banner/PopupV4/Slider"))

interface iLayout {
  className?: string
  selectedPage?: string
}

const Layout: FC<iLayout> = (): JSX.Element => {
  const [close] = usePopupBannerStore(store => [store.close], shallow)
  const {handleNavigateTo} = useNavBarController()

  const handleOnClick = useCallback(
    label => {
      if (label) {
        handleNavigateTo(label)
        const popup = usePopupBannerStore.getState()
        popup.close()
      }
    },
    [handleNavigateTo],
  )

  const BANNERS = useMemo(() => {
    const isEz = window.location.host === "ezbet.com"
    return isEz ? EZ_HOME_BANNERS : HOME_BANNERS
  }, [])

  return (
    <>
      <div className={s.closeContainer}>
        <button className={s.closeBtn} onClick={() => close()}>
          {iconClose}
        </button>
      </div>
      <div className={s.container}>
        {/* <Slider items={BANNERS} /> */}
        <img className={s.bannerImage} src={WholeBannerImage} draggable={false} />
      </div>
      {/* <div
        role="button"
        className={s.redirectBtn}
        onClick={() => handleOnClick(PROMOTIONS)}
      >
        <span>자세한 내용은 이벤트 페이지에서 확인해주세요</span>
      </div> */}
    </>
  )
}

export default memo(Layout)
