import shallow from "zustand/shallow"
import useRegisterModalStore from "components/RegisterButton/hooks/useRegisterModalStore"
import useLoginModalStore from "components/LoginButton/hooks/useLoginModalStore"

const useRegisterModalController = () => {
  const {setIsOpen, close, handleOnExit} = useRegisterModalStore(
    store => ({
      setIsOpen: store.setIsOpen,
      close: store.close,
      handleOnExit: store.handleOnExit,
    }),
    shallow,
  )

  const {handleCheckOpenLogin} = useLoginModalStore(
    store => ({
      handleCheckOpenLogin: store.handleCheckOpen,
    }),
    shallow,
  )

  const handleToggleModal = (value: boolean) => {
    if (!value) {
      handleOnExit(close)
    } else {
      handleCheckOpenLogin(() => setIsOpen(true))
    }
  }

  return {
    handleToggleModal,
  }
}

export default useRegisterModalController
