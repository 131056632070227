import React, {useState, useRef} from "react"
import {useModal} from "react-modal-hook"

import ModalConfirm from "components/ModalConfirm"

export interface ConfirmModal {
  showConfirmModal: (props: ShowConfirmModal) => void
}

export type ShowConfirmModal = {
  message?: string
  onConfirm: () => void
}

const useConfirmModal = (): ConfirmModal => {
  const confirmCallbackRef = useRef<() => void>()
  const msgRef = useRef<string>("")

  const [showConfirm, hideConfirm] = useModal(
    () => (
      <ModalConfirm
        message={msgRef.current}
        confirm={() => confirmCallbackRef.current()}
        cancel={() => hideConfirm()}
      />
    ),
    [msgRef.current],
  )

  const showConfirmModal = (props: ShowConfirmModal) => {
    const {message, onConfirm} = props

    msgRef.current = message
    confirmCallbackRef.current = onConfirm

    showConfirm()
  }

  return {
    showConfirmModal,
  }
}

export default useConfirmModal
