import {useEffect} from "react"
import shallow from "zustand/shallow"
import usePusherStore from "stores/usePusherStore"
import EVENT from "provider/pusher/pusherEvents"
import Bus from "utils/events/bus"

const useBankEvents = () => {
  const privateNotifChannel = usePusherStore(
    store => store.privateNotifChannel,
    shallow,
  )

  const approvedBank = data => {
    Bus.emit("flash", {message: data.title, type: "success", details: data.message})
  }

  const rejectedBank = data => {
    if (data?.attempt !== 3) {
      Bus.emit("flash", {message: data.title, type: "danger", details: data.message})
    } else if (data?.attempt >= 3) {
      Bus.emit("flash", {
        message: "정보 불일치로 인해 관리자 취소 되었습니다.",
        type: "danger",
        details: `일 3회 등록/변경 제한으로 <span style="color: #FA3939;">10시간</span> 이후 재신청 하시기 바랍니다.`,
      })
    }
  }

  useEffect(() => {
    if (!privateNotifChannel) return

    // console.log("bind bankEvents")

    privateNotifChannel.bind(EVENT.ADMIN_APPROVED_BANK_LOG, approvedBank)
    privateNotifChannel.bind(EVENT.ADMIN_REJECTED_BANK_LOG, rejectedBank)

    return () => {
      const pusherStore = usePusherStore.getState()
      const channel = pusherStore.privateNotifChannel
      if (channel) {
        // console.log("unbind bankEvents")
        channel.unbind(EVENT.ADMIN_APPROVED_BANK_LOG)
        channel.unbind(EVENT.ADMIN_REJECTED_BANK_LOG)
      }
    }
  }, [privateNotifChannel])
}

export default useBankEvents
