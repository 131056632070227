import create from "zustand"
import {DEPOSIT_APPLICATION} from "screens/MyProfile/constants"

export interface iWalletStore {
  isOpen?: boolean
  selectedTab: string
  setIsOpen?: (value: boolean) => void
  setSelectedTab: (value: string) => void
}

const useWalletStore = create<iWalletStore>((set, get) => ({
  isOpen: false,
  selectedTab: DEPOSIT_APPLICATION,
  setIsOpen: isOpen => set({isOpen}),
  setSelectedTab: selectedTab => set({selectedTab}),
}))

export default useWalletStore
