import {useEffect} from "react"
import shallow from "zustand/shallow"
import {useQueryClient} from "react-query"
import {useUserStore} from "stores/userStore"
import usePusherStore from "stores/usePusherStore"
import useChangeBankStore from "screens/MyProfile/screens/ChangeBank/store/useChangeBankStore"
import EVENT from "provider/pusher/pusherEvents"
import {RECHARGE_HISTORY_QUERY} from "screens/Home/Account/Screens/History/config"
import {MONEY_HISTORY_QUERY} from "screens/Home/MyPage/Screens/MoneyHistory/config"
import Bus from "utils/events/bus"

const useWithdrawEvents = () => {
  const client = useQueryClient()
  const privateNotifChannel = usePusherStore(
    store => store.privateNotifChannel,
    shallow,
  )

  const approvedRefreshHistory = data => {
    client.invalidateQueries([RECHARGE_HISTORY_QUERY])
    client.invalidateQueries([MONEY_HISTORY_QUERY])
    Bus.emit("flash", {message: data.title, type: "success", details: data.message})
  }

  const rejectedRefreshHistory = data => {
    client.invalidateQueries([RECHARGE_HISTORY_QUERY])
    client.invalidateQueries([MONEY_HISTORY_QUERY])
    Bus.emit("flash", {message: data.title, type: "danger", details: data.message})

    const bankStore = useChangeBankStore.getState()
    bankStore.setHasPendingWithdraw(false)
  }

  const doneRefreshHistory = data => {
    const bankStore = useChangeBankStore.getState()
    bankStore.setHasPendingWithdraw(false)

    client.invalidateQueries([RECHARGE_HISTORY_QUERY])
    client.invalidateQueries([MONEY_HISTORY_QUERY])

    const user = useUserStore.getState()
    user.getUserDetails()

    Bus.emit("flash", {message: data.title, type: "success", details: data.message})
  }

  useEffect(() => {
    if (!privateNotifChannel) return

    // console.log("bind withdrawEvents")

    privateNotifChannel.bind(EVENT.ADMIN_APPROVED_WITHDRAW, approvedRefreshHistory)
    privateNotifChannel.bind(EVENT.ADMIN_REJECTED_WITHDRAW, rejectedRefreshHistory)
    privateNotifChannel.bind(EVENT.ADMIN_ROLLBACK_WITHDRAW, rejectedRefreshHistory)
    privateNotifChannel.bind(EVENT.ADMIN_CANCEL_WITHDRAW, rejectedRefreshHistory)
    privateNotifChannel.bind(EVENT.ADMIN_EXPIRE_WITHDRAW, rejectedRefreshHistory)
    privateNotifChannel.bind(EVENT.ADMIN_DONE_WITHDRAW, doneRefreshHistory)

    return () => {
      const pusherStore = usePusherStore.getState()
      const channel = pusherStore.privateNotifChannel
      if (channel) {
        // console.log("unbind withdrawEvents")
        channel.unbind(EVENT.ADMIN_APPROVED_WITHDRAW)
        channel.unbind(EVENT.ADMIN_REJECTED_WITHDRAW)
        channel.unbind(EVENT.ADMIN_ROLLBACK_WITHDRAW)
        channel.unbind(EVENT.ADMIN_CANCEL_WITHDRAW)
        channel.unbind(EVENT.ADMIN_EXPIRE_WITHDRAW)
        channel.unbind(EVENT.ADMIN_DONE_WITHDRAW)
      }
    }
  }, [privateNotifChannel])
}

export default useWithdrawEvents
