import React, {FC, useMemo, memo} from "react"
import cx from "classnames"
import shallow from "zustand/shallow"
import {useWindowSize} from "react-use"
import navStore from "screens/NavHome/store/navStore"
import {useUserStore} from "stores/userStore"
import {SIGNUP, LOGIN, MY_PAGE} from "screens/NavHome/utils/config"
import LinkWithFade from "components/LinkWithFade"
import UserCircleIcon from "layout/components/Header/UserCircleIcon"
import useInboxStore from "screens/MyProfile/store/useInboxStore"
import {useInquiryStore} from "screens/Home/MyPage/Screens/CustomerCenter/store"
import s from "screens/NavHome/components/LoginBar/loginBar.module.scss"
import RegisterButton from "components/RegisterButton"
import LoginButton from "components/LoginButton"
interface iNavList {
  className?: string
  path: string
  handleClick: (index: string) => void
}

const LoginBar: FC<iNavList> = ({className, path, handleClick}): JSX.Element => {
  const authToken = useUserStore(x => x.authToken, shallow)
  const {width} = useWindowSize()
  const unreadInboxCount = useInboxStore(store => store.unreadCount, shallow)
  const customerSupportNotif = useInquiryStore(
    store => store.hasNotification,
    shallow,
  )
  const {selectedSlideTitle} = navStore(
    store => ({
      selectedSlideTitle: store.selectedSlideTitle,
    }),
    shallow,
  )
  const isModal = width >= 674

  const notifCount = useMemo(() => {
    if (customerSupportNotif) return unreadInboxCount + 1
    else return unreadInboxCount
  }, [unreadInboxCount, customerSupportNotif])

  return (
    <div className={cx(s.layoutHeaderLinks, className, {[s.hasToken]: authToken})}>
      {authToken ? (
        <div
          className={cx(s.layoutHeaderProfile, {
            [s.active]: selectedSlideTitle === MY_PAGE,
          })}
          onClick={() => handleClick(MY_PAGE)}
        >
          <div className={s.homeLink}>
            <UserCircleIcon />
          </div>
          {notifCount !== 0 && (
            <div className={s.badge}>
              <span>{notifCount}</span>
            </div>
          )}
        </div>
      ) : (
        <>
          {isModal && (
            <>
              <RegisterButton />
              <LoginButton />
            </>
          )}
          {!isModal && (
            <>
              <LinkWithFade
                className={cx(s.layoutHeaderLink, {
                  [s.active]: selectedSlideTitle === SIGNUP,
                })}
                to={SIGNUP}
                label={SIGNUP}
              />
              <LinkWithFade
                data-cy={`account-login`}
                className={cx(s.layoutHeaderLink, {
                  [s.active]: selectedSlideTitle === LOGIN,
                })}
                to={LOGIN}
                label={LOGIN}
              />
            </>
          )}
        </>
      )}
    </div>
  )
}

export default memo(LoginBar)
