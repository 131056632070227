import create from "zustand"
import NewsItem from "screens/News/type/NewsItem"

export interface iNewsStore {
  id: number
  isOpen: boolean
  isListLoading: boolean
  isLoading: boolean
  newsList: NewsItem[]
  newsDetails: NewsItem
  setId: (value: number) => void
  setIsOpen: (value: boolean) => void
  setIsListIsLoading: (value: boolean) => void
  setIsLoading: (value: boolean) => void
  setNewsList: (value: NewsItem[]) => void
  setNewsDetails: (value: NewsItem) => void
  resetStore: () => void
}

const useNewsStore = create<iNewsStore>((set, get) => ({
  id: 0,
  isOpen: false,
  isListLoading: false,
  isLoading: false,
  newsList: [],
  newsDetails: null,
  setId: id => set({id}),
  setIsOpen: isOpen => set({isOpen}),
  setIsLoading: isLoading => set({isLoading}),
  setIsListIsLoading: isListLoading => set({isListLoading}),
  setNewsList: newsList => set({newsList}),
  setNewsDetails: newsDetails => set({newsDetails}),
  resetStore: () => {
    set({
      id: 0,
      isOpen: false,
      newsList: [],
      newsDetails: null,
    })
  },
}))

export default useNewsStore
