import request from "services/request"
import API from "services/Account/ChangeMobileNumber/endpoints"
import {AxiosResponse} from "axios"
import {useUserStore} from "stores/userStore"

const headers = () => {
  const {authToken} = useUserStore.getState()

  return {
    "Accept": "application/json",
    "Content-Type": "application/json",
    "Authorization": `Bearer ${authToken}`,
  }
}

export type OtpResponse = {
  status_code: number
  message: string
}

const checkPending = (): Promise<AxiosResponse<any>> => {
  return request({
    url: API.CHECK_PENDING,
    method: "GET",
    headers: headers(),
  })
}

const checkMobile = (
  data: Record<string, string>,
): Promise<AxiosResponse<OtpResponse>> => {
  return request({
    url: API.CHECK_MOBILE,
    method: "POST",
    data: data,
    headers: headers(),
  })
}

const getOtpCode = (
  data: Record<string, string>,
): Promise<AxiosResponse<OtpResponse>> => {
  return request({
    url: API.GET_OTP_CODE,
    method: "POST",
    data,
    headers: headers(),
  })
}

const sendOTP = (data: Record<string, any>): Promise<unknown> => {
  return request({
    url: API.SEND_OTP,
    method: "POST",
    data,
    headers: headers(),
  })
}

const updateMobileNumber = (data: Record<string, string>): Promise<unknown> => {
  return request({
    url: API.SUBMIT_FORM,
    method: "PATCH",
    data,
    headers: headers(),
  })
}

function phoneNumberHistory(): Promise<unknown> {
  return request({
    url: API.SUBMIT_FORM,
    method: "GET",
    headers: headers(),
  })
}

export default {
  checkPending,
  checkMobile,
  getOtpCode,
  sendOTP,
  updateMobileNumber,
  phoneNumberHistory,
}
