import React, {memo, useContext} from "react"

import {useWindowSize} from "react-use"
import DesktopView from "./components/DesktopView"
import MobileView from "./components/MobileView"
import {pathType} from "hooks/useNavigate"
import {ThemeContext} from "context/ThemeContext"

interface ImageData {
  src: string
  caption: string
  path: pathType
}

interface BannerImageData {
  bottom_banner_mobile: BannerImageData
  top_banner_mobile: BannerImageData
  bottom_banner_desktop: BannerImageData
  top_banner_desktop: BannerImageData
  desktopSrc: string
  mobileSrc: string
  caption: string
  path: pathType
}

const Home = () => {
  const {theme} = useContext(ThemeContext)
  const {width} = useWindowSize()
  const isLarge = width >= 842

  const carouselImages: ImageData[] = theme?.images.carousel
  const bannersImages: BannerImageData = theme?.images.banners

  return (
    <>
      {isLarge ? (
        <DesktopView
          carousel_images={carouselImages}
          banners_images={bannersImages}
        />
      ) : (
        <MobileView
          carousel_images={carouselImages}
          banners_images={bannersImages}
        />
      )}
    </>
  )
}

export default memo(Home)
