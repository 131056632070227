import {DateHistoryType} from "types/MyPage/BetHistory"

export const HEADER = "베팅 역사"

export const defaultDates: DateHistoryType = [
  {
    endDate: new Date(),
    startDate: new Date(),
  },
]

export enum TypeName {
  All = "게임선택 (전체)",
  SubAll = "세부선택 (전체)",
  Recharge = "충전/환전",
  Sports = "스포츠",
  Casino = "라이브카지노",
  Slots = "슬롯머신",
  MiniGames = "파워볼",
  Bet365 = "벳365",
  Skypark = "스카이파크",
}

export enum RechargeTypes {
  Withdraw = "환전",
  Deposit = "충전",
}

export enum SportsTypes {
  Live = "라이브",
  PreMatch = "프리매치",
}

export enum CasinoTypes {
  Top = "탑게임",
  Baccarat = "바카라",
  Roulette = "룰렛",
  Poker = "포커",
  DragonTiger = "식보 & 드래곤타이거",
  Blackjack = "블랙잭",
  GameShows = "게임쇼",
  FirstPerson = "일인칭 게임",
}

export enum SlotsTypes {
  Popular = "인기게임",
  Jackpot = "잭팟",
  Classic = "클래식",
  Video = "비디오슬롯",
}

export enum MiniGamesTypes {
  OriginalPowerball = "DH 복권",
  EOSPowerball = "에오스",
}

export enum Bet365Types {
  Soccer = "축구",
  Basketball = "농구",
  Greyhound = "개경주",
  HorseRacing = "경마",
  Football = "미식축구",
}

export enum SkyparkType {
  Baccarat = "바카라",
  DragonTiger = "드래곤타이거",
  Samchigi = "삼치기",
  SpeedBaccarat = "스피드바카라",
  OddEven = "홀짝",
  Dice = "주사위",
  SunMoonStars = "해달별",
}

export const RechargeTypeList = [
  TypeName.All,
  RechargeTypes.Withdraw,
  RechargeTypes.Deposit,
]

export const SportsTypeList = [TypeName.All]

export const CasinoTypeList = [
  TypeName.All,
  CasinoTypes.Top,
  CasinoTypes.Baccarat,
  CasinoTypes.Roulette,
  CasinoTypes.Poker,
  CasinoTypes.DragonTiger,
  CasinoTypes.Blackjack,
  CasinoTypes.GameShows,
  CasinoTypes.FirstPerson,
]

export const SlotTypeList = [
  TypeName.All,
  SlotsTypes.Popular,
  SlotsTypes.Jackpot,
  SlotsTypes.Classic,
  SlotsTypes.Video,
]

export const MiniGamesTypeList = [
  TypeName.All,
  MiniGamesTypes.OriginalPowerball,
  MiniGamesTypes.EOSPowerball,
]

export const Bet365TypeList = [
  TypeName.All,
  Bet365Types.Soccer,
  Bet365Types.Basketball,
  Bet365Types.Greyhound,
  Bet365Types.HorseRacing,
  Bet365Types.Football,
]

export const SkyparkTypeList = [
  TypeName.All,
  SkyparkType.Baccarat,
  SkyparkType.DragonTiger,
  SkyparkType.Samchigi,
  SkyparkType.SpeedBaccarat,
  SkyparkType.OddEven,
  SkyparkType.Dice,
  SkyparkType.SunMoonStars,
]

export const TypeMap = {
  [TypeName.All]: [],
  [TypeName.Recharge]: RechargeTypeList,
  [TypeName.Sports]: [],
  [TypeName.Casino]: CasinoTypeList,
  [TypeName.Slots]: SlotTypeList,
  [TypeName.MiniGames]: MiniGamesTypeList,
  [TypeName.Bet365]: Bet365TypeList,
  [TypeName.Skypark]: SkyparkTypeList,
}

export const TypeList = [
  TypeName.All,
  TypeName.Recharge,
  TypeName.Sports,
  TypeName.Casino,
  TypeName.Slots,
  TypeName.MiniGames,
  TypeName.Bet365,
  TypeName.Skypark,
]

export const BET_HISTORY_QUERY = "BetHistory"
export const BET_HISTORY_TOTALS_QUERY = "BetHistoryTotals"
export const BET_HISTORY_FILTER_QUERY = "BetHistoryFilters"
