import React, {FC, memo, useEffect} from "react"
import {useQuery} from "react-query"
import {useParams, useHistory} from "react-router-dom"
import punycode from "punycode"
import {
  HOME,
  NAV_HOME_SELECTED_PAGE,
  CASINO_PATH,
  LIVE_CASINO,
  BTI_SPORTS,
} from "screens/NavHome/utils/config"
import useNavHomeController from "screens/NavHome/hooks/useNavHomeController"
import servicePlayer from "services/Player"
import s from "screens/NavHome/navHome.module.scss"
import NavBody from "./components/NavBody"
import useBtiStore from "stores/useBtiStore"

export interface iNavHome {
  isDrawerOpen: boolean
  resizeHome: () => void
  resizeContent: any
}

const NavHome: FC = (): JSX.Element => {
  const {id} = useParams() as {id: string}
  const history = useHistory()
  const {
    isLarge,
    navItems,
    setSelectedTitle,
    setSelectedIndex,
    setSliderIndex,
    handleClick,
  } = useNavHomeController()

  useEffect(() => {
    const pathName = history.location.pathname
    if (pathName !== "/") {
      window.history.replaceState(null, "", "/")
    }
  }, [])

  useQuery(
    ["PLAYER_REFERRAL_CODE"],
    async () => {
      const host = window.location.host
      const domain = host.split(".")

      const request = {
        distributor_name: punycode.toUnicode(domain[0]),
      }

      const resp = await servicePlayer.getReferralCode(request)
      return resp?.data
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess(data) {
        const host = window.location.host
        const domain = host.split(".")
        const hasSubDomain = domain.length > 2
        const isSecure = domain[0].includes("secure")
        const isCasino = id === "casino" || id === LIVE_CASINO
        const code =
          hasSubDomain && !isSecure ? data?.code : !isCasino && id ? id : data?.code
        localStorage.setItem("REFERRAL_CODE", code)
      },
    },
  )

  useEffect(() => {
    const path = id?.toLocaleLowerCase()
    const urlPath = history.location.pathname
    const paths = urlPath?.split("/")
    const isCasino = paths?.length > 2 && paths[1] === "casino"
    const isBettingRules = path === "betting-rules"
    if (isBettingRules) {
      const bti = useBtiStore.getState()
      bti.setIsBettingRules(true)
      setSelectedIndex(1)
      setSliderIndex(1)
      setSelectedTitle(BTI_SPORTS)
      handleClick(BTI_SPORTS)
    } else if (isCasino) {
      handleClick(LIVE_CASINO)
      localStorage.setItem("REFERRAL_CODE", paths[2])
    } else if (path === CASINO_PATH || path === "casino") {
      handleClick(LIVE_CASINO)
    }
  }, [id])

  useEffect(() => {
    const cachedTitle = localStorage.getItem(NAV_HOME_SELECTED_PAGE) ?? HOME
    const parsedTitle = cachedTitle.replace(/['"]+/g, "")
    const titles = navItems.map(item => item.title)
    const cachedIndex =
      titles.indexOf(parsedTitle) < 0 ? 0 : titles.indexOf(parsedTitle)
    setSelectedIndex(cachedIndex)
    setSliderIndex(cachedIndex)
    setSelectedTitle(parsedTitle)
  }, [isLarge, setSliderIndex, setSelectedIndex, setSelectedTitle])

  return <NavBody disabledSwipe={isLarge} />
}

export default memo(NavHome)
