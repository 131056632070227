import React, {useMemo} from "react"
import {CategoryIconType} from "./CategoryIconType"

const SoccerSVG = ({
  active,
  className,
  fill1,
  fill2,
}: CategoryIconType): JSX.Element => {
  const fill = useMemo(() => {
    return active ? fill1 : fill2
  }, [active])

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={className}
    >
      <g id="ball" transform="translate(-10 -3)">
        <g id="Group_25961" data-name="Group 25961">
          {active && (
            <g id="Group_13625" data-name="Group 13625" transform="translate(10 3)">
              <circle
                id="Ellipse_352"
                data-name="Ellipse 352"
                cx="12"
                cy="12"
                r="12"
                transform="translate(0)"
                fill="#fff"
              />
            </g>
          )}
          <g
            id="Group_13884"
            data-name="Group 13884"
            transform="translate(4.5 -2.5)"
          >
            <g
              id="Group_13626"
              data-name="Group 13626"
              transform="translate(6.196 11.088)"
            >
              <path
                id="Path_30611"
                data-name="Path 30611"
                d="M8,67.6l3.351-2.435L10,61.02a11.718,11.718,0,0,0-2,6.572S8,67.6,8,67.6Z"
                transform="translate(-8 -61.02)"
                fill={fill}
              />
            </g>
            <g
              id="Group_13627"
              data-name="Group 13627"
              transform="translate(13.619 6.196)"
            >
              <path
                id="Path_30612"
                data-name="Path 30612"
                d="M88.734,8.645l3.852,2.8,3.852-2.8a11.819,11.819,0,0,0-7.7,0Z"
                transform="translate(-88.734 -8)"
                fill={fill}
              />
            </g>
            <g
              id="Group_13628"
              data-name="Group 13628"
              transform="translate(8.408 24.317)"
            >
              <path
                id="Path_30613"
                data-name="Path 30613"
                d="M37.408,201.732l-4.2-.147a11.8,11.8,0,0,0,5.256,3.841Z"
                transform="translate(-33.211 -201.585)"
                fill={fill}
              />
            </g>
            <g
              id="Group_13629"
              data-name="Group 13629"
              transform="translate(21.014 24.189)"
            >
              <path
                id="Path_30614"
                data-name="Path 30614"
                d="M169.224,205.538a12.15,12.15,0,0,0,5.411-3.954l-4.32.151Z"
                transform="translate(-169.224 -201.584)"
                fill={fill}
              />
            </g>
            <g
              id="Group_13630"
              data-name="Group 13630"
              transform="translate(25.368 11.065)"
            >
              <path
                id="Path_30615"
                data-name="Path 30615"
                d="M213.6,64.949l3.374,2.452h0a11.718,11.718,0,0,0-2.019-6.623Z"
                transform="translate(-213.603 -60.778)"
                fill={fill}
              />
            </g>
            <g
              id="Group_13631"
              data-name="Group 13631"
              transform="translate(12.806 12.882)"
            >
              <path
                id="Path_30616"
                data-name="Path 30616"
                d="M85.113,81.108,80.447,84.5l1.782,5.485H88L89.778,84.5Z"
                transform="translate(-80.447 -81.108)"
                fill={fill}
              />
            </g>
            <g
              id="Group_13632"
              data-name="Group 13632"
              transform="translate(12.61 12.686)"
            >
              <path
                id="Path_30617"
                data-name="Path 30617"
                d="M86.192,88.376H80.425a.2.2,0,0,1-.187-.136l-1.782-5.485a.2.2,0,0,1,.071-.219l4.666-3.39a.2.2,0,0,1,.231,0l4.666,3.39a.2.2,0,0,1,.071.219L86.379,88.24A.2.2,0,0,1,86.192,88.376Zm-5.624-.392h5.482l1.694-5.213-4.435-3.222L78.874,82.77Z"
                transform="translate(-78.447 -79.109)"
                fill={fill}
              />
            </g>
            <g id="Group_13633" data-name="Group 13633" transform="translate(6 6)">
              <path
                id="Path_30618"
                data-name="Path 30618"
                d="M17.5,29A11.5,11.5,0,1,1,29,17.5,11.513,11.513,0,0,1,17.5,29Zm0-22.623A11.123,11.123,0,1,0,28.623,17.5,11.136,11.136,0,0,0,17.5,6.377Z"
                transform="translate(-6 -6)"
                fill={fill}
              />
            </g>
            <g
              id="Group_13634"
              data-name="Group 13634"
              transform="translate(25.172 10.984)"
            >
              <path
                id="Path_30619"
                data-name="Path 30619"
                d="M215.11,66.8a.2.2,0,0,1-.115-.037l-3.312-2.406a.2.2,0,0,1-.071-.219l1.318-4.057a.2.2,0,0,1,.373.121l-1.274,3.921,3.2,2.322a.2.2,0,0,1-.116.355Z"
                transform="translate(-211.602 -59.943)"
                fill={fill}
              />
            </g>
            <g
              id="Group_13635"
              data-name="Group 13635"
              transform="translate(21.439 14.932)"
            >
              <path
                id="Path_30620"
                data-name="Path 30620"
                d="M173.749,102.9a.2.2,0,0,1-.061-.383l3.733-1.213a.2.2,0,0,1,.121.373l-3.733,1.213A.2.2,0,0,1,173.749,102.9Z"
                transform="translate(-173.553 -101.293)"
                fill={fill}
              />
            </g>
            <g
              id="Group_13636"
              data-name="Group 13636"
              transform="translate(20.837 23.996)"
            >
              <path
                id="Path_30621"
                data-name="Path 30621"
                d="M167.629,203.922a.215.215,0,0,1-.058-.008.2.2,0,0,1-.143-.251l1.107-3.749a.208.208,0,0,1,.194-.147l4.357-.148a.209.209,0,0,1,.216.2.205.205,0,0,1-.2.21l-4.206.143-1.065,3.607A.208.208,0,0,1,167.629,203.922Z"
                transform="translate(-167.42 -199.62)"
                fill={fill}
              />
            </g>
            <g
              id="Group_13637"
              data-name="Group 13637"
              transform="translate(19.971 21.265)"
            >
              <path
                id="Path_30622"
                data-name="Path 30622"
                d="M157.806,173.043a.2.2,0,0,1-.16-.082l-2.22-3.092a.2.2,0,0,1,.319-.229l2.22,3.092a.2.2,0,0,1-.159.311Z"
                transform="translate(-155.389 -169.558)"
                fill={fill}
              />
            </g>
            <g
              id="Group_13638"
              data-name="Group 13638"
              transform="matrix(0.999, -0.035, 0.035, 0.999, 8.4, 24.262)"
            >
              <path
                id="Path_30623"
                data-name="Path 30623"
                d="M35.894,200.148h-.006l-.154-.006L32.114,200a.2.2,0,0,1,.012-.392l3.62.138.154.006a.19.19,0,0,1,.173.2.188.188,0,0,1-.179.189Z"
                transform="translate(-31.941 -199.612)"
                fill={fill}
              />
            </g>
            <g
              id="Group_13639"
              data-name="Group 13639"
              transform="translate(12.358 21.047)"
            >
              <path
                id="Path_30624"
                data-name="Path 30624"
                d="M75.216,176.648a.2.2,0,0,1-.189-.142L73.993,172.9a.2.2,0,0,1,.029-.168l2.22-3.093a.2.2,0,0,1,.319.229L74.4,172.884,75.4,176.4a.2.2,0,0,1-.189.25Z"
                transform="translate(-73.986 -169.557)"
                fill={fill}
              />
            </g>
            <g
              id="Group_13640"
              data-name="Group 13640"
              transform="translate(6.093 11)"
            >
              <path
                id="Path_30625"
                data-name="Path 30625"
                d="M7.141,66.908a.2.2,0,0,1-.116-.355l3.143-2.284L8.9,60.364a.2.2,0,1,1,.373-.121l1.313,4.041a.2.2,0,0,1-.071.219L7.257,66.87A.2.2,0,0,1,7.141,66.908Z"
                transform="translate(-6.945 -60.107)"
                fill={fill}
              />
            </g>
            <g
              id="Group_13641"
              data-name="Group 13641"
              transform="translate(9.351 14.932)"
            >
              <path
                id="Path_30626"
                data-name="Path 30626"
                d="M44.109,102.9a.2.2,0,0,1-.06-.01l-3.757-1.213a.2.2,0,1,1,.121-.374l3.757,1.213a.2.2,0,0,1-.06.383Z"
                transform="translate(-40.156 -101.296)"
                fill={fill}
              />
            </g>
            <g
              id="Group_13642"
              data-name="Group 13642"
              transform="translate(13.486 6.691)"
            >
              <path
                id="Path_30627"
                data-name="Path 30627"
                d="M91.363,16.193a.2.2,0,0,1-.115-.037L87.458,13.4a.2.2,0,0,1,.231-.317l3.674,2.669,3.666-2.663a.2.2,0,1,1,.231.317l-3.781,2.747A.2.2,0,0,1,91.363,16.193Z"
                transform="translate(-87.377 -13.048)"
                fill={fill}
              />
            </g>
            <g
              id="Group_13643"
              data-name="Group 13643"
              transform="translate(17.275 9.444)"
            >
              <path
                id="Path_30628"
                data-name="Path 30628"
                d="M126.2,45.229a.2.2,0,0,1-.2-.2V41.3a.2.2,0,1,1,.392,0v3.728A.2.2,0,0,1,126.2,45.229Z"
                transform="translate(-126 -41.108)"
                fill={fill}
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SoccerSVG
