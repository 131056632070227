const API = {
  LAUNCHER: `/game/launch/live`, // JKF - game id should not be static , should call an api to get the game id
  GAMES: `/bet365/games`,
  LEAGUE_DETAILS: `/bet365`,
  DEBIT: `/bet365/debit`,
  CHARGES: `/bet365/charges`,
  OPEN_BETS: `/bet365/charges?size=1000&filter[status_id]=0`, // 0 - pending , 1 - win , -1 lose , draw = 2
  PRESETS: `/bet365/presets`,
  BASE_GAMES: `/games`,
}

export default API
