enum GameType {
  All = "all",
  New = "new",
  TopRated = "topRated",
  Jackpot = "jackpot",
  Video = "video",
  Classic = "classic",
  Favorites = "favorites",
  RecentlyPlayed = "recentlyPlayed",
  Recommended = "recommended",
  Search = "search",
}

export default GameType
