import React, {useState, useEffect} from "react"

type WindowDimensions = {
  width: number
  height: number
}

const useWindowDimensions = (): WindowDimensions => {
  const [windowSize, setWindowSize] = useState<WindowDimensions>({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  useEffect(() => {
    const resizeCallback = () => {
      setWindowSize({width: window.innerWidth, height: window.innerHeight})
    }

    window.addEventListener("resize", resizeCallback)

    return () => {
      window.removeEventListener("resize", resizeCallback)
    }
  }, [])

  return windowSize
}

export default useWindowDimensions
