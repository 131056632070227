// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, {useContext, useState} from "react"
import {colorPalette as cp} from "utils/theme/colors"
import {isIOS} from "react-device-detect"
import Fade from "react-reveal/Fade"
import cx from "classnames"
import {FieldError} from "react-hook-form"
/**
 * Component
 */
import {IconExclamation} from "components/Icons/Forms"
/**
 * Styles
 */
import s from "./inputdefault.module.scss"
/**
 * Utils
 */
import {tabElement} from "utils/helpers"
import {ThemeContext} from "context/ThemeContext"

interface IFormUsernameInput {
  label: string
  stateValue?: string
  defaultValue?: string
  labelHelper?: string
  type?: string
  placeholder?: string
  errors?: FieldError
  onChange?: any
  maxLength?: number
  onFocus?: () => void
  onBlur?: () => void
  setValidUsername?: (validValue: boolean) => void
  register?: any
}

const FormInput = ({
  label,
  placeholder,
  labelHelper,
  errors,
  stateValue,
  onChange,
  register,
  maxLength = 30,
  onFocus,
  onBlur,
}: IFormUsernameInput): JSX.Element => {
  const [isFocused, setFocus] = useState(false)
  const {theme} = useContext(ThemeContext)
  function handleFocus() {
    setFocus(true)
    onFocus && onFocus()
  }

  function handleBlur() {
    setFocus(false)

    onBlur && onBlur()
  }

  return (
    <div>
      <div
        className={cx(s.inputBordered, {
          [s.inputBorderedValid]: errors === undefined && stateValue !== "",
          [s.inputBorderedActive]: isFocused,
          [s.inputBorderedError]:
            (!isFocused && errors) || errors?.type === "invalidFormat",
        })}
      >
        <div className={s.labelContainer}>
          <div className={s.label}>{label}</div>
          {labelHelper && (
            <div
              className={cx(s.labelInfo, {
                [s.labelInfoActive]: isFocused,
                [s.labelInfoError]:
                  (!isFocused && errors) || errors?.type === "invalidFormat",
              })}
            >
              <span className="message">
                <span className={s.parenStyle}>{`( `}</span>
                {labelHelper}
                <span className={s.parenStyle}>{` )`}</span>
              </span>
            </div>
          )}
        </div>
        <input
          id="player-name"
          autoCapitalize="off"
          autoCorrect="off"
          autoComplete="off"
          key={label}
          className={isIOS ? s.formInputIOS : s.formInput}
          name={label}
          type="text"
          ref={register}
          maxLength={maxLength}
          placeholder={isFocused ? `` : placeholder}
          onClick={() => onChange("")}
          onKeyUp={e => tabElement(e, true)}
          onFocus={() => handleFocus()}
          onBlur={() => handleBlur()}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e?.target?.value
            onChange(value)
          }}
        />
      </div>

      <div>
        <div
          className={cx(s.defaultMessage, {
            [s.defaultMessageActive]: isFocused,
            [s.defaultMessageError]:
              (!isFocused && errors) || errors?.type === "invalidFormat",
          })}
        >
          <Fade
            top
            opposite
            when={(!isFocused && errors) || errors?.type === "invalidFormat"}
          >
            <div className={`${s.defaultHelper} ${s.defaultHelperError}`}>
              <div className={s.icon}>
                <IconExclamation color={theme.colors.inputFieldErrorColor} />
              </div>
              <div className={s.defaultHelperTextError}>{errors?.message}</div>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  )
}

export default FormInput
