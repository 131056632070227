import React, {useMemo} from "react"
import {CategoryIconType} from "./CategoryIconType"

const HorseSVG = ({
  active,
  className,
  fill1,
  fill2,
}: CategoryIconType): JSX.Element => {
  const fill = useMemo(() => {
    return active ? fill1 : fill2
  }, [active])

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36.304"
      height="24"
      viewBox="0 0 36.304 24"
    >
      <g
        id="Group_25461"
        data-name="Group 25461"
        transform="translate(-233.168 -182)"
      >
        <g
          id="Group_25460"
          data-name="Group 25460"
          transform="translate(233.168 182)"
        >
          <g
            id="Group_25405"
            data-name="Group 25405"
            transform="translate(0 0)"
            clip-path="url(#clip-path)"
          >
            <path
              id="Path_62146"
              data-name="Path 62146"
              d="M9.952,10.308c-.55.17-1.131.3-1.675.524a7.476,7.476,0,0,1-5.009.565A5.1,5.1,0,0,1,0,8.716c2.563.882,4.816-.1,7.123-.326a5.664,5.664,0,0,1,4.007.851c1.522-1.463,3.385-1.324,5.26-.957a6.739,6.739,0,0,1,4.528-5.421A2.041,2.041,0,0,1,23.8,4c.312.609.616,1.222.971,1.926.981-.857,1.923-1.661,2.838-2.5a1.052,1.052,0,0,0,.186-.59c.04-.283.039-.572.064-1.015l.7.577.643-.953a2.884,2.884,0,0,0,.311.625c.211.233.458.58.714.6,1.851.158,2.932,1.682,4.435,2.449a.622.622,0,0,1-.009,1.142c-.054.035,0,.213-.053.273-.17.194-.368.519-.552.518-1.359-.015-2.717-.1-4.066-.156A14.046,14.046,0,0,0,28.2,13.049a1.193,1.193,0,0,0,.428.759c2.006,1.774,4.036,3.522,6.05,5.287a14.167,14.167,0,0,1,1.371,1.322c.43.495.3.936-.333,1.139a4.119,4.119,0,0,1-1.132.19.692.692,0,0,1-.807-.732c-.029-.562-.186-1.058-.993-.771-.109-1.057-.837-1.534-1.611-2.006-.943-.575-1.845-1.218-2.775-1.816a7.3,7.3,0,0,0-.8-.39l-.162.19c.649.612,1.294,1.228,1.948,1.835a.739.739,0,0,1,.027,1.2c-.674.734-1.3,1.517-1.984,2.238a2.688,2.688,0,0,1-2.71.507.746.746,0,0,1-.361-.561,1.21,1.21,0,0,1,1.895-1,2.252,2.252,0,0,0,.346.134c.017-.264.031-.474.048-.735.111-.033.27-.034.351-.113a3.8,3.8,0,0,0,.8-.944c.054-.11-.318-.539-.572-.666-2.492-1.242-5-2.443-7.865-2.548-.884-.033-1.768-.1-2.648-.178a1.217,1.217,0,0,0-1.213.646,15.513,15.513,0,0,1-1.837,2.09.812.812,0,0,0-.258,1.079,7.528,7.528,0,0,0,2.346,3.248c.7.465.77.833.707,1.807-1.463.45-2.222.042-2.01-.943-.352-.529-.626-1.007-.964-1.435-.7-.892-1.45-1.746-2.151-2.638-.354-.45-.521-.907.131-1.318.211-.133.293-.468.559-.926-.642.13-1.032.2-1.42.288a8.907,8.907,0,0,1-2.021.408,1.4,1.4,0,0,0-1.548,1.145A6.813,6.813,0,0,0,6.455,22.3a1.764,1.764,0,0,1-1.027,1.816c-.336-.45-.686-.894-1-1.361-.079-.116-.117-.4-.051-.453,1.279-1.058.818-2.6,1.124-3.927.037-.161.089-.32.112-.483a1.88,1.88,0,0,1,1.9-1.914c1.846-.2,2.5-1.375,2.331-3.254a18.442,18.442,0,0,1,.111-2.416M23.3,7.123l-.837-1.455L20.708,7.345c1.644.828,1.665.826,2.593-.222"
              transform="translate(0 -0.44)"
              fill={fill}
            />
            <path
              id="Path_62147"
              data-name="Path 62147"
              d="M35.519,3.659a1.842,1.842,0,0,1-1.853-1.83A1.8,1.8,0,0,1,35.506,0a1.84,1.84,0,0,1,1.871,1.817,1.88,1.88,0,0,1-1.857,1.842"
              transform="translate(-10.272 0)"
              fill={fill}
            />
            <path
              id="Path_62148"
              data-name="Path 62148"
              d="M32.393,8.978c-.928,1.047-.949,1.049-2.593.222l1.755-1.677.837,1.455"
              transform="translate(-9.092 -2.295)"
              fill={fill}
            />
            <path
              id="Path_62149"
              data-name="Path 62149"
              d="M17.024,8.416H7.571a.521.521,0,1,1,0-1.042h9.453a.521.521,0,1,1,0,1.042"
              transform="translate(-2.151 -2.25)"
              fill={fill}
            />
            <path
              id="Path_62150"
              data-name="Path 62150"
              d="M20.35,5.511H13.168a.521.521,0,1,1,0-1.042H20.35a.521.521,0,0,1,0,1.042"
              transform="translate(-3.859 -1.363)"
              fill={fill}
            />
            <path
              id="Path_62151"
              data-name="Path 62151"
              d="M23.266,2.518H16.917a.521.521,0,0,1,0-1.042h6.348a.521.521,0,1,1,0,1.042"
              transform="translate(-5.003 -0.45)"
              fill={fill}
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default HorseSVG
