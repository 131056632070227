// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, {useEffect, useState} from "react"
import {isIOS, isFirefox, isOpera, isMacOs, isWindows} from "react-device-detect"
import Swipe from "react-easy-swipe"
import Fade from "react-reveal/Fade"
/**
 * Components
 */
import {IconClose} from "components/Icons/Sidebar"
/**
 * Utils
 */
import Bus from "utils/events/bus"
/**
 * Styles
 */
import s from "./flash.module.css"

const Flash = (): JSX.Element => {
  const [visible, setVisibility] = useState<boolean>(false)
  const [message, setMessage] = useState<string>("")
  const [type, setType] = useState<string>("")
  const [details, setDetails] = useState<string>("")

  function alertType(type: string) {
    switch (type) {
      case `danger`:
        return s.flashMessageValueAlert
      case `success`:
        return s.flashMessageValueSuccess
      default:
        return s.flashMessageValueDefault
    }
  }

  useEffect(() => {
    Bus.addListener("flash", ({message, type, details}) => {
      setVisibility(true)
      setMessage(message)
      setType(type)
      setDetails(details)

      setTimeout(() => {
        setVisibility(false)
      }, 5000)
    })
  }, [])

  return (
    <Fade bottom when={visible}>
      <div
        className={`${
          isIOS || isFirefox || isOpera
            ? s.flashMessageContainerIOS
            : isMacOs || isWindows
            ? s.flashMessageContainer
            : s.flashMessageContainerAndroid
        } ${visible ? "" : s.flashMessageHeight}`}
      >
        <Swipe onSwipeDown={() => setVisibility(false)}>
          <div className={s.flashMessageContent}>
            <div className={s.flashMessageInfo}>
              {message?.split("\n").map((mValue: string) => (
                <div
                  key={mValue}
                  className={`${s.flashMessageValue} ${alertType(type)}`}
                >
                  <div dangerouslySetInnerHTML={{__html: mValue}} />
                </div>
              ))}
              {details?.split("\n").map((fValue: string) => (
                <div key={fValue} className={s.flashMessageValue}>
                  <div dangerouslySetInnerHTML={{__html: fValue}} />
                </div>
              ))}
            </div>
            <div
              className={s.flashMessageClose}
              onClick={() => setVisibility(false)}
              role="button"
            >
              <IconClose />
            </div>
          </div>
        </Swipe>
      </div>
    </Fade>
  )
}

export default Flash
