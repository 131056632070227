import {strongTypedRequest} from "services/request"
import API from "services/Bet365/endpoints"
/**
 * Utils
 */
import {getCookie} from "utils/hooks/useCookieStorage"
/**
 * Types
 */
import {
  BaseGames,
  Bet365Game,
  LeagueDetail,
  Round,
  ResultsResponse,
  Result,
  ChargeHistoryResponse,
  Charge,
  Presets,
  GameLauncherResponse,
} from "types/Bet365"
import {useUserStore} from "stores/userStore"
import {dateParamsFormat} from "../../utils/date"

function headers() {
  const {authToken} = useUserStore.getState()

  return {
    "Accept": "application/json",
    "Content-Type": "application/json",
    "Authorization": `Bearer ${authToken}`,
  }
}

function getBaseGames(): Promise<BaseGames> {
  return strongTypedRequest<BaseGames>({
    url: `${API.BASE_GAMES}?filter[vendor]=bet365`,
    method: "GET",
    headers: headers(),
  })
}

function getGames(): Promise<Bet365Game[]> {
  return strongTypedRequest<Bet365Game[]>({
    url: API.GAMES,
    method: "GET",
    headers: headers(),
  })
}

function getBet365Launcher(): Promise<GameLauncherResponse> {
  return strongTypedRequest<GameLauncherResponse>({
    url: localStorage.getItem("bet365-id") + API.LAUNCHER,
    method: "GET",
    headers: headers(),
  })
}

function getLeaugeDetail(leauge_code: string): Promise<LeagueDetail> {
  return strongTypedRequest<LeagueDetail>({
    url: `${API.LEAGUE_DETAILS}/${leauge_code}/odds?count=4`,
    method: "GET",
    headers: headers(),
  })
  // }).then(e => {
  //   const {code, hangul_name, name, rounds, game_id, id, interval} = e

  //   return {
  //     code,
  //     hangul_name,
  //     name,
  //     game_id,
  //     id,
  //     interval,
  //     rounds: rounds ? rounds.sort((a, b) => (a.round > b.round ? 1 : -1)) : [],
  //   }
  // })
}

function getNextRounds(
  leauge_code: string,
  last_round: number,
  rounds_length: number,
): Promise<[Round[], string]> {
  return strongTypedRequest<LeagueDetail>({
    url: `${API.LEAGUE_DETAILS}/${leauge_code}/odds`,
    method: "GET",
    headers: headers(),
    params: {
      last_round: last_round,
      count: 4 - rounds_length,
    },
  }).then(e => {
    return [e.rounds, e.code]
  })
}

function postBetSlips(data: Record<string, any>): Promise<any> {
  return strongTypedRequest<any>({
    url: `${API.DEBIT}`,
    method: `POST`,
    headers: headers(),
    data,
  })
}

function getLeagueResults(gameCode: string, leagueCode: string): Promise<Result[]> {
  return strongTypedRequest<ResultsResponse>({
    url: `${API.LEAGUE_DETAILS}/${gameCode}/${leagueCode}/results?size=4`,
    method: `GET`,
    headers: headers(),
  }).then(res => {
    return res.data
  })
}

function getPlayerCharges(): Promise<Charge[]> {
  return strongTypedRequest<ChargeHistoryResponse>({
    url: `${API.CHARGES}?filter[date_between]=${dateParamsFormat(
      new Date(),
    )} 00:00:00,${dateParamsFormat(new Date())} 23:59:59`,
    method: `GET`,
    headers: headers(),
  }).then(response => {
    return response.data
  })
}

function getOpenBets(): Promise<Charge[]> {
  return strongTypedRequest<ChargeHistoryResponse>({
    url: `${API.OPEN_BETS}`,
    method: `GET`,
    headers: headers(),
  }).then(response => {
    return response.data
  })
}

function getPlayerPresets(): Promise<Presets> {
  return strongTypedRequest<Presets>({
    url: `${API.PRESETS}`,
    method: `GET`,
    headers: headers(),
  })
}

function patchPresets(presets: Presets): Promise<Presets> {
  return strongTypedRequest<Presets>({
    url: `${API.PRESETS}`,
    method: `PATCH`,
    headers: headers(),
    data: presets,
  })
}

export default {
  getBet365Launcher,
  getBaseGames,
  getGames,
  getLeaugeDetail,
  getNextRounds,
  getLeagueResults,
  getPlayerCharges,
  postBetSlips,
  getPlayerPresets,
  patchPresets,
  getOpenBets,
}
