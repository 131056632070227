import create from "zustand"

interface iBtiStore {
  gameOpen: boolean
  isIFrameLoaded: boolean
  isLoading: boolean
  isBettingRules: boolean
  isLive: boolean
  isActive: boolean
  gameUrl: string
  setGameOpen: (value: boolean) => void
  setIsIFrameLoaded: (value: boolean) => void
  setIsLoading: (value: boolean) => void
  setIsBettingRules: (value: boolean) => void
  setIsLive: (value: boolean) => void
  setIsActive: (value: boolean) => void
  setGameUrl: (value: string) => void
  resetStore: () => void
}

const useBtiStore = create<iBtiStore>((set, get) => ({
  gameOpen: false,
  gameUrl: null,
  isIFrameLoaded: false,
  isLoading: false,
  isBettingRules: false,
  isLive: false,
  isActive: true,
  setGameOpen: gameOpen => set({gameOpen}),
  setIsIFrameLoaded: isIFrameLoaded => set({isIFrameLoaded}),
  setIsLoading: isLoading => set({isLoading}),
  setIsBettingRules: isBettingRules => set({isBettingRules}),
  setIsLive: isLive => set({isLive}),
  setIsActive: isActive => set({isActive}),
  setGameUrl: gameUrl => set({gameUrl}),
  resetStore: () => {
    set({
      gameOpen: false,
      gameUrl: null,
      isIFrameLoaded: false,
      isLoading: false,
      isBettingRules: false,
      isActive: true,
      isLive: false,
    })
  },
}))

export default useBtiStore
