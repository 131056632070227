import React from "react"
import create from "zustand"
import {parsePendingOTPMessage} from "utils/otp"
import {MAX_OTP_REQUEST} from "screens/MyProfile/constants"

interface iGlobalAlertStore {
  isModalOpen: boolean
  type: string
  message: React.ReactNode
  className: string
  setClassname: (classname: string) => void
  close: () => void
  setIsModalOpen: (value: boolean) => void
  setType: (value: string) => void
  setMessage: (value: React.ReactNode) => void
  setClose: (value: () => void) => void
  showAlert: (value: string | React.ReactNode, category?: string) => void
  resetStore: () => void
}

const useGlobalAlertStore = create<iGlobalAlertStore>((set, get) => ({
  isModalOpen: false,
  type: "",
  message: null,
  className: "",
  setClassname: (className: string) => set({className}),
  close: () => {
    //console
  },
  setIsModalOpen: isModalOpen => set({isModalOpen}),
  setType: type => set({type}),
  setMessage: message => set({message}),
  setClose: close => set({close}),
  showAlert: (value: string | React.ReactNode, category?: string) => {
    if (value === "otp.getOtpCode.pending.request")
      value = parsePendingOTPMessage(category)
    else if (value === "player.otp.maxRequest.otpCode") value = MAX_OTP_REQUEST
    get().setMessage(value)
    get().setIsModalOpen(true)
  },
  resetStore: () => {
    set({
      isModalOpen: false,
      type: "",
      message: null,
      className: "",
    })
  },
}))

export default useGlobalAlertStore
