// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from "react"

export default function IconDeposit(): JSX.Element {
  return (
    <svg width="28.125" height="25" viewBox="0 0 28.125 25">
      <defs>
        <linearGradient
          id="linear-gradient"
          x2="1.44"
          y2="2.605"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#00acee" />
          <stop offset="1" stopColor="#6c71b4" />
        </linearGradient>
      </defs>
      <path
        id="inbox-in-solid"
        d="M12.231,0h3.35a.835.835,0,0,1,.835.835V7.813h3.335a.975.975,0,0,1,.688,1.665l-5.83,5.859a.979.979,0,0,1-1.4-.015L7.666,9.463a.977.977,0,0,1,.708-1.65H11.4V.835A.838.838,0,0,1,12.231,0ZM27.612,16.548l-4.619-5.771a.592.592,0,0,0-.879-.044l-1.372,1.411a.594.594,0,0,0-.039.776l2.163,2.705H18.359L16.8,18.75H11.328L9.766,15.625H5.254l2.139-2.671a.586.586,0,0,0-.029-.767l-1.348-1.44a.586.586,0,0,0-.884.034L.522,16.543A2.309,2.309,0,0,0,0,18.008v4.648A2.344,2.344,0,0,0,2.344,25H25.781a2.344,2.344,0,0,0,2.344-2.344V18.008A2.333,2.333,0,0,0,27.612,16.548Z"
        transform="translate(0)"
        fill="#00ACEE"
      />
    </svg>
  )
}
