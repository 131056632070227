import create from "zustand"

interface iFadeOverlayStore {
  isTransition: boolean
  isShowed: boolean
  isFromWhite: boolean
  duration: number
  setIsTransition: (value: boolean) => void
  setIsShowed: (value: boolean) => void
  setIsFromWhite: (value: boolean) => void
  setDuration: (value: number) => void
}

const useFadeOverlayStore = create<iFadeOverlayStore>((set, get) => ({
  isTransition: false,
  isShowed: false,
  isFromWhite: false,
  duration: 3000,
  setIsTransition: isTransition => set({isTransition}),
  setIsShowed: isShowed => set({isShowed}),
  setIsFromWhite: isFromWhite => set({isFromWhite}),
  setDuration: duration => set({duration}),
}))

export default useFadeOverlayStore
