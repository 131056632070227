import create from "zustand"

import {Inquiry} from "types/CustomerCenter"

type InquiryStore = {
  hasNotification: boolean
  isSelectActive: boolean
  isModalOpen: boolean
  openInquiry: Inquiry | null
  addedInquiries: Inquiry[]
  setModalOpen: (value: boolean) => void
  setHasNotification: (value: boolean) => void
  setOpenInquiry: (inquiry: Inquiry) => void
  addInquiry: (inquiry: Inquiry) => void
  setIsSelectActive: (value: boolean) => void
}

export const useInquiryStore = create<InquiryStore>(set => ({
  hasNotification: false,
  isSelectActive: false,
  isModalOpen: false,
  openInquiry: null,
  addedInquiries: [],
  setModalOpen: isModalOpen => set({isModalOpen}),
  setHasNotification: hasNotification => set({hasNotification}),
  setOpenInquiry: (inquiry: Inquiry) => {
    set({openInquiry: inquiry})
  },
  addInquiry: (inquiry: Inquiry) => {
    set(state => ({
      addedInquiries: [...state.addedInquiries, inquiry],
    }))
  },
  setIsSelectActive: isSelectActive => set({isSelectActive}),
}))
