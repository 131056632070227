import create from "zustand"

interface iBeticaStore {
  gameOpen: boolean
  retryCount: number
  isBetSlipOpen: boolean
  isBetpSlipMounted: boolean
  isIFrameLoaded: boolean
  isLoading: boolean
  gameUrl: string
  setGameOpen: (value: boolean) => void
  setRetryCount: (value: number) => void
  setIsBetSlipOpen: (value: boolean) => void
  setIsBetpSlipMounted: (value: boolean) => void
  setIsIFrameLoaded: (value: boolean) => void
  setIsLoading: (value: boolean) => void
  setGameUrl: (value: string) => void
  addRetryCount: () => void
  getRetryCount: () => number
  resetStore: () => void
}

const useBeticaStore = create<iBeticaStore>((set, get) => ({
  gameOpen: false,
  retryCount: 0,
  gameUrl: null,
  isBetSlipOpen: null,
  isBetpSlipMounted: false,
  isIFrameLoaded: false,
  isLoading: false,
  setGameOpen: gameOpen => set({gameOpen}),
  setRetryCount: retryCount => set({retryCount}),
  setIsBetSlipOpen: isBetSlipOpen => set({isBetSlipOpen}),
  setIsBetpSlipMounted: isBetpSlipMounted => set({isBetpSlipMounted}),
  setIsIFrameLoaded: isIFrameLoaded => set({isIFrameLoaded}),
  setIsLoading: isLoading => set({isLoading}),
  setGameUrl: gameUrl => set({gameUrl}),
  addRetryCount: () => {
    const currentCount = get().retryCount
    set({retryCount: currentCount + 1})
  },
  getRetryCount: () => get().retryCount,
  resetStore: () => {
    set({
      gameOpen: false,
      retryCount: 0,
      gameUrl: null,
      isBetSlipOpen: null,
      isBetpSlipMounted: false,
      isIFrameLoaded: false,
      isLoading: false,
    })
  },
}))

export default useBeticaStore
