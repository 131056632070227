import service from "services/Player"
import {addSeconds} from "date-fns"
import {useUserStore} from "stores/userStore"

export async function refreshToken(): Promise<unknown> {
  const {setAuthToken, setExpiresIn} = useUserStore.getState()

  try {
    const response = await service.refreshToken()
    const {token, expires_in} = response?.data

    const dateNow = new Date()
    const timeExpiry = addSeconds(dateNow, Number(expires_in)).getTime()

    setAuthToken(token)
    setExpiresIn(timeExpiry)

    console.log(`refreshed`)

    return token
  } catch (e) {
    return e
  }
}
