import React, {FC, memo, useEffect, Suspense, CSSProperties, useContext} from "react"
import cx from "classnames"
import {useModal} from "react-modal-hook"
import shallow from "zustand/shallow"
import {LOGIN} from "screens/NavHome/utils/config"
import ModalLogin from "components/LoginButton/ModalLogin"
import useLoginModalStore from "components/LoginButton/hooks/useLoginModalStore"
import useLoginModalController from "components/LoginButton/hooks/useLoginModalController"
import navStore from "screens/NavHome/store/navStore"
import s from "components/LoginButton/loginButton.module.scss"
import ModalLayout from "components/LoginButton/Layout"
import {ThemeContext} from "context/ThemeContext"

interface iLoginButton {
  className?: string
}

interface CSSCustomProperties extends CSSProperties {
  "--buttonActiveTextColor"?: string
  "--buttonActivebottomLine"?: string
  "--headerHoverColor"?: string
  "--headerActiveColor"?: string
}

const LoginButton: FC<iLoginButton> = ({className}): JSX.Element => {
  const {theme} = useContext(ThemeContext)

  const custom_colors: CSSCustomProperties = {
    "--buttonActiveTextColor": theme?.colors.buttonActiveTextColor,
    "--buttonActivebottomLine": theme?.colors.buttonActivebottomLine,
    "--headerHoverColor": theme?.colors.headerHoverColor,
    "--headerActiveColor": theme?.colors.headerActiveColor,
  }

  const {isOpen, selectedTab, close} = useLoginModalStore(
    store => ({
      isOpen: store.isOpen,
      selectedTab: store.selectedTab,
      close: store.close,
    }),
    shallow,
  )

  const {selectedSlideTitle} = navStore(
    store => ({
      selectedSlideTitle: store.selectedSlideTitle,
    }),
    shallow,
  )

  const [showModal, hideModal] = useModal(() => {
    return (
      <ModalLogin
        content={
          <Suspense fallback={<></>}>
            <ModalLayout />
          </Suspense>
        }
        hideModal={hideModal}
      />
    )
  }, [])

  const {handleToggleModal} = useLoginModalController()

  useEffect(() => {
    if (isOpen) showModal()
  }, [isOpen])

  useEffect(() => {
    close && close()
  }, [selectedSlideTitle])

  return (
    <button
      style={custom_colors}
      className={cx(s.container, className, {
        [s.active]: isOpen && selectedTab === LOGIN,
      })}
      onClick={() => handleToggleModal(!isOpen)}
    >
      <div className={s.label}>{LOGIN}</div>
    </button>
  )
}

export default memo(LoginButton)
