import React, {FC, memo, useMemo} from "react"
import cx from "classnames"
import kebabCase from "lodash/kebabCase"

import {Charge} from "types/Bet365/index"

import s from "screens/Bet365/components/BetSlipV2/betSlipV2.module.scss"

interface iHomeAway {
  charge: Charge
  className?: string
}

const HomeAway: FC<iHomeAway> = ({charge, className}) => {
  const teams = charge.teams
  const home = teams.home
  const away = teams.away

  // const details = charge.details[0]
  const game = charge.game
  // const league = details.league
  const gameCode = game?.code
  const isBaseball = gameCode === "baseball"
  // const leagueCode = league?.code

  const homeImage = useMemo(() => {
    const homeCode = kebabCase(home.name)
    const imgPath = `/assets/img/`
    const type = gameCode === "soccer" ? `.png` : `.svg`

    const imgUrl = `${imgPath}${gameCode}/${homeCode}${type}`

    return imgUrl
  }, [charge])

  const awayImage = useMemo(() => {
    const awayCode = kebabCase(away.name)
    const imgPath = `/assets/img/`
    const type = gameCode === "soccer" ? `.png` : `.svg`

    const imgUrl = `${imgPath}${gameCode}/${awayCode}${type}`

    return imgUrl
  }, [teams])

  if (!home?.hangul_name?.length) return <></>

  return (
    <div className={cx(s.homeAway, className)}>
      <div className={s.teamRow}>
        {!isBaseball && (
          <img
            className={s.teamImg}
            src={homeImage}
            onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
              const eventTarget = e.target as HTMLImageElement
              eventTarget.onerror = null
              eventTarget.src = `/assets/img/bet365-icon.jpeg`
            }}
            alt="home"
          />
        )}
        <span
          className={s.team}
          style={{
            color: `#525252`,
          }}
        >
          {home.hangul_name}
        </span>
      </div>
      <span
        className={s.vs}
        style={{
          color: `#fd8f1f`,
        }}
      >
        VS
      </span>
      <div className={s.teamRow}>
        <span
          className={s.team}
          style={{
            color: `#525252`,
          }}
        >
          {away.hangul_name}
        </span>
        {!isBaseball && (
          <img
            className={s.teamImg}
            src={awayImage}
            onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
              const eventTarget = e.target as HTMLImageElement
              eventTarget.onerror = null
              eventTarget.src = `/assets/img/bet365-icon.jpeg`
            }}
            alt="away"
          />
        )}
      </div>
    </div>
  )
}

export default memo(HomeAway)
