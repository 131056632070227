// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from "react"

interface iIconExclamation {
  color: string
  size?: string
}

export default function IconExclamation({
  color,
  size = "12",
}: iIconExclamation): JSX.Element {
  return (
    <svg width={size} height={size} viewBox="0 0 17 17">
      <g id="Group_64" data-name="Group 64" transform="translate(19923 15428)">
        <g
          id="Ellipse_1"
          data-name="Ellipse 1"
          transform="translate(-19922 -15427)"
          fill="none"
          stroke={color}
          strokeWidth="1.2"
        >
          <circle cx="7.5" cy="7.5" r="7.5" stroke="none" />
          <circle cx="7.5" cy="7.5" r="8" fill="none" />
        </g>
        <g
          id="Repeat_Grid_3"
          data-name="Repeat Grid 3"
          transform="translate(-19915 -15424)"
        >
          <g id="Group_8" data-name="Group 8">
            <g
              id="Rectangle_106"
              data-name="Rectangle 106"
              fill={color}
              stroke={color}
              strokeWidth="3"
            >
              <rect width="1" height="7" rx="0.5" stroke="none" />
              <rect x="0.5" y="0.5" height="6" fill="none" />
            </g>
            <g
              id="Ellipse_2"
              data-name="Ellipse 2"
              transform="translate(0 8)"
              fill={color}
              stroke={color}
              strokeWidth="1"
            >
              <circle cx="0.5" cy="0.5" r="0.5" stroke="none" />
              <circle cx="0.5" cy="0.5" fill="none" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
