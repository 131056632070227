import {format, parseISO} from "date-fns"

export function appendLeadingZeroes(n: number): number | string {
  if (n <= 9) {
    return "0" + n
  }
  return n
}

export function dateDepositFormat(createdAt: string): string {
  const current_datetime = new Date(createdAt?.replace(" ", "T"))
  if (current_datetime.toString() == 'Invalid Date') {
    return '-';
  }

  return (
    appendLeadingZeroes(current_datetime.getMonth() + 1) +
    "-" +
    appendLeadingZeroes(current_datetime.getDate()) +
    " " +
    appendLeadingZeroes(current_datetime.getHours()) +
    ":" +
    appendLeadingZeroes(current_datetime.getMinutes())
  )
}

export function dateParamsFormat(date: Date, dateFormat = "yyyy-MM-dd"): string {
  return format(date, dateFormat)
}

export function dateFormat(date: string, dateFormat = "yyyy-MM-dd"): string {
  return format(new Date(parseISO(date)), dateFormat)
}
