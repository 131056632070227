import React from "react"
import IconProps from "./IconProps"

const RightArrow: React.FC<IconProps> = ({fill = "#fff", ...props}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="37"
    height="34"
    viewBox="0 0 37 34"
    {...props}
  >
    <g id="Group_39540" data-name="Group 39540" transform="translate(-179 -662)">
      <g
        id="Rectangle_18477"
        data-name="Rectangle 18477"
        transform="translate(216 662) rotate(90)"
        fill="none"
        stroke={fill}
        stroke-width="1"
      >
        <rect width="34" height="37" rx="8" stroke="none" />
        <rect x="0.5" y="0.5" width="33" height="36" rx="7.5" fill="none" />
      </g>
      <g id="Group_39541" data-name="Group 39541" transform="translate(0 1.669)">
        <path
          id="Path_86698"
          data-name="Path 86698"
          d="M0,0C.4.282,6.863,7.221,6.863,7.221L0,14.722"
          transform="translate(204.861 670.4) rotate(90)"
          fill="none"
          stroke={fill}
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <path
          id="Path_86699"
          data-name="Path 86699"
          d="M0,0C.4.282,6.863,7.221,6.863,7.221L0,14.722"
          transform="translate(190.139 684.263) rotate(-90)"
          fill="none"
          stroke={fill}
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
      </g>
    </g>
  </svg>
)
export default RightArrow
