import {
  HOME,
  BTI_SPORTS,
  // LIVE_SPORTS,
  // SPORTS,
  LIVE_CASINO,
  SLOTS,
  MINI_GAMES,
  BET365,
  SKY_PARK,
  PROMOTIONS,
  MY_PAGE,
  RECHARGE,
  SIGNUP,
  LOGIN,
  FIND_ID,
  FIND_PASSWORD,
  FREE_GAMES,
} from "screens/NavHome/utils/config"
import {lazyWithRetry} from "utils/lazyWithRetry"

// import Home from "screens/Home/MainV2"
import Home from "screens/Home/MainV3"
// const Home = lazyWithRetry(() => import("screens/Home/MainV2"))
// const LiveSports = lazyWithRetry(() => import("screens/Home/LiveSports"))
// const Sports = lazy(() => import("screens/Home/Sports"))
const LiveCasino = lazyWithRetry(() => import("screens/Casino"))
const SlotMachines = lazyWithRetry(() => import("screens/Slots"))
const MiniGame = lazyWithRetry(() => import("screens/Home/MiniGame"))
const FreeGame = lazyWithRetry(() => import("screens/Home/FreeGames"))
const Bet365 = lazyWithRetry(() => import("screens/Home/Bet365"))
const SkyPark = lazyWithRetry(() => import("screens/Home/SkyPark"))
const Promotions = lazyWithRetry(() => import("screens/PromotionsV2"))
const MyPage = lazyWithRetry(() => import("screens/Home/MyPage"))
const Account = lazyWithRetry(() => import("screens/Home/Account"))
const Register = lazyWithRetry(() => import("screens/Account/Register"))
const Login = lazyWithRetry(() => import("screens/Account/Login"))
const FindID = lazyWithRetry(() => import("screens/Account/FindID"))
const FindPassword = lazyWithRetry(() => import("screens/Account/FindPassword"))
const BTI = lazyWithRetry(() => import("screens/BTI"))

const HOME_CONFIG = {
  title: HOME,
  component: Home,
}

const BTI_SPORTS_CONFIG = {
  title: BTI_SPORTS,
  component: BTI,
}

// const LIVE_SPORTS_CONFIG = {
//   title: LIVE_SPORTS,
//   component: LiveSports,
// }

// const SPORTS_CONFIG = {
//   title: SPORTS,
//   component: LiveSports,
// }

const LIVE_CASINO_CONFIG = {
  title: LIVE_CASINO,
  component: LiveCasino,
}

const SLOTS_CONFIG = {
  title: SLOTS,
  component: SlotMachines,
}

const MINI_GAMES_CONFIG = {
  title: MINI_GAMES,
  component: MiniGame,
}

const BET365_CONFIG = {
  title: BET365,
  component: Bet365,
}

const SKY_PARK_CONFIG = {
  title: SKY_PARK,
  component: SkyPark,
}

const FREE_GAMES_CONFIG = {
  title: FREE_GAMES,
  component: FreeGame,
}

const PROMOTIONS_CONFIG = {
  title: PROMOTIONS,
  component: Promotions,
}

const MY_PAGE_CONFIG = {
  title: MY_PAGE,
  component: MyPage,
}

const REGISTER_CONFIG = {
  title: SIGNUP,
  component: Register,
}

const LOGIN_CONFIG = {
  title: LOGIN,
  component: Login,
}

const FIND_ID_CONFIG = {
  title: FIND_ID,
  component: FindID,
}

const FIND_PASSWORD_CONFIG = {
  title: FIND_PASSWORD,
  component: FindPassword,
}

export const RECHARGE_CONFIG = {
  title: RECHARGE,
  component: Account,
}

export const PRIVATE_PC_NAVER_ROUTES = [
  HOME_CONFIG,
  BTI_SPORTS_CONFIG,
  // LIVE_SPORTS_CONFIG,
  // SPORTS_CONFIG,
  LIVE_CASINO_CONFIG,
  SLOTS_CONFIG,
  MINI_GAMES_CONFIG,
  BET365_CONFIG,
  SKY_PARK_CONFIG,
  PROMOTIONS_CONFIG,
  FREE_GAMES_CONFIG,
  MY_PAGE_CONFIG,
]

export const PUBLIC_PC_NAVER_ROUTES = [
  HOME_CONFIG,
  BTI_SPORTS_CONFIG,
  // LIVE_SPORTS_CONFIG,
  // SPORTS_CONFIG,
  LIVE_CASINO_CONFIG,
  SLOTS_CONFIG,
  MINI_GAMES_CONFIG,
  SKY_PARK_CONFIG,
  PROMOTIONS_CONFIG,
  FREE_GAMES_CONFIG,
]

export const PRIVATE_TABLET_NAVER_ROUTES = [
  HOME_CONFIG,
  BTI_SPORTS_CONFIG,
  // LIVE_SPORTS_CONFIG,
  // SPORTS_CONFIG,
  LIVE_CASINO_CONFIG,
  SLOTS_CONFIG,
  MINI_GAMES_CONFIG,
  BET365_CONFIG,
  SKY_PARK_CONFIG,
  PROMOTIONS_CONFIG,
  FREE_GAMES_CONFIG,
  MY_PAGE_CONFIG,
]

export const PUBLIC_TABLET_NAVER_ROUTES = [
  HOME_CONFIG,
  BTI_SPORTS_CONFIG,
  // LIVE_SPORTS_CONFIG,
  // SPORTS_CONFIG,
  LIVE_CASINO_CONFIG,
  SLOTS_CONFIG,
  MINI_GAMES_CONFIG,
  SKY_PARK_CONFIG,
  PROMOTIONS_CONFIG,
  FREE_GAMES_CONFIG,
]

export const PRIVATE_MOBILE_NAVER_ROUTES = [
  HOME_CONFIG,
  BTI_SPORTS_CONFIG,
  // LIVE_SPORTS_CONFIG,
  // SPORTS_CONFIG,
  LIVE_CASINO_CONFIG,
  SLOTS_CONFIG,
  MINI_GAMES_CONFIG,
  BET365_CONFIG,
  SKY_PARK_CONFIG,
  PROMOTIONS_CONFIG,
  FREE_GAMES_CONFIG,
  MY_PAGE_CONFIG,
  RECHARGE_CONFIG,
]

export const PUBLIC_MOBILE_NAVER_ROUTES = [
  HOME_CONFIG,
  BTI_SPORTS_CONFIG,
  // LIVE_SPORTS_CONFIG,
  // SPORTS_CONFIG,
  LIVE_CASINO_CONFIG,
  SLOTS_CONFIG,
  MINI_GAMES_CONFIG,
  SKY_PARK_CONFIG,
  PROMOTIONS_CONFIG,
  FREE_GAMES_CONFIG,
]

export const PRIVATE_TABLET_SLIDER = [
  HOME,
  BTI_SPORTS,
  // LIVE_SPORTS,
  // SPORTS,
  LIVE_CASINO,
  SLOTS,
  MINI_GAMES,
  BET365,
  SKY_PARK,
  PROMOTIONS,
  FREE_GAMES,
  MY_PAGE,
]

export const PUBLIC_TABLET_SLIDER = [
  HOME,
  BTI_SPORTS,
  // LIVE_SPORTS,
  // SPORTS,
  LIVE_CASINO,
  SLOTS,
  MINI_GAMES,
  SKY_PARK,
  PROMOTIONS,
  FREE_GAMES,
]

export const PRIVATE_MOBILE_SLIDER = [
  HOME,
  BTI_SPORTS,
  // LIVE_SPORTS,
  // SPORTS,
  LIVE_CASINO,
  SLOTS,
  MINI_GAMES,
  BET365,
  SKY_PARK,
  PROMOTIONS,
  FREE_GAMES,
  MY_PAGE,
  RECHARGE,
]

export const PUBLIC_MOBILE_SLIDER = [
  HOME,
  BTI_SPORTS,
  // LIVE_SPORTS,
  // SPORTS,
  LIVE_CASINO,
  SLOTS,
  MINI_GAMES,
  SKY_PARK,
  PROMOTIONS,
  FREE_GAMES,
]

export const EZ_PRIVATE_PC_NAVER_ROUTES = [
  HOME_CONFIG,
  // BTI_SPORTS_CONFIG,
  // LIVE_SPORTS_CONFIG,
  // SPORTS_CONFIG,
  LIVE_CASINO_CONFIG,
  SLOTS_CONFIG,
  MINI_GAMES_CONFIG,
  BET365_CONFIG,
  SKY_PARK_CONFIG,
  PROMOTIONS_CONFIG,
  FREE_GAMES_CONFIG,
  MY_PAGE_CONFIG,
]

export const EZ_PUBLIC_PC_NAVER_ROUTES = [
  HOME_CONFIG,
  // BTI_SPORTS_CONFIG,
  // LIVE_SPORTS_CONFIG,
  // SPORTS_CONFIG,
  LIVE_CASINO_CONFIG,
  SLOTS_CONFIG,
  MINI_GAMES_CONFIG,
  SKY_PARK_CONFIG,
  PROMOTIONS_CONFIG,
  FREE_GAMES_CONFIG,
]

export const EZ_PRIVATE_TABLET_NAVER_ROUTES = [
  HOME_CONFIG,
  // BTI_SPORTS_CONFIG,
  // LIVE_SPORTS_CONFIG,
  // SPORTS_CONFIG,
  LIVE_CASINO_CONFIG,
  SLOTS_CONFIG,
  MINI_GAMES_CONFIG,
  BET365_CONFIG,
  SKY_PARK_CONFIG,
  PROMOTIONS_CONFIG,
  FREE_GAMES_CONFIG,
  MY_PAGE_CONFIG,
]

export const EZ_PUBLIC_TABLET_NAVER_ROUTES = [
  HOME_CONFIG,
  // BTI_SPORTS_CONFIG,
  // LIVE_SPORTS_CONFIG,
  // SPORTS_CONFIG,
  LIVE_CASINO_CONFIG,
  SLOTS_CONFIG,
  MINI_GAMES_CONFIG,
  SKY_PARK_CONFIG,
  PROMOTIONS_CONFIG,
  FREE_GAMES_CONFIG,
]

export const EZ_PRIVATE_MOBILE_NAVER_ROUTES = [
  HOME_CONFIG,
  // BTI_SPORTS_CONFIG,
  // LIVE_SPORTS_CONFIG,
  // SPORTS_CONFIG,
  LIVE_CASINO_CONFIG,
  SLOTS_CONFIG,
  MINI_GAMES_CONFIG,
  BET365_CONFIG,
  SKY_PARK_CONFIG,
  PROMOTIONS_CONFIG,
  FREE_GAMES_CONFIG,
  MY_PAGE_CONFIG,
  RECHARGE_CONFIG,
]

export const EZ_PUBLIC_MOBILE_NAVER_ROUTES = [
  HOME_CONFIG,
  // BTI_SPORTS_CONFIG,
  // LIVE_SPORTS_CONFIG,
  // SPORTS_CONFIG,
  LIVE_CASINO_CONFIG,
  SLOTS_CONFIG,
  MINI_GAMES_CONFIG,
  SKY_PARK_CONFIG,
  PROMOTIONS_CONFIG,
  FREE_GAMES_CONFIG,
]

export const EZ_PRIVATE_TABLET_SLIDER = [
  HOME,
  // BTI_SPORTS,
  // LIVE_SPORTS,
  // SPORTS,
  LIVE_CASINO,
  SLOTS,
  MINI_GAMES,
  BET365,
  SKY_PARK,
  PROMOTIONS,
  FREE_GAMES,
  MY_PAGE,
]

export const EZ_PUBLIC_TABLET_SLIDER = [
  HOME,
  // BTI_SPORTS,
  // LIVE_SPORTS,
  // SPORTS,
  LIVE_CASINO,
  SLOTS,
  MINI_GAMES,
  SKY_PARK,
  PROMOTIONS,
  FREE_GAMES,
]

export const EZ_PRIVATE_MOBILE_SLIDER = [
  HOME,
  // BTI_SPORTS,
  // LIVE_SPORTS,
  // SPORTS,
  LIVE_CASINO,
  SLOTS,
  MINI_GAMES,
  BET365,
  SKY_PARK,
  PROMOTIONS,
  FREE_GAMES,
  MY_PAGE,
  RECHARGE,
]

export const EZ_PUBLIC_MOBILE_SLIDER = [
  HOME,
  // BTI_SPORTS,
  // LIVE_SPORTS,
  // SPORTS,
  LIVE_CASINO,
  SLOTS,
  MINI_GAMES,
  SKY_PARK,
  PROMOTIONS,
  FREE_GAMES,
]
