import React, {memo, Suspense} from "react"
import {Switch, Route, withRouter, useLocation} from "react-router-dom"
import shallow from "zustand/shallow"
import {FooterMenu} from "routes/Footer"
import {useBetSlipStore} from "screens/Bet365/store"
import {useBetSlipStore as useFreeBetSlipstore} from "screens/FreeBet365/store"
import useNewsStore from "screens/News/stores/useNewsStore"
import {lazyWithRetry} from "utils/lazyWithRetry"
import CenterLayout from "./CenterLayout"
import NavHome from "screens/NavHome"
const Footer = lazyWithRetry(() => import("layout/Footer"))
const BetSlips = lazyWithRetry(() => import("screens/Bet365/components/BetSlips"))
const FreeBetSlips = lazyWithRetry(
  () => import("screens/FreeBet365/components/BetSlips"),
)
const News = lazyWithRetry(() => import("screens/News"))

const Content = () => {
  const location = useLocation()

  const betSlipsOpen = useBetSlipStore(store => store.betSlipsOpen, shallow)
  const freeBetSlipsOpen = useFreeBetSlipstore(store => store.betSlipsOpen, shallow)
  const isNewsOpen = useNewsStore(store => store.isOpen, shallow)

  return (
    <>
      <CenterLayout>
        {betSlipsOpen && (
          <Suspense fallback={<></>}>
            <BetSlips />
          </Suspense>
        )}
        {freeBetSlipsOpen && (
          <Suspense fallback={<></>}>
            <FreeBetSlips />
          </Suspense>
        )}
        {isNewsOpen && (
          <Suspense fallback={<></>}>
            <News />
          </Suspense>
        )}
        <Suspense fallback={<></>}>
          <Switch location={location}>
            {FooterMenu?.map((pRoutes): any => {
              return (
                <Route
                  key={pRoutes.path}
                  exact={true}
                  path={pRoutes?.path}
                  component={pRoutes.component}
                />
              )
            })}
            <Route exact path="/casino/:id" component={NavHome} />
            <Route exact path="/:id" component={NavHome} />
            <Route exact path="/" component={NavHome} />
          </Switch>
        </Suspense>
      </CenterLayout>
      <Footer />
    </>
  )
}

export default memo(withRouter(Content))
