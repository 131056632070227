import {SkyparkGame, Game as GameDetail, OddBet, Odd} from "types/SkyPark"
import create from "zustand"
import clone from "lodash/clone"

type SkyparkStore = {
  games: Record<string, GameDetail>
  selectedGame: SkyparkGame
  selectedGameDetails: GameDetail
  setGames: (games: GameDetail[]) => void
  setSelectedGame: (game: SkyparkGame) => void
  setSelectedGameDetails: (gameCode: string) => void

  selectedOdds: Record<string, OddBet>
  setSelectedOdds: (key: string, odd: Odd) => void
  removeOdds: (gameId: number) => void

  setOddBet: (key: string, amount: number) => void
  clearOddAmount: (key: string) => void

  isLoading: boolean
  isLoadingGame: boolean
  setIsLoading: (loading: boolean) => void
  setIsLoadingGame: (loadingGame: boolean) => void

  isRoundClosed: boolean
  setRound: (roundStatus: boolean) => void

  isSkyparkAvailable: () => boolean

  refresh: boolean
  setRefresh: (value: boolean) => void

  isSkyParkActive: boolean
  setIsSkyParkActive: (value: boolean) => void

  isKeypadOpen: boolean
  setIsKeypadOpen: (value: boolean) => void

  hasRound: boolean
  setHasRound: (value: boolean) => void
  clearSelection: () => void

  isPlaceBetLoading: boolean
  setPlaceBetIsLoading: (value: boolean) => void

  placeBet: () => void
  setPlaceBet: (value: () => void) => void
  resetGames: () => void
}

export const useSkyparkStore = create<SkyparkStore>(
  // persist(
  (set, get) => ({
    hasRound: false,
    setHasRound: hasRound => set({hasRound}),
    isKeypadOpen: false,
    setIsKeypadOpen: isKeypadOpen => set({isKeypadOpen}),
    isSkyParkActive: true,
    setIsSkyParkActive: isSkyParkActive => set({isSkyParkActive}),
    refresh: false,
    setRefresh: refresh => set({refresh}),
    // ODDS VARIABLES

    selectedOdds: {},
    setSelectedOdds: (key: string, odd: Odd) => {
      const {selectedOdds} = get()
      const marketOdd = selectedOdds[key]

      if (marketOdd && marketOdd.name === odd.name) {
        const tempOdds = clone(selectedOdds)
        delete tempOdds[key]
        set({selectedOdds: tempOdds})
        return
      }
      set({selectedOdds: {...selectedOdds, [key]: odd}})
    },
    removeOdds: (gameId: number) => {
      const {selectedOdds} = get()

      const tempOdds = clone(selectedOdds)
      delete tempOdds[String(gameId)]
      set({selectedOdds: tempOdds})
    },
    setOddBet: (key: string, amount: number) => {
      const {selectedOdds} = get()

      const odd = selectedOdds[key]

      if (!odd) return

      set({
        selectedOdds: {
          ...selectedOdds,
          [key]: {...odd, amount: amount},
        },
      })
    },

    clearOddAmount: (key: string) => {
      const {selectedOdds} = get()

      const selectedOdd = clone(selectedOdds[key])

      if (!selectedOdd) return

      delete selectedOdd.amount

      set({selectedOdds: {...selectedOdds, [key]: selectedOdd}})
    },

    // GAMES VARIABLES

    games: {},
    selectedGame: null,
    selectedGameDetails: null,
    setGames: (games: GameDetail[]) => {
      const gameRecord: Record<string, GameDetail> = {}
      for (const game of games) {
        gameRecord[String(game.code)] = game
      }

      set({games: gameRecord})
    },
    setSelectedGame: (game: SkyparkGame) => {
      set({selectedGame: game})
    },
    setSelectedGameDetails: (gameCode: string) => {
      const {games} = get()
      set({selectedGameDetails: games[gameCode]})
    },

    // LOADING VARIABLES

    isLoading: false,
    isLoadingGame: false,
    setIsLoading: (loading: boolean) => {
      set({isLoading: loading})
    },
    setIsLoadingGame: (loadingGame: boolean) => {
      set({isLoadingGame: loadingGame})
    },

    isRoundClosed: true,
    setRound: (roundStatus: boolean) => {
      set({isRoundClosed: roundStatus})
    },
    isSkyparkAvailable: () => {
      const {games} = get()
      return Object.values(games).reduce(
        (available, game) => available || game.is_active,
        false,
      )
    },
    clearSelection: () => {
      set({selectedOdds: {}})
    },

    isPlaceBetLoading: false,
    placeBet: null,
    setPlaceBet: placeBet => set({placeBet}),
    setPlaceBetIsLoading: isPlaceBetLoading => set({isPlaceBetLoading}),
    resetGames: () => set({games: {}}),
  }),
  // {
  //   name: "Skypark",
  // },
  // ),
)

type TimerStore = {
  serverTimestamp: number | null
  setServerTimestamp: (timestamp: number) => void
  tick: () => void
  getServerDate: () => Date
  getRemainingSeconds: (closeTime: number) => number
  isFuture: (closeTime: number) => boolean
}

export const useTimerStore = create<TimerStore>((set, get) => ({
  serverTimestamp: null,
  setServerTimestamp: (timestamp: number) => {
    set({serverTimestamp: timestamp})
  },
  tick: () => {
    const skyparkStore = useSkyparkStore.getState()
    const {serverTimestamp} = get()

    const selectedRound = skyparkStore.selectedGame?.round?.expired_at
    const diff = selectedRound - serverTimestamp
    if (diff <= 0) skyparkStore.setRound(true)

    set({serverTimestamp: serverTimestamp + 1000})
  },
  getServerDate: () => {
    const {serverTimestamp} = get()
    return new Date(serverTimestamp)
  },
  getRemainingSeconds: (closeTime: number) => {
    const {serverTimestamp} = get()
    return closeTime - serverTimestamp
  },
  isFuture: (closeTime: number) => {
    const {serverTimestamp} = get()
    const remainingSeconds = closeTime - serverTimestamp

    return remainingSeconds > 0 ? true : false
  },
}))
