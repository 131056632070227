import { useEffect, useState, useRef } from 'react';

const useOnInteraction = (inactiveTimeout = 10000, iframeRef = null) => {
  const [interacted, setInteracted] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const resetTimeout = () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        setInteracted(false);
      }, inactiveTimeout);
    };

    const handleInteraction = (): void => {
      setInteracted(true);
      resetTimeout();
    };

    // Add event listeners for user interaction on the main window
    const mainEvents = ['mousemove', 'click', 'scroll', 'touchstart'];
    mainEvents.forEach(event => window.addEventListener(event, handleInteraction));

    // If iframeRef is provided, add event listeners to the iframe's content window
    if (iframeRef && iframeRef.current) {
      const iframeWindow = iframeRef.current.contentWindow;

      if (iframeWindow) {
        const iframeEvents = ['mousemove', 'click', 'scroll', 'touchstart', 'focus'];

        // Check if the iframe supports adding event listeners
        iframeEvents.forEach(event => iframeWindow.addEventListener(event, handleInteraction));

        // Optional: Listen for focus/blur events on the iframe
        iframeRef.current.addEventListener('focus', handleInteraction);
        iframeRef.current.addEventListener('blur', () => {
          // Optionally reset interacted when the iframe loses focus
          setInteracted(false);
        });
      }
    }

    return () => {
      // Clean up event listeners on the main window
      mainEvents.forEach(event => window.removeEventListener(event, handleInteraction));

      // Clean up iframe event listeners
      if (iframeRef && iframeRef.current) {
        const iframeWindow = iframeRef.current.contentWindow;

        if (iframeWindow) {
          const iframeEvents = ['mousemove', 'click', 'scroll', 'touchstart', 'focus'];
          iframeEvents.forEach(event => iframeWindow.removeEventListener(event, handleInteraction));
        }
      }

      // Clear the timeout
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, [inactiveTimeout, iframeRef]);

  return interacted;
};

export { useOnInteraction };
