import React, {FC, useMemo, memo, Suspense} from "react"
import {useWindowSize} from "react-use"
import Mobile from "./Mobile"
import Tablet from "./Tablet"

interface iNavSlider {
  className?: string
}

const NavSlider: FC<iNavSlider> = ({className}): JSX.Element => {
  const {width} = useWindowSize()
  const isTablet = useMemo(() => {
    return width > 673
  }, [width])

  if (isTablet)
    return (
      <Suspense fallback={<></>}>
        <Tablet className={className} />
      </Suspense>
    )

  return (
    <Suspense fallback={<></>}>
      <Mobile className={className} />
    </Suspense>
  )
}

export default memo(NavSlider)
