import React, {FC, useState, useEffect} from "react"
import cx from "classnames"
import shallow from "zustand/shallow"
import useRegisterModalStore from "components/RegisterButton/hooks/useRegisterModalStore"
import useNavBarController from "screens/NavHome/hooks/useNavBarController"
import Button from "screens/MyProfile/components/DateRangePicker/Button"
import {PROMOTIONS} from "screens/NavHome/utils/config"
import s from "./success.module.scss"

interface iSuccess {
  handleOpenlogin: () => void
}

const SuccessView: FC<iSuccess> = ({handleOpenlogin}): JSX.Element => {
  const {handleNavigateTo} = useNavBarController()
  const [fadeIn, setFadeIn] = useState<boolean>(false)
  const [fadeCheck, setFadeCheck] = useState<boolean>(false)
  const {close} = useRegisterModalStore(
    store => ({
      close: store.close,
    }),
    shallow,
  )

  useEffect(() => {
    setTimeout(() => {
      setFadeIn(true)
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setFadeCheck(true)
    }, 1000)
  }, [])

  const handleRedirect = (to: string) => {
    handleNavigateTo(to)
    setTimeout(() => {
      close()
    }, 600)
  }

  return (
    <>
      <div className={cx(s.container, {[s.show]: fadeIn})}>
        <div className={s.header}>
          <div className={cx(s.check, {[s.show]: fadeCheck})}>
            <img src="/assets/img/register-success-check-blue.svg" />
          </div>
          <div className={s.title}>{`회원 가입이 완료 되었습니다.`}</div>
        </div>
        <div className={s.description}>
          <p>
            <span className={s.bold}>Ezbet</span> 회원 가입이 정상적으로
            완료되었습니다.
          </p>
          <p>스포츠 및 유료 컨텐츠 이용을 위해서는</p>
          <p>필히 계좌번호 추가 등록을 해주셔야 합니다.</p>
          <p className={s.contentBlue}>
            신규 회원을 위해 첫 계좌 등록시 무료 게임 체험 머니를 지급해드립니다.
          </p>
          <p>확인 버튼 클릭시 로그인 화면으로 이동 합니다.</p>
        </div>
        <div className={s.buttonContainer}>
          <Button
            className={s.button}
            label={"로그인"}
            type={"read"}
            handleClick={() => handleOpenlogin()}
          />
        </div>
        <div className={s.contentLinks}>
          <div className={s.links} onClick={() => handleRedirect(PROMOTIONS)}>
            이벤트
          </div>
          <div>
            <div className={s.links} onClick={() => handleRedirect("/")}>
              고객지원
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SuccessView
