interface iColorPalette {
  white: string
  red: string
  grey: string
  grey2: string
  lightgrey: string
  lightgrey2: string
  lightgrey3: string
  darkgrey: string
  aquablue: string
  blue: string
  darkblue: string
  green: string
}

export const colorPalette: iColorPalette = {
  white: "#ffffff",
  red: "#FA3939",
  grey: "#7f7f7f",
  grey2: "#525252",
  lightgrey: "#f4f4f4",
  lightgrey2: "#959595",
  lightgrey3: "#c9c9c9",
  darkgrey: "#444444",
  aquablue: "#00acee",
  blue: "#007aff",
  darkblue: "#007ac4",
  green: "#00CE18",
}

export const colorHandler = (color: string): string => {
  switch (color) {
    case "error": {
      return colorPalette.red
    }
    case "valid": {
      return colorPalette.aquablue
    }
    case "success": {
      return colorPalette.green
    }
    case "transparent": {
      return "transparent"
    }
    default: {
      return colorPalette.grey
    }
  }
}

export const borderColorHandler = (color: string): string => {
  switch (color) {
    case "error": {
      return colorPalette.red
    }
    case "valid": {
      return colorPalette.aquablue
    }
    case "focused": {
      return colorPalette.aquablue
    }
    case "transparent": {
      return "transparent"
    }
    default: {
      return colorPalette.grey
    }
  }
}

export const buttonThemeHandler = (color: string): Record<string, string> => {
  switch (color) {
    case "primary":
    default:
      return {
        bgColor: colorPalette.aquablue,
        fontColor: "#ffffff",
        borderColor: "#1da1f2",
      }
    case "disabled":
      return {
        bgColor: colorPalette.lightgrey,
        fontColor: "#444444",
        borderColor: "#f4f4f4",
      }
    case "disabled2":
      return {
        bgColor: colorPalette.lightgrey3,
        fontColor: "#ffffff",
        borderColor: "transparent",
      }
    case "transparent":
      return {
        bgColor: "transparent",
        fontColor: "#444444",
        borderColor: "#7f7f7f",
      }
  }
}

export function hexToRGBA(hex: string, opacity: number): string {
  let c: any
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("")
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }

    c = "0x" + c.join("")
    return (
      "rgba(" +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
      ", " +
      opacity +
      ")"
    )
  }
  throw new Error("Bad Hex")
}
