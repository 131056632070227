import create from "zustand"
import Question from "screens/MyProfile/types/Affiliate/Question"
import Details from "screens/MyProfile/types/Affiliate/Details"
import InterviewAnswers from "screens/MyProfile/types/Affiliate/InterviewAnswers"
import ApplicationRequest from "services/Affiliate/types/ApplicationRequest"
import InterviewRequest from "services/Affiliate/types/InterviewRequest"

interface iAffiliateStore {
  isEnbabled: boolean
  alertMessage: string
  currentStep: number
  totalSteps: number
  selectedTags: string[]
  questionList: Question[]
  interviewAnswers: InterviewAnswers
  applicationRequest: ApplicationRequest
  applicationStatus: number
  details: Details
  isRead: boolean
  setIsRead: (value: boolean) => void
  setDetails: (value: Details) => void
  setIsEnbabled: (value: boolean) => void
  setApplicationStatus: (value: number) => void
  setAlertMessage: (value: string) => void
  setCurrentStep: (value: number) => void
  setTotalSteps: (value: number) => void
  setQuestionList: (value: Question[]) => void
  upsertInterviewAnswers: (key: string, value: string, order: number) => void
  removeInterviewAnswer: (key: string) => void
  updateTag: (value: string) => void
  nextStep: () => void
  setApplicationRequest: (value: ApplicationRequest) => void
  setSelectedTags: (value: string[]) => void
  setDistributorReq: (value: string) => void
  setEmailReq: (value: string) => void
  setTelegramReq: (value: string) => void
  setInterviewReq: (value: InterviewRequest[]) => void
  setTagsReq: (value: string[]) => void
  setOTPReq: (value: number) => void
  handleParseAnswer: (value: InterviewAnswers) => void
  resetStore: () => void
  handleSendApplication: (value: ApplicationRequest) => void
  setHandleSendApplication: (submit: (value: ApplicationRequest) => void) => void
}

const useAffiliateStore = create<iAffiliateStore>((set, get) => ({
  isEnbabled: false,
  alertMessage: null,
  currentStep: 1,
  totalSteps: 4,
  selectedTags: [],
  questionList: [],
  applicationRequest: {
    distributor_name: null,
    email: null,
    telegram_id: null,
    interview: null,
    tags: null,
    otp: null,
  },
  interviewAnswers: {},
  applicationStatus: null,
  handleSendApplication: null,
  details: null,
  isRead: false,
  setIsRead: isRead => set({isRead}),
  setDetails: details => set({details}),
  setIsEnbabled: isEnbabled => set({isEnbabled}),
  setApplicationStatus: applicationStatus => set({applicationStatus}),
  setAlertMessage: alertMessage => set({alertMessage}),
  setCurrentStep: currentStep => set({currentStep}),
  setTotalSteps: totalSteps => set({totalSteps}),
  setQuestionList: questionList => set({questionList}),
  upsertInterviewAnswers: (key: string, value: string, order: number) => {
    const parse = get().handleParseAnswer
    const answers = get().interviewAnswers
    answers[key] = {value, order}
    parse(answers)
    set({
      interviewAnswers: answers,
    })
  },
  removeInterviewAnswer: (key: string) => {
    const parse = get().handleParseAnswer
    const answers = get().interviewAnswers
    delete answers[key]
    parse(answers)
  },
  updateTag: (value: string) => {
    const setTagsReq = get().setTagsReq
    const tags = get().selectedTags
    if (tags.indexOf(value) < 0) {
      const newVal = [...tags, value]
      setTagsReq(newVal)
      set({
        selectedTags: newVal,
      })
    } else {
      const newVal = tags.filter(item => item !== value)
      setTagsReq(newVal)
      set({
        selectedTags: newVal,
      })
    }
  },
  nextStep: () => {
    const step = get().currentStep
    set({
      currentStep: step + 1,
    })
  },
  setApplicationRequest: applicationRequest => set({applicationRequest}),
  setSelectedTags: selectedTags => set({selectedTags}),
  setDistributorReq: (value: string) => {
    const request = get().applicationRequest
    const update = {...request, distributor_name: value}
    set({applicationRequest: update})
  },
  setEmailReq: (value: string) => {
    const request = get().applicationRequest
    const update = {...request, email: value}
    set({applicationRequest: update})
  },
  setTelegramReq: (value: string) => {
    const request = get().applicationRequest
    const update = {...request, telegram_id: value}
    set({applicationRequest: update})
  },
  setInterviewReq: (value: InterviewRequest[]) => {
    const request = get().applicationRequest
    const update = {...request, interview: value}
    set({applicationRequest: update})
  },
  setTagsReq: (value: string[]) => {
    const request = get().applicationRequest
    const update = {...request, tags: value}
    set({applicationRequest: update})
  },
  setOTPReq: (value: number) => {
    const request = get().applicationRequest
    const update = {...request, otp: value}
    set({applicationRequest: update})
  },
  handleParseAnswer: (value: InterviewAnswers) => {
    const setInterviewReq = get().setInterviewReq
    const answers = Object.entries(value).sort((a, b) => a[1].order - b[1].order)
    const answersRequest = answers.map(item => ({
      question: item[0],
      answer: item[1].value,
    }))
    setInterviewReq(answersRequest)
  },
  setHandleSendApplication: handleSendApplication => set({handleSendApplication}),
  resetStore: () => {
    set({
      alertMessage: null,
      currentStep: 1,
      totalSteps: 4,
      selectedTags: [],
      questionList: [],
      applicationRequest: {
        distributor_name: null,
        email: null,
        telegram_id: null,
        interview: null,
        tags: null,
        otp: null,
      },
      interviewAnswers: {},
      handleSendApplication: null,
    })
  },
}))

export default useAffiliateStore
