import React, {CSSProperties, FC, useContext, useEffect, useState} from "react"
import ReactModal from "react-modal"
import {useWindowSize} from "react-use"
import cx from "classnames"

import s from "./alertmodal.module.scss"
import {ThemeInterface} from "types/Theme"
import {ThemeContext} from "context/ThemeContext"
import {getCustomColors} from "types/Theme/colors"

interface iAlertModal {
  message: React.ReactNode
  confirmLabel?: string
  className?: string
  msgClassName?: string
  alertImg?: any
  action?: () => void
}
interface CSSCustomProperties extends CSSProperties, ThemeInterface {}

const AlertModal: FC<iAlertModal> = ({
  action,
  message,
  confirmLabel,
  className,
  msgClassName,
  alertImg,
}: iAlertModal): JSX.Element => {
  const {theme} = useContext(ThemeContext)
  const custom_colors: CSSCustomProperties = getCustomColors(theme)
  const [isOpen, setIsOpen] = useState(false)
  const {height} = useWindowSize()
  useEffect(() => {
    document.body.style.overflow = `hidden`
    document.body.style.height = `100vh`
    setTimeout(() => {
      setIsOpen(true)
    }, 50)
    return () => document.body.removeAttribute(`style`)
  }, [])

  const handleClose = (): void => {
    setIsOpen(false)
    setTimeout(() => {
      action()
    }, 300)
  }

  return (
    <ReactModal
      ariaHideApp={false}
      className={s.container}
      id={`confirm-modal`}
      isOpen
      style={{
        content: {
          minHeight: `${height}px`,
        },
      }}
      overlayClassName={cx(s.overlay, {
        [s.show]: isOpen,
      })}
    >
      <div
        className={cx(s.modalWrapper, className, {
          [s.show]: isOpen,
        })}
        style={custom_colors}
      >
        {alertImg && (
          <div className={s.modalImage}>
            {/* <img src={alertImg} alt=""/> */}
            {/* <PendingIcon /> */}
            <video
              id="video_banner"
              src={theme.images.pendingVideo}
              className={s.fullWidthImage}
              autoPlay
              muted
              playsInline
              loop
            />
          </div>
        )}
        <div className={cx(s.modalMessage, msgClassName)}>
          <pre>{message}</pre>
        </div>
        {action && (
          <button onClick={() => handleClose()} className={s.modalConfirm}>
            {confirmLabel ? confirmLabel : `확인`}
          </button>
        )}
      </div>
    </ReactModal>
  )
}

export default AlertModal
