import React, {FC, memo} from "react"
import {Charge} from "types/Bet365/index"
import SingleBet from "./SingleBet"
import MultiBet from "./MultiBet"

interface iMarketDetails {
  charge: Charge
  className?: string
}

const MarketDetails: FC<iMarketDetails> = ({charge}) => {
  switch (charge.game.code) {
    case "greyhounds":
    case "horseracing":
      return <MultiBet charge={charge} />
    default:
      return <SingleBet charge={charge} />
  }
}

export default memo(MarketDetails)
