import shallow from "zustand/shallow"
import {REGEX_NUM_ONLY_PATTERN} from "screens/MyProfile/config"
import DefaultInputController from "screens/MyProfile/types/Input/DefaultInputController"
import useUsernameInputStore from "screens/MyProfile/screens/Affiliate/store/useUsernameInputStore"

const useUsernameInputController = (): DefaultInputController => {
  const {
    setValue,
    setError,
    setIsDirty,
    setIsTouched,
    setIsFocused,
    setIsValidPattern,
  } = useUsernameInputStore(
    store => ({
      setValue: store.setValue,
      setError: store.setError,
      setIsDirty: store.setIsDirty,
      setIsTouched: store.setIsTouched,
      setIsFocused: store.setIsFocused,
      setIsValidPattern: store.setIsValidPattern,
    }),
    shallow,
  )

  const handleValidate = (value: string) => {
    return value?.length > 0 && value?.length >= 9
  }

  const handleReset = () => {
    setValue("")
    setIsValidPattern(false)
    setIsDirty(false)
    setError({
      status_code: 0,
      message: "valid",
    })
  }

  const handleOnFocus = () => {
    setIsFocused(true)
    setIsTouched(true)
    handleReset()
  }

  const handleOnBlur = (value: string) => {
    setIsFocused(false)
    const isValid = handleValidate(value)
    if (!isValid) {
      setIsValidPattern(false)
      setIsDirty(true)
      setError({
        status_code: -1,
        message: "invalid",
      })
    } else {
      setIsValidPattern(true)
      setIsTouched(false)
      setError({
        status_code: 0,
        message: "valid",
      })
    }
  }

  const handleOnChange = (value: string) => {
    setIsDirty(true)
    const val = value.replaceAll(REGEX_NUM_ONLY_PATTERN, "")
    setValue(val)
    const isValid = handleValidate(val)
    if (isValid) {
      setIsValidPattern(true)
      setIsTouched(false)
      setError({
        status_code: 0,
        message: "match",
      })
    } else {
      setIsTouched(true)
      setIsValidPattern(false)
      setError({
        status_code: 0,
        message: "valid",
      })
    }
  }

  return {
    handleOnBlur,
    handleOnChange,
    handleOnFocus,
    handleValidate,
  }
}

export default useUsernameInputController
