import {
  AllIcon,
  SackDollarIcon,
  StarIcon,
  BombIcon,
  CrossHairIcon,
  ClassicIcon,
  All,
  TopRated,
  Favorites,
  Search,
  FireSolid,
} from "screens/Home/common/icons"

import GameType from "screens/Slots/types/GameType"
import GameFilter from "screens/Slots/types/GameFilter"

export const slotsItems: GameFilter[] = [
  {
    value: GameType.All,
    name: "전체게임",
    Icon: All,
  },
  {
    value: GameType.TopRated,
    name: "탑게임",
    Icon: TopRated,
  },
  {
    value: GameType.Favorites,
    name: "즐겨찾기",
    Icon: Favorites,
  },
  {
    value: GameType.Jackpot,
    name: "잭팟",
    customWidth: 56,
    Icon: SackDollarIcon,
  },
  {
    value: GameType.Video,
    name: "비디오슬롯",
    customWidth: 84,
    Icon: CrossHairIcon,
  },
  {
    value: GameType.Classic,
    name: "클래식",
    customWidth: 64,
    Icon: ClassicIcon,
  },
  {
    value: "search",
    name: "검색",
    Icon: Search,
  },
  {
    value: GameType.Recommended,
    name: "당신에게 추천된",
    Icon: FireSolid,
  },
]

export const PLACEHOLDER_DESCRIPTION = `온라인 원탑..! 에볼루션, 그 중에서도 높은 인기와 관심을 받는 스피드 바카라 를 소개 해드립니다. 총 52 장의 카드로 된 8개의 덱을 사용하여 진행되며 오리지널 바카라에 비해 게임당 평균 30초 를 넘지 않아 더욱 빠르게 뱅 커와 플레이어를 예측 및 결과를 즐길수 있습니다. 해당 테이블은 외국 딜러가 아닌 한국인 딜러와 함께 플레이 할수 있는 테이블 입니다. 이제 더욱 편하게, 더욱 쉽게, 딜러와 서로 채팅 및 공유 하며 실제 카지노에서 플레이 하는 듯한 기분을 느껴 보시기 바랍 니다.`

export const GameWithAnimation = [
  "PowerofThorMegaways",
  "SweetBonanza",
  "CaishensGold",
  "CurseoftheWerewolfMegaways",
  "DragonKingdom-EyesofFire",
  "EyeoftheStorm",
]

export const MOBILE_PAGE_SIZE = 8
export const PC_PAGE_SIZE = 32
export const MOBILE_LOAD_SIZE = 8
export const PC_LOAD_SIZE = 24
export const STALE_TIME = 6000000
export const ANIM_INTERVAL = 10
export const FADE_DELAY = 400
export const PC_SEARCH_BREAKPOINT = 1080

export const CASINO_QUERY_NAME = "GetCategorizedCasinoGames"
export const CATEGORIZED_QUERY_NAME = "GetCategorizedSlotGames"
export const FAVORITE_QUERY_NAME = "GetFavoriteSlotGames"
export const TOP_RATED_QUERY_NAME = "GetTopRatedSlotGames"
export const JACKPOT_QUERY_NAME = "GetJackpotSlotGames"
export const VIDEO_QUERY_NAME = "GetVideoSlotGames"
export const CLASSIC_QUERY_NAME = "GetClassicSlotGames"
export const RECENTLY_PLAYED_QUERY_NAME = "GetRecentlyPlayedSlotGames"
export const SEARCH_QUERY_NAME = "GetSearchSlotGames"
export const SLOTS_ALL_QUERIES = [
  CATEGORIZED_QUERY_NAME,
  FAVORITE_QUERY_NAME,
  TOP_RATED_QUERY_NAME,
  JACKPOT_QUERY_NAME,
  VIDEO_QUERY_NAME,
  CLASSIC_QUERY_NAME,
]

export const SEARCH_TAG_FILTERS = [
  "전체게임",
  "신규",
  "비디오슬롯",
  "클래식",
  "잭팟",
]

export const EDITABLE_SLOTS = [
  "888 Dragons",
  "Buffalo King Megaways",
  "Curse of the Werewolf Megaways",
  "Chilli Heat Megaways",
  "Diamonds are Forever 3 Lines",
  "Dragon Kingdom",
  "3 Kingdoms - Battle of Red Cliffs",
  "Power of Thor Megaways",
  "5 Lions",
  "5 Lions Gold",
  "7 Piggies",
  "Congo Cash",
  "Christmas Carol Megaways",
  "Caishen's Cash",
  "Caishen's Gold",
  "8 Dragons",
  "Cowboys Gold",
  "The Dragon Tiger",
  "Aztec Gems",
  "Aztec Gems Deluxe",
  "Aladdin and the Sorcerer",
  "Eye of the Storm",
  "Aztec Bonanza",
  "Ancient Egypt",
  "Big Bass Bonanza",
  "Bonanza Gold",
  "Ancient Egypt Classic",
  "Emerald King Rainbow Road",
  "Dance Party",
  "Diamond Strike",
  "Book Of Kingdoms",
  "Drago - Jewels of Fortune",
  "5 Lions Dance",
  "Buffalo King",
  "Dragon Kingdom - Eyes of Fire",
  "Hokkaido Wolf",
]
