const CATEGORY_TYPE_FIND_ID = "FIND_ID"
const CATEGORY_TYPE_FORGOT_PASSWORD = "FORGOT_PASSWORD"
const CATEGORY_TYPE_REGISTER_PLAYER = "REGISTER_PLAYER"
const CATEGORY_TYPE_CHANGE_MOBILE_NUMBER = "PLAYER_CHANGE_MOBILE_NUMBER"

export const categoryType = {
  findID: CATEGORY_TYPE_FIND_ID,
  forgotPassword: CATEGORY_TYPE_FORGOT_PASSWORD,
  registerPlayer: CATEGORY_TYPE_REGISTER_PLAYER,
  playerChangeMobileNumber: CATEGORY_TYPE_CHANGE_MOBILE_NUMBER,
}
//as of 04/26/2023 used modal messages
export const alertMessage = {
  findID: {
    ok: `인증번호가 전송되었습니다.`,
    blocked: `인증번호 요청 10회 초과 \n60분 후 재요청 해주시기 바랍니다.`,
    failed: `가입되어 있지 않거나 \n사용 할 수 없는 번호 입니다.`,
  },
  forgotPassword: {
    ok: `인증번호가 전송되었습니다.`,
    blocked: `인증번호 요청 10회 초과 \n60분 후 재요청 해주시기 바랍니다.`,
    failed: `가입되어 있지 않거나 \n사용 할 수 없는 번호 입니다.`,
  },
  registerPlayer: {
    ok: `인증번호가 전송되었습니다.`,
    blocked: `인증번호 요청 5 회 초과 \n10 분 후 재요청 해주시기 바랍니다.`,
    bannedUser: `현재 이용 할 수 없는 \n회원 입니다.`,
    failed: `사용할 수 없는 번호입니다.`,
  },
  playerChangeMobileNumber: {
    ok: `인증번호가 전송되었습니다.`,
  },
}
